import { useState, useEffect } from "react"
import { TextField, InputAdornment, IconButton } from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { useAppContext } from "./AppContext"
import { getTheme } from "../config"
import { isEmailValid } from "../services/helper"

const theme = getTheme()

export default function ChangePassword({ idPrefix = "", userEmail, userPassword, oldPassword, passwordLabel,
setUserPassword, setConfirmButton, passwordConfirmed, confirmPasswordLabel, setPasswordConfirmed, email, userProfile, hideConfirmPassword = false }) {
	const { state } = useAppContext()
	const { siteConfig } = state

	const [passwordValid, setPasswordValid] = useState(true)
	const [passwordConfirmValid, setPasswordConfirmValid] = useState(true)
	const [passwordBlurred, setPasswordBlurred] = useState(false)
	const [passwordConfirmationBlurred, setPasswordConfirmationBlurred] = useState(false)
	const [showPassword, setShowPassword] = useState({
		password: false,
		confirmPassword: false
	});

	const showHidePassword = (key) => setShowPassword({
		...showPassword,
		[key]: !showPassword[key]
	})

	useEffect(() => {
		if (userProfile || oldPassword) {
			setConfirmButton(
				oldPassword.length >= theme.validation.passwordLength &&
				theme.validation.passwordRegex.test(userPassword) &&
				(userPassword === passwordConfirmed ||
					siteConfig.confirmPassword === false || hideConfirmPassword)
			)
		} else if (email || userEmail) {
			setConfirmButton(
				isEmailValid(userEmail) &&
				theme.validation.passwordRegex.test(userPassword) &&
				(userPassword === passwordConfirmed ||
					siteConfig.confirmPassword === false || hideConfirmPassword)
			)
		} else {
			setConfirmButton(
				theme.validation.passwordRegex.test(userPassword) &&
				(userPassword === passwordConfirmed ||
					siteConfig.confirmPassword === false || hideConfirmPassword)
			)
		}
	})

	const onChangePassword = (e) => {
		setUserPassword(e.target.value)
		setPasswordValid( e.target.value.length >= theme.validation.passwordLength)
		if (passwordConfirmed?.length) setPasswordConfirmValid(e.target.value === passwordConfirmed)
	}

	const onChangePasswordConfirm = (e) =>  {
		setPasswordConfirmed(e.target.value)
		setPasswordConfirmValid(e.target.value === userPassword)
	}

	const passedAll = !theme.validation.passwordRequirements.filter(requirement => !requirement.regex.test(userPassword)).length

	return (
		<div className="flex flex-col items-center">
			<div className="mb-5 mt-2.5 w-64 sm:w-96">
				{passedAll ?
					<p
						className="text-left"
						style={{ color: theme.siteConfig.color.primary }}
					>
						{`\u2713 All password requirements have been met`}
					</p> :
					<>
						<p className="text-sm md:text-base text-left pb-5 pt-2 dark:text-white">
							Your password must meet the following
							requirements...
						</p>

						{theme.validation.passwordRequirements.map((requirement, i) => {
							let icon = `\u2022`
							const pass = requirement.regex.test(userPassword)
							if (pass) icon = `\u2713`
							return (
								<p className="text-sm md:text-base text-left"
									key={requirement.key + i}
									style={pass ? { color: theme.siteConfig.color.primary } : { color: theme.color.primary.dark_grey }}
								>
									{`${icon} ${requirement.label}`}
								</p>
							)
						})}
					</>
				}
			</div>

			<TextField
				onChange={(e) => onChangePassword(e)}
				onBlur={() => setPasswordBlurred(true)}
				value={userPassword}
				label={userPassword === '' ? null : passwordLabel}
				placeholder={passwordLabel}
				id={`${idPrefix}PasswordInput`}
				variant="standard"
				type={showPassword.password ? "text" : "password"}
				className="w-64 mx-2.5 sm:w-96 sm:px-12"
				error={!passwordValid && passwordBlurred}
				helperText={!passwordValid && passwordBlurred ? "Password does not meet the requirements" : ""}
				InputProps={{
					autoComplete: "new-password",
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => showHidePassword('password')}
								onMouseDown={() => showHidePassword('password')}
							>
								{showPassword.password ? <VisibilityIcon /> : <VisibilityOffIcon />}
							</IconButton>
						</InputAdornment>
					)
				}}
			/>

			{userPassword.length > 7 && !hideConfirmPassword &&
				<TextField
					onChange={(e) => onChangePasswordConfirm(e)}
					onBlur={() => setPasswordConfirmationBlurred(true)}
					value={passwordConfirmed}
					label={passwordConfirmed === '' ? null : confirmPasswordLabel}
					placeholder={confirmPasswordLabel}
					id={`${idPrefix}ConfirmPasswordInput`}
					error={!passwordConfirmValid && passwordConfirmationBlurred}
					helperText={!passwordConfirmValid && passwordConfirmationBlurred ? "Password does not match" : ""}
					variant="standard"
					type={showPassword.confirmPassword ? "text" : "password"}
					className="w-64 sm:w-96"
					style={{ marginTop: 16 }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => showHidePassword('confirmPassword')}
									onMouseDown={() => showHidePassword('confirmPassword')}
								>
									{showPassword.confirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			}

			{/* <div>
				<p className="mt-3 text-sm text-red-500">
					{!passwordConfirmValid && userPassword.length > 7 && "Password does not match"}
				</p>
			</div> */}
		</div>
	)
}
