import { useReducer, useMemo } from "react"
import { AppContext, defaultState } from "./"
import { reducer } from "./AppReducer"

export const AppProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, defaultState, init)

	const contextValue = useMemo(() => {
		return { state, dispatch }
	}, [state, dispatch])

	return (
		<AppContext.Provider value={contextValue}>
			{children}
		</AppContext.Provider>
	)
}

function init(initialArg) {
	return initialArg
}
