import axios from 'axios'
import { getTheme } from '../config'
import { state as ventiState } from 'venti'
import PublishSubscribe from "publish-subscribe-js"

const theme = getTheme()

const host = process.env.NODE_ENV === "development"
	? theme.api.host.dev
	: theme.api.host.production

const host2 = process.env.NODE_ENV === "development"
	? theme.api.host2.dev
	: theme.api.host2.production

axios.interceptors.response.use(function (response) {
	return response
}, function (error) {
	if (error?.response?.status === 401) {
		PublishSubscribe.publish("LOG_OUT_USER")
	}

	return Promise.reject(error)
})

/**
 * Set Authorization header with token from passed param or storage
 * @param accessToken
 * @return {{headers: {Authorization: string}}}
 * @private
 */
const _setAuthHeader = accessToken => ({ headers: { 'Authorization': `Bearer ${accessToken || ventiState.get(theme.storageKeys.authToken)}` } })

/**
 * @param {string} userName
 * @param {string} password
 * @param {string} id
 * @returns {Promise}
 */
export function authenticate(userName, password, id) {
	return post(
		`${host}/token`,
		{
			userName,
			password,
			siteConfigurationId: id
		},
	)
}

/**
 * Create new user account
 * @param {{branchId: unknown, firstName: string, lastName: string, password: string, phone: string, siteConfigurationId, sendNotification: boolean, title: string, email: string}} data
 * @param {string} data.siteConfigurationId
 * @param {string} data.email
 * @param {string} data.password
 * @param {string} data.phone
 * @param {string} data.firstName
 * @param {string} data.lastName
 * @param {string} data.title
 * @param {string} data.inviteToken
 * @returns {Promise}
 */
export function registerBorrower(data) {
	return post(
		`${host}/api/user/register`,
		data,
		_setAuthHeader()
	)
}

/** Resend registration code
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.siteConfigurationId
 * @returns {Promise}
 */
export function resendRegistrationCode(data) {
	return post(
		`${host}/api/user/resendregistrationcode`,
		data,
		_setAuthHeader()
	)
}

/**
 * Confirm new user accounts following create account
 *  @param {Object} data
 */
export function verifyRegistration(data) {
	return post(
		`${host}/api/user/verifyregistration`,
		data,
		_setAuthHeader()
	)
}

/**
 * @deprecated
 * @param {object} data
 * @param {string} password
 * @returns {Promise}
 */
export function registerAccount(data, password) {
	const body = { Email: data.email, Password: password, ConfirmPassword: password }
	return post(
		`${host}/api/customer/account/RegisterAccount`,
		body
	)
}

/**
 * @param email
 * @param siteConfigId
 */
export function sendForgotPasswordEmail(email, siteConfigId) {
	return post(
		`${host}/api/user/forgotpassword`,
		{
			email,
			siteConfigurationId: siteConfigId
		}
	)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function registerLoanOfficer({ AccountID = '', SiteConfigurationID = '', Email = '', Password = '', Phone = '', FirstName = '', LastName = ''}) {
	return post(
		`${host}/api/users/registerLoanOfficer`,
		{ AccountID, SiteConfigurationID, Email, Password, ConfirmPassword: Password, Phone, FirstName, LastName },
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function registerUser(data) {
	return post(
		`${host}/api/user/register`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function createUser(data) {
	return post(
		`${host}/api/user`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function updateUser(data) {
	return put(
		`${host}/api/user/${data.id}`,
		data,
		_setAuthHeader()
	)
}

/**
 * Get authenticated user data
 * @returns {Promise}
 */
export function getMe(authToken) {
	return get(
		`${host}/api/user/me`,
		{ headers: { 'Authorization': `Bearer ${authToken}` } }
	)
}

/**
 * Send E-Consent
 * @param loanId
 * @param siteConfigurationID
 * @returns {Promise}
 */
export function sendEConsent({ loanId = '', siteConfigurationId = '' }) {
	return post(
		`${host}/api/los/loan/econsent`,
		{ loanId, siteConfigurationId },
		_setAuthHeader()
	)
}

/**
 * Send Credit Auth
 * @param loanId
 * @returns {Promise}
 */
export function sendCreditAuth({ loanId = '' }) {
	return post(
		`${host}/api/los/loan/creditauth`,
		{ loanId },
		_setAuthHeader()
	)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function deleteUser(id) {
	return remove(
		`${host}/api/user/${id}`,
		_setAuthHeader()
	)
}

/**
 * @param {string} oldPassword
 * @param {string} newPassword

 * @returns {Promise}
 */
export function changePassword(oldPassword, newPassword) {
	return post(
		`${host}/api/user/changepassword`,
		{ oldPassword, newPassword },
		_setAuthHeader()
	)
}

/**
 * Get customer data
 * @param {string} id
 * @returns {Promise}
 */
export function getCustomer(id) {
	return get(
		`${host}/api/customers/${id}`,
		_setAuthHeader()
	)
}

/**
 * Update existing customer
 * @param {object} data
 * @returns {Promise}
 */
export function updateCustomer(data) {
	return patch(
		`${host}/api/customers/${data.ID}`,
		data,
		_setAuthHeader()
	)
}

/**
 * Get all loan fields available
 * @returns {Promise}
 */
export function getAllLoanFields() {
	return get(
		`${host}/api/los/system/loanfields`,
		_setAuthHeader()
	)
}

export function getAllDocumentBuckets() {
	return get(
		`${host}/api/los/system/documentbuckets`,
		_setAuthHeader()
	)
}

export function test() {
	return get(`http://mortgagepeepshow.com/api/loanofficer/Marvin_Rosenberg`)
}

/**
 * Get users

 * @returns {Promise}
 */
export function getUsers(includeDeleted = false) {
	return get(
		`${host}/api/user${includeDeleted ? '?showAll=true' : ''}`,
		_setAuthHeader()
	)
}

/**
 * Get user relations
 * @returns {Promise}
 */
export function getUserRelations(id) {
	return get(
		`${host}/api/user/${id}/relation`,
		_setAuthHeader()
	)
}

/**
 * Create user relation
 * @returns {Promise}
 */
export function createUserRelation(userId1, userId2, relationType) {
	return post(
		`${host}/api/user/relation`,
		{
			userId1,
			userId2,
			relationType
		},
		_setAuthHeader()
	)
}

/**
 * Create user relation
 * @returns {Promise}
 */
export function removeUserRelation(id) {
	return remove(`${host}/api/user/relation/${id}`, _setAuthHeader())
}

/**
 * Undelete user
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteUser(id) {
	return post(
		`${host}/api/user/${id}/undelete`,
		null,
		_setAuthHeader()
	)
}

/**
 * Save loan draft
 * @param {object} data
 * @returns {Promise}
 */
export function saveLoanDraft(data) {
	if (data.applicationPayload.draftId) {
		return put(
			`${host}/api/los/loan/drafts/${data.applicationPayload.draftId}`,
			data,
			_setAuthHeader()
		)
	}

	return post(
		`${host}/api/los/loan/drafts`,
		data,
		_setAuthHeader()
	)
}

/**
 * Get loan drafts
 * @returns {Promise}
 */
export function getLoanDrafts() {
	return get(
		`${host}/api/los/loan/drafts`,
		_setAuthHeader()
	)
}

/**
 * Get loan draft
 * @param {string} id
 * @returns {Promise}
 */
export function getLoanDraft(id) {
	return get(
		`${host}/api/los/loan/drafts/${id}`,
		_setAuthHeader()
	)
}

/**
 * Delete loan draft (permanent)
 * @param id
 * @returns {Promise}
 */
export function deleteLoanDraft(id) {
	return remove(
		`${host}/api/los/loan/drafts/${id}`,
		_setAuthHeader()
	)
}

/**
 * Get loans
 * @returns {Promise}
 */
export function getLoans() {
	return get(
		`${host}/api/los/loan`,
		_setAuthHeader()
	)
}

/**
 * Get loan data
 * @param {string} id
 * @returns {Promise}
 */
export function getLoan(id) {
	return get(
		`${host}/api/los/loan/application/${id}`,
		_setAuthHeader()
	)
}

/**
 * Run report
 * @returns {Promise}
 */
export function getReport(data) {
	return post(
		`${host}/api/los/loan/reports`,
		data,
		_setAuthHeader()
	)
}

/**
 * Run LO Loan Calculation
 * @param {Object} data
 * @returns {Promise}
 */
export function runLOCalculation(data) {
	return post(
		`${host}/api/los/loan/localculation`,
		data,
		_setAuthHeader()
	)
}

/**
 * Get LO Loan Calculation
 * @param {string} id
 * @returns {Promise}
 */
export function getLOCalculation(id) {
	return get(
		`${host}/api/los/loan/localculation/${id}`,
		_setAuthHeader()
	)
}

export function getLoanComparisons(loanID) {
	return get(
		`${host}/api/los/loan/loan-comparison/${loanID}`,
		_setAuthHeader()
	)
}

export function saveLoanComparison(loanID, index, data) {
	return post(
		`${host}/api/los/loan/loan-comparison/${loanID}/${index}`,
		data,
		_setAuthHeader()
	)
}

export function saveLoanComparisonPdf(loanID, data) {
	return post(
		`${host}/api/los/loan/loan-comparison/pdf/${loanID}`,
		data,
		_setAuthHeader()
	)
}

export function deleteLoanComparison(loanID, index) {
	return remove(
		`${host}/api/los/loan/loan-comparison/${loanID}/${index}`,
		_setAuthHeader()
	)
}

/**
 * Get loan tasks
 * @param {string} id
 * @returns {Promise}
 */
export function getLoanTasks(id) {
	return get(
		`${host}/api/los/loan/tasks/${id}`,
		_setAuthHeader()
	)
}

/**
 * Post loan task field
 * @param {object} data
 * @returns {Promise}
 */
export function postTaskField(data) {
	return post(
		`${host}/api/los/loan/tasks/fielddata`,
		data,
		_setAuthHeader()
	)
}

/**
 * Post loan task document
 * @param {object} data
 * @returns {Promise}
 */
export function postTaskDocument(data) {
	return post(
		`${host}/api/los/loan/tasks/fielddata`,
		data,
		_setAuthHeader()
	)
}

/**
 * Get rules
 * @returns {Promise}
 */
export function getBusinessRules(includeDeleted = false) {
	return get(
		`${host}/api/customer/businessrule${includeDeleted ? '?showAll=true' : ''}`,
		_setAuthHeader()
	)
}

/**
 * Get rule by id
 * @returns {Promise}
 */
export function getBusinessRule(id) {
	return get(
		`${host}/api/customer/businessrule/${id}`,
		_setAuthHeader()
	)
}

/**
 * Create rule
 * @param {object} data
 * @returns {Promise}
 */
export function createBusinessRule(data) {
	return post(
		`${host}/api/customer/businessrule`,
		data,
		_setAuthHeader()
	)
}

/**
 * Update rule
 * @param {object} data
 * @returns {Promise}
 */
export function updateBusinessRule(data) {
	return put(
		`${host}/api/customer/businessrule/${data.id}`,
		data,
		_setAuthHeader()
	)
}

/**
 * Delete rule
 * @param {string} id
 * @returns {Promise}
 */
export function removeBusinessRule(id) {
	return remove(
		`${host}/api/customer/businessrule/${id}`,
		_setAuthHeader()
	)
}

/**
 * Undelete rule
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteBusinessRule(id) {
	return post(
		`${host}/api/customer/businessrule/${id}/undelete`,
		_setAuthHeader()
	)
}

/**
 * Get forms by Site Config
 * @param {string} siteConfigurationId
 * @param {number=} formType
 * @param {string=} slug
 */
export function getFormsBySiteConfig({ siteConfigurationId, formType, slug }) {
	return post(
		`${host}/api/siteForm`,
		{
			siteConfigurationId,
			formType,
			slug
		}
	)
}

/**
 * Submit a form that is not a loan
 * @param data
 * @returns {Promise}
 */
export function submitForm(data) {
	return post(
		`${host2}/forms/${data.formName}/submissions`,
		data
	)
}

/**
 * @returns {Promise}
 */
export function getRealtorApplications() {
	return get(
		`${host}/api/customer/realtor/applications`,
		_setAuthHeader()
	)
}

/**
 * @returns {Promise}
 */
export function getSettlementAgentApplications() {
	return get(
		`${host}/api/customer/settlementagent/applications`,
		_setAuthHeader()
	)
}

/**
 * Create a new loan application
 * @param {object} data
 * @param {string} accessToken
 * @returns {Promise}
 */
export function createLoanApplication(data, accessToken = '') {
	return post(
		`${host}/api/los/loan/application`,
		data,
		_setAuthHeader(accessToken)
	)
}

/**
 * Update a loan application
 * @param data
 * @returns {Promise}
 */
export function updateLoanApplication(id, data) {
	return patch(
		`${host}/api/los/loan/application/${id}`,
		data,
		_setAuthHeader()
	)
}

/**
 * Add a document to a loan task
 * @param {object} data
 * @returns {Promise}
 */
export function addLoanTaskDocument(data) {
	return post(
		`${host}/api/los/loan/tasks/documents`,
		data,
		_setAuthHeader()
	)
}

/**
 * Get loan documents associated with a loan
 * @param {string} id
 * @returns {Promise}
 */
export function getLoanDocuments(id) {
	return get(
		`${host}/api/los/loan/tasks/documents/${id}`,
		_setAuthHeader()
	)
}

export function getLoanDocumentContentUrl({ loanId, documentId, contentType = 'binary' }) {
	return `${host}/api/los/loan/${loanId}/document/${documentId}/content?contentType=${contentType}&accessToken=${ventiState.get(theme.storageKeys.authToken)}`
}

/**
 * Add a condition to a loan
 * @param {string} type
 * @param {object} data
 * @returns {Promise}
 */
export function addLoanCondition(type, data) {
	return post(
		`${host}/api/los/loan/condition/${type}`,
		data,
		_setAuthHeader()
	)
}

/**
 * Get a mortgage insurance quote for a loan
 * @param {string} id
 * @returns {Promise}
 */
export function getMortgageInsuranceQuote(id) {
	return get(
		`${host}/api/users`,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function getLoanPricing(data) {
	return post(
		`${host}/api/los/pricing/calculator`,
		data
	)
}

/**
 * Get disclosure package information
 * @param {string} loanId
 * @param {string} email
 * @returns {Promise}
 */
export function getDisclosurePackageInfo(loanId, email) {
	return post(
		`${host}/api/los/loan/disclosureinfo`,
		{
			BorrowerEmail: email,
			LoanID: loanId
		},
		_setAuthHeader()
	)
}

/**
 * @returns {Promise}
 */
export function getCustomerSiteConfigurations() {
	return get(
		`${host}/api/customer/account/siteconfiguration`,
		_setAuthHeader()
	)
}

/**
 * @returns {Promise}
 */
export function getAccount() {
	return get(
		`${host}/api/customer/account/siteconfiguration`,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function updateAccount(data) {
	return put(
		`${host}/api/customer/account/siteconfiguration`,
		data,
		_setAuthHeader()
	)
}

/**
 * @returns {Promise}
 */
export function getCorporations(includeDeleted = false) {
	return get(
		`${host}/api/customer/corporate${includeDeleted ? '?showAll=true' : ''}`,
		_setAuthHeader()
	)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function deleteCorporate(id) {
	return remove(
		`${host}/api/customer/corporate/${id}`,
		_setAuthHeader()
	)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteCorporate(id) {
	return post(
		`${host}/api/customer/corporate/${id}/undelete`,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function createCorporation(data) {
	return post(
		`${host}/api/customer/corporate`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @param {string} corporateId
 * @returns {Promise}
 */
export function createCorporationSiteConfiguration(data, corporateId) {
	return post(
		`${host}/api/customer/corporate/${corporateId}/siteconfiguration`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @param {string} corporateId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function updateCorporationSiteConfiguration(data, corporateId, siteConfigurationId) {
	return put(
		`${host}/api/customer/corporate/${corporateId}/siteconfiguration/${siteConfigurationId}${data.updateDownstream ? "?applyToChildren=true" : ""}`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param {string} corporateId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function getCorporationSiteConfiguration(corporateId, siteConfigurationId) {
	return get(
		`${host}/api/customer/corporate/${corporateId}/siteconfiguration/${siteConfigurationId}`,
		_setAuthHeader()
	)
}

/**
 * @returns {Promise}
 */
export function getLoanOfficers(includeDeleted = false) {
	return get(
		`${host}/api/customer/loanofficer${includeDeleted ? '?showAll=true' : ''}`,
		_setAuthHeader()
	)
}

/**
 * @param {string} id
 * @param {string} accessToken
 * @returns {Promise}
 */
export function getLoanOfficer(id, accessToken) {
	return get(
		`${host}/api/customer/loanofficer/${id}`,
		_setAuthHeader(accessToken)
	)
}

/**
 * @param {string} loanOfficerId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function getLoanOfficerSiteConfiguration(loanOfficerId, siteConfigurationId) {
	return get(
		`${host}/api/customer/loanofficer/${loanOfficerId}/siteconfiguration/${siteConfigurationId}`,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @param {string} loanOfficerId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function updateLoanOfficerSiteConfiguration(data, loanOfficerId, siteConfigurationId) {
	return put(
		`${host}/api/customer/loanofficer/${loanOfficerId}/siteconfiguration/${siteConfigurationId}`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @param {string} loanOfficerId
 * @returns {Promise}
 */
export function createLoanOfficerSiteConfiguration(data, loanOfficerId) {
	return post(
		`${host}/api/customer/loanofficer/${loanOfficerId}/siteconfiguration`,
		data,
		_setAuthHeader()
	)
}

/**
 * Get forms
 * @returns {Promise}
 */
export function getForms(includeDeleted = false) {
	return get(
		`${host}/api/form${includeDeleted ? '?showAll=true' : ''}`,
		_setAuthHeader()
	)
}

/**
 * Get form
 * @param {string} id
 * @returns {Promise}
 */
export function getForm(id) {
	return get(
		`${host}/api/form/${id}`,
		_setAuthHeader()
	)
}

/**
 * Create or update form
 * @param {object} data
 * @returns {Promise}
 */
export function saveForm(data) {
	return !data.id ? post(
		`${host}/api/form`,
		data,
		_setAuthHeader()
	) : put(
		`${host}/api/form/${data.id}`,
		data,
		_setAuthHeader()
	)
}

/**
 * Delete form
 * @param {string} id
 * @returns {Promise}
 */
export function deleteForm(id) {
	return remove(
		`${host}/api/form/${id}`,
		_setAuthHeader()
	)
}

/**
 * Undelete form
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteForm(id) {
	return post(
		`${host}/api/form/${id}/undelete`,
		null,
		_setAuthHeader()
	)
}

/**
 * Get branches
 * @returns {Promise}
 */
export function getBranches(includeDeleted = false) {
	return get(
		`${host}/api/customer/branch${includeDeleted ? '?showAll=true' : ''}`,
		_setAuthHeader()
	)
}

/**
 * Delete branch
 * @param {string} id
 * @returns {Promise}
 */
export function deleteBranch(id) {
	return remove(
		`${host}/api/customer/branch/${id}`,
		_setAuthHeader()
	)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteBranch(id) {
	return post(
		`${host}/api/customer/branch/${id}/undelete`,
		_setAuthHeader()
	)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function getBranch(id) {
	return get(
		`${host}/api/customer/branch/${id}`,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function createBranch(data) {
	return post(
		`${host}/api/customer/branch`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function updateBranch(data) {
	return put(
		`${host}/api/customer/branch/${data.id}`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @param {string} branchId
 * @returns {Promise}
 */
export function createBranchSiteConfiguration(data, branchId) {
	return post(
		`${host}/api/customer/branch/${branchId}/siteconfiguration`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @param {string} branchId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function updateBranchSiteConfiguration(data, branchId, siteConfigurationId) {
	return put(
		`${host}/api/customer/branch/${branchId}/siteconfiguration/${siteConfigurationId}${data.updateDownstream ? "?applyToChildren=true" : ""}`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param {string} branchId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function getBranchSiteConfiguration(branchId, siteConfigurationId) {
	return get(
		`${host}/api/customer/branch/${branchId}/siteconfiguration/${siteConfigurationId}`,
		_setAuthHeader()
	)
}

/**
 * @returns {Promise}
 */
export function getPartners(role = 'realtor', includeDeleted = false) {
	return get(
		`${host}/api/customer/${role}${includeDeleted ? '?showAll=true' : ''}`,
		_setAuthHeader()
	)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function getPartner(id) {
	return get(
		`${host}/api/customer/realtor/${id}`,
		_setAuthHeader()
	)
}

/**
 * @param {string} realtorId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function getPartnerSiteconfiguration(realtorId, siteConfigurationId) {
	return get(
		`${host}/api/customer/realtor/${realtorId}/siteconfiguration/${siteConfigurationId}`,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @param {string} realtorId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function updatePartnerSiteconfiguration(data, realtorId, siteConfigurationId) {
	return put(
		`${host}/api/customer/realtor/${realtorId}/siteconfiguration/${siteConfigurationId}`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @param {string} realtorId
 * @returns {Promise}
 */
export function createPartnerSiteconfiguration(data, realtorId) {
	return post(
		`${host}/api/customer/realtor/${realtorId}/siteconfiguration`,
		data,
		_setAuthHeader()
	)
}


/**
 * @param {string} id
 * @returns {Promise}
 */
export function deletePartner(id) {
	return remove(
		`${host}/api/user/${id}`,
		_setAuthHeader()
	)
}

/**
 * @returns {Promise}
 */
export function getBorrowerApplications() {
	return get(
		`${host}/api/los/loan/application/borrower`,
		_setAuthHeader()
	)
}

/**
 * @param {uuid} id
 * @returns {Promise}
 */
export function getAccountApplications(id) {
	return get(
		`${host}/api/customer/account/applications/${id}`,
		_setAuthHeader()
	)
}

/**
 * @param {uuid} id
 * @returns {Promise}
 */
export function getCorporateApplications(id) {
	return get(
		`${host}/api/customer/corporate/applications/${id}`,
		_setAuthHeader()
	)
}

/**
 * @param {uuid} id
 * @returns {Promise}
 */
export function getBranchApplications(id) {
	return get(
		`${host}/api/customer/branch/applications/${id}`,
		_setAuthHeader()
	)
}

/**
 * @returns {Promise}
 */
export function getLoanOfficerApplications() {
	return get(
		`${host}/api/customer/loanofficer/applications`,

		_setAuthHeader()
	)
}

/**
 * @param {string} email
 * @returns {Promise}
 */
export function getAdminLoanOfficerApplications(email) {
	return post(
		`${host}/api/customer/loanofficer/applications`,
		{
			loanOfficerEmail: email
		},
		_setAuthHeader()
	)
}

export function getCorporateLoanOfficers(id, sendToken = false) {
	return get(`${host}/api/customer/corporate/${id}/loanofficers`, sendToken ? _setAuthHeader() : null)
}

export function getBranchLoanOfficers(id) {
	return get(`${host}/api/customer/branch/${id}/loanofficers`)
}

/**
 * @returns {Promise}
 */
export function getSurveys() {
	return get(`${host}/api/survey`)
}

/**
 * @param {String} email
 * @returns {Promise}
 */
export function getSurveysByEmail(email) {
	return post(
		`${host}/api/survey`,
		{
			loanOfficerEmailAddress: email
		}
	)
}

/**
 * @returns {Promise}
 */
export function getRequestsQueue() {
	return get(
		`${host}/api/requestqueue`,
		_setAuthHeader()
	)
}

/**
 * Delete request from queue
 * @param {string} id
 * @returns {Promise}
 */
export function deleteRequestQueue(id) {
	return remove(
		`${host}/api/requestqueue/${id}`,
		_setAuthHeader()
	)
}

/**
 * @param {uuid} id
 * @returns {Promise}
 */
export function runQueuedTask(id) {
	return post(
		`${host}/api/requestqueue/run/${id}`,
		null,
		_setAuthHeader()
	)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function createSiteConfiguration(data) {
	return post(
		`${host}/api/customer/siteconfiguration`,
		data,
		_setAuthHeader()
	)
}

/**
 * Get site configuration by url
 * @param {string} url
 * @returns {Promise}
 */
export function getSiteConfigurationByUrl(url) {
	if (url === 'localhost' || url.includes('ngrok-free')) url = theme.api._localhostAlias
	return post(
		`${host}/api/customer/siteconfiguration/url`,
		{
			url,
		},
		_setAuthHeader()
	)
}

/**
 * Send borrower invitation
 * @param {object} data
 * @returns {Promise}
 */
export function sendBorrowerInvitation(data) {
	return post(
		`${host}/api/user/invite`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param token
 * @returns {Promise}
 */
export function verifyBorrowerInvitation(token) {
	return get(`${host}/api/user/invite/${token}/verify`)
}

/**
 * Forgot Password
 * @param data
 * @returns {Promise}
 */
export function verifyUserResetPassword(data) {
	return post(`${host}/api/user/verifypassword`,
		data,
		_setAuthHeader()
	)
}

/**
 * @param data
 * @returns {Promise}
 */
export function verify(data) {
	return post(`${host}/api/verification/verify`,
		data,
		_setAuthHeader()
	)
}

export function getEmbeddedSigningUrl({ envelopeId, firstName, lastName, email }) {
	return post(
		`${host}/api/los/loan/embeddedsigning/${envelopeId}/${firstName}%20${lastName}/${email}`,
		{
			envelopeID: envelopeId,
			userName: `${firstName}%20${lastName}`,
			email
		},
		_setAuthHeader())
}

/**
 * @param {string} id
 * @param {string} password
 * @returns {Promise}
 */
export function updateUserPassword(id, password) {
	return post(
		`${host}/api/user/${id}/overridepassword`,
		{ password },
		_setAuthHeader()
	)
}

/**
 * @param {string} url
 * @param {object} config
 * @returns {Promise}
 */
export function get(url, config = {}) {
	return axios.get(url, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function post(url, data, config = {}) {
	return axios.post(url, data, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function put(url, data, config = {}) {
	return axios.put(url, data, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function patch(url, data, config = {}) {
	return axios.patch(url, data, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} config
 * @returns {Promise}
 */
export function remove(url, config) {
	return axios.delete(url, config).then(response => response?.data).catch(error => Promise.reject(error.response.data))
}

/**
 * Get notification templates
 * @returns {Promise}
 */
export function getNotificationTemplates() {
	return get(
		`${host}/api/notification/template`,
		_setAuthHeader()
	)
}

/**
 * Get notification template
 * @param {string} id
 * @returns {Promise}
 */
export function getNotificationTemplate(id = '') {
	return get(
		`${host}/api/notification/template/${id}`,
		_setAuthHeader()
	)
}

/**
 * Save notification template
 * @param {object} data
 * @returns {Promise}
 */
export function saveNotificationTemplate(data) {
	return !data.id ?
		post(
			`${host}/api/notification/template`,
			data,
			_setAuthHeader()
		)
		:
		put(
			`${host}/api/notification/template/${data.id}`,
			data,
			_setAuthHeader()
		)
}

/**
 * Send test notification
 * @param {object} data
 * @returns {Promise}
 */
export function sendTestNotification(data) {
	return post(
		`${host}/api/notification/test`,
		data,
		_setAuthHeader()
	)
}

/**
 * @returns {Promise}
 */
export function getDocumentTemplates(includeDeleted = false) {
	return get(
		`${host}/api/document/template${includeDeleted ? '?showAll=true' : ''}`,
		_setAuthHeader()
	)
}

/**
 * Get document templates by type
 * @param {string} type
 * @returns {Promise}
 */
export function getDocumentTemplatesByType(type) {
	return get(`${host}/api/document/template/${type}`, _setAuthHeader())
}

/**
 * Get document template
 * @param {string} id
 * @returns {Promise}
 */
export function getDocumentTemplate(id = '') {
	return get(
		`${host}/api/document/template/${id}`,
		_setAuthHeader()
	)
}

/**
 * Save document template
 * @param {object} data
 * @returns {Promise}
 */
export function saveDocumentTemplate(data) {
	return !data.id ?
		post(
			`${host}/api/document/template`,
			data,
			_setAuthHeader()
		)
		:
		put(
			`${host}/api/document/template/${data.id}`,
			data,
			_setAuthHeader()
		)
}

/**
 * Delete document template
 * @param {string} id
 * @returns {Promise}
 */
export function deleteDocumentTemplate(id) {
	return remove(
		`${host}/api/document/template/${id}`,
		_setAuthHeader()
	)
}

/**
 * Undelete document template
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteDocumentTemplate(id) {
	return post(
		`${host}/api/document/template/${id}/undelete`,
		_setAuthHeader()
	)
}

/**
 * Generate document from template
 * @param {object} data
 * @returns {Promise}
 */
export function generateDocumentFromTemplate(data) {
	return post(
		`${host}/api/los/loan/generateDocument`,
		data,
		_setAuthHeader()
	)
}

/**
 * Generate disclosures SSO login url
 * @param {object} siteConfig
 * @param {string} token
 * @returns {Promise}
 */
export function generateDisclosuresSSOLoginUrl(siteConfig, token) {
	return post(
		`${host}/ssoUrl`,
		{
			siteConfigurationId: siteConfig.id,
			redirectUri: siteConfig.disclosuresSSOLoginRedirectUri
		},
		_setAuthHeader(token)
	)
}

/**
 * Generate disclosures SSO logout url
 * @param {object} siteConfig
 * @param {string} idToken
 * @returns {Promise}
 */
export function generateDisclosuresSSOLogoutUrl(siteConfig, idToken = null ) {
	return post(
		`${host}/logoutUrl`,
		{
			siteConfigurationId: siteConfig.id,
			redirectUri: siteConfig.disclosuresSSOLogoutRedirectUri,
			idToken
		})
}

/**
 * Get milestone configurations
 * @returns {Promise}
 */
export function getMilestoneConfigurations( ) {
	return get(
		`${host}/api/milestones`,
		_setAuthHeader()
	)
}

/**
 * Create milestone configurations
 * @param {object} data
 * @returns {Promise}
 */
export function createMilestoneConfiguration(data) {
	return post(
		`${host}/api/milestones`,
		data,
		_setAuthHeader()
	)
}

/**
 * Update milestone configurations
 * @param {string }id
 * @param {object} data
 * @returns {Promise}
 */
export function updateMilestoneConfiguration(id, data) {
	return put(
		`${host}/api/milestones/${id}`,
		data,
		_setAuthHeader()
	)
}

/**
 * Delete milestone configuration
 * @param {string} id
 * @returns {Promise}
 */
export function deleteMilestoneConfiguration(id) {
	return remove(
		`${host}/api/milestones/${id}`,
		_setAuthHeader()
	)
}

/**
 * Get leads bt site config
 * @param {string} id
 * @returns {Promise}
 */
export function getLeadsBySiteConfig(id) {
	return get(`${host2}/siteConfigurations/${id}/formSubmissions`, _setAuthHeader())
}

/**
 * Get leads by LO
 * @param {string} id
 * @returns {Promise}
 */
export function getLeadsByLoanOfficer(id) {
	return get(`${host2}/loanOfficers/${id}/formSubmissions`, _setAuthHeader())
}

/**
 * Get lead
 * @param {string} id
 * @returns {Promise}
 */
export function getLead(id) {
	return get(`${host2}/formSubmissions/${id}`, _setAuthHeader())
}

/**
 * Delete lead
 * @param {string} id
 * @returns {Promise}
 */
export function deleteLead(id) {
	return remove(`${host2}/formSubmissions/${id}`, _setAuthHeader())
}

/**
 * Create lead
 * @param {string} formName
 * @param {object} data
 * @returns {Promise}
 */
export function createLead(formName, data) {
	return post(
		`${host2}/forms/${formName}/submissions`,
		data,
		_setAuthHeader()
	)
}

/**
 * Update lead
 * @param {object} data
 * @returns {Promise}
 */
export function updateLead(data) {
	return patch(`${host2}/formSubmissions/${data.ID}`,
		data,
		_setAuthHeader()
	)
}

/**
 * Get open houses by site config
 * @param {string} id
 * @param {string} email
 * @returns {Promise}
 */
export function getOpenHousesBySiteConfig(id, email) {
	const config = _setAuthHeader();
	config.params = {
		Email: email,
		FormName: 'OpenHouse'
	}

	return get(`${host2}/siteConfigurations/${id}/formSubmissions`, config)
}

/**
 * Get open houses by LO
 * @param {string} id
 * @param {string} email
 * @returns {Promise}
 */
export function getOpenHousesByLoanOfficer(id, email) {
	const config = _setAuthHeader();
	config.params = {
		Email: email,
		FormName: 'OpenHouse'
	}
	return get(`${host2}/loanOfficers/${id}/formSubmissions`, config)
}

/**
 * Get devices
 * @param {string} accountId
 * @returns {Promise}
 */
export function getDevices(accountId) {
	return get(`${host2}/devices?posAccountId=${accountId}`, _setAuthHeader())
}

/**
 * Get device
 * @param {string} id
 * @returns {Promise}
 */
export function getDevice(id) {
	return get(`${host2}/devices/${id}`, _setAuthHeader())
}

/**
 * Update device
 * @param {object} data
 * @returns {Promise}
 */
export function updateDevice(data) {
	return patch(`${host2}/devices/${data.id}`, data, _setAuthHeader())
}

/**
 * Get device by serial number
 * @param sn
 * @returns {Promise}
 */
export function getDeviceBySerialNumber(sn) {
	return get(`${host2}/devices/${sn}/profile`, _setAuthHeader())
}

/**
 * Send device actions by serial number
 * @param {string} sn
 * @param {string} action
 * @returns {Promise}
 */
export function sendDeviceActionBySerialNumber({ sn, action }) {
	return post(`${host2}/devices/${sn}/actions/${action}`, null, _setAuthHeader())
}

/**
 * Roles
 * @type {{realtor: string, branchManager: string, borrower: string, admin: string, loanOfficer: string, superAdmin: string, settlementAgent: string}}
 */
export const Roles = {
	borrower: "Borrower", // 0
	loanOfficer: "LoanOfficer", // 1
	admin: "Admin", // 2
	superAdmin: "SuperAdmin", // 3
	realtor: "Realtor", // 4
	settlementAgent: "SettlementAgent",
	branchManager: "BranchManager" // 17
}

/**
 * Site types
 * @type {{realtor: number, corporate: number, admin: number, branch: number, loanOfficer: number}}
 */
export const SiteTypes = {
	admin: 0,
	corporate: 1,
	branch: 2,
	loanOfficer: 3,
	realtor: 4
}

