import React, {useEffect, useState} from "react";
import {getWorkflowField} from "../../services/utils";
import {omit} from 'lodash';
import CheckboxGroup from "./CheckboxGroup";

const WorkflowCheckboxGroup = ({name, workflow, ...rest}) => {
	const [items, setItems] = useState([]);
	useEffect(() => {
		async function getFieldOptions() {
			const result = await getWorkflowField(workflow, name);
			if(result) {
				const options = [];
				result.options.forEach(r => {
					options.push({
						label: r.label,
						value: `${name}.${r.fieldId}`
					})
				})
				setItems(options);
			} else {
				console.warn(`No fieldId ${name} found in ${workflow}`)
			}
		}
		getFieldOptions();
	}, [name, workflow])

	return (
		<CheckboxGroup name={name} items={items || []} {...omit(rest, 'items')} />
	)
}

export default WorkflowCheckboxGroup;
