import * as React from "react"
import PropTypes from "prop-types"
import LinearProgress from "@mui/material/LinearProgress"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

export const LinearProgressWithLabel = (props) => {
	return (
		<Box sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
			<Box sx={{ width: "100%", mr: 1 }}>
				<LinearProgress sx={{ height: 20, borderRadius: 10 }} variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary" className={`select-none`}>{`${Math.round(
					props.value,
				)}% complete`}</Typography>
			</Box>
		</Box>
	)
}

LinearProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate and buffer variants.
	 * Value between 0 and 100.
	 */
	value: PropTypes.number.isRequired,
}

// function LinearWithValueLabel() {
//     const [progress, setProgress] = React.useState(10)

//     React.useEffect(() => {
//         const timer = setInterval(() => {
//             setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10))
//         }, 800)
//         return () => {
//             clearInterval(timer)
//         }
//     }, [])

//     return (
//         <Box sx={{ width: "100%" }}>
//             <LinearProgressWithLabel value={progress} />
//         </Box>
//     )
// }
