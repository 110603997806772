import Dialog from "@mui/material/Dialog"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { CheckCircleRounded } from "@mui/icons-material"
import { Button } from '../Button'

export const ModalSuccess = ({ visible, setVisible, message, handleOk }) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

	return (
		<Dialog
			open={visible}
			aria-labelledby="responsive-dialog-title"
		>
			<div className="flex flex-col items-center px-6 md:max-w-lg py-8 md:px-10">
				<CheckCircleRounded sx={{ fontSize: 90 }} className="mb-2" />
				<p className="font-lg mb-5 md:mx-10 text-center text-slate-500">{message}</p>

				<Button
					id="ModalConfirmCloseButton"
					text="OK"
					onClick={() => {
						handleOk && handleOk()
						setVisible(false)
					}}
					style={fullScreen ? { width: "90%" } : { width: 290 }}
					variant="outlined"
				/>
			</div>
		</Dialog>
	)
}
