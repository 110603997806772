import Dialog from "@mui/material/Dialog"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { Button } from "../Button"

export const ModalRemoveRecordConfirm = (props) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

	const closeModal = () => {
		props.setRemoveModalVisible(false)
	}

	return (
		<Dialog
			open={props.removeModalVisible}
			onClose={closeModal}
			aria-labelledby="responsive-dialog-title"
		>
			<div className="flex flex-col items-center px-6 md:max-w-lg py-8 md:px-10">
				<p className="pb-5 text-xl text-center font-bold">Confirm { !props.restore ? 'Delete' : 'Reactivation' }</p>
				<p className="font-lg mb-5 md:mx-10 text-center">{ !props.restore ? 'Remove' : 'Restore'} {props.row?.name || props.row?.Name || "Record"}?</p>
				<Button
					id="ModalRemoveConfirmRemoveButton"
					text={ `Yes, ${!props.restore ? 'Remove' : 'Restore'} It` }
					onClick={ () => !props.restore ? props.remove() : props.restore() }
					style={fullScreen ? { width: "90%", marginBottom: 10 } : { width: 290, marginBottom: 10 }}
					variant="contained"
				/>
				<Button
					id="ModalRemoveConfirmCloseButton"
					text="Cancel"
					onClick={closeModal}
					style={fullScreen ? { width: "90%" } : { width: 290 }}
					variant="outlined"
				/>
			</div>
		</Dialog>
	)
}
