import React, { useEffect, useMemo, useState } from "react"
import { LoadingPage } from "./components/LoadingPage"
import { useSiteConfig } from "./hooks/useSiteConfig"
import { useAppContextActions } from "./components/AppContext/AppHooks"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import PublishSubscribe from "publish-subscribe-js"
import App from "./App"
import { useVenti } from 'venti'
import { getTheme } from "./config"
import { useAppContext } from "./components/AppContext"
import MaintenanceView from "./pages/open/Maintenance"
import CssBaseline from '@mui/material/CssBaseline'
import { setItemAsyncStorage, newShade } from "./services/helper"

const theme = getTheme()

export default function AppTheme() {
	const [mode, setMode] = useState("light")
	const { siteConfig, socialLinks, legalLinks, isLoading } = useSiteConfig()
	const { state } = useAppContext()
	const { siteConfig: siteConfigContext } = state
	const ventiState = useVenti()
	const { applySiteConfig, logOut } = useAppContextActions()

	useEffect(() => {
		PublishSubscribe.subscribe("SET_DARK_MODE", (value) => {
			setMode(value)

			if (value === "dark") {
				localStorage.theme = "dark"
				document.documentElement.classList.add("dark")
			} else {
				localStorage.theme = "light"
				document.documentElement.classList.remove("dark")
			}
		})

		PublishSubscribe.subscribe("LOG_OUT_USER", () => {
			logOut()
		})

		// Set Disclosure SSO token
		if (window.location.hash) {
			const tokenParts = window.location.hash.split('id_token=')
			if (tokenParts?.length) {
				ventiState.set(theme.storageKeys.ssoToken, tokenParts[1])
				setItemAsyncStorage(theme.storageKeys.ssoToken, tokenParts[1])
			}
		}

		return () => {
			PublishSubscribe.unsubscribeAll()
		}
	}, [])

	useEffect(() => {
		applySiteConfig({ siteConfig, socialLinks, legalLinks })

		if (window.localStorage.getItem("theme") === "dark" && mode !== "dark") {
			setMode("dark")
		}
	}, [isLoading, siteConfig])

	const muiTheme = useMemo(() => {
		return {
			palette: {
				mode,
				...(mode === 'light'
					? {
						primary: {
							main: siteConfig?.primaryColor || theme.siteConfig.color.primary,
						},
						secondary: {
							main: siteConfig?.secondaryColor || theme.siteConfig.color.secondary,
						}
					}
					: {
						primary: {
							main: siteConfig?.darkModePrimaryColor || newShade(siteConfig?.primaryColor || theme.siteConfig.color.primary, 40),
						},
						secondary: {
							main: siteConfig?.darkModeSecondaryColor || newShade(siteConfig?.secondaryColor || theme.siteConfig.color.secondary, 40),
						}
					})
			},
			typography: {
				fontFamily: [
					"Rubik",
				].join(","),
				link: {
					"&:hover": {
						textDecoration: "underline"
					},
					cursor: "pointer",
					color: mode === 'light'
						? siteConfig?.primaryColor || theme.siteConfig.color.primary
						: newShade(siteConfig?.primaryColor || theme.siteConfig.color.primary, 40),
				},
				primaryText: {
					color: mode === 'light'
						? siteConfig?.primaryColor || theme.siteConfig.color.primary
						: newShade(siteConfig?.primaryColor || theme.siteConfig.color.primary, 40),
				},
			},
			components: {
				MuiInputBase: {
					styleOverrides: {
						root: {
							fontWeight: 600,
						},
					},
				},
			},
		}
	}, [mode, siteConfig])

	if (isLoading || !siteConfigContext) {
		return <LoadingPage />
	}

	if (!isLoading && theme.maintenance) {
		return <MaintenanceView />
	}

	return (
		<ThemeProvider theme={createTheme(muiTheme)}>
			<CssBaseline />
			<App />
		</ThemeProvider>
	)
}
