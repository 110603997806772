import React, { useState, useEffect, useRef } from "react"
import { useVenti } from "venti"
import { isEqual } from "lodash"
import { Tabs, Tab, Card, CardContent } from "@mui/material"
import { useTheme } from '@mui/material/styles'
import { ProfileView } from "./Profile"
import { ModalYesNo } from "../../../components/modals/ModalYesNo"
import SwipeableViews from 'react-swipeable-views'
import { LoadingBtn } from "../../../components/Button"
import Page from "../../../components/Page"
import { Integrations } from "../../../components/admin/Integrations"
import { useAppContextActions } from "../../../components/AppContext/AppHooks"
import { useAppContext } from "../../../components/AppContext"
import { a11yProps, getErrorMessage } from "../../../services/helper"
import { getCustomerSiteConfigurations, updateAccount } from "../../../services/client"
import { getTheme } from "../../../config"
import { siteConfigurationModel } from "../../../services/utils"
import { Loading } from "../../../components/Loading"
import { useWindowSize, useAlert } from "../../../hooks"
import MilestoneConfigurations from "../../../components/admin/MilestoneConfigurations"

const theme = getTheme()

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div className="mt-5">{children}</div>
			)}
		</div>
	)
}

export default function AccountEdit({ navigation }) {
	const { alert } = useAlert()
	const [ tab, setTab ] = useState(0)
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const themeMUI = useTheme()
	const [ width ] = useWindowSize()
	const ventiState = useVenti()
	const { siteConfig, user } = state

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const [modalConfirmation, setModalConfirmation] = useState({ open: false, text: "", leavePage: false, canBeSaved: false, isSave: false, event: null })
	const [loading, setLoading] = useState(false)
	const [initialState, setInitialState] = useState(siteConfigurationModel)
	const [formData, setFormData] = useState(siteConfigurationModel)
	const [canBeSubmitted, setCanBeSubmitted] = useState(true)
	const initialRender = useRef(true)

	if (!user?.isLoggedIn) (async () => await logOut())()

	useEffect(() => {
		const canBeSubmitted = formData.url.trim() !== "" && !isEqual(initialState, formData)
		setCanBeSubmitted(canBeSubmitted)

		if (initialRender.current) {
			initialRender.current = false
		}
	}, [formData, initialState])

	useEffect(() => {
		(async () => {
			if (!user.isLoggedIn) {
				await logOut()
				return
			}

			try {
				const data = await getCustomerSiteConfigurations()
				ventiState.set(theme.storageKeys.account, data)
				setFormData(data)
				setInitialState(data)
				setLoading(false)
			} catch (e) {
				alert(getErrorMessage(e), { severity: "error" })
				ventiState.set(theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error)
			}
		})()
	}, [user])

	const saveForm = async () => {
		setLoading(true)

		try {
			await updateAccount({ ...ventiState.get(theme.storageKeys.account), ...formData })
			setInitialState(formData)
			setLoading(false)
			setCanBeSubmitted(false)
			alert("Account successfully updated")
		} catch (e) {
			setLoading(false)
			alert(getErrorMessage(e, "support"), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || `There was a problem saving your request. Please contact Big POS customer support (${theme.support.email}).`)
		}
	}

	const modalConfirm = async (modalConfirmation) => {
		setLoading(true)
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({ open: false, text: "", leavePage: false })

		try {
			await updateAccount({ ...ventiState.get(theme.storageKeys.account), ...formData })
			setLoading(false)
			alert("Account successfully updated")
		} catch (e) {
			setLoading(false)
			alert(getErrorMessage(e, "support"), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || `There was a problem saving your request. Please contact Big POS customer support (${theme.support.email}).`)
		}
	}

	return (
		<Page page="app-profile" title="Account Settings" isFullWidth={true}>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
				saveForm={saveForm}
			/>

			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">Account Settings</p>
				{!formData.id
					? <div className="flex justify-center dark:bg-[#121212]" style={{ height: `calc(100vh - 216px)` }}><Loading size="small" /></div>
					: (
						<>
							<div className="flex flex-wrap sm:flex-row justify-end mb-5">
								<LoadingBtn
									id={`AdminAccountEditSaveButton`}
									disabled={!canBeSubmitted}
									loading={loading}
									text="Update Account"
									onClick={saveForm}
									fullWidth={false}
								/>
							</div>
							<Tabs
								variant="scrollable"
								scrollButtons="auto"
								value={tab}
								onChange={handleTabChange}
								aria-label="Tabs"
							>
								<Tab label="Account Profile" {...a11yProps(0)} />
								<Tab label="Integrations" {...a11yProps(1)} />
								<Tab label="Milestone Configurations" {...a11yProps(2)} />
							</Tabs>
							<SwipeableViews
								axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
								index={tab}
								onChangeIndex={handleChangeIndex}
							>
								<TabPanel value={tab} index={0} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<ProfileView formData={formData} setFormData={setFormData} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={1} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<Integrations formData={formData} setFormData={setFormData} entityType={0} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={2} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<MilestoneConfigurations />
										</CardContent>
									</Card>
								</TabPanel>
							</SwipeableViews>
						</>
					)
				}
			</div>
		</Page>
	)
}
