import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useVenti } from 'venti'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { styled, useTheme } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import CloseIcon from '@mui/icons-material/Close'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { navigationLinking, getActionsByRole, getRouteNameFromPath } from '../services/navigation'
import { adminMenu } from '../services/utils'
import { useAppContextActions } from './AppContext/AppHooks'
import { useAppContext } from './AppContext'
import DashboardPNG from 'assets/lordicons/Dashboard.png'
import DashboardGIF from 'assets/lordicons/DashboardAnimated.gif'
import { getTheme } from '../config'
import Brightness7Icon from "@mui/icons-material/Brightness7"
import Brightness4Icon from "@mui/icons-material/Brightness4"
import DangerousIcon from '@mui/icons-material/Dangerous'
import PublishSubscribe from "publish-subscribe-js"
import { Roles } from '../services/client'
import { isDarkMode } from '../services/helper'
import HomeIcon from '@mui/icons-material/Home'
import { routeTypes } from '../pages/loanapp/LoanApp'
import packageInfo from '../../package.json'
const drawerWidth = 240
const theme = getTheme()
const middleScreen = 768

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open, width }) => ({
		flexGrow: 1,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: 0,
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: width > middleScreen ? drawerWidth : 0,
		}),
	}),
)

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	justifyContent: 'space-between',
	...theme.mixins.toolbar,
}))

export const HeaderNavigation = ({ children, width }) => {
	const navigate = useNavigate()
	const route = useLocation()
	const ventiState = useVenti()
	const themeMUI = useTheme()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { siteConfig, user, authToken } = state
	const [anchorEl, setAnchorEl] = useState(null)
	const [open, setOpen] = useState(width >= 768)
	const [portalNavItems, setPortalNavItems] = useState([])
	const [hoveredId, setHoveredId] = useState(null)
	const routeName = getRouteNameFromPath(route.pathname)

	const loanId = ventiState.get(theme.storageKeys.loanId) || ''
	const loanData = ventiState.get(theme.storageKeys.loanData)

	const DashboardStaticIcon = siteConfig?.dashboardStaticIcon || DashboardPNG
	const DashboardAnimatedIcon = siteConfig?.dashboardAnimatedIcon || DashboardGIF

	const bgColor = {
		"&:hover": {
			backgroundColor: "transparent"
		}
	}

	const textUnderline = {
		"&:hover": {
			textDecoration: "underline"
		}
	}

	useEffect(() => {
		PublishSubscribe.subscribe("CLOSE_MENU", () => {
			handleDrawerClose()
		})

		return () => {
			PublishSubscribe.unsubscribe("CLOSE_MENU")
		}
	}, [])

	useEffect(() => {
		if (width >= 768) {
			handleDrawerOpen(true)
		}
	}, [width])

	useEffect(() => {
		if (user?.isLoggedIn && siteConfig?.id) {
			let navItems = getActionsByRole(user, siteConfig, loanId)

			setPortalNavItems(navItems)
		}
	}, [user, loanId, siteConfig])

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	const handleCloseInMobile = () => {
		if (width <= 768) {
			handleDrawerClose()
		}
	}

	const handleAppLink = async (item) => {
		switch (item.action) {
			case 'disclosures': await handleDisclosures()
				break
			default:
				navigate(`/${item.route}`, { state: item } || {})
				handleCloseInMobile()
		}
	}

	const handleDisclosures = async () => {
		if (user.disclosuresSSOLoginUrl) {
			return window.open(`${user.disclosuresSSOLoginUrl}`, '_blank', 'noopener,noreferrer')
		} else {
			navigate(`/${navigationLinking.Disclosures}`)
		}
	}

	const stopImpersonating = () => {
		ventiState.set(theme.storageKeys.loanId, null)
		ventiState.set(theme.storageKeys.loanData, null)
		ventiState.set(theme.storageKeys.loanTasks, null)
		ventiState.set(theme.storageKeys.loanDocs, null)
		navigate(`/${navigationLinking.Portal}`)
	}

	const changeMode = () => {
		PublishSubscribe.publish("SET_DARK_MODE",
			themeMUI.palette.mode === 'light' ? 'dark' : 'light')
	}

	if (!authToken) return <>{children}</>

	return (
		<>
			<Main open={open} width={width}>
				<AppBar color="inherit" position="relative" width={width} open={open}>
					<Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
						<div className="flex items-center">
							{Number(routeTypes.indexOf(routeName)) !== -1 && <div className="pr-2 mr-1">
								<HomeIcon onClick={() => navigate(navigationLinking.Landing)} />
							</div>}
							{!open &&
								<Tooltip title="Menu">
									<IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="menu"
										sx={{ mr: 2 }}
										onClick={handleDrawerOpen}
									>
										<MenuIcon />
									</IconButton>
								</Tooltip>
							}
							{loanData && loanId && [Roles.loanOfficer, Roles.branchManager, Roles.realtor].includes(user.role) &&
								<div className="flex items-center">
									<p className="font-bold font-rubik dark:text-white text-sm lg:text-base">Impersonating {loanData?.BorrowerFirstName} {loanData?.BorrowerLastName}</p>
									<div className="ml-4 hidden sm:block">
										<Tooltip title="Stop Impersonating">
											<IconButton sx={{ ml: 0, p: 0, color: "#ef4444" }} onClick={stopImpersonating}>
												<DangerousIcon />
											</IconButton>
										</Tooltip>
									</div>
								</div>
							}
						</div>
						<div>
							<div className="flex items-center">
								<div onClick={handleMenu} className="text-right">
									<span className="text-md text-right cursor-pointer" style={{ color: theme.color.primary.dark_grey }}>welcome back <Typography variant="link" className="font-bold">{user?.firstName}!</Typography></span>
								</div>
								<div className="mr-2">
									<Tooltip title={`Turn on ${themeMUI.palette.mode === 'light' ? 'Dark' : 'Light'} mode`}>
										<IconButton sx={{ ml: 1 }} onClick={changeMode} color="inherit">
											{themeMUI.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
										</IconButton>
									</Tooltip>
								</div>
							</div>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								className="mt-8 sm:mt-10"
								onClose={handleClose}
							>
								{adminMenu.map((item, index) => (
									<MenuItem
										key={index}
										onClick={() => {
											if (item.route) {
												handleClose()
												navigate(`/${item.route}`, { state: item } || {})
											} else {
												handleClose()
												logOut()
											}
										}}
									>
										{item.name}
									</MenuItem>
								))}
							</Menu>
						</div>
					</Toolbar>
				</AppBar >
				<Drawer
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: drawerWidth,
							boxSizing: 'border-box',
						},
					}}
					variant="persistent"
					anchor="left"
					open={open}
				>
					<DrawerHeader>
						<img
							className="h-5 pl-2.5 mt-1"
							src={isDarkMode() && siteConfig?.darkModeLogoUrl ? siteConfig.darkModeLogoUrl : siteConfig.logoUrl}
							alt="Logo"
						/>

						<Tooltip title="Close">
							<IconButton onClick={handleDrawerClose}>
								<CloseIcon />
							</IconButton>
						</Tooltip>
					</DrawerHeader>
					<Divider />
					<List>
						<ListItem
							onClick={() => {
								navigate(`/${navigationLinking.Portal}`)
								handleCloseInMobile()
							}}
							id={"dashboard"}
							onMouseOver={e => setHoveredId(e.currentTarget.id)}
							onMouseOut={e => setHoveredId(null)}
							disablePadding
						>
							<ListItemButton sx={bgColor}>
								<img
									className="h-8 mr-2.5 my-1"
									alt="Dashboard"
									src={hoveredId === "dashboard" ? DashboardAnimatedIcon : DashboardStaticIcon}
									onMouseOver={e => (e.currentTarget.src = DashboardAnimatedIcon)}
									onMouseOut={e => (e.currentTarget.src = DashboardStaticIcon)}
								/>
								<ListItemText sx={textUnderline} primary="Dashboard" />
							</ListItemButton>
						</ListItem>
						{portalNavItems.map((item, index) => {
							if ((!!item.route && siteConfig.enabledServices[item.id] !== false) || item.id === "disclosures")
								return (
									<ListItem
										key={index}
										onClick={() => handleAppLink(item)}
										disablePadding
										onMouseOver={e => setHoveredId(e.currentTarget.id)}
										onMouseOut={e => setHoveredId(null)}
										id={index}
									>
										<ListItemButton sx={bgColor}>
											{!item.staticIcon ?
												<item.icon color={theme.color.primary.dark_grey}
													className="h-8 mr-2.5 my-1" />
												: <img
													className="h-8 mr-2.5 my-1"
													alt={item.id}
													src={parseInt(hoveredId?.toString(), 10) === index ? item.animatedIcon : item.staticIcon}
												/>
											}
											<ListItemText sx={textUnderline} primary={item.name} />
										</ListItemButton>
									</ListItem>
								)
							return null
						})}
					</List>
					{Roles.admin === user.role && <ListItem
						className="ml-5 mt-5 opacity-40"
						disablePadding
						id={'bigposversion'}
					>
						<ListItemText sx={textUnderline} primary={`v${packageInfo.version}`} />
					</ListItem>}
				</Drawer>
				{children}
			</Main>
		</>
	)
}
