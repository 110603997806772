import React, { useState, useEffect, lazy } from "react"
import { Route, Routes } from "react-router-dom"
import { navigationLinking } from "./services/navigation"
import PrivateRoute from "./routes/PrivateRoute"
import { HeaderNavigation } from "./components/HeaderNavigation"
import { useWindowSize } from "./hooks"
import { ModalError } from "./components/modals/ModalError"
import { useVenti } from "venti"
import { getTheme } from "./config"
import { useAppContext } from "./components/AppContext"
import { ModalPleaseWait } from "./components/modals/ModalPleaseWait"
import { ModalSuccess } from "./components/modals/ModalSuccess"
import { ModalInfo } from "./components/modals/ModalInfo"
import { ModalSessionExpired } from "./components/modals/ModalSessionExpired"
import Suspense from "./components/Suspense"
import { Tooltip } from "react-tooltip"

// OPEN
import CreateAccount from "./pages/auth/CreateAccount"
import SignIn from "./pages/auth/SignIn"
import ForgotPassword from "./pages/auth/ForgotPassword"
import ConfirmAccount from "./pages/auth/ConfirmAccount"
import Landing from "./pages/open/Landing"
import Pricing from "./pages/open/pricing/Pricing"
import PricingResults from "./pages/open/pricing/PricingResults"
import PricingDetails from "./pages/open/pricing/PricingDetails"
import Disclosures from "./pages/open/Disclosures"

// PROTECTED
import Portal from "./pages/protected/Portal"
import UserProfile from "./pages/protected/UserProfile"
import QuickPricer from "./pages/protected/QuickPricer"
import Pipeline from "./pages/protected/Pipeline"
import LoanAppDetails from "./pages/protected/LoanAppDetails"
import Fees from "./pages/protected/Fees"
import LoanApp from "./pages/loanapp/LoanApp"
import Tasks from "./pages/protected/Tasks"
import Documents from "./pages/protected/Documents"
import LoanCalculator from "./pages/protected/LoanCalculator"
import LoanPricingResults from "./pages/protected/LoanPricingResults"

// ADMIN
import AccountEdit from "./pages/admin/Account/AccountEdit"
import AdminRules from "./pages/admin/Rules/Rules"
import AdminRuleTasks from "./pages/admin/Rules/RuleTasks"
import AdminCorporations from "./pages/admin/Corporations/Corporations"
import AdminCorporateEdit from "./pages/admin/Corporations/CorporateEdit"
import AdminLoanOfficers from "./pages/admin/LoanOfficers/LoanOfficers"
import AdminLoanOfficerEdit from "./pages/admin/LoanOfficers/LoanOfficerEdit"
import AdminBranches from "./pages/admin/Branches/Branches"
import AdminBranchEdit from "./pages/admin/Branches/BranchEdit"
import AdminUsers from "./pages/admin/Users/Users"
import AdminRequestQueue from "./pages/admin/RequestQueue/RequestQueue"
import AdminNotificationTemplates from "./pages/admin/NotificationTemplates/NotificationTemplates"
import AdminNotificationTemplateEdit from "./pages/admin/NotificationTemplates/Template"
import AdminPartnerView from "./pages/admin/Partners/Partners"
import AdminPartnerEdit from "./pages/admin/Partners/PartnerEdit"
import AdminPartnerProfile from "./pages/admin/Partners/Profile"
import AdminWorkflows from "./pages/admin/Workflows/Workflows"
import AdminWorkflowEdit from "./pages/admin/Workflows/WorkflowEdit"
import AdminDevices from "./pages/admin/Devices/Devices"
import AdminDeviceEdit from "./pages/admin/Devices/DeviceEdit"
import AdminDocumentTemplates from './pages/admin/DocumentTemplates/DocumentTemplates'
import AdminDocumentTemplateEdit from './pages/admin/DocumentTemplates/Template'
import ErrorBoundary from "./components/ErrorBoundary";

const Leads = lazy(() => import('./pages/protected/leads'))
const LeadEdit = lazy(() => import('./pages/protected/leads/LeadEdit'))

const theme = getTheme()

let interval = null

export default function App() {
	const [width] = useWindowSize()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const { user } = state
	const [isSessionModalVisible, setIsSessionModalVisible] = useState(false)
	const pleaseWaitMessage = ventiState.get(theme.storageKeys.pleaseWaitMessage) || ''
	const successObject = ventiState.get(theme.storageKeys.successObject) || { message: '', handleOK: null }
	const errorObject = ventiState.get(theme.storageKeys.errorObject) || { message: '', handleOK: null }
	const infoMessage = ventiState.get(theme.storageKeys.infoMessage) || ''
	const authTokenExp = ventiState.get(theme.storageKeys.authTokenExp)

	useEffect(() => {
		if (user?.isLoggedIn && !!authTokenExp && !interval) {
			interval = setInterval(() => {
				if (new Date() >= authTokenExp) {
					clearInterval(interval)
					interval = null
					setIsSessionModalVisible(true)
				}
			}, 10000, 'IS_TOKEN_EXPIRED')
		}

		return () => {
			clearInterval(interval)
		}
	}, [user, authTokenExp])

	const handleSuccessModalClose = () => {
		if (typeof successObject.handleOK === 'function') successObject.handleOK()
		else ventiState.set(theme.storageKeys.successObject, { message: '', handleOK: null })
	}

	const handleErrorModalClose = () => {
		if (typeof errorObject.handleOK === 'function') errorObject.handleOK()
		else ventiState.set(theme.storageKeys.errorObject, { message: '', handleOK: null })
	}

	return (
		<div className="app-pos">
			<HeaderNavigation width={width}>
				<ErrorBoundary>
					<Routes>
						{/* OPEN */}
						<Route path="/" element={<Landing />} />
						<Route path={navigationLinking.CreateAccount} element={<CreateAccount />} />
						<Route path={navigationLinking.VerifyInvite} element={<CreateAccount />} />
						<Route path={navigationLinking.SignIn} element={<SignIn />} />
						<Route path={navigationLinking.ForgotPassword} element={<ForgotPassword />} />
						<Route path={navigationLinking.ConfirmAccount} element={<ConfirmAccount />} />
						<Route path={navigationLinking.Pricing} element={<Pricing />} />
						<Route path={navigationLinking.PricingTable} element={<PricingResults />} />
						<Route path={navigationLinking.PricingDetails} element={<PricingDetails />} />
						<Route path={navigationLinking.Disclosures} element={<Disclosures />} />
						<Route path={navigationLinking.DisclosuresSSO} element={<Disclosures />} />
						<Route path={navigationLinking.Test} element={<LoanApp />} />
						<Route path={navigationLinking.Apply} element={<LoanApp />} />
						<Route path={navigationLinking.InviteApply} element={<LoanApp />} />
						<Route path={navigationLinking.ApplySpanish} element={<LoanApp />} />
						<Route path={navigationLinking.Prequalify} element={<LoanApp />} />
						<Route path={navigationLinking.PrequalifySpanish} element={<LoanApp />} />
						<Route path={navigationLinking.PrequalifyCoBorrower} element={<LoanApp />} />
						<Route path={navigationLinking.ApplyCoBorrower} element={<LoanApp />} />
						<Route path={navigationLinking.LeadApp} element={<LoanApp />} />
						<Route path={navigationLinking.AgentReferral} element={<LoanApp />} />
						<Route path={navigationLinking.OpenHouse} element={<LoanApp />} />
						<Route path={navigationLinking.OpenHouseWithLO} element={<LoanApp />} />
						<Route path={navigationLinking.SellerClient} element={<LoanApp />} />
						<Route path={navigationLinking.BuyerClient} element={<LoanApp />} />
						<Route path={navigationLinking.ClientLifestyle} element={<LoanApp />} />
						<Route path={navigationLinking.Walmart} element={<LoanApp />} />
						<Route path={navigationLinking.LeadCheckIn} element={<LoanApp />} />
						<Route path={navigationLinking.CustomWorkflow} element={<LoanApp />} />

						{/* PROTECTED */}
						<Route path={navigationLinking.Portal} element={<PrivateRoute><Portal /></PrivateRoute>} />
						<Route path={navigationLinking.AdminAccountEdit} element={<PrivateRoute><AccountEdit /></PrivateRoute>} />
						<Route path={navigationLinking.Leads} element={<PrivateRoute><Suspense><Leads /></Suspense></PrivateRoute>} />
						<Route path={navigationLinking.LeadAdd} element={<PrivateRoute><Suspense><LeadEdit /></Suspense></PrivateRoute>} />
						<Route path={navigationLinking.LeadEdit} element={<PrivateRoute><Suspense><LeadEdit /></Suspense></PrivateRoute>} />
						<Route path={navigationLinking.LoanApplications} element={<PrivateRoute><Pipeline /></PrivateRoute>} />
						<Route path={navigationLinking.LoanApplication} element={<PrivateRoute><LoanAppDetails /></PrivateRoute>} />
						<Route path={navigationLinking.UserProfile} element={<PrivateRoute><UserProfile /></PrivateRoute>} />
						<Route path={navigationLinking.QuickPricer} element={<PrivateRoute><QuickPricer /></PrivateRoute>} />
						<Route path={navigationLinking.Fees} element={<PrivateRoute><Fees /></PrivateRoute>} />
						<Route path={navigationLinking.LOApply} element={<PrivateRoute><LoanApp /></PrivateRoute>} />
						<Route path={navigationLinking.LOApplyCoBorrower} element={<PrivateRoute><LoanApp /></PrivateRoute>} />
						<Route path={navigationLinking.LOPrequal} element={<PrivateRoute><LoanApp /></PrivateRoute>} />
						<Route path={navigationLinking.LOLeadApp} element={<PrivateRoute><LoanApp /></PrivateRoute>} />
						<Route path={navigationLinking.Tasks} element={<PrivateRoute><Tasks /></PrivateRoute>} />
						<Route path={navigationLinking.Documents} element={<PrivateRoute><Documents /></PrivateRoute>} />
						<Route path={navigationLinking.LoanCalculator} element={<PrivateRoute><LoanCalculator /></PrivateRoute>} />
						<Route path={navigationLinking.LoanPricingResults} element={<PrivateRoute><LoanPricingResults /></PrivateRoute>} />

						{/* ADMIN */}
						<Route path={navigationLinking.AdminPartner} element={<PrivateRoute><AdminPartnerView /></PrivateRoute>} />
						<Route path={navigationLinking.AdminPartnerEdit} element={<PrivateRoute><AdminPartnerEdit /></PrivateRoute>} />
						<Route path={navigationLinking.AdminPartnerEditProfile} element={<PrivateRoute><AdminPartnerProfile /></PrivateRoute>} />
						<Route path={navigationLinking.AdminRules} element={<PrivateRoute><AdminRules /></PrivateRoute>} />
						<Route path={navigationLinking.AdminRuleTasks} element={<PrivateRoute><AdminRuleTasks /></PrivateRoute>} />
						<Route path={navigationLinking.AdminCorporate} element={<PrivateRoute><AdminCorporations /></PrivateRoute>} />
						<Route path={navigationLinking.AdminCorporateEdit} element={<PrivateRoute><AdminCorporateEdit /></PrivateRoute>} />
						<Route path={navigationLinking.AdminLoanOfficer} element={<PrivateRoute><AdminLoanOfficers /></PrivateRoute>} />
						<Route path={navigationLinking.AdminLoanOfficerEdit} element={<PrivateRoute><AdminLoanOfficerEdit /></PrivateRoute>} />
						<Route path={navigationLinking.AdminBranch} element={<PrivateRoute><AdminBranches /></PrivateRoute>} />
						<Route path={navigationLinking.AdminBranchEdit} element={<PrivateRoute><AdminBranchEdit /></PrivateRoute>} />
						<Route path={navigationLinking.AdminUser} element={<PrivateRoute><AdminUsers /></PrivateRoute>} />
						<Route path={navigationLinking.AdminRequestQueue} element={<PrivateRoute><AdminRequestQueue /></PrivateRoute>} />
						<Route path={navigationLinking.AdminNotificationTemplates} element={<PrivateRoute><AdminNotificationTemplates /></PrivateRoute>} />
						<Route path={navigationLinking.AdminNotificationTemplateEdit} element={<PrivateRoute><AdminNotificationTemplateEdit /></PrivateRoute>} />
						<Route path={navigationLinking.AdminDocumentTemplates} element={<PrivateRoute><AdminDocumentTemplates /></PrivateRoute>} />
						<Route path={navigationLinking.AdminDocumentTemplateEdit} element={<PrivateRoute><AdminDocumentTemplateEdit /></PrivateRoute>} />
						<Route path={navigationLinking.AdminWorkflow} element={<PrivateRoute><AdminWorkflows /></PrivateRoute>} />
						<Route path={navigationLinking.AdminWorkflowEdit} element={<PrivateRoute><AdminWorkflowEdit /></PrivateRoute>} />
						<Route path={navigationLinking.AdminDevice} element={<PrivateRoute><AdminDevices /></PrivateRoute>} />
						<Route path={navigationLinking.AdminDeviceEdit} element={<PrivateRoute><AdminDeviceEdit /></PrivateRoute>} />

						{/* 404 */}
						<Route path="*" element={<Landing />} />
					</Routes>
				</ErrorBoundary>
			</HeaderNavigation>

			<ModalPleaseWait
				visible={!!pleaseWaitMessage}
				message={pleaseWaitMessage}
			/>
			<ModalInfo
				visible={!!infoMessage}
				setVisible={() => ventiState.set(theme.storageKeys.infoMessage, '')}
				message={infoMessage}
				handleOk={() => ventiState.set(theme.storageKeys.infoMessage, '')} />
			<ModalError
				visible={!!errorObject.message}
				setVisible={handleErrorModalClose}
				message={errorObject.message}
				secondaryMessage={errorObject.secondaryMessage}
			/>
			<ModalSuccess
				visible={!!successObject.message}
				setVisible={handleSuccessModalClose}
				message={successObject.message}
				secondaryMessage={successObject.secondaryMessage}
			/>
			<ModalSessionExpired
				visible={isSessionModalVisible}
				setVisible={setIsSessionModalVisible}
			/>

			<Tooltip id="pos-tooltip" />
		</div>
	)
}
