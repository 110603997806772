import React, { useState, useEffect } from "react"
import PublishSubscribe from "publish-subscribe-js"
import { useNavigate } from "react-router-dom"
import { useVenti } from 'venti'
import { useAppContext } from "../../../components/AppContext"
import { useAppContextActions } from "../../../components/AppContext/AppHooks"
import Page from "../../../components/Page"
import { ModalRemoveRecordConfirm } from "../../../components/modals/ModalRemoveRecordConfirm"
import { DataTable } from "../../../components/DataTable"
import {
	getBusinessRules, removeBusinessRule,
	createBusinessRule, updateBusinessRule, undeleteBusinessRule
} from '../../../services/client';
import { getErrorMessage } from "../../../services/helper"
import { navigationLinking } from "../../../services/navigation"
import rulesQuestions from '../../../workflows/rules-questions'
import { getTheme } from '../../../config'
import { useAlert } from "../../../hooks"

const theme = getTheme()

const headers = [
	{ id: "rn", label: "Rule Name" },
	{ id: "persona", label: "Persona" },
	{ id: "desc", label: "Description" },
	{ id: "tasks", label: "Tasks" },
	{ id: "created", label: "Created" }]

const fields = ["name", "targetUserRole", "description", "tasks", "createdAt"]

export default function AdminRules() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const navigate = useNavigate()
	const ventiState = useVenti()
	const { user } = state

	const [data, setData] = useState([])
	const [fetching, setFetching] = useState(true)
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] = useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [rowToRestore, setRowToRestore] = useState()

	const loadRules = async () => {
		try {
			setFetching(true)
			setData(await getBusinessRules(true))
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	useEffect(() => {
		if (!user?.isLoggedIn) {
			logOut()
		} else {
			loadRules()
		}
	}, [user])

	useEffect(() => {
		PublishSubscribe.subscribe("UPDATE_ADMIN_COMPONENT_STATE", async () => {
			await loadRules()
		})

		return () => {
			PublishSubscribe.unsubscribe("UPDATE_ADMIN_COMPONENT_STATE")
		}
	}, [])

	const showRemoveModal = row => {
		setRemoveModalVisible(true)
		setRowToRemove(row)
	}

	const removeRule = async () => {
		setRemoveModalVisible(false)

		try {
			await removeBusinessRule(rowToRemove.id)
			setData(rulesList => rulesList.filter(rule => rule.id !== rowToRemove.id))
			setRowToRemove(null)
			await loadRules()
			alert(`Rule "${rowToRemove.name}" successfully removed`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const saveRule = async ruleData => {
		try {
			let rule = {}
			let response

			const standardFields = [
				"LoanPurpose",
				"LoanType",
				"OccupancyType",
				"BorrowerEmploymentType"
			]
			const standardFilters = [
				{
					"conditionType": 5,
					"targetFieldID": "LoanPurpose",
					"targetFieldValue": ruleData.loanPurpose
				},
				{
					"conditionType": 5,
					"targetFieldID": "LoanType",
					"targetFieldValue": ruleData.loanType
				},
				{
					"conditionType": 5,
					"targetFieldID": "OccupancyType",
					"targetFieldValue": ruleData.occupancyType
				},
				{
					"conditionType": 5,
					"targetFieldID": "PropertyType",
					"targetFieldValue": ruleData.propertyType
				},
				{
					"conditionType": 5,
					"targetFieldID": "BorrowerEmploymentType",
					"targetFieldValue": ruleData.borrowerEmploymentType
				}
			]

			rule = {
				name: ruleData.name,
				description: ruleData.description,
				tasks: [],
				targetUserRole: 0,
				applyToAllBorrowerPairs: true
			}

			if (!ruleData.id) {
				rule.filter = standardFilters
				response = await createBusinessRule(rule)
				const newData = [ ...data ]
				newData.unshift(response)
				setData(newData)

				// await loadRules()
				alert(`Rule "${ruleData.name}" successfully created`)
				await ventiState.set(theme.storageKeys.viewingRuleId, response.id)
				await navigate(`/${navigationLinking.AdminRuleTasks}`)
			} else {
				rule.filter = [ ...ruleData.filter.filter(item => !standardFields.includes(item.targetFieldID)), ...standardFilters]
				ruleData.targetUserRole--
				await updateBusinessRule(ruleData)
				setData(data.map(row => ruleData.id === row.id ? ruleData : row))
				alert(`Rule "${ruleData.name}" successfully updated`)
			}
		} catch (e) {
			console.log(e)
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const showRestoreModal = row => {
		setRestoreModalVisible(true)
		setRowToRestore(row)
	}

	const restoreRule = async () => {
		setRestoreModalVisible(false)

		try {
			await undeleteBusinessRule(rowToRestore.id)
			alert(`Loan Officer ${rowToRestore.name} successfully restored`)
			setRowToRestore(null)
			await loadRules()
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	return (
		<Page title="Business Rules" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-screen overflow-auto">
				<ModalRemoveRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeRule}
					row={rowToRemove}
				/>
				<ModalRemoveRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restoreRule}
					row={rowToRestore}
				/>
				<DataTable
					entity={"Rule"}
					data={data.map(row => ({
						...row,
						loanPurpose: row.filter.find(item => item.targetFieldID === 'LoanPurpose').targetFieldValue,
						loanType: row.filter.find(item => item.targetFieldID === 'LoanType').targetFieldValue,
						occupancyType: row.filter.find(item => item.targetFieldID === 'OccupancyType').targetFieldValue,
						propertyType: row.filter.find(item => item.targetFieldID === 'PropertyType').targetFieldValue,
						borrowerEmploymentType: row.filter.find(item => item.targetFieldID === 'BorrowerEmploymentType').targetFieldValue
					}))}
					headers={headers}
					title={"Business Rules"}
					tableType={"rules"}
					buttonTitle={"Add Rule"}
					fetching={fetching}
					get={data}
					set={setData}
					remove={showRemoveModal}
					restore={showRestoreModal}
					viewPath={navigationLinking.AdminRuleTasks}
					fields={fields}
					save={saveRule}
					refresh={loadRules}
					questions={rulesQuestions}
				/>
			</div>
		</Page>
	)
}
