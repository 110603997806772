import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { setDefaults, fromAddress } from 'react-geocode';
import { useVenti } from 'venti'
import { groupBy } from 'lodash'
import { useAppContext } from "../../../components/AppContext"
import { Button } from "../../../components/Button"
import { CardItem } from "../../../components/CardItem"
import { Footer } from "../../../components/Footer"
import { HeaderOpen } from "../../../components/HeaderOpen"
import { RateForm } from '../../../components/pricing/RateForm'
import Page from "../../../components/Page"
import { navigationLinking, getAppLinks } from "../../../services/navigation"
import { hasEmptyProperties, getErrorMessage } from '../../../services/helper'
import { getLoanPricing } from '../../../services/client'
import { getTheme } from '../../../config'
import { useAlert } from "../../../hooks"

const theme = getTheme()

setDefaults({
	key: theme.google.apiKey,
	language: theme.google.language,
	region: theme.google.region
})
export default function Pricing() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const navigate = useNavigate()
	const ventiState = useVenti()
	const {
		siteConfig,
		user,
		socialLinks,
		legalLinks
	} = state

	const [appLinks, setAppLinks] = useState([])
	const [fetching, setFetching] = useState(false)
	const [counties, setCounties] = useState([])
	const [formData, setFormData] = useState({
		totalMortgageAmount: '',
		propertyValue: '',
		propertyType: '',
		zipCode: '',
		county: '',
		loanPurpose: '',
		loanType: '',
		propertyOccupancy: '',
		escrow: '',
		loanTerm: '',
		creditScore: '',
	})

	useEffect(() => {
		// const mainNavItems = [
		// 	{ name: 'Home', link: '/', selected: true },
		// ]

		// if (siteConfig.LicenseInfoUrl) mainNavItems.push({ name: 'License Information', link: siteConfig.LicenseInfoUrl, selected: false })
		// if (siteConfig.ContactUsUrl) mainNavItems.push({ name: 'Contact Us', link: siteConfig.ContactUsUrl, selected: false })

		// setMainNav(mainNavItems)
		if (siteConfig !== null) {
			if (siteConfig.entityType === 0) navigate(`/${navigationLinking.AdminDashboard}`)
			setAppLinks(getAppLinks(siteConfig))
			setFormData(current => ({ ...current }))
		}
	}, [siteConfig])

	const handleZipCodeBlur = async event => {
		const val = event.target.value
		fromAddress(val).then(({ results }) => {
			if (results.length > 1) {
				setCounties(results.map(result => result.address_components.filter(c => c.types.includes('administrative_area_level_2'))))
			}
			setFormData(current => ({ ...current, county: results[0].address_components.find(comp => comp.types.includes('administrative_area_level_2'))?.long_name.replace(' County', '') }))
		})
	}

	const handleAppLink = async app => {
		switch (app.fieldName) {
			case 'RingCentral':
				await window.open(`tel:${siteConfig.phone}`)
				break
			case 'ringCentral':
				await window.open(`tel:${siteConfig.phone}`)
				break
			case 'Alexa':
				await window.open(app.link, '_blank', 'noopener,noreferrer')
				break
			default:
				navigate(`/${app.link}`, { state: app } || {})
		}
	}

	const handleGetRate = async () => {
		try {
			setFetching(true)
			const totalMortgageAmount = parseInt(formData.totalMortgageAmount.replace(/[^0-9]+/g, ''))
			delete formData.accountID
			let response = await getLoanPricing({
				...formData,
				totalMortgageAmount,
				eppsUserName: siteConfig.eppsUserName,
				propertyValue: parseInt(formData.propertyValue.replace(/[^0-9]+/g, '')),
				loanTerm: String(parseInt(formData.loanTerm.replace(/[^0-9]+/g, ''))),
				siteConfigurationId: siteConfig.id
			})

			let separator = formData.loanType
			let suffix = formData.loanType
			if (separator === 'Conventional') separator = 'Conf'

			const rates = response.rates.filter(rate => rate.loanProgram.toLowerCase().includes(separator.toLowerCase()))

			const max = totalMortgageAmount * 0.02
			const min = -1 * max

			const grouped = groupBy(rates, 'rate')

			let parsed = []

			for (const [key, value] of Object.entries(grouped)) {
				parsed.push(value.sort((a, b) => a.price - b.price)[0])
			}

			parsed = parsed
				.filter(row => row.price <= max && row.price >= min)
				.map(row => ({ ...row, loanProgram: `${row.loanProgram.split(separator)[1]} Year ${suffix}` }))

			parsed.sort((a, b) => a.rate - b.rate)

			ventiState.set(theme.storageKeys.publicRates, { rates: parsed, formData })
			//await setItemAsyncStorage(theme.storageKeys.publicRates, JSON.stringify({ rates: parsed, formData }))
			setFetching(false)
			navigate(`/${navigationLinking.PricingTable}`)
		} catch (e) {
			setFetching(false)
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const ratesButtonDisabled = hasEmptyProperties(formData)

	return (
		<Page page="app-pricing">
			<HeaderOpen siteConfig={siteConfig} user={user} />

			<div className="pl-5 pr-5 pb-10">
				<div className="flex justify-center pt-8 pb-8 pl-0">
					<p className="text-2xl text-center font-rubik font-bold dark:text-white">
						Customer Mortgage Program Pricing
					</p>
				</div>
				<div className="flex justify-center flex-col md:flex-row">
					<div className="w-full md:w-80 md:mr-14">
						<RateForm
							getRate={handleGetRate}
							formData={formData}
							setFormData={setFormData}
							ratesButtonDisabled={ratesButtonDisabled}
							fetching={fetching}
							handleZipCodeBlur={handleZipCodeBlur}
						/>
					</div>
					<div className="w-full md:w-80 flex flex-col items-center">
						<div className="flex items-center">
							<p className="text-7xl font-bold pr-5 text-sky-600">30</p>
							<div className="flex-col">
								<p className="text-2xl font-light dark:text-white">YEAR</p>
								<div className="border-b-2 border-black">
									<p className="text-2xl font-medium dark:text-white">Fixed</p>
								</div>
							</div>
						</div>
						<div className="flex flex-col justify-center items-center mt-5">
							<p className="text-2xl font-bold dark:text-white">2.250%</p>
							<p className="text-2xl font-bold dark:text-white">(2.402% APR)</p>
						</div>
						<div>
							<p className="text-sm font-light mt-2.5 text-center text-slate-300 dark:text-white">
								Last Updated 2020-09-23 09:37:30.0
							</p>
							<p className="text-lg my-2.5 text-center w-64 dark:text-white">
								Monthly payments that are always the same
							</p>
						</div>
						{/*<Button bstyle={{ width: 220, height: 40, marginBottom: 20 }} text="Show rate / Fee Options" onClick={() => console.log('Show rate / Fee Options')} />*/}
						{appLinks.filter(app => app.name !== 'By Phone').map((app, index) => {
							if (!siteConfig.enabledServices || siteConfig.enabledServices[app.fieldName] !== false && app.entityTypes.includes(siteConfig.entityType)) return (
								<CardItem
									key={app.fieldName + index}
									item={app}
									index={index}
									onClick={() => handleAppLink(app)}
									source={'LandingAppLink'}
								/>
							)
						})}
					</div>
				</div>
			</div>

			<Footer
				siteConfig={siteConfig}
				socialLinks={socialLinks}
				legalLinks={legalLinks}
			/>
		</Page>
	)
}
