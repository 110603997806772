import React from "react";
import {
	FormControlLabel,
	Checkbox as MuiCheckbox
} from "@mui/material";
import {Controller} from "react-hook-form";

const Checkbox = ({name, label, children, control, defaultValue, className, ...rest}) => (
	<div className={className}>
		{control &&
			<Controller name={name}
			            control={control}
			            defaultValue={defaultValue}
			            render={({ field }) => (
				            <FormControlLabel
					            label={label}
					            control={<MuiCheckbox
						            {...field}
						            {...rest}
						            checked={field.value}
						            onChange={(e) => field.onChange(e.target.checked)}
						            />
										} />
			            )} />
		}
		{!control &&
			<FormControlLabel label={label} control={
				<MuiCheckbox name={name}
				             defaultValue={defaultValue}
				             {...rest} />
			} />
		}
	</div>
);

export default Checkbox;
