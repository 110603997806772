import React, { useState, useEffect } from "react"
import {
	setDefaults,
	fromAddress
} from "react-geocode";

import { useNavigate } from "react-router-dom"
import * as _ from "lodash"
import { useVenti } from "venti"
import { useAppContext } from "../../components/AppContext"
import Page from "../../components/Page"
import { useAppContextActions } from "../../components/AppContext/AppHooks"
import { RateForm } from "../../components/pricing/RateForm"
import { PricingTable } from "../../components/pricing/PricingTable"
import { LoadingBtn } from '../../components/Button'
import { ModalError } from "../../components/modals/ModalError"
import { ModalPleaseWait } from "../../components/modals/ModalPleaseWait"
import { getTheme } from "../../config"
import { closestToZero, getErrorMessage } from "../../services/helper"
import { getLoanPricing } from "../../services/client"
import { applyMask } from "../../services/utils"
import { navigationLinking } from "../../services/navigation"
import { useAlert } from "../../hooks"

const theme = getTheme()

setDefaults({
	key: theme.google.apiKey,
	language: theme.google.language,
	region: theme.google.region
})

export default function QuickPricer() {
	const { alert } = useAlert()
	const navigate = useNavigate()
	const { state } = useAppContext()
	const { siteConfig, user } = state
	const { logOut } = useAppContextActions()
	const ventiState = useVenti()
	const loanData = ventiState.get(theme.storageKeys.loanData)

	const [pleaseWaitText, setPleaseWaitText] = useState("")
	const [counties, setCounties] = useState([])
	const [error, setError] = useState(false)
	const [fetchingRates, setFetchingRates] = useState(false)
	const [rates, setRates] = useState({ rates: [] })
	const [errorModalVisible, setErrorModalVisible] = useState(false)
	const [errorMsg, setErrorMsg] = useState("")

	const [formData, setFormData] = useState({
		totalMortgageAmount: loanData?.LoanAmount || "",
		propertyValue: loanData?.SubjectPropertySalePrice || "",
		propertyType: "",
		loanPurpose: loanData?.LoanPurpose || "",
		loanType: loanData?.CurrentLoanType || "",
		propertyOccupancy: "",
		escrow: "",
		loanTerm: loanData?.LoanTerm ? (parseInt(loanData.LoanTerm) / 12).toString() : "",
		creditScore: loanData?.CreditScore || "",
		state: loanData?.SubjectPropertyAddressState || "",
		city: loanData?.SubjectPropertyAddressCity || "",
		county: loanData?.SubjectPropertyAddressCounty || "",
		zipCode: loanData?.SubjectPropertyAddressZip || ""
	})

	const pricingButtonDisabled = formData.totalMortgageAmount === ""
		|| formData.propertyValue === ""
		|| formData.propertyType === ""
		|| formData.creditScore === ""
		|| formData.city === ""
		|| formData.county === ""
		|| formData.zipCode === ""
		|| formData.loanPurpose === ""
		|| formData.loanType === ""
		|| formData.loanTerm === ""
		|| formData.propertyOccupancy === ""
		|| formData.state === ""


	useEffect(() => {
		(async () => {
			if (!user.isLoggedIn) await logOut()
		})()
	}, [user])

	const handleZipCodeBlur = async event => {
		const val = event.target.value
		fromAddress(val).then(({ results }) => {
			if (results.length > 1) {
				setCounties(results.map(result => result.address_components.filter(c => c.types.includes('administrative_area_level_2'))))
			}
			const addressComponents = results[0].address_components
			setFormData(current => ({
				...current,
				city: addressComponents.find(comp => comp.types.includes('locality') || comp.types.includes('political'))?.long_name || '',
				county: addressComponents.find(comp => comp.types.includes('administrative_area_level_2'))?.long_name.replace(' County', '') || '',
				state: addressComponents.find(comp => comp.types.includes('administrative_area_level_1'))?.short_name || ''
			}))
		})
	}

	const handlePricingSubmit = async () => {
		try {
			setPleaseWaitText(`Fetching pricing scenarios from ${siteConfig.pricingEngine?.name || "EPPS"}`)
			let creditScore
			switch (true) {
				case !formData.creditScore:
					creditScore = null
					break
				case formData.creditScore >= 720:
					creditScore = "Excellent"
					break
				case formData.creditScore < 720 && formData.creditScore >= 660:
					creditScore = "Good"
					break
				case formData.creditScore < 660 && formData.creditScore >= 620:
					creditScore = "Average"
					break
				case formData.creditScore < 620:
					creditScore = "Poor"
			}

			const requestData = {
				...formData,
				totalMortgageAmount: parseInt(formData.totalMortgageAmount.replace(/[^0-9]+/g, "")),
				propertyValue: parseInt(formData.propertyValue.replace(/[^0-9]+/g, "")),
				propertyOccupancy: formData.propertyOccupancy === "PrimaryResidence" ? "PrimaryHome" : formData.propertyOccupancy,
				loanTerm: String(parseInt(formData.loanTerm.replace(/[^0-9]+/g, "")) / 12),
				eppsUserName: siteConfig.eppsUserName,
				siteConfigurationId: siteConfig.id
			}

			const responseData = await getLoanPricing(requestData)

			let separator = requestData.loanType
			if (separator === 'Conventional') separator = 'Conf'

			const filteredRates = responseData.rates.filter(rate => rate.price > -4 && rate.price < 4 && rate.loanProgram.toLowerCase().includes(separator.toLowerCase()))

			const grouped = _.groupBy(filteredRates, 'rate')

			let parsed = []

			for (const [key, value] of Object.entries(grouped)) {
				parsed.push(value.sort((a, b) => a.price - b.price)[0])
			}

			parsed.sort((a, b) => a.rate - b.rate)

			const parPrice = closestToZero(parsed.map(row => row.price))

			const parIndex = parsed.findIndex(row => row.price === parPrice)

			parsed = parsed
				.slice(parIndex - 3, parIndex + 4)
			// .map(row => ({ ...row, loanProgram: `${row.loanProgram.split(separator)[1]} Year ${suffix}`}))

			//console.log(parsed)
			setRates({ rates: parsed, formData: requestData })
			setPleaseWaitText('')
		} catch (e) {
			setPleaseWaitText("")
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const getOccupancyValues = val => {
		if (val === 'PrimaryResidence') return 'PrimaryHome'
		if (val === 'Investment') return
	}

	useEffect(() => {
		if (loanData?.LoanAmount) {
			setFormData({
				totalMortgageAmount: applyMask(loanData.LoanAmount, "currency"),
				propertyValue: applyMask(loanData.SubjectPropertySalePrice, "currency"),
				propertyType: loanData.SubjectPropertyResidencyType === "detached" ? "singleFamily" : loanData.SubjectPropertyResidencyType,
				loanPurpose: loanData.LoanPurpose,
				loanType: loanData.CurrentLoanType,
				propertyOccupancy: getOccupancyValues(loanData.SubjectPropertyUse),
				escrow: "Yes",
				loanTerm: (parseInt(loanData.LoanTerm) / 12).toString(),
				creditScore: loanData?.CreditScore || "",
				state: loanData?.SubjectPropertyAddressState || "",
				city: loanData?.SubjectPropertyAddressCity || "",
				zipCode: loanData.SubjectPropertyAddressZip,
				county: loanData?.SubjectPropertyAddressCounty || ""
			})
		}
	}, [loanData])

	return (
		<Page page="app-price-loan" title="Quick Pricer" isFullWidth={true}>
			<ModalPleaseWait logo={siteConfig.logoUrl} message={pleaseWaitText} visible={!!pleaseWaitText} />
			<ModalError visible={errorModalVisible} setVisible={setErrorModalVisible} message={errorMsg} />
			<ModalError setVisible={setError} visible={error}
				handleOk={() => navigate(`/${navigationLinking.Portal}`)}
				message="There was a problem fetching the calculations. Please try again later."
			/>
			<div className="pl-5 pr-5 pb-10 pt-5 h-screen overflow-auto">
				<p className="text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">Price a Loan</p>
				<div className="flex flex-col md:flex-row">
					<div className="flex flex-col items-center w-full md:w-2/5 md:mr-14">
						<LoadingBtn
							disabled={pricingButtonDisabled}
							style={{ width: 220, height: 40, marginBottom: 20 }}
							text="Get Your Rate"
							variant="contained"
							onClick={handlePricingSubmit}
							loading={fetchingRates}
						/>
						<RateForm
							user={user}
							showButton={false}
							getRate={handlePricingSubmit}
							formData={formData}
							setFormData={setFormData}
							ratesButtonDisabled={pricingButtonDisabled}
							fetching={fetchingRates}
							handleZipCodeBlur={handleZipCodeBlur}
						/>
					</div>
					<div className="flex flex-col items-center w-full mb-5 md:mb-0 md:w-3/5">
						{!!loanData?.borrowerFirstName && <p className="mb-5">Impersonating {loanData.borrowerLastName}, {loanData.borrowerFirstName}</p>}
						{rates.rates.length > 0
							? <PricingTable rates={rates} tableState={state} />
							: <p className="text-slate-500">Use the form to create a pricing scenario</p>
						}
					</div>
				</div>
			</div>
		</Page>
	)
}
