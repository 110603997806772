import { useState, useEffect } from "react"
import {
	TextField, Dialog, DialogActions, DialogContent, DialogTitle,
	InputAdornment, IconButton, CircularProgress, Box, Typography
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingBtn } from "../Button/LoadingButton"
import { Button } from "../Button"
import { getTheme } from '../../config'
import { authenticate } from '../../services/client'
import { useAppContext } from '../AppContext'
import { useAppContextActions } from "../AppContext/AppHooks"
import PublishSubscribe from "publish-subscribe-js"
import { useVenti } from "venti"

const theme = getTheme()
let timerId = null

export const ModalSessionExpired = ({ visible, setVisible }) => {
	const { state: { siteConfig, user } } = useAppContext()
	const { applyAuthToken, applyUser } = useAppContextActions()
	const ventiState = useVenti()
	const [showPassword, setShowPassword] = useState(false)
	const [passwordSignIn, setPasswordSignIn] = useState('')
	const [passwordSignInValidation, setPasswordSignInValidation] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [timerValue, setTimerValue] = useState(100)
	const [timerText, setTimerText] = useState(30)

	useEffect(() => {
		if (visible) {
			timerId = setInterval(countdown, 1000)

			function countdown() {
				if (timerText === 1) {
					clearInterval(timerId)
					timerId = null
					setVisible(false)
					PublishSubscribe.publish("LOG_OUT_USER")
				} else {
					setTimerValue((prevState) => {
						return prevState - 3.3
					})
					setTimerText((prevState) => {
						return prevState - 1
					})
				}
			}
		}

		return () => {
			clearInterval(timerId)
			timerId = null
		}
	}, [visible, timerValue, timerText])

	const passwordSignInOnChange = (val) => {
		setPasswordSignIn(val)
		setPasswordSignInValidation(val.length >= theme.validation.passwordLength)
	}

	const handleKeepSignIn = () => {
		setIsLoading(true)
		authenticate(user?.email, passwordSignIn, siteConfig.id).then((response) => {
			let authTokenExp = new Date()
			authTokenExp.setMinutes(authTokenExp.getMinutes() + ((user?.expires_in || 3600) / 60) - 5)
			const userData = {
				...user,
				isLoggedIn: true,
				tokenExp: authTokenExp
			}

			clearInterval(timerId)
			timerId = null
			applyAuthToken(response.access_token)
			applyUser(userData)
			ventiState.set(theme.storageKeys.authToken, response.access_token)
			ventiState.set(theme.storageKeys.authTokenExp, authTokenExp)
			ventiState.set(theme.storageKeys.user, userData)
			setIsLoading(false)
			setTimerValue(100)
			setTimerText(30)
			setVisible(false)
		})
			.catch((err) => {
				setErrorMessage(err?.data?.message || 'Invalid username and/or password')
				setIsLoading(false)
			})
	}

	return (
		<Dialog
			open={visible}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogTitle>Your session is about to expire</DialogTitle>
			<DialogContent>
				<p>If you need more time, enter your password and click "Keep me signed in"</p>
				<TextField
					onChange={(e) => passwordSignInOnChange(e.target.value)}
					value={passwordSignIn}
					label={passwordSignIn === '' ? null : 'Password'}
					placeholder="Password"
					error={!passwordSignInValidation}
					id={`SignInPasswordInput`}
					helperText={!passwordSignInValidation ? "Password must be at least 8 characters" : ""}
					variant="standard"
					type={showPassword ? "text" : "password"}
					className="w-[100%] sm:w-96 sm:px-12 dark:text-white"
					style={{ marginTop: 16 }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setShowPassword(!showPassword)}
									onMouseDown={() => setShowPassword(!showPassword)}
								>
									{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
				{!!errorMessage && <p className="w-64 sm:w-96 text-sm pt-3 pb-2 text-red-500">
					{errorMessage}
				</p>}
			</DialogContent>
			<DialogActions className="ml-5 mr-5">
				<div className="flex flex-col sm:flex-row justify-between w-[100%] items-center">
					<Box sx={{ position: 'relative', display: 'block' }} className="mb-4 sm:mb-0">
						<CircularProgress variant="determinate" value={timerValue} />
						<Box
							sx={{
								top: -5,
								left: 0,
								bottom: 0,
								right: 0,
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography
								variant="caption"
								component="div"
								color="text.secondary"
							><span className="text-[14px]">{timerText}</span></Typography>
						</Box>
					</Box>
					<div className="flex flex-row justify-center items-center">
						<div className="mr-4">
							<Button
								text="Sign out"
								onClick={() => {
									PublishSubscribe.publish("LOG_OUT_USER")
								}}
								variant="outlined"
							/>
						</div>
						<div>
							<LoadingBtn
								className="mt-5"
								text="Keep me signed in"
								onClick={() => handleKeepSignIn()}
								loading={isLoading}
							/>
						</div>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	)
}
