import React, {useMemo} from 'react';
import {TextField as MuiTextField} from "@mui/material";
import {Controller} from "react-hook-form";
import useFormField from "../../hooks/useFormField";

const TextField = ({variant = 'standard', placeholder, label, control, required, name, errors, onChange, ...rest}) => {
	const {error} = useFormField({name, errors});
	const placeholderTxt = useMemo(() => placeholder || label, [placeholder, label]);
	const labelTxt = useMemo(() => required && label ? `${label} *` : label, [required, label]);
	if(control) {
		return (
			<Controller name={name}
			            control={control}
			            render={({ field }) => (
										<MuiTextField variant={variant}
										              label={labelTxt}
										              placeholder={placeholderTxt}
										              error={!!error}
										              helperText={error ? error.message : undefined}
										              {...field}
																	onChange={(e) => {
																		if(onChange) {
																			onChange(e);
																		}
																		field.onChange(e);
																	}}
										              {...rest} />
			            )}
			/>
		)
	}

	return <MuiTextField name={name}
	                     variant={variant}
	                     label={labelTxt}
	                     placeholder={placeholderTxt}
	                     error={!!error}
	                     helperText={error ? error.message : undefined}
	                     onChange={onChange}
	                     {...rest} />
}

export default TextField
