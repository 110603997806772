import React, {useState, useEffect, useMemo} from "react"
import { useVenti } from "venti"
import Page from "../../../components/Page"
import { DataTable } from "../../../components/DataTable"
import {
	getDocumentTemplates,
	deleteDocumentTemplate,
	undeleteDocumentTemplate
} from '../../../services/client';
import { documentTemplateTypes, getErrorMessage } from '../../../services/helper';
import { getTheme } from "../../../config"
import { useAlert } from "../../../hooks"
import { ModalRemoveRecordConfirm } from '../../../components/modals/ModalRemoveRecordConfirm';
import { useAppContext } from '../../../components/AppContext';
import { useAppContextActions } from '../../../components/AppContext/AppHooks';

const theme = getTheme()

const headers = [
	{ id: "name", label: "Name" },
	{ id: "type", label: "Type" },
	{ id: "description", label: "Description" },
	{ id: "created", label: "Created On" },
	{ id: "updatedAt", label: "Last Updated" },
]
const fields = ["name", "type", "description", "createdAt", "updatedAt"]

export default function AdminDocumentTemplates() {
	const ventiState = useVenti()
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user } = state
	const [tableData, setTableData] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] = useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [rowToRestore, setRowToRestore] = useState()


	const showRemoveModal = row => {
		setRemoveModalVisible(true)
		setRowToRemove(row)
	}

	const removeDocumentTemplate = async () => {
		setRemoveModalVisible(false)

		try {
			await deleteDocumentTemplate(rowToRemove.id)
			await loadDocumentTemplates()
			alert(`Brand "${rowToRemove.name}" successfully removed`)
			setRowToRemove(null)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const showRestoreModal = row => {
		setRestoreModalVisible(true)
		setRowToRestore(row)
	}

	const restoreDocumentTemplate = async () => {
		setRestoreModalVisible(false)

		try {
			await undeleteDocumentTemplate(rowToRestore.id)
			setTableData(data => data.filter(d => d.id !== rowToRestore.id))
			alert(`Brand ${rowToRestore.name} successfully restored`)
			setRowToRestore(null)
			await loadDocumentTemplates()
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const loadDocumentTemplates = async () => {
		try {
			setIsLoading(true)
			setTableData(await getDocumentTemplates(true))
			setIsLoading(false)
		} catch (e) {
			setIsLoading(false)
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	useEffect(() => {
		(async () => {
			if (!user.isLoggedIn) await logOut()
			else await loadDocumentTemplates()
		})()
	}, [])

	const populatedTableData = useMemo(() => {
		return tableData.map(row => {
			const templateType = documentTemplateTypes.find(t => t.value === row.type)
			return { ...row, type: templateType ? templateType.label : '' }
		})
	}, [tableData])

	return (
		<Page page="app-document-templates" title="Document Templates" isFullWidth={true}>
			<ModalRemoveRecordConfirm
				removeModalVisible={removeModalVisible}
				setRemoveModalVisible={setRemoveModalVisible}
				remove={removeDocumentTemplate}
				row={rowToRemove}
			/>
			<ModalRemoveRecordConfirm
				removeModalVisible={restoreModalVisible}
				setRemoveModalVisible={setRestoreModalVisible}
				restore={restoreDocumentTemplate}
				row={rowToRestore}
			/>
			<div className="pl-5 pr-5 pb-10 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">Document Templates</p>
				<DataTable
					loading={isLoading}
					data={populatedTableData}
					headers={headers}
					fields={fields}
					entity={"DocumentTemplate"}
					refresh={loadDocumentTemplates}
					remove={showRemoveModal}
					restore={showRestoreModal}
				/>
			</div>
		</Page>
	)
}
