import ApplicationPNG from "assets/lordicons/Application.png"
import ApplicationGIF from "assets/lordicons/ApplicationAnimated.gif"
import PhonePNG from "assets/lordicons/Phone.png"
import PhoneGIF from "assets/lordicons/PhoneAnimated.gif"
import PrequalPNG from "assets/lordicons/Questionnaire.png"
import PrequalGIF from "assets/lordicons/QuestionnaireAnimated.gif"
import DollarCoinPNG from "assets/lordicons/DollarCoin.png"
import DollarCoinGIF from "assets/lordicons/DollarCoinAnimated.gif"
import QuickPricerPNG from "assets/lordicons/QuickPricer.png"
import QuickPricerGIF from "assets/lordicons/QuickPricer.gif"
import ListPNG from "assets/lordicons/List.png"
import ListGIF from "assets/lordicons/ListAnimated.gif"
import RulesPNG from "assets/lordicons/Rules.png"
import RulesGIF from "assets/lordicons/RulesAnimated.gif"
import PaymentPNG from "assets/lordicons/Payment.png"
import PaymentGIF from "assets/lordicons/PaymentAnimated.gif"
import LoanApplicationsPNG from "assets/lordicons/LoanApplications.png"
import LoanApplicationsGIF from "assets/lordicons/LoanApplicationsAnimated.gif"
import UserPNG from "assets/lordicons/User.png"
import UserGIF from "assets/lordicons/UserAnimated.gif"
import SignaturePNG from "assets/lordicons/Signature.png"
import SignatureGIF from "assets/lordicons/SignatureAnimated.gif"
import DocumentsPNG from "assets/lordicons/Documents.png"
import CalculatorPNG from "assets/lordicons/Calculator.png"
import AddCoBorrowerPNG from "assets/lordicons/AddCoBorrower.png"
import LOConnectPNG from "assets/lordicons/LOConnect.png"
import DocumentsGIF from "assets/lordicons/DocumentsAnimated.gif"
import CalculatorGIF from "assets/lordicons/CalculatorAnimated.gif"
import AddCoBorrowerGIF from "assets/lordicons/AddCoBorrowerAnimated.gif"
import LOConnectGIF from "assets/lordicons/LOConnectAnimated.gif"
import SettingsPNG from "assets/lordicons/Settings.png"
import BrandsPNG from "assets/lordicons/Brands.png"
import LocationPinPNG from "assets/lordicons/LocationPin.png"
import LoanOfficersPNG from "assets/lordicons/LoanOfficers.png"
import UsersPNG from "assets/lordicons/Users.png"
import NotificationsPNG from "assets/lordicons/Notifications.png"
import RequestQueuePNG from "assets/lordicons/Queue.png"
import SettingsGIF from "assets/lordicons/SettingsAnimated.gif"
import BrandsGIF from "assets/lordicons/BrandsAnimated.gif"
import LocationPinGIF from "assets/lordicons/LocationPinAnimated.gif"
import LoanOfficersGIF from "assets/lordicons/LoanOfficersAnimated.gif"
import UsersGIF from "assets/lordicons/UsersAnimated.gif"
import NotificationsGIF from "assets/lordicons/NotificationsAnimated.gif"
import RequestQueueGIF from "assets/lordicons/QueueAnimated.gif"
import TimerPNG from "assets/lordicons/Timer.png"
import TimerGIF from "assets/lordicons/TimerAnimated.gif"
import ReleaseNotesPNG from 'assets/lordicons/ReleaseNotes.png'
import ReleaseNotesGIF from 'assets/lordicons/ReleaseNotes.gif'
import SupportPNG from 'assets/lordicons/Support.png'
import SupportGIF from 'assets/lordicons/SupportAnimated.gif'
import BuyAHomePNG from 'assets/lordicons/BuyAHome.png'
import BuyAHomeGIF from 'assets/lordicons/BuyAHomeAnimated.gif'
import SellMyHomePNG from 'assets/lordicons/SellMyHome.png'
import SellMyHomeGIF from 'assets/lordicons/SellMyHomeAnimated.gif'
import ClientLifestylePNG from 'assets/lordicons/ClientLifestyle.png'
import ClientLifestyleGIF from 'assets/lordicons/ClientLifestyleAnimated.gif'
import ClientsPNG from 'assets/lordicons/Clients.png'
import ClientsGIF from 'assets/lordicons/ClientsAnimated.gif'
import PartnersPNG from 'assets/lordicons/Partners.png'
import PartnersGIF from 'assets/lordicons/PartnersAnimated.gif'
import KioskPNG from 'assets/lordicons/Kiosk.png'
import KioskGIF from 'assets/lordicons/KioskAnimated.gif'
import { Roles, SiteTypes } from './client';

/**
 * Navigation Linking
 */
export const navigationLinking = {
	"Landing": "/",
	"SignIn": 'sign-in',
	"CreateAccount": 'create-account',
	"VerifyInvite": 'verify/invite/:token',
	"ForgotPassword": 'forgot-password',
	"ConfirmAccount": 'confirm-account',
	"SetPassword": 'set-password',
	"Prequalify": 'prequalify',
	"LeadApp": "questionnaire",
	"BuyerClient": "buy-a-home",
	"SellerClient": "sell-my-home",
	"ClientLifestyle": "client-lifestyle",
	"OpenHouseWithLO": 'open-house/:address/:loanOfficerEmail',
	"OpenHouse": 'open-house/:address',
	"Walmart": "walmart",
	"LeadCheckIn": "lead-check-in",
	"PrequalifySpanish": 'prequalify/esp',
	"PrequalifyCoBorrower": 'prequalify/co-borrower',
	"ApplyCoBorrower": 'apply/co-borrower',
	"Pricing": 'pricing',
	"PricingTable": 'pricing/rates',
	"PricingDetails": 'pricing/rates/details',
	"PricingSettings": 'portal/pricing-settings',
	"Fees": 'portal/fees',
	"Disclosures": 'portal/disclosures/sign-in',
	"DisclosuresSSO": 'portal/disclosures/:id?',
	"Test": 'test',

	// Protected
	"Portal": 'portal',
	"Leads": 'portal/leads',
	"LeadAdd": 'portal/leads/add',
	"LeadEdit": 'portal/leads/edit',
	"LeadOpenHouses": "portal/leads/open-houses",
	"LeadOpenHouseDetail": "portal/leads/open-houses/detail",
	"LeadPropertyInfoBuyer": "portal/leads/property-info/buyer",
	"LeadPropertyInfoSeller": "portal/leads/property-info/seller",
	"LeadFinancingInfo": "portal/leads/financing-info",
	"LeadLifestyleInfo": "portal/leads/lifestyle-info",
	"AgentReferral": 'portal/agent-referral',
	"UserProfile": 'portal/profile',
	"Tasks": 'portal/tasks',
	"CameraView": 'portal/tasks/camera',
	"Documents": 'portal/documents',
	"Document": 'portal/document',
	"LoanApplications": 'portal/applications',
	"LoanApplication": 'portal/application/summary',
	"Apply": 'apply',
	"ApplySpanish": 'apply/esp',
	"LOLeadApp": 'portal/lead-app',
	"LOApply": 'portal/apply/borrower',
	"LOApplySpanish": 'portal/apply/esp',
	"LOApplyCoBorrower": 'portal/apply/coborrower',
	"LOPrequal": 'portal/prequal/borrower',
	"LOPrequalCoBorrower": 'portal/prequal/coborrower',
	"InviteApply": 'invite/apply/:token',
	"InvitePrequalify": 'invite/prequalify/:token',
	"CustomWorkflow": 'app/:slug',

	"QuickPricer": 'portal/pricing',
	"WorkflowEditor": 'portal/workflows',
	"LoanCalculator": 'portal/calculator',
	"LoanPricingResults": 'portal/calculator/pricing',
	"LoanComparison": 'portal/calculator/comparison',

	// Admin
	"AdminDashboard": 'admin',

	"AdminPartner": 'portal/partners',
	"AdminPartnerEdit": 'portal/partners/edit',
	"AdminPartnerEditProfile": 'portal/partners/edit/profile',
	"AdminPartnerEditWebsiteSettings": 'portal/partners/edit/website-settings',

	"AdminAccount": 'portal/account',
	"AdminAccountEdit": 'portal/account/edit',

	"AdminCorporate": 'portal/corporations',
	"AdminCorporateEdit": 'portal/corporations/edit',
	"AdminCorporateEditProfile": 'portal/corporations/edit/profile',
	"AdminCorporateEditWebsiteSettings": 'portal/corporations/edit/website-settings',

	"AdminBranch": 'portal/branches',
	"AdminBranchEdit": 'portal/branches/edit',
	"AdminBranchEditProfile": 'portal/branches/edit/profile',
	"AdminBranchEditWebsiteSettings": 'portal/branches/edit/website-settings',

	"AdminLoanOfficer": 'portal/loanOfficers',
	"AdminLoanOfficerEdit": 'portal/loanOfficers/edit',
	"AdminLoanOfficerEditProfile": 'portal/loanOfficers/edit/profile',
	"AdminLoanOfficerEditWebsiteSettings": 'portal/loanOfficers/edit/website-settings',

	"AdminWorkflow": "portal/workflows",
	"AdminWorkflowEdit": "portal/workflows/edit",

	"AdminDevice": "portal/kiosks",
	"AdminDeviceEdit": "portal/kiosks/edit",

	"AdminIntegrations": 'portal/integrations',
	"AdminUser": 'portal/users',
	"AdminRequestQueue": 'portal/queue',

	"AdminNotificationTemplates": 'portal/notifications/templates',
	"AdminNotificationTemplateEdit": 'portal/notifications/templates/edit',
	"AdminDocumentTemplates": 'portal/documents/templates',
	"AdminDocumentTemplateEdit": 'portal/documents/templates/edit',

	"AdminRules": 'portal/rules',
	"AdminRuleTasks": 'portal/rules/tasks',

}

/**
 * Get Application Links
 *
 * @param siteConfig
 * @returns {[{staticIcon, fieldName: string, animatedIcon, name: string, link: string, description: string, entityTypes: number[], id: string}, {staticIcon, fieldName: string, animatedIcon, name: string, link: string, description: string, entityTypes: number[], id: string}, {staticIcon, fieldName: string, animatedIcon, name: string, link: string, description: string, entityTypes: number[], id: string}, {staticIcon: never, fieldName: string, animatedIcon: never, name: string, description: string, entityTypes: (number)[], id: string}, {staticIcon: never, fieldName: string, animatedIcon: never, name: string, link: string, description: string, entityTypes: (number)[], id: string}, null, null, null, null]}
 */
export const getAppLinks = (siteConfig = {}) => {
	return [
		{ name: 'Buy a Home', id: "BuyerClient", fieldName: 'buyerClient', staticIcon: BuyAHomePNG, animatedIcon: BuyAHomeGIF, description: 'Find the perfect home', link: navigationLinking.BuyerClient, entityTypes: [SiteTypes.realtor] },
		{ name: 'Sell my Home', id: "SellerClient", fieldName: 'sellerClient', staticIcon: SellMyHomePNG, animatedIcon: SellMyHomeGIF, description: 'Sell my home', link: navigationLinking.SellerClient, entityTypes: [SiteTypes.realtor] },
		{ name: 'Client Lifestyle', id: "ClientLifestyle", fieldName: 'clientLifestyle', staticIcon: ClientLifestylePNG, animatedIcon: ClientLifestyleGIF, description: 'Tailor my experience', link: navigationLinking.ClientLifestyle, entityTypes: [SiteTypes.realtor] },
		{ name: siteConfig.byPhoneTitle || 'By Phone', id: 'RingCentral', fieldName: 'ringCentral', staticIcon: siteConfig.byPhoneStaticIcon || PhonePNG, animatedIcon: siteConfig.byPhoneAnimatedIcon || PhoneGIF, description: siteConfig.byPhoneSubtitle || 'Speak with an agent', entityTypes: [SiteTypes.corporate, SiteTypes.branch, SiteTypes.loanOfficer] },
		{ name: siteConfig.shortAppTitle || 'Get Pre-Qualified', id: "Prequalify", fieldName: 'mobilePrequal', staticIcon: siteConfig.shortAppStaticIcon || PrequalPNG, animatedIcon: siteConfig.shortAppAnimatedIcon || PrequalGIF, description: siteConfig.shortAppSubtitle || 'Pre-qualify for a loan', link: navigationLinking.Prequalify, entityTypes: [SiteTypes.corporate, SiteTypes.branch, SiteTypes.loanOfficer, SiteTypes.realtor] },
		{ name: siteConfig.fullAppTitle || 'Get Pre-Approved', id: "Apply", fieldName: 'fullApp', staticIcon: siteConfig.fullAppStaticIcon || ApplicationPNG, animatedIcon: siteConfig.fullAppAnimatedIcon || ApplicationGIF, description: siteConfig.fullAppSubtitle || 'Apply for a loan', link: navigationLinking.Apply, entityTypes: [SiteTypes.corporate, SiteTypes.branch, SiteTypes.loanOfficer, SiteTypes.realtor] },
		{ name: 'Super Short App', id: "LeadApp", fieldName: 'leadApp', staticIcon: siteConfig.superShortAppStaticIcon || TimerPNG, animatedIcon: siteConfig.superShortAppAnimatedIcon || TimerGIF, description: 'I just want some info', link: navigationLinking.LeadApp, entityTypes: [SiteTypes.corporate, SiteTypes.branch, SiteTypes.loanOfficer, SiteTypes.realtor] },
		{ name: siteConfig.spanishPrequalTitle || 'Forma Corta', id: "PrequalifySpanish", fieldName: 'spanishPrequal', staticIcon: siteConfig.shortAppStaticIcon || PrequalPNG, animatedIcon: siteConfig.shortAppAnimatedIcon || PrequalGIF, description: siteConfig.spanishPrequalSubtitle || 'Obtenga una Precalificación', link: navigationLinking.PrequalifySpanish, entityTypes: [SiteTypes.corporate, SiteTypes.branch, SiteTypes.loanOfficer, SiteTypes.realtor] },
		{ name: siteConfig.spanishFullAppTitle || 'Forma Completa', id: "SpanishFullApp", fieldName: 'spanishFullApp', staticIcon: siteConfig.fullAppStaticIcon || ApplicationPNG, animatedIcon: siteConfig.fullAppAnimatedIcon || ApplicationGIF, description: siteConfig.spanishFullAppSubtitle || 'Solicitar Préstamo', link: navigationLinking.ApplySpanish, entityTypes: [SiteTypes.corporate, SiteTypes.branch, SiteTypes.loanOfficer, SiteTypes.realtor] },
		// { name: 'Mobile App', fieldName: 'MobileApp', staticIcon: siteConfig.FullAppStaticIcon || MobilePNG, animatedIcon: siteConfig.FullAppAnimatedIcon || MobileGIF, description: 'Tracking on the go', link: navigationLinking.CreateAccount },
		// { name: 'Alexa', fieldName: 'Alexa', staticIcon: siteConfig.AlexaStaticIcon || MobilePNG, animatedIcon: siteConfig.AlexaAnimatedIcon || MobileGIF, description: 'Apply with Alexa', link: siteConfig.alexaUrl || 'https://www.amazon.com/Francisco-Correal-loan-application/dp/B07Y7WDWQK/ref=sr_1_1?d[…]words=loan-application&qid=1610083950&s=digital-skills&sr=1-1' }
	].filter(app => siteConfig?.enabledServices[app.fieldName] !== false && app.entityTypes.includes(siteConfig.entityType))
}

/**
 * Get route name from url path
 *
 * @param path
 * @returns {string}
 */
export const getRouteNameFromPath = path => {
	path = path.substring(1)
	for (const [key, value] of Object.entries(navigationLinking)) {
		if (value === path) return key

		// match routes with variables
		const indexesToIgnore = []
		if (value.includes(':')) {
			const valueParts = value.split('/')
			valueParts.forEach((part, index) => {
				if (part.substring(0,1) === ':') indexesToIgnore.push(index)
			})

			const pathParts = path.split('/')
			if (pathParts.length === valueParts.length) {
				let matching = true
				pathParts.forEach((part, index) => {
					if (!indexesToIgnore.includes(index) && part !== valueParts[index]) matching = false
				})
				if (matching) return key
			}
		}
	}
	return ''
}

/**
 * Get Actions for Portal / Nav by User Role
 * @param user
 * @param siteConfig
 * @param loanId
 * @returns {*[]}
 */
export const getActionsByRole = (user, siteConfig = {}, loanId = '') => {
	const role = user.role
	let actions = []
	switch (true) {
		// Loan Officer
		case [Roles.loanOfficer, Roles.branchManager].includes(role):
			actions = [
				{ name: "My Pipeline", id: "loanApplications", staticIcon: siteConfig.pipelineStaticIcon || DollarCoinPNG, animatedIcon: siteConfig.pipelineAnimatedIcon || DollarCoinGIF, route: navigationLinking.LoanApplications, description: 'View My Loans' },
				{ name: "Leads", id: "leads", staticIcon: UsersPNG, animatedIcon: UsersGIF, route: navigationLinking.Leads, description: 'View Leads' },
				{ name: "Quick Pricer", id: "pricingCalculator", staticIcon: siteConfig.quickPricerStaticIcon || QuickPricerPNG, animatedIcon: siteConfig.quickPricerAnimatedIcon || QuickPricerGIF, route: navigationLinking.QuickPricer, description: 'Price a Loan' }
			]
			if (loanId)
				actions = [
					...[
						{ name: "Loan Details", id: "loanDetails", staticIcon: siteConfig.loanDetailsStaticIcon || ListPNG, animatedIcon: siteConfig.loanDetailsAnimatedIcon || ListGIF, description: 'Loan Details', action: 'loanDetails', route: navigationLinking.LoanApplication },
						{ name: "Tasks", id: "tasks", staticIcon: siteConfig.rulesStaticIcon || RulesPNG, animatedIcon: siteConfig.rulesAnimatedIcon || RulesGIF, route: navigationLinking.Tasks, description: 'Borrower Tasks' },
						{ name: "Documents", id: "documents", staticIcon: siteConfig.documentsStaticIcon || DocumentsPNG, animatedIcon: siteConfig.documentsAnimatedIcon || DocumentsGIF, route: navigationLinking.Documents, description: 'Borrower Documents' },
						{ name: "Loan Calculator", id: "loanCalculator", staticIcon: siteConfig.calculatorStaticIcon || CalculatorPNG, animatedIcon: siteConfig.calculatorAnimatedIcon || CalculatorGIF, route: navigationLinking.LoanCalculator, description: 'Calculate Payments' },
						{ name: "Add a Co-Borrower", id: "invite", staticIcon: siteConfig.addCoBorrowerStaticIcon || AddCoBorrowerPNG, animatedIcon: siteConfig.addCoBorrowerAnimatedIcon || AddCoBorrowerGIF, description: 'Send an Invite', action: 'invite' },
						// { name: "New Application", id: "LODuplicate", staticIcon: siteConfig.fullAppStaticIcon || ApplicationPNG, animatedIcon: siteConfig.fullAppAnimatedIcon || ApplicationGIF, route: navigationLinking.LOApply, description: 'Duplicate Application' }
					],
					...actions,
					...[
						{ name: "Encompass Web", id: "encompassWeb", staticIcon: siteConfig.loConnectStaticIcon || LOConnectPNG, animatedIcon: siteConfig.loConnectAnimatedIcon || LOConnectGIF, description: 'Edit Application', action: 'loConnect' },
						{ name: "Need Help?", id: "support", staticIcon: siteConfig.supportStaticIcon || SupportPNG, animatedIcon: siteConfig.supportAnimatedIcon || SupportGIF, href: 'https://support.thebigpos.com', description: 'Get Support' }
					]
				]
			else
				actions = [...actions,
					...[
						{ name: "New Application", id: "fullApp", staticIcon: siteConfig.fullAppStaticIcon || ApplicationPNG, animatedIcon: siteConfig.fullAppAnimatedIcon || ApplicationGIF, route: navigationLinking.LOApply, description: 'Start New Application' },
						{ name: "New Prequal", id: "mobilePrequal", staticIcon: siteConfig.shortAppStaticIcon || PrequalPNG, animatedIcon: siteConfig.shortAppAnimatedIcon || PrequalGIF, route: navigationLinking.LOPrequal, description: 'Start New Prequal' },
						{ name: "New Short App", id: "leadApp", staticIcon: siteConfig.superShortAppStaticIcon || TimerPNG, animatedIcon: siteConfig.superShortAppAnimatedIcon || TimerGIF, route: navigationLinking.LOLeadApp, description: 'Super Short App' },
						{ name: "My Profile", id: "myProfile", staticIcon: siteConfig.myProfileStaticIcon || UserPNG, animatedIcon: siteConfig.myProfileAnimatedIcon || UserGIF, route: navigationLinking.UserProfile, description: 'Security Credentials' },
						{ name: "Encompass Web", id: "encompassWeb", staticIcon: siteConfig.loConnectStaticIcon || LOConnectPNG, animatedIcon: siteConfig.loConnectAnimatedIcon || LOConnectGIF, description: 'Edit Application', action: 'loConnect' },
						{ name: "Need Help?", id: "support", staticIcon: siteConfig.supportStaticIcon || SupportPNG, animatedIcon: siteConfig.supportAnimatedIcon || SupportGIF, href: 'https://support.thebigpos.com', description: 'Get Support' }
					]
				]
			break
		// Borrower and Co-Borrower
		case [Roles.borrower].includes(role):
			actions = [
				{ name: "Apply for a New Loan", id: "fullApp", staticIcon: siteConfig.shortAppStaticIcon || PrequalPNG, animatedIcon: siteConfig.shortAppAnimatedIcon || PrequalGIF, route: navigationLinking.Apply, description: 'New Purchase / Refi', action: 'duplicate' },
				{ name: "Aplicación Nueva", id: "spanishFullApp", staticIcon: siteConfig.shortAppStaticIcon || PrequalPNG, animatedIcon: siteConfig.shortAppAnimatedIcon || PrequalGIF, route: navigationLinking.ApplySpanish, description: 'Nueva Compra / Refi' },
				{ name: "Loan Applications", id: "loanApplications", staticIcon: siteConfig.loanApplicationsStaticIcon || LoanApplicationsPNG, animatedIcon: siteConfig.loanApplicationsAnimatedIcon || LoanApplicationsGIF, route: navigationLinking.LoanApplications, description: 'View My Loans' },
			]
			if (loanId) actions = [...[
				{ name: "Loan Details", id: "loanDetails", staticIcon: siteConfig.loanDetailsStaticIcon || ListPNG, animatedIcon: siteConfig.loanDetailsAnimatedIcon || ListGIF, route: navigationLinking.LoanApplication, description: 'Track Application', action: 'loanDetails' },
				{ name: "Tasks", id: "Tasks", staticIcon: siteConfig.rulesStaticIcon || RulesPNG, animatedIcon: siteConfig.rulesAnimatedIcon || RulesGIF, route: navigationLinking.Tasks, description: 'View My Tasks' },
				{ name: "E-Sign Documents", id: "disclosures", staticIcon: siteConfig.signatureStaticIcon || SignaturePNG, animatedIcon: siteConfig.signatureAnimatedIcon || SignatureGIF, description: 'Sign Documents', action: 'disclosures' },
				{ name: "Documents", id: "documents", staticIcon: siteConfig.documentsStaticIcon || DocumentsPNG, animatedIcon: siteConfig.documentsAnimatedIcon || DocumentsGIF, route: navigationLinking.Documents, description: 'View My Documents' },
				{ name: "Payment", id: "payments", staticIcon: siteConfig.paymentStaticIcon || PaymentPNG, animatedIcon: siteConfig.paymentAnimatedIcon || PaymentGIF, description: 'Make a Payment', action: 'payment' },
				{ name: "Add a Co-Borrower", id: "invite", staticIcon: siteConfig.addCoBorrowerStaticIcon || AddCoBorrowerPNG, animatedIcon: siteConfig.addCoBorrowerAnimatedIcon || AddCoBorrowerGIF, description: 'Send an Invite', action: 'invite' },
			], ...actions]
			actions = [
				...actions,
				...[
					{ name: "My Profile", id: "myProfile", staticIcon: siteConfig.myProfileStaticIcon || UserPNG, animatedIcon: siteConfig.myProfileAnimatedIcon || UserGIF, route: navigationLinking.UserProfile, description: 'Security Credentials' },
					// { name: "Need Help?", id: "Support", staticIcon: siteConfig.supportStaticIcon || SupportPNG, animatedIcon: siteConfig.supportAnimatedIcon || SupportGIF, href: 'https://support.thebigpos.com', description: 'Get Support' }
				]
			]
			break
		// Admin
		case role === Roles.admin:
			actions = [
				// { name: "Loan Applications", id: "LoanApplications", icon: CurrencyInputSVG, staticIcon: siteConfig.LoanApplicationsStaticIcon || DollarCoinPNG, animatedIcon: siteConfig.LoanApplicationsAnimatedIcon || DollarCoinGIF, route: navigationLinking.LoanApplications, description: 'View All Loans' },
				{ name: "Rules", id: "Rules", staticIcon: siteConfig.rulesStaticIcon || RulesPNG, animatedIcon: siteConfig.rulesAnimatedIcon || RulesGIF, route: navigationLinking.AdminRules, description: 'Set Custom Rules' },
				{ name: "Account Settings", id: "accountSettings", staticIcon: siteConfig.accountSettingsStaticIcon || SettingsPNG, animatedIcon: siteConfig.accountSettingsAnimatedIcon || SettingsGIF, route: navigationLinking.AdminAccountEdit, description: 'Billing & Profile' },
				{ name: "Brands", id: "corporations", staticIcon: siteConfig.brandStaticIcon || BrandsPNG, animatedIcon: siteConfig.brandAnimatedIcon || BrandsGIF, route: navigationLinking.AdminCorporate, description: 'Corporate Pages' },
				{ name: "Branches", id: "branches", staticIcon: siteConfig.branchesStaticIcon || LocationPinPNG, animatedIcon: siteConfig.branchesAnimatedIcon || LocationPinGIF, route: navigationLinking.AdminBranch, description: 'Branch Pages' },
				{ name: "Loan Officers", id: "loanOfficers", staticIcon: siteConfig.loanOfficersStaticIcon || LoanOfficersPNG, animatedIcon: siteConfig.loanOfficersAnimatedIcon || LoanOfficersGIF, route: navigationLinking.AdminLoanOfficer, description: 'Loan Officer Pages' },
				{ name: "Partners", id: "partners", staticIcon: siteConfig.realtorsStaticIcon || PartnersPNG, animatedIcon: siteConfig.realtorsAnimatedIcon || PartnersGIF, route: navigationLinking.AdminPartner, description: 'Partner Pages' },
				{ name: "Users", id: "users", staticIcon: siteConfig.usersStaticIcon || UsersPNG, animatedIcon: siteConfig.usersAnimatedIcon || UsersGIF, route: navigationLinking.AdminUser, description: 'Create and Edit Users' },
				{ name: "Notifications", id: "notifications", staticIcon: siteConfig.notificationsStaticIcon || NotificationsPNG, animatedIcon: siteConfig.notificationsAnimatedIcon || NotificationsGIF, route: navigationLinking.AdminNotificationTemplates, description: 'Manage Alerts' },
				{ name: "Documents", id: "generatedDocuments", staticIcon: siteConfig.documentTemplatesStaticIcon || DocumentsPNG, animatedIcon: siteConfig.documentTemplatesAnimatedIcon || DocumentsGIF, route: navigationLinking.AdminDocumentTemplates, description: 'Generated Documents' },
				{ name: "Kiosks", id: "kiosks", staticIcon: KioskPNG, animatedIcon: KioskGIF, route: navigationLinking.AdminDevice, description: 'Manage Kiosks' },
				{ name: "Request Queue", id: "queue", staticIcon: siteConfig.requestQueueStaticIcon || RequestQueuePNG, animatedIcon: siteConfig.requestQueueAnimatedIcon || RequestQueueGIF, route: navigationLinking.AdminRequestQueue, description: 'View Queue' },
				{ name: "What's New?", id: "releaseNotes", staticIcon: siteConfig.releaseNotesStaticIcon || ReleaseNotesPNG, animatedIcon: siteConfig.releaseNotesAnimatedIcon || ReleaseNotesGIF, href: 'https://thebigpos.launchnotes.io', description: 'Release Notes' },
				{ name: "Need Help?", id: "support", staticIcon: siteConfig.supportStaticIcon || SupportPNG, animatedIcon: siteConfig.supportAnimatedIcon || SupportGIF, href: 'https://support.thebigpos.com', description: 'Get Support' },
				{ name: "My Profile", id: "myProfile", staticIcon: siteConfig.myProfileStaticIcon || UserPNG, animatedIcon: siteConfig.myProfileAnimatedIcon || UserGIF, route: navigationLinking.UserProfile, description: 'Security Credentials' },
				{ name: "Workflows", id: "Workflows", staticIcon: siteConfig.workflowsStaticIcon || PrequalPNG, animatedIcon: siteConfig.workflowsAnimatedIcon || PrequalGIF, route: navigationLinking.WorkflowEditor, description: 'Manage Workflows' }
			]
			break
		// Realtor
		case role === Roles.realtor:
			actions = [
				{ name: "Leads", id: "leads", staticIcon: UsersPNG, animatedIcon: UsersGIF, route: navigationLinking.Leads, description: 'View Leads' },
				{ name: "Clients", id: "loanApplications", staticIcon: siteConfig.usersStaticIcon || ClientsPNG, animatedIcon: siteConfig.usersAnimatedIcon || ClientsGIF, route: navigationLinking.LoanApplications, description: 'View My Clients' },
				//{ name: "Pricer", id: "PricingCalculator", staticIcon: siteConfig.QuickPricerStaticIcon || QuickPricerPNG, animatedIcon: siteConfig.QuickPricerAnimatedIcon || QuickPricerGIF, route: navigationViews.QuickPricer, description: 'Price a Loan' }
			]
			if (loanId)
				actions = [...[
					{ name: "Loan Details", id: "loanDetails", staticIcon: siteConfig.loanDetailsStaticIcon || ListPNG, animatedIcon: siteConfig.loanDetailsAnimatedIcon || ListGIF, description: 'Loan Details', action: 'loanDetails', route: navigationLinking.LoanApplication },
					{ name: "Tasks", id: "tasks", staticIcon: siteConfig.rulesStaticIcon || RulesPNG, animatedIcon: siteConfig.rulesAnimatedIcon || RulesGIF, route: navigationLinking.Tasks, description: 'Borrower Tasks' },
					{ name: "Documents", id: "documents", staticIcon: siteConfig.documentsStaticIcon || DocumentsPNG, animatedIcon: siteConfig.documentsAnimatedIcon || DocumentsGIF, route: navigationLinking.Documents, description: 'Borrower Documents' },
					{ name: "Loan Calculator", id: "loanCalculator", staticIcon: siteConfig.calculatorStaticIcon || CalculatorPNG, animatedIcon: siteConfig.calculatorAnimatedIcon || CalculatorGIF, route: navigationLinking.LoanCalculator, description: 'Calculate Payments' },
					// { name: "Invite Co-Borrower", id: "Invite", staticIcon: siteConfig.addCoBorrowerStaticIcon || AddCoBorrowerPNG, animatedIcon: siteConfig.addCoBorrowerAnimatedIcon || AddCoBorrowerGIF, description: 'Send an Invite', action: 'invite' },
					// { name: "Duplicate", id: "LODuplicate", staticIcon: siteConfig.fullAppStaticIcon || ApplicationPNG, animatedIcon: siteConfig.fullAppAnimatedIcon || ApplicationGIF, route: navigationViews.LOApply, description: 'Duplicate Application' }
				], ...actions]
			else
				actions = [...actions,
					...[
						{ name: "Refer New Client", id: "agentReferral", staticIcon: siteConfig.addCoBorrowerStaticIcon || AddCoBorrowerPNG, animatedIcon: siteConfig.addCoBorrowerAnimatedIcon || AddCoBorrowerGIF, route: navigationLinking.AgentReferral, description: 'New Application' },
					]
				]
			actions = [
				...actions,
				...[
					{ name: "My Profile", id: "myProfile", staticIcon: siteConfig.myProfileStaticIcon || UserPNG, animatedIcon: siteConfig.myProfileAnimatedIcon || UserGIF, route: navigationLinking.UserProfile, description: 'Security Credentials' },
					{ name: "Need Help?", id: "support", staticIcon: siteConfig.supportStaticIcon || SupportPNG, animatedIcon: siteConfig.supportAnimatedIcon || SupportGIF, href: 'https://support.thebigpos.com', description: 'Get Support' }
				]
			]
			break
		// All others default to clients and documents
		default:
			actions = [
				{ name: "Clients", id: "loanApplications", staticIcon: siteConfig.usersStaticIcon || ClientsPNG, animatedIcon: siteConfig.usersAnimatedIcon || ClientsGIF, route: navigationLinking.LoanApplications, description: 'View My Clients' },
			]
			if (loanId)
				actions = [...[
					{ name: "Documents", id: "documents", staticIcon: siteConfig.documentsStaticIcon || DocumentsPNG, animatedIcon: siteConfig.documentsAnimatedIcon || DocumentsGIF, route: navigationLinking.Documents, description: 'Borrower Documents' },
				], ...actions]
			actions = [
				...actions,
				...[
					{ name: "My Profile", id: "myProfile", staticIcon: siteConfig.myProfileStaticIcon || UserPNG, animatedIcon: siteConfig.myProfileAnimatedIcon || UserGIF, route: navigationLinking.UserProfile, description: 'Security Credentials' },
					{ name: "Need Help?", id: "support", staticIcon: siteConfig.supportStaticIcon || SupportPNG, animatedIcon: siteConfig.supportAnimatedIcon || SupportGIF, href: 'https://support.thebigpos.com', description: 'Get Support' }
				]
			]
			break
	}

	return actions
}
