import React from 'react'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow
} from "@mui/material";
import {applyMask} from "../../services/utils";

const LOLoanComparisonScenario = ({ width, scenario, loanData }) => {
	const {
		loanPurpose,
		zipCode,
		creditScore,
		taxes,
		insurance,
		escrow,
		loanTerm1,
		loanTerm2,
		propertyOccupancy,
		propertyType,
		county,
		flood,
		hoa,
		borrowerIncome,
	} = loanData;

	const {
		loanType,
		propertyValue,
		downpaymentAmount,
		downpaymentPercent,
		loanAmount,
		totalAssets,
		monthlyPayment,
		totalMortgageAmount,
		dtiFront,
		dtiBack,
		ltvFront,
		ltvBack,
		totalCashToClose,
		apr,
		mi,
		miFactor,
		rate
	} = scenario;

	const loanPurposeMap = {
		Purchase: 'Purchase',
		'NoCash-Out Refinance': 'Refi',
		'Cash-Out Refinance': 'Cash Out Refi',
	}

	const escroMap = {
		Y: 'Taxes and Insurance',
		N: 'No Escrow'
	};

	const propertyOccupancyMap = {
		PrimaryResidence: 'Primary Residence',
		SecondHome: 'Second Home',
		InvestmentProperty: 'Investment'
	};

	const propertyTypeMap = {
		SingleFamily: 'Single Family',
		MultiFamily: 'Multi Family 2-4 Units',
		Condo: 'Condo',
		TownHome: 'Town Home',
		CoOp: 'Co-Op'
	};

	return (
		<>
			<TableContainer className="w-100">
				<Table aria-label="loan scenario table">
					<TableBody>
						<TableRow>
							<TableCell align="left">Loan Purpose</TableCell>
							<TableCell align="right">{loanPurpose ? loanPurposeMap[loanPurpose] : ''}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Loan Type</TableCell>
							<TableCell align="right">{loanType}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Property Value</TableCell>
							<TableCell align="right">${applyMask(propertyValue, "fullCurrency")}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Down Payment Amount</TableCell>
							<TableCell align="right">${applyMask(downpaymentAmount, "fullCurrency")}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Down Payment Percent</TableCell>
							<TableCell align="right">{downpaymentPercent}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Loan Amount</TableCell>
							<TableCell align="right">${applyMask(loanAmount, "fullCurrency")}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Zip Code</TableCell>
							<TableCell align="right">{zipCode}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Credit Score</TableCell>
							<TableCell align="right">{creditScore}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Monthly Taxes</TableCell>
							<TableCell align="right">${applyMask(taxes, "fullCurrency")}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Monthly Insurance</TableCell>
							<TableCell align="right">${applyMask(insurance, "fullCurrency")}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Escrow</TableCell>
							<TableCell align="right">{escrow ? escroMap[escrow] : ''}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Total Assets</TableCell>
							<TableCell align="right">${applyMask(totalAssets, "fullCurrency")}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Loan Term 1</TableCell>
							<TableCell align="right">{loanTerm1}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Loan Term 2</TableCell>
							<TableCell align="right">{loanTerm2}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Property Occupancy</TableCell>
							<TableCell align="right">{propertyOccupancy ? propertyOccupancyMap[propertyOccupancy] : ''}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Home Type</TableCell>
							<TableCell align="right">{propertyType ? propertyTypeMap[propertyType] : ''}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Borrower Income</TableCell>
							<TableCell align="right">${applyMask(borrowerIncome, "fullCurrency")}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Rate</TableCell>
							<TableCell align="right">{rate}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">County</TableCell>
							<TableCell align="right">{county}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Monthly Flood</TableCell>
							<TableCell align="right">{flood}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Monthly HOA</TableCell>
							<TableCell align="right">{hoa}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">MI Factor</TableCell>
							<TableCell align="right">{miFactor}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">MI</TableCell>
							<TableCell align="right">{mi}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<div className="mt-4">
				<p className="text-xl font-rubik font-bold md:mr-2 dark:text-white">
					Calculated Values
				</p>
				<TableContainer>
					<Table aria-label="calculated values table">
						<TableBody>
							<TableRow>
								<TableCell align="left">Monthly Payment</TableCell>
								<TableCell align="right">${applyMask(monthlyPayment, "fullCurrency")}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">DTI Front</TableCell>
								<TableCell align="right">{dtiFront}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">DTI Back</TableCell>
								<TableCell align="right">{dtiBack}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">LTV</TableCell>
								<TableCell align="right">{ltvFront}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">CLTV</TableCell>
								<TableCell align="right">{ltvBack}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">Cash to Close</TableCell>
								<TableCell align="right">${applyMask(totalCashToClose, "fullCurrency")}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">APR</TableCell>
								<TableCell align="right">{apr}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left">Total Mortgage Amount</TableCell>
								<TableCell align="right">${applyMask(totalMortgageAmount, "fullCurrency")}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	)
}

export default LOLoanComparisonScenario
