import { useEffect, useState, useReducer } from "react"
import { getSiteConfigurationByUrl } from "../services/client"
import { isDarkMode } from "../services/helper"
import { setThemeColorsAndImages, setFavicon, setTitle } from "../config"
import { faFacebookF, faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const initialState = {
	siteConfig: null,
	socialLinks: null,
	legalLinks: null
}

export function useSiteConfig() {
	const [state, dispatch] = useReducer(reducer, initialState, init);
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		getSiteConfigurationByUrl(window.location.hostname).then(siteConfig => {
			setThemeColorsAndImages(siteConfig)
			setFavicon(isDarkMode() && siteConfig.darkModeIconUrl ? siteConfig.darkModeIconUrl : siteConfig.iconUrl)
			setTitle(siteConfig.name)

			// Set Social Links
			const socialLinks = []
			if (siteConfig.facebookUrl) socialLinks.push({ linkItem: 'Facebook', link: siteConfig.facebookUrl, icon: faFacebookF })
			if (siteConfig.twitterUrl) socialLinks.push({ linkItem: 'Twitter', link: siteConfig.twitterUrl, icon: faXTwitter })
			if (siteConfig.instagramUrl) socialLinks.push({ linkItem: 'Instagram', link: siteConfig.instagramUrl, icon: faInstagram })
			if (siteConfig.linkedInUrl) socialLinks.push({ linkItem: 'Linked In', link: siteConfig.linkedInUrl, icon: faLinkedin })

			// Set Legal Links
			const legalLinks = []
			if (siteConfig.companyUrl) legalLinks.push({ linkItem: 'Website', link: siteConfig.companyUrl })
			if (siteConfig.contactUsUrl) legalLinks.push({ linkItem: 'Contact Us', link: siteConfig.contactUsUrl })
			if (siteConfig.privacyPolicyUrl) legalLinks.push({ linkItem: 'Privacy Policy', link: siteConfig.privacyPolicyUrl })
			if (siteConfig.termsUrl) legalLinks.push({ linkItem: 'Terms of Use', link: siteConfig.termsUrl })
			if (siteConfig.licenseInfoUrl) legalLinks.push({ linkItem: 'License Information', link: siteConfig.licenseInfoUrl })

			dispatch({
				type: "setSiteConfig",
				payload: { siteConfig, socialLinks, legalLinks }
			})
		}).catch(err => {
			console.warn("getSiteConfigurationByUrl", err)
		}).finally(() => {
			setIsLoading(false)
		})
	}, []);

	return {
		siteConfig: state.siteConfig,
		socialLinks: state.socialLinks,
		legalLinks: state.legalLinks,
		isLoading
	}
}

function init() {
	return initialState
}

function reducer(state, action) {
	switch (action.type) {
		case 'setSiteConfig':
			return {
				siteConfig: action.payload.siteConfig,
				socialLinks: action.payload.socialLinks,
				legalLinks: action.payload.legalLinks
			}
		default:
			throw new Error()
	}
}

