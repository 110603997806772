import React from "react"
import { useNavigate } from "react-router-dom"
import { IconButton, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Button } from "./Button"
import { useAppContextActions } from "./AppContext/AppHooks"
import { navigationLinking } from "../services/navigation"
import { isDarkMode } from "../services/helper"
import Brightness7Icon from "@mui/icons-material/Brightness7"
import Brightness4Icon from "@mui/icons-material/Brightness4"
import PublishSubscribe from "publish-subscribe-js"

export const HeaderOpen = ({ siteConfig, user, landing, signOut }) => {
	const { logOut } = useAppContextActions()
	const navigate = useNavigate()
	const themeMUI = useTheme()

	const changeMode = () => {
		PublishSubscribe.publish("SET_DARK_MODE",
			themeMUI.palette.mode === 'light' ? 'dark' : 'light')
	}

	return (
		<div className="h16 w-full flex flex-row items-center justify-between py-3.5">
			<div className="mt-1 ml-4 mr-4" style={{ maxWidth: "250px" }}>
				<a href="/">
					<img alt="Logo" src={isDarkMode() && siteConfig?.darkModeLogoUrl ? siteConfig.darkModeLogoUrl : siteConfig?.logoUrl} />
				</a>
			</div>
			<div className="flex flex-row items-center justify-end md:w-1/3 py-2.5 mr-4">
				<div className="mr-2">
					<Tooltip title={`Turn on ${themeMUI.palette.mode === 'light' ? 'Dark' : 'Light'} mode`}>
						<IconButton sx={{ ml: 1 }} onClick={changeMode} color="inherit">
							{themeMUI.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
						</IconButton>
					</Tooltip>
				</div>
				{!!siteConfig?.contactUsUrl && landing && (
					<div className="mr-3">
						<Button text="Help" variant="outlined" onClick={() => window.open(siteConfig.contactUsUrl, "_blank")} />
					</div>
				)}
				{!landing && (
					<div className="mr-3">
						<Button text="Help" variant="outlined" onClick={() => window.open(siteConfig.contactUsUrl, "_blank")} />
					</div>
				)}
				{!user?.isLoggedIn && typeof signOut !== 'function'
					? <Button style={{ minWidth: "80px" }} text="Sign In" onClick={() => { navigate(`/${navigationLinking.SignIn}`) }} />
					: <Button style={{ minWidth: "90px" }} text="Log Out" variant="outlined" onClick={() => typeof signOut !== 'function' ? logOut("/") : signOut()} />}
			</div>
		</div>
	)
}
