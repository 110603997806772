import { useState, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useVenti } from "venti"
import { fromAddress } from "react-geocode"
import * as _ from "lodash"
import { Icon, Divider, Tabs, Tab } from "@mui/material"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { useTheme } from '@mui/material/styles'
import SwipeableViews from 'react-swipeable-views'
import { useAppContext } from "../../components/AppContext"
import { applyMask } from "../../services/utils"
import { getErrorMessage, a11yProps } from "../../services/helper"
import Page from "../../components/Page"
import { navigationLinking } from "../../services/navigation"
import { Button, LoadingBtn } from "../../components/Button"
import { LOCalculatorForm } from "../../components/lo/LOCalculatorForm"
import { LOLockedCalculatorForm } from "../../components/lo/LOLockedCalculatorForm"
import {
	getLOCalculation,
	getLoanPricing,
	runLOCalculation,
	verify,
	Roles,
	getLoanDocuments,
	getLoan,
	getDocumentTemplatesByType,
	generateDocumentFromTemplate
} from '../../services/client'
import { getTheme } from "../../config"
import { useAlert, useWindowSize } from "../../hooks"
import TabPanel from "../../components/TabPanel"
import LOCalculatorResults from "../../components/lo/LOCalculatorResults"
import LOLoanComparisonForm from "../../components/lo/LOLoanComparisonForm"
import { clone } from "lodash";
import { ModalGeneratedDocumentSelector } from '../../components/modals/ModalGeneratedDocumentSelector'

const theme = getTheme()

const initialFormState = {
	loanID: '',
	loanAmount: '',
	totalMortgageAmount: '',
	propertyValue: '',
	downPaymentAmount: '',
	downPaymentPercent: '',
	propertyType: '',
	loanType: '',
	zipCode: '',
	city: '',
	county: '',
	state: '',
	loanPurpose: '',
	propertyOccupancy: '',
	escrow: '',
	loanTerm1: '',
	loanTerm2: '',
	creditScore: '',
	taxes: '',
	insurance: '',
	borrowerIncome: '',
	loanProgram: '',
	rate: '',
	monthlyPayment: '',
	principleAndInterestPITIField: '',
	dtiFront: '',
	dtiBack: '',
	ltvFront: '',
	ltvBack: null,
	totalCashtoClose: '',
	apr: '',
	flood: '',
	hoa: '',
	miFactor: '',
	mi: '', // read only
	totalAssets: '', // read only
	loanLocked: false, // read only
	canGeneratePreQual: false, // read only
	canGeneratePreApproval: false, // read only
	lienType: ''
}

export default function LoanCalculator() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const navigate = useNavigate()
	const [width] = useWindowSize()
	const themeMUI = useTheme()

	const { siteConfig, user } = state

	const ventiState = useVenti()
	const loanId = ventiState.get(theme.storageKeys.loanId)

	const [pleaseWaitText, setPleaseWaitText] = useState("")
	const [counties, setCounties] = useState([])
	const [zipcodeResults, setZipcodeResults] = useState([])
	const [selectedTab, setSelectedTab] = useState(0)
	const [documentTemplateSelectorVisible, setDocumentTemplateSelectorVisible] = useState(false)
	const [documentTemplates, setDocumentTemplates] = useState([])

	const tabRoutes = [
		{ key: 'loanCalculatorAdjustments', title: 'Adjustments', index: 0 },
		{ key: 'loanComparison', title: 'Loan Comparison', index: 1 },
	]

	const [formData, setFormData] = useState(clone(initialFormState))

	const isLoOrBM = useMemo(() => {
		return [Roles.loanOfficer, Roles.branchManager].includes(user.role)
	}, [user])

	const buttonDisabled = false
	const pricingButtonDisabled = !formData.loanAmount
		|| !formData.propertyValue
		|| !formData.propertyType
		|| !formData.creditScore
		|| !formData.city
		|| !formData.county
		|| !formData.state
		|| !formData.zipCode
		|| !formData.loanPurpose
		|| !formData.loanType
		|| (!formData.loanTerm2 && !formData.loanTerm1)
		|| !formData.propertyOccupancy

	useEffect(() => {
		if (loanId) {
			(async () => {
				try {
					setPleaseWaitText(`Retrieving Calculations from ${siteConfig.los?.name || "Encompass"}`)
					const data = await getLOCalculation(loanId)

					if (data.zipCode && (!data.county || !data.city || !data.state)) {
						try {
							const { results } = await fromAddress(data.zipCode)
							const addressComponents = results[0].address_components
							if (results.length > 1) {
								setCounties(results.map(result => result.address_components.filter(c => c.types.includes("administrative_area_level_2"))))
							}
							if (!data.county) data.county = addressComponents.find(comp => comp.types.includes('administrative_area_level_2'))?.long_name.replace(' County', '') || ''
							if (!data.city) data.city = addressComponents.find(comp => comp.types.includes('locality') || comp.types.includes('political'))?.long_name || ''
							if (!data.state) data.state = addressComponents.find(comp => comp.types.includes('administrative_area_level_1')).short_name || ''
						} catch (e) {
							alert(`Geocode failed for zipcode ${data.zipCode}. Check console for error.`, { severity: "warning" })
						}
					}

					setFormData({
						...formData,
						...{
							...data,
							downPaymentAmount: applyMask(data.downPaymentAmount.replace('.00', ''), 'currency'),
							downPaymentPercent: applyMask(data.downPaymentPercent, 'percent'),
							loanAmount: applyMask(data.loanAmount.replace('.00', ''), "currency"),
							propertyValue: applyMask(data.propertyValue.replace('.00', ''), "currency"),
							taxes: applyMask(data.taxes, "fullCurrency"),
							insurance: applyMask(data.insurance, "fullCurrency"),
							borrowerIncome: applyMask(data.borrowerIncome, "fullCurrency"),
							totalAssets: applyMask(data.totalAssets, "fullCurrency"),
							lienType: data.lienType || 'First Lien'
						}
					})
				} catch (e) {
					ventiState.set(theme.storageKeys.errorObject, {
						message: getErrorMessage(e)
					})
				} finally {
					setPleaseWaitText("")
				}
			})()
		}
	}, [loanId])

	const handleUpdateSubmit = async () => {
		try {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, `Updating & Retrieving calculations from ${siteConfig.los?.name || "Encompass"}`)
			const data = await runLOCalculation(formData)

			setFormData(current => ({
				...current,
				...{
					...data,
					downPaymentAmount: applyMask(data.downPaymentAmount.replace('.00', ''), 'currency'),
					downPaymentPercent: applyMask(data.downPaymentPercent, 'percent'),
					loanAmount: applyMask(data.loanAmount.replace('.00', ''), "currency"),
					propertyValue: applyMask(data.propertyValue.replace('.00', ''), "currency"),
					taxes: applyMask(data.taxes, "fullCurrency"),
					insurance: applyMask(data.insurance, "fullCurrency"),
					borrowerIncome: applyMask(data.borrowerIncome, "fullCurrency"),
					totalAssets: applyMask(data.totalAssets, "fullCurrency"),
				}
			}))

			const loan = await getLoan(loanId)

			await ventiState.set(theme.storageKeys.loanData, loan)

			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
		} catch (e) {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			ventiState.set(theme.storageKeys.errorObject, {
				message: getErrorMessage(e)
			})
		}
	}

	const handleZipCodeBlur = async event => {
		const val = event.target.value
		fromAddress(val).then(({ results }) => {
			if (results.length > 1) {
				const data = []
				results.forEach(result => {
					data.push({
						city: result.address_components.find(comp => comp.types.includes('locality') || comp.types.includes('political'))?.long_name || '',
						county: result.address_components.find(comp => comp.types.includes('administrative_area_level_2'))?.long_name.replace(' County', '') || '',
						state: result.address_components.find(comp => comp.types.includes('administrative_area_level_1'))?.short_name || ''
					})
				})
				setZipcodeResults(data)
				setCounties(results.map(result => result.address_components.find(comp => comp.types.includes('administrative_area_level_2'))?.long_name.replace(' County', '')))
			} else {
				setCounties([])
			}

			const addressComponents = results[0].address_components
			setFormData(current => ({
				...current,
				city: addressComponents.find(comp => comp.types.includes('locality') || comp.types.includes('political'))?.long_name || '',
				county: addressComponents.find(comp => comp.types.includes('administrative_area_level_2'))?.long_name.replace(' County', '') || '',
				state: addressComponents.find(comp => comp.types.includes('administrative_area_level_1'))?.short_name || ''
			}))

		})
	}

	const handlePricingSubmit = async () => {
		try {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, `Fetching pricing scenarios from ${siteConfig.pricingEngine?.name || "EPPS"}`)

			const loanTerm = formData.loanTerm2 || formData.loanTerm1

			const requestData = {
				...formData,
				totalMortgageAmount: String(parseInt(formData.loanAmount.replace(/[^0-9]+/g, ""))),
				loanAmount: parseInt(formData.loanAmount.replace(/[^0-9]+/g, "")),
				propertyValue: parseInt(formData.propertyValue.replace(/[^0-9]+/g, "")),
				propertyOccupancy: formData.propertyOccupancy === "PrimaryResidence" ? "PrimaryHome" : formData.propertyOccupancy,
				loanTerm: String(parseInt(loanTerm.replace(/[^0-9]+/g, "")) / 12),
				eppsUserName: siteConfig.eppsUserName,
				siteConfigurationId: siteConfig.id
			}

			delete requestData.accountID
			const responseData = await getLoanPricing(requestData)

			let separator = requestData.loanType
			if (separator === "Conventional") separator = "Conf"

			const rates = responseData.rates.filter(rate => rate.price > -4 && rate.price < 4 && rate.loanProgram.toLowerCase().includes(separator.toLowerCase()))

			const grouped = _.groupBy(rates, "rate")

			let parsed = []

			for (const [, value] of Object.entries(grouped)) {
				parsed.push(value.sort((a, b) => a.price - b.price)[0])
			}

			parsed.sort((a, b) => a.rate - b.rate)

			const parIndex = parsed.findIndex(row => parseFloat(row.rate) === parseFloat(formData.rate))

			parsed = parsed
				.slice(parIndex - 3, parIndex + 4)

			await ventiState.set(theme.storageKeys.loanRates, { rates: parsed, formData: requestData })
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			navigate(`/${navigationLinking.LoanPricingResults}`)
		} catch (e) {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			ventiState.set(theme.storageKeys.errorObject, {
				message: getErrorMessage(e)
			})
		}
	}

	const handleVOC = async () => {
		ventiState.set(theme.storageKeys.pleaseWaitMessage, 'Requesting Credit Soft Pull')
		try {
			await verify({
				requestID: "",
				loanID: loanId,
				operations: ["voc"]
			})
			setPleaseWaitText(``)
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			ventiState.set(theme.storageKeys.successObject, { message: "Credit Soft Pull request was successful!" })
		} catch (e) {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			ventiState.set(theme.storageKeys.errorObject, {
				message: getErrorMessage(e)
			})
		}
	}

	const handleGenerateLetter = async (id) => {
		setDocumentTemplateSelectorVisible(false)
		ventiState.set(theme.storageKeys.pleaseWaitMessage, `Please be patient while the document is generated`)
		try {
			await handleUpdateSubmit()
			ventiState.set(theme.storageKeys.pleaseWaitMessage, `Please be patient while the document is generated`)
			const letter = await generateDocumentFromTemplate({
				templateID: id,
				loanID: loanId,
				siteConfigurationID: siteConfig.id,
				preview: false
			})

			ventiState.set(theme.storageKeys.document, { ...letter, extension: letter.extension.replace('.', '') })

			ventiState.set(theme.storageKeys.pleaseWaitMessage, `Fetching loan documents`)
			await ventiState.set(theme.storageKeys.loanDocs, await getLoanDocuments(loanId))
			ventiState.set(theme.storageKeys.pleaseWaitMessage, ``)
			navigate(`/${navigationLinking.Documents}`)
		} catch (e) {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, ``)
			ventiState.set(theme.storageKeys.errorObject, {
				message: getErrorMessage(e)
			})
		}
	}

	const handleOpenLOConnectServices = () => {
		window.open(`https://encompassloconnect.com/pipeline/${loanId.slice(1, -1)}/services`, '_blank', 'noopener,noreferrer')
	}

	const handleTabChange = (event, value) => {
		setSelectedTab(value)
	}

	const handleChangeIndex = (index) => {
		setSelectedTab(index)
	}

	const handleSelectDocumentTemplateType = async (type) => {
		try {
			const templates = await getDocumentTemplatesByType(type)
			setDocumentTemplates(templates)
			if (templates.length > 1) setDocumentTemplateSelectorVisible(true)
			else await handleGenerateLetter(templates[0].id)
		} catch (e) {
			ventiState.set(theme.storageKeys.errorObject, {
				message: "There was an error fetching the document templates. Please contact your administrator."
			})
		}
	}

	const handleCountyChange = (val) => {
		const data = zipcodeResults.find(r => r.county === val)
		setFormData(current => ({ ...current, ...data  }))
	}

	return (
		<>
			<Page page="loan-calculator" title="Loan Calculator" isFullWidth={true}>
				<div className="pl-5 pr-5 pb-10 pt-5 h-screen overflow-auto">
					<p className="text-2xl font-rubik font-bold mr-4 mb-10 mt-2 dark:text-white">Loan Calculator</p>
					{isLoOrBM &&
						<Tabs
							variant="scrollable"
							scrollButtons="auto"
							value={selectedTab}
							onChange={handleTabChange}
							aria-label="Tabs"
						>
							{tabRoutes.map((route) => <Tab value={route.index}
								label={route.title}
								key={route.key} {...a11yProps(route.index)} />)}
						</Tabs>
					}
					<SwipeableViews
						axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
						index={selectedTab}
						onChangeIndex={handleChangeIndex}
					>
						<TabPanel value={selectedTab} index={0} dir={themeMUI.direction}>
							<div className="flex flex-col md:flex-row">
								<div className="flex flex-col grow">
									<div className="flex flex-row items-center mb-4">
										{!isLoOrBM &&
											<p
												className="text-xl font-rubik font-bold mr-2 dark:text-white">
												Adjustments
											</p>
										}
										{formData.loanLocked &&
											<Icon
												aria-label="upload"
												sx={{ height: 40, width: 24 }}
											>
												<LockOutlinedIcon />
											</Icon>
										}
									</div>
									{isLoOrBM ?
										<LOCalculatorForm
											loanLocked={formData.loanLocked}
											submitAction={handleUpdateSubmit}
											handleZipCodeBlur={handleZipCodeBlur}
											counties={counties}
											handleCountyChange={handleCountyChange}
											formData={formData}
											setFormData={setFormData}
											buttonDisabled={buttonDisabled}
											width={width}
										/>
										:
										<LOLockedCalculatorForm
											loanLocked={formData.loanLocked}
											submitAction={handleUpdateSubmit}
											handleZipCodeBlur={handleZipCodeBlur}
											counties={counties}
											handleCountyChange={handleCountyChange}
											formData={formData}
											setFormData={setFormData}
											buttonDisabled={buttonDisabled}
											width={width}
										/>
									}
								</div>
								<Divider orientation="vertical" flexItem />
								<div className="flex flex-col md:ml-2 grow items-center">
									<LOCalculatorResults width={width} formData={formData} />
									<LoadingBtn
										id={`LOCalculatorFormGetRateButton`}
										text={formData.loanLocked ? `Loan is Locked` : `Update Loan & Calculate Payment`}
										onClick={handleUpdateSubmit}
										loading={!!pleaseWaitText}
										disabled={!!pleaseWaitText || buttonDisabled || formData.loanLocked}
										variant="contained"
										style={{ marginBottom: 12, marginTop: 12, width: 280 }}
									/>
									{![Roles.realtor].includes(user.role) && siteConfig.enabledServices.pricingCalculator &&
										<LoadingBtn
											id={`LOCalculatorFormGetRateButton`}
											text="Price Loan & Get Rates"
											onClick={handlePricingSubmit}
											loading={!!pleaseWaitText}
											disabled={!!pleaseWaitText || pricingButtonDisabled || formData.loanLocked}
											variant="contained"
											style={{ marginBottom: 12, width: 280 }}
										/>
									}
									{![Roles.realtor].includes(user.role) && siteConfig.enabledServices.voc &&
										<LoadingBtn
											id={`LOCalculatorFormVOCButton`}
											text="Soft Pull Credit"
											onClick={handleVOC}
											loading={!!pleaseWaitText}
											disabled={!!pleaseWaitText || formData.loanLocked}
											variant="contained"
											style={{ marginBottom: 12, width: 280 }}
										/>
									}
									<LoadingBtn
										id={`LOCalculatorFormVOCButton`}
										text="Credit, AUS, Pricing"
										onClick={handleOpenLOConnectServices}
										loading={!!pleaseWaitText}
										disabled={!!pleaseWaitText || formData.loanLocked}
										variant="contained"
										style={{ marginBottom: 12, width: 280 }}
									/>
									{formData.loanPurpose === "Purchase" &&
										<>
											<Button
												id={`LOCalculatorFormGeneratePrequalLetterButton`}
												text="Generate Prequal Letter"
												onClick={() => handleSelectDocumentTemplateType("PrequalLetter")}
												disabled={!formData.canGeneratePreQual || formData.loanLocked}
												variant="contained"
												style={{ marginBottom: 12, width: 280 }}
											/>
											<Button
												id={`LOCalculatorFormGeneratePreapprovalLetterButton`}
												text="Generate Preapproval Letter"
												onClick={() => handleSelectDocumentTemplateType("PreapprovalLetter")}
												disabled={!formData.canGeneratePreApproval || formData.loanLocked}
												variant="contained"
												style={{ marginBottom: 12, width: 280 }}
											/>
										</>}
								</div>
							</div>
						</TabPanel>
						<TabPanel value={selectedTab} index={1} dir={themeMUI.direction}>
							<LOLoanComparisonForm
								loanLocked={formData.loanLocked}
								submitAction={handleUpdateSubmit}
								handleZipCodeBlur={handleZipCodeBlur}
								handleCountyChange={handleCountyChange}
								counties={counties}
								formData={formData}
								setFormData={setFormData}
								buttonDisabled={buttonDisabled}
								loading={!!pleaseWaitText}
								width={width} />
						</TabPanel>
					</SwipeableViews>
				</div>
				<ModalGeneratedDocumentSelector
					visible={documentTemplateSelectorVisible}
					setVisible={setDocumentTemplateSelectorVisible}
					options={documentTemplates}
					submit={handleGenerateLetter}
					siteConfig={siteConfig} />
			</Page>
		</>
	)
}

export { initialFormState }
