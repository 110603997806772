import React, { useEffect, useState } from "react"
import { useVenti } from "venti"
import Page from "../../../components/Page"
import { DataTable } from "../../../components/DataTable"
import { useAppContext } from "../../../components/AppContext"
import { useAppContextActions } from "../../../components/AppContext/AppHooks"
import { getTheme } from "../../../config"
import { ModalYesNo } from "../../../components/modals/ModalYesNo"
import { deleteCorporate, deleteRequestQueue, getRequestsQueue, runQueuedTask } from '../../../services/client';
import { getErrorMessage } from "../../../services/helper"
import { useAlert } from "../../../hooks"
import { ModalRemoveRecordConfirm } from '../../../components/modals/ModalRemoveRecordConfirm';

const theme = getTheme()

export default function AdminRequestQueue() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user } = state
	const ventiState = useVenti()

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])
	const [activeRow, setActiveRow] = useState()
	const [modalConfirmation, setModalConfirmation] = useState({ open: false, text: "Are you sure you want to send this request?", leavePage: false, canBeSaved: false, isSave: false, event: null })
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [rowToRemove, setRowToRemove] = useState()

	const headers = [
		{ id: "endp", label: "Endpoint" },
		{ id: "errmsg", label: "Error Message" },
		{ id: "user", label: "User" },
		{ id: "created", label: "Created On" },
		{ id: "key", label: "Key" },
	]

	const fields = [
		"endpoint",
		"errorMessage",
		"userEmail",
		"createdAt",
		"requestKey",
	]

	useEffect(() => {
		(async () => {
			if (!user.isLoggedIn) await logOut()
			else await loadRequestQueue()
		})()
	}, [user])

	const loadRequestQueue = async () => {
		try {
			setFetching(true)
			const requestsData = await getRequestsQueue()
			const requests = []
			requestsData.forEach(item => {
				requests.push({ ...item, ID: item.id })
			})
			setData(requests)
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const handleOnUse = row => {
		setActiveRow(row)
		setModalConfirmation(current => ({ ...current, open: true }))
	}

	const sendTask = async () => {
		try {
			setModalConfirmation(current => ({ ...current, open: false }))
			await runQueuedTask(activeRow.id)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || "There was a problem retrying the task. Please contact your administrator.")
		}
	}

	const showRemoveModal = row => {
		setRemoveModalVisible(true)
		setRowToRemove(row)
	}

	const removeRequest = async () => {
		setRemoveModalVisible(false)

		try {
			console.log(rowToRemove)
			await deleteRequestQueue(rowToRemove.id)
			await loadRequestQueue()
			alert(`Queued request successfully removed`)
			setRowToRemove(null)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	return (
		<Page title="Loan Officers" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-screen overflow-auto">
				<ModalYesNo
					yesButtonName="Send"
					noButtonName="Cancel"
					modalConfirmation={modalConfirmation}
					setModalConfirmation={setModalConfirmation}
					modalConfirm={sendTask}
				/>
				<ModalRemoveRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeRequest}
					row={rowToRemove}
				/>
				<DataTable
					data={data}
					headers={headers}
					fields={fields}
					entity={"RequestQueue"}
					title={"Queued Requests"}
					fetching={fetching}
					hideNew={true}
					handleOnUse={handleOnUse}
					remove={showRemoveModal}
					refresh={loadRequestQueue}
				/>
			</div>
		</Page>
	)
}
