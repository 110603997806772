import React, { useState, useEffect } from "react"
import PublishSubscribe from "publish-subscribe-js"
import { useVenti } from "venti"
import { useAppContext } from "../../../components/AppContext"
import { useAppContextActions } from "../../../components/AppContext/AppHooks"
import Page from "../../../components/Page"
import { ModalRemoveRecordConfirm } from "../../../components/modals/ModalRemoveRecordConfirm"
import { DataTable } from "../../../components/DataTable"
import { getForms, deleteForm, undeleteForm, getForm, saveForm } from '../../../services/client';
import { borrowerTypes, formTypes, getErrorMessage } from '../../../services/helper';
import rulesQuestions from "../../../workflows/rules-questions"
import { getTheme } from "../../../config"
import { useAlert } from "../../../hooks"
import { ModalExportData } from '../../../components/modals/ModalExportData';
import { exportToCsv, exportToExcel, exportToJSON } from '../../../services/utils';

const theme = getTheme()

const headers = [
	{ id: "name", label: "Name" },
	{ id: "type", label: "Form Type" },
	{ id: "borrowerType", label: "Borrower Type" },
	{ id: "desc", label: "Description" },
	{ id: "isDefault", label: "System Default" }
]

const fields = ["name", "type", "borrowerType", "description", "isDefault"]

export default function AdminWorkflows() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const ventiState = useVenti()
	const { user } = state

	const [tableData, setTableData] = useState([])
	const [fetching, setFetching] = useState(true)
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] = useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [rowToRestore, setRowToRestore] = useState()
	const [rowToExport, setRowToExport] = useState()
	const [exportModalVisible, setExportModalVisible] = useState(false)

	const loadWorkflows = () => {
			setFetching(true)
			getForms(true).then(data => {
				const overrides = data.filter(row => !row.isDefault).map(row => row.name)
				setTableData(data.filter(row => !row.isDefault || !overrides.includes(row.name)).map(row => ({
					...row,
					borrowerType: borrowerTypes[row.borrowerType],
					type: formTypes[row.type]
				})))
			})
			.catch((e) => {
				alert(getErrorMessage(e), { severity: "error" })
				ventiState.set(theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error)
			})
			.finally(() => {
				setFetching(false)
			})
	}

	useEffect(() => {
		if (!user?.isLoggedIn) {
			logOut()
		} else {
			loadWorkflows()
		}
	}, [user])

	useEffect(() => {
		PublishSubscribe.subscribe("UPDATE_ADMIN_COMPONENT_STATE", async () => {
			await loadWorkflows()
		})

		return () => {
			PublishSubscribe.unsubscribe("UPDATE_ADMIN_COMPONENT_STATE")
		}
	}, [])

	const showRemoveModal = row => {
		setRemoveModalVisible(true)
		setRowToRemove(row)
	}

	const removeForm = async () => {
		setRemoveModalVisible(false)

		try {
			await deleteForm(rowToRemove.id)
			setRowToRemove(null)
			await loadWorkflows()
			alert(`Workflow "${rowToRemove.name}" successfully removed`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const showRestoreModal = row => {
		setRestoreModalVisible(true)
		setRowToRestore(row)
	}

	const restoreForm = async () => {
		setRestoreModalVisible(false)

		try {
			await undeleteForm(rowToRestore.id)
			setTableData(data => data.filter(d => d.id !== rowToRestore.id))
			alert(`Loan Officer ${rowToRestore.name} successfully restored`)
			setRowToRestore(null)
			await loadWorkflows()
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const duplicateRow = async row => {
		const data = await getForm(row.id)
		delete data.id
		data.isDefault = false
		data.accountID = user.accountID
		data.name = `${data.name} Copy`
		await saveForm(data)
		alert(`${data.name} was created`)
		loadWorkflows()
	}

	const showExportModal = async row => {
		try {
			const workflow = await getForm(row.id)
			setRowToExport(workflow)
			setExportModalVisible(true)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}

	}

	const exportRow = (fileType) => {
		const data = rowToExport.formJSON
		switch (fileType) {
			case 'csv':
				exportToCsv(data, rowToExport.name, false)
				break;
			case 'xlsx':
				exportToExcel(data, rowToExport.name, false)
				break;
			case 'json':
				exportToJSON(data, rowToExport.name)
				break;
			default:
				break;
		}
	}

	return (
		<Page title="Workflows" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-screen overflow-auto">
				<ModalRemoveRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeForm}
					row={rowToRemove} />
				<ModalRemoveRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restoreForm}
					row={rowToRestore} />
				<ModalExportData
					visible={exportModalVisible}
					setVisible={setExportModalVisible}
					action={exportRow} />
				<DataTable
					entity={"Workflow"}
					data={tableData}
					headers={headers}
					title={"Workflows"}
					buttonTitle={"Add Workflow"}
					fetching={fetching}
					get={tableData}
					set={setTableData}
					// remove={showRemoveModal}
					// restore={showRestoreModal}
					fields={fields}
					handleDuplicate={duplicateRow}
					handleOnExport={showExportModal}
					refresh={loadWorkflows}
					questions={rulesQuestions}
				/>
			</div>
		</Page>
	)
}
