  export default [
	{
		"title": "New User",
		"qId": "UserRegistration",
		"name": "Borrower Home Address Group",
		"type": "group",
		"target": "",
		"items": [
			{
				"question": "Choose a Role",
				"type": "picker",
				// "options": theme.roles.map((role, index) => ({ label: role, value: index + 1 })),
				"options": [{ label: "Admin", value: "Admin" }],
				"qId": "Role",
				"target": "Email",
				"fieldId": "role",
				"placeholder": "Enter Role",
				"name": "User Role",
				"validation": "numeric"
			},
			{
				"title": "New User",
				"question": "Email Address",
				"type": "text",
				"qId": "Email",
				"target": "FirstName",
				"fieldId": "email",
				"placeholder": "Enter Email",
				"name": "User Email",
				"validation": "email"
			},
			{
				"question": 'Password',
				"type": "text",
				"qId": 'Password',
				"fieldId": "password",
				"placeholder": "Password",
				"name": "Password",
				"secure": true,
				"validation": "password"
			},
			{
				"question": "Phone Number",
				"type": "text",
				"qId": "Phone",
				"fieldId": "phone",
				"placeholder": "Enter Phone Number",
				"name": "Phone",
				"validation": "phone"
			},
			{
				"question": "First Name",
				"type": "text",
				"qId": "FirstName",
				"target": "LastName",
				"fieldId": "firstName",
				"placeholder": "Enter First Name",
				"name": "First Name",
				"validation": "alphanumeric"
			},
			{
				"question": "Last Name",
				"type": "text",
				"qId": "LastName",
				"fieldId": "lastName",
				"placeholder": "Enter Last Name",
				"name": "Last Name",
				"validation": "alphanumeric"
			}
		]
	}
]
