import { Dialog, Link, Typography } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { Button } from "../Button"
import { getTheme } from "../../config"
import { isDarkMode } from "../../services/helper"

const siteTheme = getTheme()

export const ModalByPhone = ({ visible, siteConfig, setVisible }) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

	return (
		<Dialog
			open={visible}
			aria-labelledby="responsive-dialog-title"
		>
			<div className="flex flex-col items-center px-6 md:max-w-lg py-8 md:px-10">
				<img src={isDarkMode() && siteConfig?.darkModeLogoUrl ? siteConfig.darkModeLogoUrl : siteConfig?.logoUrl} alt="Company Logo" className="w-64 sm:w-96 py-10" />
				<Typography>
					<span>To apply over the phone, please call us now at...&nbsp;</span>
					<p className="text-center">
						<Link
							className="text-blue-500 cursor-pointer"
							onClick={() => window.open(`tel:${siteConfig.phone}`)}
							rel="noopener noreferrer"
							underline="hover"
							style={{ color: siteTheme.siteConfig.color.primary, marginTop: 16 }}
						>
							<span>{siteConfig?.phone}</span>
						</Link>
					</p>
				</Typography>
				{fullScreen &&
					<Button
						id="ModalYesNoOKButton"
						text="Call Now"
						onClick={() => window.open(`tel:${siteConfig.phone}`)}
						style={{ width: "90%", marginBottom: 10, marginTop: 20 }}
						variant="contained"
					/>
				}
				<Button
					id="ModalYesNoYesButton"
					text="Close window"
					onClick={() => setVisible(false)}
					style={fullScreen ? { width: "90%" } : { width: 290, marginTop: 20 }}
					variant="outlined"
				/>
			</div>
		</Dialog>
	)
}
