import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { useAppContext } from '../../components/AppContext';
import { getTheme } from '../../config';
import { getItemAsyncStorage, isMobileDevice } from '../../services/helper';
import { navigationLinking } from '../../services/navigation';
import { HeaderOpen } from '../../components/HeaderOpen';
import { generateDisclosuresSSOLogoutUrl } from '../../services/client';
import { useVenti } from 'venti';

const theme = getTheme()

export default function Disclosures() {
	const { state } = useAppContext()
	const { siteConfig, user } = state
	const navigate = useNavigate()
	const ventiState = useVenti()

	// useEffect(() => {
	// 	(async () => {
	// 		try {
	// 			const dp = await getItemAsyncStorage(theme.storageKeys.disclosurePackageInfo)
	// 			if (dp) {
	// 				const packageInfo = JSON.parse(dp)
	// 				if (packageInfo.latToken) setDisclosurePackage(packageInfo)
	// 			}
	// 		} catch (err) {
	// 			console.log(err)
	// 			ventiState.set(theme.storageKeys.errorMessage, 'There was a problem fetching the disclosure package')
	// 		}
	// 	})()
	// }, [])

	useEffect(() => {
		if (siteConfig.entityType === 0) navigate(`/${navigationLinking.AdminDashboard}`)
	}, [siteConfig])

	const handleSignOut = async () => {
		try {
			const ssoToken = ventiState.get(theme.storageKeys.ssoToken)
			window.location.href = await generateDisclosuresSSOLogoutUrl(siteConfig, ssoToken)
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Page page="app-disclosures" title="Disclosures SSO">
			<HeaderOpen siteConfig={siteConfig} user={user} landing signOut={handleSignOut} />

			<div className="em-widget"
					 data-width={ isMobileDevice() ? '100%' : '1160px' }
					 data-height="100%"
					 data-min-height="800px"
					 data-site-id={siteConfig.disclosuresSSOSiteID}
					 data-name="ecc-borrower-portal"
					 data-config={`{"externalLoginWindow":"true"}`}
					 data-aria-label="Borrower Portal" />
		</Page>
	)
}
