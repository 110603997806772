import React, { useEffect, useState } from "react"
import { ModalExportData } from "../modals/ModalExportData"
import DownloadIcon from "@mui/icons-material/Download"
import { IconButton, Tooltip } from '@mui/material';
import { useTheme } from "@mui/material/styles"
import { exportToCsv, exportToExcel, exportToJSON } from "../../services/utils"

export const ExportTable = ({ data, title }) => {
	const themeMUI = useTheme()
	const [exportData, setExportData] = useState()
	const [fileTitle, setFileTitle] = useState()
	const [exportModalOpen, setExportModalOpen] = useState(false)

	useEffect(() => {
		setExportData(data)
	}, [data])

	useEffect(() => {
		setFileTitle(title.toLowerCase().replace(' ', '-'))
	}, [title])

	const exportTable = (fileType) => {
		switch (fileType) {
			case 'csv':
				exportToCsv(exportData, fileTitle)
				break;
			case 'xlsx':
				exportToExcel(exportData, fileTitle)
				break;
			case 'json':
				exportToJSON(exportData, fileTitle)
				break;
			default:
				break;
		}
	}

	return (
		<>
			<ModalExportData visible={exportModalOpen} setVisible={setExportModalOpen} action={exportTable} />
			<Tooltip title={`Export table data`}>
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="Export to CSV"
					sx={{ mr: 2 }}
					onClick={() => setExportModalOpen(true)}
				>
					<DownloadIcon sx={{ color: themeMUI.palette.mode === 'dark' ? "#fff" : "#000" }} />
				</IconButton>
			</Tooltip>
		</>
	)
}
