import React, {useMemo, useState} from "react";
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Heading from "./Heading";
import {Step, StepLabel, Stepper} from "@mui/material";
import DownIcon from "@mui/icons-material/ArrowDropDown"
import UpIcon from "@mui/icons-material/ArrowDropUp"

const MobileStepper = ({ activeStep, steps, showCompletedLabel = false, labelKey = 'label', renderStep = null, className = undefined }) => {
	const [verticalStepperOpen, setVerticalStepperOpen] = useState(false);
	const onCompactStepperClick = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setVerticalStepperOpen(!verticalStepperOpen);
	}

	const percent = useMemo(() => Math.round((activeStep / steps.length) * 100), [activeStep, steps]);

	const activeStepIndex = showCompletedLabel ? activeStep - 1 : activeStep;
	const activeStepObj = steps[activeStepIndex];
	const nextStepObj = steps.length > (activeStepIndex + 1) ? steps[activeStepIndex + 1] : null;

	return (
		<div className={className}>
			<div className="cursor-pointer hover:bg-gray-50 rounded-md border-gray-200 border-2 dark:border-gray-600 dark:hover:bg-gray-900" onClick={onCompactStepperClick}>
				<div className="gray-100 p-2">
					<div className="flex flex-row justify-between items-center">
						<div className="flex flex-row">
							<div className="w-20 h-20 me-5">
								<CircularProgressbar value={percent} text={`${activeStep} of ${steps.length}`} />
							</div>
							<div>
								{activeStepObj && <Heading size="xl">{`${activeStepObj[labelKey]}`}</Heading>}
								{nextStepObj &&
									<p>Next: {nextStepObj[labelKey]}</p>
								}
							</div>
						</div>
						{verticalStepperOpen && <UpIcon />}
						{!verticalStepperOpen && <DownIcon />}
					</div>
				</div>

				{verticalStepperOpen &&
					<div className="bg-white dark:bg-transparent">
						<div className="mt-3 ml-6 p-2">
							<Stepper orientation="vertical" activeStep={activeStep}>
								{steps.map((step, index) => renderStep ? renderStep(step, index) : (
									<Step key={step.id || index}>
										<StepLabel>{step.name}</StepLabel>
									</Step>
								))}
							</Stepper>
						</div>
					</div>
				}
			</div>
		</div>
	)
}

export default MobileStepper
