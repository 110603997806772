export const ProfileView = ({ formData, width }) => {
	return (
		<div className="flex flex-col md:flex-row">
			<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
				<div className={`w-1/4`}>
					<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm font-bold">Name</p>
					{ formData.name }
				</div>
				<div className={`w-1/4`}>
					<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm font-bold">Serial Number</p>
					{ formData.serialNumber }
				</div>
				<div className={`w-1/4`}>
					<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm font-bold">Status</p>
					{ formData.status }
				</div>
				<div className={`w-1/4`}>
					<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm font-bold">Comments</p>
					{ formData.comments }
				</div>
			</div>
		</div>
	)
}
