import { useState, useMemo } from 'react'
import { DataTable } from '../../components/DataTable'
import { useAppContext } from "../../components/AppContext"
import { useAppContextActions } from "../../components/AppContext/AppHooks"
import {
	getAccountApplications, getCorporateApplications, getBranchApplications,
	getLoanOfficerApplications, getBorrowerApplications, getAdminLoanOfficerApplications,
	getRealtorApplications, getSettlementAgentApplications, Roles
} from '../../services/client'
import { applyMask } from '../../services/utils'
import { getErrorMessage } from "../../services/helper"
import Page from "../../components/Page"
import { navigationLinking } from "../../services/navigation"
import { useAlert } from "../../hooks"

export default function Pipeline() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { siteConfig, user } = state

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])
	const [headers, setHeaders] = useState([])
	const [fields, setFields] = useState([])
	const [showAddNew, setShowAddNew] = useState(false)

	const loadApplications = async () => {
		try {
			setFetching(true)

			let apps = []
			let roleBasedHeaders = []
			let roleBasedFields = []
			let showAddButton = user?.role === Roles.borrower || user?.role === Roles.superAdmin
			setShowAddNew(showAddButton)

			switch (true) {
				// Borrower and Co-Borrower
				case [Roles.borrower, Roles.superAdmin].includes(user.role):
					apps = await getBorrowerApplications()
					roleBasedHeaders = [
						{ id: 'loanAmount', label: 'Loan Amount' },
						{ id: 'loanOfficer', label: 'Loan Officer' },
						{ id: 'subjectPropertyAddress', label: 'Address' },
						{ id: 'applicationDate', label: 'Application Date' }
					]
					roleBasedFields = ['loanAmount', 'loanOfficer', 'subjectPropertyAddress', 'applicationDate']
					break
				// Loan Officer
				case [Roles.loanOfficer, Roles.branchManager].includes(user.role):
					apps = await getLoanOfficerApplications()
					roleBasedHeaders = [
						{ id: 'loanNumber', label: 'Loan Number' },
						{ id: 'loanAmount', label: 'Loan Amount' },
						{ id: 'firstName', label: 'First Name' },
						{ id: 'lastName', label: 'Last Name' },
						{ id: 'subjectPropertyAddress', label: 'Address' },
						{ id: 'applicationDate', label: 'Application Date' },
						//{ id: 'currentMilestone', label: 'Current Milestone' },
						{ id: 'loanStatus', label: 'Status' }
					]
					roleBasedFields = [
						'loanNumber',
						'loanAmount',
						'firstName',
						'lastName',
						'subjectPropertyAddress',
						'applicationDate',
						//'currentMilestone',
						'loanStatus'
					]
					break
				// Realtor
				case [Roles.realtor].includes(user.role):
					apps = await getRealtorApplications()
					roleBasedHeaders = [
						{ id: 'loanAmount', label: 'Loan Amount' },
						{ id: 'firstName', label: 'First Name' },
						{ id: 'lastName', label: 'Last Name' },
						{ id: 'role', label: 'Role' },
						{ id: 'subjectPropertyAddress', label: 'Address' },
						{ id: 'applicationDate', label: 'Application Date' }
					]
					roleBasedFields = ['loanAmount', 'firstName', 'lastName', 'role', 'subjectPropertyAddress', 'applicationDate']
					break
				// Settlement Agent
				case [Roles.settlementAgent].includes(user.role):
					apps = await getSettlementAgentApplications()
					roleBasedHeaders = [
						{ id: 'loanNumber', label: 'Loan Number' },
						{ id: 'loanAmount', label: 'Loan Amount' },
						{ id: 'firstName', label: 'First Name' },
						{ id: 'lastName', label: 'Last Name' },
						{ id: 'subjectPropertyAddress', label: 'Address' },
						{ id: 'applicationDate', label: 'Application Date' }
					]
					roleBasedFields = ['loanNumber', 'loanAmount', 'firstName', 'lastName', 'subjectPropertyAddress', 'applicationDate']
					break
				// Admin
				case user.role === Roles.admin:
					switch (siteConfig.entityType) {
						case 0:
							apps = await getAccountApplications(siteConfig.entityID)
							break
						case 1:
							apps = await getCorporateApplications(siteConfig.entityID)
							break
						case 2:
							apps = await getBranchApplications(siteConfig.entityID)
							break
						case 3:
							apps = await getAdminLoanOfficerApplications(siteConfig.email)
							break
						default:
							return true
					}
					roleBasedHeaders = [
						{ id: 'loanAmount', label: 'Loan Amount' },
						{ id: 'loanOfficer', label: 'Loan Officer' },
						{ id: 'firstName', label: 'First Name' },
						{ id: 'lastName', label: 'Last Name' },
						{ id: 'applicationDate', label: 'Application Date' },
					]
					roleBasedFields = ['loanAmount', 'loanOfficer', 'firstName', 'lastName', 'createdAt']
					break
				default:
					return true
			}
			setData(apps.applications)
			setHeaders(roleBasedHeaders)
			setFields(roleBasedFields)
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			setFetching(false)
		}
	}

	useMemo(() => {
		(async () => {
			if (!user.isLoggedIn) {
				await logOut()
			} else {
				await loadApplications()
			}
		})()
	}, [user])

	const tableData = useMemo(() => {
		return data.map(row => (
			{
				...row,
				loanOfficer: row.loanOfficer || 'not yet assigned',
				loanAmount: `${applyMask(row.loanAmount.toFixed(2), 'fullCurrency')}`,
				applicationDate: new Date(row.applicationDate || row.createdAt).toLocaleString()
			}))
	}, [data])

	return (
		<Page page="app-pipeline" title="My Pipeline" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-screen">
				<DataTable
					data={tableData.map(row => {
						const subjectPropertyAddress = []
						if (row.subjectPropertyStreet) subjectPropertyAddress.push(row.subjectPropertyStreet)
						if (row.subjectPropertyUnitNumber) subjectPropertyAddress.push(`${row.subjectPropertyUnitType} ${row.subjectPropertyUnitNumber}`)
						if (row.subjectPropertyCity) subjectPropertyAddress.push(`${row.subjectPropertyCity}, ${row.subjectPropertyState} ${row.subjectPropertyZip}`)

						return { ...row, subjectPropertyAddress: subjectPropertyAddress.length > 0 ? subjectPropertyAddress.map((line, index) => <div key={index}>{line}</div>) : 'TBD'}

					})}
					headers={headers}
					fields={fields}
					entity={'LoanApplication'}
					title={'Loan Applications'}
					sortByField={"applicationDate"}
					sortDirection={"desc"}
					fetching={fetching}
					tableType={'loanApplication'}
					viewPath={navigationLinking.LoanApplication}
					hideNew={!showAddNew}
					refresh={loadApplications}
					user={user}
				/>
			</div>
		</Page>
	)
}
