import { Navigate } from "react-router-dom"
import { useAppContext } from "../components/AppContext"
import { navigationLinking } from "../services/navigation"

export default function PrivateRoute({ children }) {
	const { state } = useAppContext()
	const { authToken } = state

	return (
		authToken ? (
			children
		) : (
			<Navigate
				to={`/${navigationLinking.SignIn}`}
			/>
		)
	)
}
