import {useForm as reactHookUseForm } from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const useForm = ({schema, ...rest}) => {
	return reactHookUseForm({
		resolver: yupResolver(schema),
		...rest
	});
}

export default useForm
