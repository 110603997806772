import React, {useCallback, useEffect, useMemo, useState} from 'react'
import { getMilestoneConfigurations } from '../../services/client';
import DataTable from "../DataTable";
import ModalMilestoneConfiguration from "../modals/ModalMilestoneConfiguration";
import { deleteMilestoneConfiguration } from '../../services/client';
import {getTheme} from "../../config";
import {useAlert} from "../../hooks";
import {useVenti} from "venti";
import {ModalRemoveRecordConfirm} from "../modals/ModalRemoveRecordConfirm";
import {Alert, ToggleButtonGroup, ToggleButton} from "@mui/material";

const theme = getTheme()

const MilestoneConfigurations = () => {
	const [fetching, setFetching] = useState(false)
	const [selectedMilestoneConfiguration, setSelectedMilestoneConfiguration] = useState(null)
	const [modalOpen, setModalOpen] = useState(false)
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
	const [milestoneConfigurations, setMilestoneConfigurations] = useState([])
	const [selectedLoanType, setSelectedLoanType] = useState('Purchase');
	const { alert } = useAlert()
	const ventiState = useVenti()

	const columns = [
		{
			name: '#',
			selector: (row, index) => index + 1,
			width: '50px'
		},
		{
			name: 'Name',
			selector: row => row.name
		},
		{
			name: 'Field ID',
			selector: row => row.fieldID
		},
		{
			name: 'Weight',
			selector: row => row.weight
		}
	]

	const loadMilestoneConfigurations = useCallback(async() => {
		try {
			setFetching(true)
			const results = await getMilestoneConfigurations()
			setMilestoneConfigurations(results)
			setFetching(false)
		} catch (e) {
			const errorMessage = "There was a problem fetching milestones"
			alert(errorMessage, { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage, errorMessage)
		}
	}, [alert])

	useEffect(() => {
		(async () => {
			await loadMilestoneConfigurations()
		})()
	}, [loadMilestoneConfigurations]);

	const onAddClick = () => {
		setModalOpen(true)
	}

	const onEditClick = (row) => {
		setSelectedMilestoneConfiguration(row)
		setModalOpen(true)
	}

	const onDeleteClick = (row) => {
		setSelectedMilestoneConfiguration(row)
		setDeleteConfirmOpen(true)
	}

	const onDeleteConfirm = async () => {
		try {
			await deleteMilestoneConfiguration(selectedMilestoneConfiguration.id)
			setDeleteConfirmOpen(false)
			setSelectedMilestoneConfiguration(null)
			await loadMilestoneConfigurations()
		} catch (e) {
			const errorMessage = "There was a problem deleting the milestone"
			alert(errorMessage, { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage, errorMessage)
		}

	}

	const onModalClose = () => {
		setSelectedMilestoneConfiguration(null)
		setModalOpen(false)
	}

	const onSave = async () => {
		setSelectedMilestoneConfiguration(null)
		setModalOpen(false)
		alert('Milestone configuration saved!')
		await loadMilestoneConfigurations()
	}

	const handleLoanTypeChange = (e, value) => {
		if(value !== null) {
			setSelectedLoanType(value)
		}
	}

	const filteredMilestoneConfigurations = useMemo(
		() => milestoneConfigurations.filter(m => m.loanType === selectedLoanType),
		[milestoneConfigurations, selectedLoanType]
	)

	return (
		<div>
			<ModalMilestoneConfiguration open={modalOpen}
																	 onClose={onModalClose}
																	 onSave={onSave}
																	 milestoneConfiguration={selectedMilestoneConfiguration}
																	 loanType={selectedLoanType} />
			<ModalRemoveRecordConfirm
				removeModalVisible={deleteConfirmOpen}
				setRemoveModalVisible={setDeleteConfirmOpen}
				remove={onDeleteConfirm}
				row={selectedMilestoneConfiguration}
			/>

			{filteredMilestoneConfigurations.length >= 10 && <Alert severity="warning" className="mb-3">Max (10) configurations reached</Alert>}

			<DataTable data={filteredMilestoneConfigurations}
			           titleActions={
				           <ToggleButtonGroup
					           color="primary"
					           value={selectedLoanType}
					           exclusive
					           onChange={handleLoanTypeChange}
					           aria-label="Loan Purpose"
					           className="mb-2"
				           >
					           <ToggleButton value="Purchase">Purchase</ToggleButton>
					           <ToggleButton value="Refinance">Refinance</ToggleButton>
				           </ToggleButtonGroup>
			           }
			           columns={columns}
			           progressPending={fetching}
			           actionItems={[
								   { name: 'Edit', onClick: (e, row) => onEditClick(row) },
								   { name: 'Delete', onClick: (e, row) => onDeleteClick(row) }
							   ]}
			           addNewBtn={{ text: 'Add', onClick: onAddClick, disabled: filteredMilestoneConfigurations.length >= 10 }}
			/>
		</div>

	)
}

export default MilestoneConfigurations
