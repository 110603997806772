import LoadingButton from "@mui/lab/LoadingButton"

export const LoadingBtn = ({ text, id, onClick, disabled, loading,
														 variant = "contained",
														 fullWidth = true, style, className }) => (
	<LoadingButton
		id={id}
		disabled={disabled}
		loading={loading}
		onClick={onClick}
		fullWidth={fullWidth}
		variant={variant}
		className={className}
		style={{
			textTransform: 'none',
			fontSize: "14px",
			...style,
		}}
	>
		{text}
	</LoadingButton>
)
