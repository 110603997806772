import Page from "../../components/Page"

export default function AuthWrapper({ children, siteConfig, title = "",
																			onKeyDown = () => {} }) {
	return (
		<div
			onKeyDown={onKeyDown}
			className="flex justify-center items-center bg-cover min-h-screen"
			style={!siteConfig?.backgroundImageUrl
				? { backgroundColor: siteConfig?.backgroundColor }
				: { backgroundImage: `url(${siteConfig?.backgroundImageUrl})` }}
		>
			<Page page="app-loan-officer-left" title={title} noDarkMode>
					<div className="rounded-lg flex self-center content-center bg-white my-5 dark:bg-slate-800">
						<div className="flex flex-col items-center justify-center w-full py-6 px-6 sm:pb-12 sm:pt-10 md:pt-12 sm:px-12">
							{children}
						</div>
					</div>
			</Page>
		</div>
	);
}
