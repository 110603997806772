import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useVenti } from "venti"
import { getTheme } from '../../config'
import { DialogContent, Dialog, DialogActions, DialogTitle, TextField } from '@mui/material'
import { applyMask } from '../../services/utils'
import { isEmailValid, isPhoneNumberValid } from '../../services/helper'
import { Roles, sendBorrowerInvitation } from '../../services/client'
import { navigationLinking } from '../../services/navigation'
import { Button } from "../Button"
import { LoadingBtn } from "../Button"
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useAlert } from '../../hooks'
import { useAppContext } from '../AppContext';

const theme = getTheme()

export const ModalAddCoBorrower = ({ role, visible, setVisible, siteConfig, blacklist = [] }) => {
	const navigate = useNavigate()
	const ventiState = useVenti()
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { user } = state

	const coBorrowerRoute = ventiState.get(theme.storageKeys.coBorrowerRoute) || Roles.borrower === role ? "ApplyCoBorrower" : "LOApplyCoBorrower"
	const loanId = ventiState.get(theme.storageKeys.loanId)

	const [display, setDisplay] = useState('method')
	const [sending, setSending] = useState(false)
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		emailAddress: '',
		phoneNumber: '',
		sourceURL: `https://${siteConfig.url}/invite/${coBorrowerRoute.toLowerCase().includes('prequalify') ? 'prequalify' : 'apply'}`,
		siteConfigurationID: siteConfig.id,
		relationship: '',
		loanID: ''
	})

	const [validationMessages, setValidationMessages] = useState({
		firstName: '',
		lastName: '',
		emailAddress: '',
		phoneNumber: ''
	})

	const updateData = (field, value) => {
		setValidationMessages(messages => ({ ...messages, [field]: '' }))
		setFormData(data => ({ ...data, [field]: value }))
	}

	const handleClose = () => {
		setDisplay('method')
		setVisible(false)
	}

	const handleNavigateToRoute = () => {
		handleClose()
		navigate(`/${navigationLinking[coBorrowerRoute]}`)
	}

	const handleBlur = field => {
		if (field === 'firstName' && formData.firstName === '') {
			setValidationMessages(messages => ({ ...messages, firstName: 'First name is required' }))
		}
		else if (field === 'lastName' && formData.lastName === '') {
			setValidationMessages(messages => ({ ...messages, lastName: 'Last name is required' }))
		}
		else if (field === 'emailAddress') {
			if (formData.emailAddress === '') setValidationMessages(messages => ({ ...messages, emailAddress: 'Email address is required' }))
			else if (!isEmailValid(formData.emailAddress)) setValidationMessages(messages => ({ ...messages, emailAddress: 'Please enter a valid email address' }))
			else if (blacklist.includes(formData.emailAddress))	setValidationMessages(messages => ({ ...messages, emailAddress: 'Each borrower must have a unique email address' }))
			else setValidationMessages(messages => ({ ...messages, emailAddress: '' }))
		} else if (field === 'phoneNumber') {
			if (formData.phoneNumber === '') setValidationMessages(messages => ({ ...messages, phoneNumber: 'Phone number is required' }))
			else if (!isPhoneNumberValid(formData.phoneNumber)) setValidationMessages(messages => ({ ...messages, phoneNumber: 'Please enter a valid phone number' }))
		}
	}

	const handleRelationshipPress = val => {
		updateData('relationship', val)
		setDisplay('submit')
	}

	const onBack = () => {
		if (display === 'invite') {
			setDisplay('method')
		}

		if (display === 'relationship') {
			setDisplay('invite')
		}

		if (display === 'submit') {
			setDisplay('relationship')
		}
	}

	const sendInvite = async () => {
		setSending(true)
		try {
			await sendBorrowerInvitation({ ...formData, loanID: loanId })
			handleClose()
			setSending(false)
			alert("The invitation has been sent!")
			ventiState.set(theme.storageKeys.successMessage, 'The invitation has been sent!')
		} catch (err) {
			setSending(false)
			alert("There was a problem sending the invite. Please try again later.", { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage, 'There was a problem sending the invite. Please try again later.')
		}
	}

	const isOwnEmail = formData.emailAddress?.toLowerCase().trim() === user.email?.toLowerCase()

	return (
		<Dialog
			open={visible}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				Add Additional Borrower
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				{display === 'method' &&
					(
						<>
							<div style={{ textAlign: 'center', marginBottom: 20 }}>Please choose your preferred method of entry...</div>
							<div className='w-9/12 text-center' style={{ margin: "0 auto" }}>
								<div className='mb-4'><Button style={{ width: "100%" }} text="Send Invitation" variant="outlined" onClick={() => setDisplay('invite')} /></div>
								<div><Button style={{ width: "100%" }} text="I'll Provide the Information" variant="outlined" onClick={handleNavigateToRoute} /></div>
							</div>
						</>
					)}

				{display === 'invite' &&
					(
						<div style={{ maxWidth: "400px" }}>
							<div style={{ textAlign: 'center' }}>Enter the Co-Borrower's contact information...</div>
							<TextField
								className='w-full'
								sx={{ mb: 1 }}
								variant="standard"
								placeholder="Enter first name"
								value={formData.firstName}
								onChange={(e) => updateData('firstName', e.target.value)}
								label='First Name'
								onBlur={() => handleBlur('firstName')}
								error={!!validationMessages.firstName}
								helperText={validationMessages.firstName ? validationMessages.firstName : ""}
							/>

							<TextField
								className='w-full'
								sx={{ mb: 1 }}
								variant="standard"
								placeholder="Enter last name"
								value={formData.lastName}
								onChange={(e) => updateData('lastName', e.target.value)}
								label='Last Name'
								onBlur={() => handleBlur('lastName')}
								error={!!validationMessages.lastName}
								helperText={validationMessages.lastName ? validationMessages.lastName : ""}
							/>

							<TextField
								className='w-full'
								sx={{ mb: 1 }}
								variant="standard"
								placeholder="Enter email address"
								value={formData.emailAddress}
								onChange={(e) => updateData('emailAddress', e.target.value)}
								label='Email Address'
								onBlur={() => handleBlur('emailAddress')}
								error={!!validationMessages.emailAddress}
								helperText={validationMessages.emailAddress ? validationMessages.emailAddress : ""}
							/>

							<TextField
								className='w-full'
								sx={{ mb: 1 }}
								variant="standard"
								placeholder="Enter mobile number"
								value={formData.phoneNumber}
								onChange={(e) => updateData('phoneNumber', applyMask(e.target.value, 'phone'))}
								label='Mobile Phone'
								onBlur={() => handleBlur('phoneNumber')}
								error={!!validationMessages.phoneNumber}
								helperText={validationMessages.phoneNumber ? validationMessages.phoneNumber : ""}
							/>
						</div>
					)}

				{display === 'relationship' &&
					(
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
							<div className='mb-4' style={{ textAlign: 'center' }}>Are you and {formData.firstName} married or joined in a civil union?</div>
							<div className='w-9/12 text-center' style={{ margin: "0 auto" }}>
								<div className='mb-4'>
									<Button
										style={{ width: "100%" }}
										text="Yes"
										className='mb-2'
										variant={formData.relationship === 1 ? "contained" : "outlined"}
										onClick={() => handleRelationshipPress(1)}
									/>
								</div>
								<div>
									<Button
										style={{ width: "100%" }}
										text="No"
										variant={formData.relationship === 2 ? "contained" : "outlined"}
										onClick={() => handleRelationshipPress(2)}
									/>
								</div>
							</div>
						</div>
					)}

				{display === 'submit' &&
					(
						<>
							<div style={{ marginBottom: 20 }} className='text-sm md:text-base'>Please review the information below. If satisfied, please click the button below to send the invitation.</div>
							<div className='flex mb-1 text-sm md:text-base'><div className='font-bold'>First Name:&nbsp;</div><div>{formData.firstName}</div></div>
							<div className='flex mb-1 text-sm md:text-base'><div className='font-bold'>Last Name:&nbsp;</div><div>{formData.lastName}</div></div>
							<div className='flex mb-1 text-sm md:text-base'><div className='font-bold'>Email Address:&nbsp;</div><div>{formData.emailAddress}</div></div>
							<div className='flex mb-1 text-sm md:text-base'><div className='font-bold'>Mobile Phone:&nbsp;</div><div>{formData.phoneNumber}</div></div>
							<div className='flex mb-1 text-sm md:text-base'><div className='font-bold'>Married/Civil Union:&nbsp;</div>{formData.relationship === 1 ? 'Yes' : 'No'}</div>

							<div style={{ marginTop: 20, marginBottom: 20 }} className='text-sm md:text-base'>By clicking the "Send Invitation" button below, you acknowledge that an email and text message will be sent to the recipient. Text and data rates may apply.</div>
						</>
					)}
			</DialogContent>
			<DialogActions className="ml-5 mr-5">
				{(display === 'invite' || display === 'relationship' || display === 'submit') && <Button text="Back" variant={display === 'invite' || display === 'relationship' || display === 'submit' ? "outlined" : "contained"}
					onClick={() => onBack()} />}
				{(display === 'method') && <Button text="Close" variant="contained" onClick={handleClose} />}
				{display === 'invite' && <Button text="Continue" disabled={!formData.firstName || !formData.lastName || !isEmailValid(formData.emailAddress) || isOwnEmail || !isPhoneNumberValid(formData.phoneNumber)}
					onClick={() => setDisplay('relationship')} />}
				{display === 'submit' && <LoadingBtn fullWidth={false} text="Send Invitation" disabled={sending} loading={sending} onClick={sendInvite} />}
			</DialogActions>
		</Dialog>
	)
}
