import React from "react";
import {
	Icon,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow
} from "@mui/material";
import {applyMask} from "../../services/utils";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

function LOCalculatorResults({ width, formData }) {
	return (
		<>
			<div className="flex flex-row items-center mb-4">
				<p className="text-xl font-rubik font-bold mr-2 dark:text-white">
					Calculated Values
				</p>
				{formData.loanLocked &&
					<Icon
						aria-label="loan locked"
						sx={{ height: 40, width: 24 }}
					>
						<LockOutlinedIcon />
					</Icon>
				}
			</div>
			<TableContainer sx={width > 768 ? { maxWidth: 500 } : {}}>
				<Table sx={width > 768 ? { maxWidth: 500 } : {}} aria-label="simple table">
					<TableBody>
						<TableRow>
							<TableCell align="left">Monthly Payment</TableCell>
							<TableCell align="right">${applyMask(formData.monthlyPayment, "fullCurrency")}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">PITI</TableCell>
							<TableCell align="right">${applyMask(formData.principleAndInterestPITIField, "fullCurrency")}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">DTI Front</TableCell>
							<TableCell align="right">{formData.dtiFront}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">DTI Back</TableCell>
							<TableCell align="right">{formData.dtiBack}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">LTV</TableCell>
							<TableCell align="right">{formData.ltvFront}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">CLTV</TableCell>
							<TableCell align="right">{formData.ltvBack}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Cash to Close</TableCell>
							<TableCell align="right">${applyMask(formData.totalCashtoClose, "fullCurrency")}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">APR</TableCell>
							<TableCell align="right">{formData.apr}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

export default LOCalculatorResults;
