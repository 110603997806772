import { useMemo, useReducer } from "react"
import { AlertsStateContext, AlertsDispatchContext } from "./AlertsContext"
import { reducer } from "./AlertsReducer"
import { Alerts } from "./Alerts"

export const AlertsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, { alerts: [] }, init)

	const contextDispatchValue = useMemo(() => {
		return { dispatch }
	}, [dispatch])

	const contextStateValue = useMemo(() => {
		return { state }
	}, [state])

	return (
		<AlertsDispatchContext.Provider value={contextDispatchValue}>
			<AlertsStateContext.Provider value={contextStateValue}>
				<Alerts />
				{children}
			</AlertsStateContext.Provider>
		</AlertsDispatchContext.Provider>
	)
}

function init(initialArg) {
	return initialArg
}
