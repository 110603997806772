import React, { useEffect, useState } from "react"
import { filter } from "lodash"
import { useVenti } from "venti"
import PublishSubscribe from "publish-subscribe-js"
import { DataTable } from "../../../components/DataTable"
import { ModalRemoveRecordConfirm } from "../../../components/modals/ModalRemoveRecordConfirm"
import Page from "../../../components/Page"
import { useAppContext } from "../../../components/AppContext"
import { useAppContextActions } from "../../../components/AppContext/AppHooks"
import { getTheme } from "../../../config"
import { getBranches, deleteBranch, undeleteBranch } from '../../../services/client';
import { getErrorMessage } from "../../../services/helper"
import { useAlert } from "../../../hooks"

const theme = getTheme()

export default function AdminBranches() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user } = state
	const ventiState = useVenti()

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])
	const [dataCopy, setDataCopy] = useState([])
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] = useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [rowToRestore, setRowToRestore] = useState()


	const headers = [
		{ id: "name", label: "Name" },
		{ id: "nmlsid", label: "NMLSID" },
		{ id: "email", label: "Email" },
		{ id: "brand", label: "Brand" },
		{ id: "domain", label: "URL" },
		{ id: "created", label: "Created On" },
	]

	const fields = ["name", "nmlsid", "email", "corporateName", "url", "createdAt"]

	useEffect(() => {
		(async () => {
			if (!user.isLoggedIn) await logOut()
			else await loadBranches()
		})()
	}, [user])

	useEffect(() => {
		PublishSubscribe.subscribe("UPDATE_ADMIN_COMPONENT_STATE", async () => {
			await updateBranches()
		})

		return () => {
			PublishSubscribe.unsubscribe("UPDATE_ADMIN_COMPONENT_STATE")
		}
	}, [])

	const loadBranches = async () => {
		try {
			setFetching(true)
			const branchesData = await getBranches(true)
			const branches = [];
			branchesData?.forEach(item => {
				item?.siteConfigurations.forEach(site => {
					// id is being removed, so replaced with ID
					// will figure out later why
					branches.push({ ...site, ID: site.id, corporateID: item.corporateID,
						corporateName: item.corporateName, branchId: item.id, createdAt: item.createdAt, deletedAt: item.deletedAt })
				})
			})
			setData(branches)
			setDataCopy(branches)
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const handleFilterByCorporations = (value) => {
		if (!value) {
			setData(dataCopy)
		} else {
			setData(filter(dataCopy, { corporateID: value }))
		}
	}

	const showRemoveModal = row => {
		setRemoveModalVisible(true)
		setRowToRemove(row)
	}

	const updateBranches = async () => {
		setData(await getBranches())
	}

	const removeBranch = async () => {
		setRemoveModalVisible(false)

		try {
			await deleteBranch(rowToRemove.branchId)
			await loadBranches()
			setRowToRemove(null)
			alert(`Branch "${rowToRemove.name}" successfully removed`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const showRestoreModal = row => {
		setRestoreModalVisible(true)
		setRowToRestore(row)
	}

	const restoreBranch = async () => {
		setRestoreModalVisible(false)

		try {
			await undeleteBranch(rowToRestore.id)
			setData(data => data.filter(d => d.id !== rowToRestore.id))
			alert(`User ${rowToRestore.name} successfully restored`)
			setRowToRestore(null)
			await loadBranches()
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	return (
		<Page title="Branches" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-screen overflow-auto">
				<ModalRemoveRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeBranch}
					row={rowToRemove}
				/>
				<ModalRemoveRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restoreBranch}
					row={rowToRestore}
				/>
				<DataTable
					data={data}
					headers={headers}
					entity={"Branch"}
					title={"Branches"}
					buttonTitle={"Add Branch"}
					fetching={fetching}
					fields={fields}
					refresh={loadBranches}
					remove={showRemoveModal}
					restore={showRestoreModal}
					handleFilterByCorporations={handleFilterByCorporations}
					allowExport
				/>
			</div>
		</Page>
	)
}
