import { useState, useEffect } from "react"
import { useVenti } from "venti"
import { useNavigate } from "react-router-dom"
import { InputLabel, MenuItem, FormControl, Select, IconButton, Tooltip } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import Page from "../../components/Page"
import { useAppContext } from "../../components/AppContext"
import { Loading } from "../../components/Loading"
import { Button } from "../../components/Button"
import { ModalPleaseWait } from "../../components/modals/ModalPleaseWait"
import { ModalVerificationTask } from "../../components/modals/ModalVerificationTask"
import { ModalDocumentType } from "../../components/modals/ModalDocumentType"
import { ModalTask } from "../../components/modals/ModalTask"
import { ModalUploadSizeLimit } from "../../components/modals/ModalUploadSizeLimit"
import { useWindowSize, useAlert } from "../../hooks"
import {
	getLoanTasks, postTaskField, getLoanDocuments, addLoanTaskDocument,
	verify, getDisclosurePackageInfo, getLoan, Roles, getEmbeddedSigningUrl
} from '../../services/client';
import {
	getTaskType, getTaskStatusName, getItemAsyncStorage, getTaskButtonTitle,
	setItemAsyncStorage, getErrorMessage
} from "../../services/helper"
import { fileMBSizes } from "../../services/utils"
import { getTheme } from "../../config"
import { navigationLinking } from "../../services/navigation"
import RefreshPNG from "assets/lordicons/Refresh.png"
import RefreshGIF from "assets/lordicons/RefreshAnimated.gif"
import RefreshDarkPNG from "assets/lordicons/RefreshDark.png"
import RefreshDarkGIF from "assets/lordicons/RefreshAnimatedDark.gif"
import { ModalUploadMimeType } from '../../components/modals/ModalUploadMimeType';

const theme = getTheme()

const bgColor = {
	"&:hover": {
		backgroundColor: "transparent"
	}
}

export default function Tasks({ route }) {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const [width] = useWindowSize()
	const navigate = useNavigate()
	const themeMUI = useTheme()
	const ventiState = useVenti()
	const { siteConfig, user } = state
	const loanId = ventiState.get(theme.storageKeys.loanId)
	const preloadedTasks = ventiState.get(theme.storageKeys.loanTasks)
	const preloadedDocs = ventiState.get(theme.storageKeys.loanDocs)
	const loanData = ventiState.get(theme.storageKeys.loanData)

	const [loadingTasks, setLoadingTasks] = useState(true)
	const [uploadingTaskDocument, setUploadingTaskDocument] = useState(false)
	const [loanTasks, setLoanTasks] = useState([])
	const [activeTask, setActiveTask] = useState(null)
	const [sortAscending, setSortAscending] = useState(true)
	const [searchTerm, setSearchTerm] = useState("")
	const [filtered, setFiltered] = useState(loanTasks)
	const [taskValue, setTaskValue] = useState("")
	const [document, setDocument] = useState(null)
	const [tasksToComplete, setTasksToComplete] = useState([])
	const [documentModalVisible, setDocumentModalVisible] = useState(false)
	const [taskModalVisible, setTaskModalVisible] = useState(false)
	const [verificationModalVisible, setVerificationModalVisible] = useState(false)
	const [activeSubmissions, setActiveSubmissions] = useState([])
	const [uploadDocumentType, setUploadDocumentType] = useState(null)
	const [errorModalVisible, setErrorModalVisible] = useState(false)
	const [uploadDocumentSuccess, setUploadDocumentSuccess] = useState(false)
	const [loanDocuments, setLoanDocuments] = useState([])
	const [filterTaskStatus, setFilterTaskStatus] = useState(3)
	const [filterTaskType, setFilterTaskType] = useState("all")
	const [fetchingDisclosureInfo, setFetchingDisclosureInfo] = useState(false)
	const [modalSizeLimitVisible, setModalSizeLimitVisible] = useState(false)
	const [modalMimeTypeVisible, setModalMimeTypeVisible] = useState(false)
	const [activeSections, setActiveSections] = useState([])
	const [externalUrl, setExternalUrl] = useState('')

	const sortedItemsWeb = filtered
		.slice()
		.map((x, i) => getTaskType(x.taskType) === "Field" ? { ...x, taskDescription: "Missing " + x.taskDescription } : { ...x, taskDescription: x.taskDescription })
		.sort((a, b) =>
			(sortAscending ? a.taskDescription > b.taskDescription : b.taskDescription > a.taskDescription)
				? 1
				: -1
		)
		.map((x, i) => getTaskType(x.taskType) === "Field" ? { ...x, taskDescription: x.taskDescription.substring(8) } : { ...x, taskDescription: x.taskDescription })
		.filter(x => x.taskType !== 4)

	useEffect(() => {
		if (!loanId) {
			getItemAsyncStorage(theme.storageKeys.loanId).then(response => {
				if (response) ventiState.set(theme.storageKeys.loanId, response)
				else navigate(`/${navigationLinking.Portal}`)
			})
		}
	}, [])

	const taskExpand = sortedItemsWeb.map((task) => (
		{
			title: task.taskName,
			expanded: false
		}
	)).flat()

	useEffect(() => {
		if (preloadedTasks) {
			setLoanTasksAndTasksToComplete(preloadedTasks)
			setLoadingTasks(false)
		}
		else if (loanId && !tasksToComplete.length) {
			getLoanTasks(loanId).then(response => {
				setLoanTasksAndTasksToComplete(response)
				setLoadingTasks(false)
				//setItemAsyncStorage(theme.storageKeys.loanTasks, JSON.stringify(response)).then(res => res)
			}).catch(async e => {
				alert(getErrorMessage(e), { severity: "error" })
				ventiState.set(theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error)
			})
		}
	}, [preloadedTasks, loanId])

	useEffect(() => {
		if (preloadedDocs && preloadedDocs.length) {
			setLoanDocuments(preloadedDocs)
		} else if (loanId && !loanDocuments.length) {
			fetchLoanDocuments(loanId)
		}
	}, [preloadedDocs, loanId])

	useEffect(() => {
		if (!loanData && loanId) {
			fetchLoanData(loanId)
		}
	}, [loanData, loanId])

	useEffect(() => {
		searchTerm.length >= 3 ?
			setFiltered(
				loanTasks
					.map(x => getTaskType(x.taskType) === "Field" ? { ...x, taskDescription: "Missing " + x.taskDescription } : x)
					.filter(task => task.taskDescription.toLowerCase().includes(searchTerm.toLowerCase()))
					.map(x => getTaskType(x.taskType) === "Field" ? { ...x, taskDescription: x.taskDescription.substring(8) } : { ...x, taskDescription: x.taskDescription })
			)
			:
			setFiltered(loanTasks)
	}, [searchTerm, loanTasks])

	const setLoanTasksAndTasksToComplete = (tasks) => {
		console.log(tasks)
		setLoanTasks(tasks)
		setTasksToComplete(tasks.filter(item => item.taskType === 0 && item.taskStatus === 0))
	}

	const refreshTasks = () => {
		if (loanId) {
			setLoadingTasks(true)

			getLoanTasks(loanId).then(response => {
				setLoanTasksAndTasksToComplete(response)
				//setItemAsyncStorage(theme.storageKeys.loanTasks, JSON.stringify(response)).then(res => res)
			}).catch(async e => {
				alert(getErrorMessage(e), { severity: "error" })
				ventiState.set(theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error)
			}).finally(() => {
				setLoadingTasks(false)
			})
		}

		if (loanId && !loanDocuments.length) {
			setLoadingTasks(true)
			fetchLoanDocuments(loanId).finally(() => {
				setLoadingTasks(false)
			})
		}
	}

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result)
			}
			fileReader.onerror = (error) => {
				reject(error)
			}
		})
	}
	const pickDocument = async (e, type, action) => {
		if (action === "camera") {
			navigate(`/${navigationLinking.Landing}`, { activeTaskParam: activeTask })
			return
		}

		if (e?.target?.files?.length > 0) {
			let result = e.target.files[0]

			if (!theme.validation.allowedDocumentTypes.includes(result.type)) {
				return setModalMimeTypeVisible(true)
			}

			if (result.size > fileMBSizes["20"]) {
				return setModalSizeLimitVisible(true)
			}

			result.uri = await convertBase64(result)

			const doc = {
				"loanGuid": loanId,
				"documents": [{
					"documentBucketTitle": type === "task" ? activeTask.taskLOSIdentifier : "",
					"documentName": result.name,
					"base64Data": String(result.uri).replace(`data:${result.type};base64,`, ""),
					"extension": /\.(\w+)$/.exec(result.name)[0]
				}]
			}

			if (type === "task") {
				try {
					setUploadingTaskDocument(true)
					await addLoanTaskDocument(doc)
					if (activeTask.verification)
						setLoanTasks(loanTasks.map(task => task.taskType === 5 && task.taskName === activeTask.taskName ? { ...task, taskType: 0, taskStatus: 1 } : task))
					else
						setLoanTasks(loanTasks.map(task => task.taskName === activeTask.taskName ? { ...task, taskStatus: 1 } : task))

					setUploadDocumentSuccess(true)
					setDocumentModalVisible(true)

					ventiState.set(theme.storageKeys.loanDocs, await getLoanDocuments(loanId))

				} catch (err) {
					setErrorModalVisible(true)
				} finally {
					setUploadingTaskDocument(false)
					setActiveTask(null)
					ventiState.set(theme.storageKeys.successMessage, 'The document was uploaded successfully. Please allow a few minutes for it to become available for viewing.')
				}
			} else {
				setDocument(doc)
				setDocumentModalVisible(true)
			}
			await fetchLoanDocuments(loanId)
		}
	}

	const handleTaskButtonPress = async task => {

		if ([3, 4].includes(task.taskType)) {
			// DISCLOSURES
			if (user.disclosuresSSOLoginUrl) {
				try {
					if (3 === task.taskType) {
						setFetchingDisclosureInfo(true)
						const packageInfo = await getDisclosurePackageInfo(loanId, user.email)
						setItemAsyncStorage(theme.storageKeys.disclosurePackageInfo, JSON.stringify(packageInfo))
						setFetchingDisclosureInfo(false)
					}
					setTimeout(() => {
						const popup = window.open(`${user.disclosuresSSOLoginUrl}`, "_blank")
						if (!popup || popup.closed || typeof popup.closed === "undefined") {
							ventiState.set(theme.storageKeys.infoMessage, "Pop-up Blocker is enabled! Please add this site to your exception list.")
						}
					}, 500)
				} catch (e) {
					setTimeout(() => {
						const popup = window.open(`${user.disclosuresSSOLoginUrl}`, "_blank")
						if (!popup || popup.closed || typeof popup.closed === "undefined") {
							ventiState.set(theme.storageKeys.infoMessage, "Pop-up Blocker is enabled! Please add this site to your exception list.")
						}
					}, 500)
				}
			} else {
				navigate(`/${navigationLinking.Disclosures}`)
			}
		} else if ([5, 6, 7, 10].includes(task.taskType)) {
			// VERIFICATIONS
			if ([Roles.borrower, Roles.superAdmin].includes(user.role)) {
				setActiveTask(task)
				setVerificationModalVisible(true)
			} else {
				setActiveTask({ ...task, taskType: 0 })
				setTaskModalVisible(true)
			}
		} else if (11 === task.taskType) {
			// HALCYON
			if (siteConfig.irsVerificationUrl) {
				try {
					const url = await getEmbeddedSigningUrl({
						envelopeId: loanData[`CX.HALCYON.${loanData.BorrowerPosition}.ENVELOPID`],
						firstName: loanData.BorrowerFirstName,
						lastName: loanData.BorrowerLastName,
						email: loanData.BorrowerEmail
					})
					setExternalUrl(url)
					setVerificationModalVisible(true)
				} catch (e) {
					ventiState.set(theme.storageKeys.errorObject, { message: e.message || 'Failed to retrieve signing url. Please try again later.' })
				}
			} else {
				ventiState.set(theme.storageKeys.errorObject, { message: 'IRS Verification URL is not set. Please contact your loan officer.' })
			}
		} else {
			// DOCUMENT OR FIELD
			setActiveTask(task)
			setTaskModalVisible(true)
		}
	}

	const openExternalUrl = () => {
		const popup = window.open(`${externalUrl}`, "_blank")
		if (!popup || popup.closed || typeof popup.closed === "undefined") {
			ventiState.set(theme.storageKeys.infoMessage, "Pop-up Blocker is enabled! Please add this site to your exception list.")
		}
	}

	const handleTaskSubmit = value => {
		setTaskModalVisible(false)
		const taskName = activeTask.taskName
		setActiveSubmissions(activeSubmissions => [taskName, ...activeSubmissions])

		setTaskValue("")
		if (activeTask.taskType === 1) {
			postTaskField({
				loanGuid: loanId,
				fieldUpdates: [
					{
						fieldID: activeTask.taskLOSIdentifier,
						fieldValue: value
					}
				]
			}).then(response => {
				// setLoanTasksAndTasksToComplete(response)
				setLoanTasksAndTasksToComplete(current => current.map(task => {
					if (task.taskName === taskName) task.taskStatus = 1
					return task
				}))
				setActiveSubmissions(activeSubmissions => activeSubmissions.filter(as => as !== taskName))
				//setItemAsyncStorage(theme.storageKeys.loanTasks, response).then(res => res)
			}).catch(e => {
				console.log(e)
				alert(getErrorMessage(e), { severity: "error" })
				ventiState.set(theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error)
			})
		}
	}

	const uploadDocument = type => {
		const data = Object.assign({}, document)

		if (type !== "task") {
			setDocumentModalVisible(false)
			data.documents[0].documentBucketTitle = uploadDocumentType
		}

		addLoanTaskDocument(data)
			.then((tasks) => {
				setLoanTasksAndTasksToComplete(tasks)
				setTaskModalVisible(false)
				setActiveTask(null)
				ventiState.set(theme.storageKeys.successMessage, 'The document was uploaded successfully!')
			})
			.catch(e => {
				alert(getErrorMessage(e), { severity: "error" })
				ventiState.set(theme.storageKeys.errorObject, {
					message: e?.data?.message || theme.api_messages.server_error,
					handleOK: closeErrorModal
				})
			})
	}

	const closeErrorModal = () => {
		ventiState.set(theme.storageKeys.errorObject, {})
		setTaskModalVisible(false)
	}

	const fetchLoanDocuments = (loanId) => {
		return getLoanDocuments(loanId).then(response => {
			setLoanDocuments(response)
		}).catch(async e => {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		})
	}

	const fetchLoanData = (loanId) => {
		getLoan(loanId).then(response => {
			ventiState.set(theme.storageKeys.loanData, response)
		}).catch(async e => {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		})
	}

	const onCancelUploadDocumentModal = () => {
		setDocument(null)
		setUploadDocumentType(null)
		setDocumentModalVisible(false)
	}

	const getUserDocuments = taskName => {
		let docs = loanDocuments.filter(obj => obj.documentBucketTitle === taskName)
		return docs
	}

	const handleDocumentPress = doc => {
		ventiState.set(theme.storageKeys.document, doc)
		navigate(`/${navigationLinking.Documents}`)
	}

	// const handleTaskExpand = task => {
	// 	let obj = taskExpand.find(obj => obj.title === task.TaskName)
	// 	let itemIndex = taskExpand.indexOf(obj)
	// 	let newArr = [...activeSections]
	//
	// 	newArr[itemIndex].expanded = !newArr[itemIndex].expanded
	// 	setActiveSections(newArr)
	// }

	const handleFilters = sortedItemsWeb => {
		let filteredTasks = {}
		if (filterTaskType <= 5) {
			filteredTasks = sortedItemsWeb.filter(task => task.taskType === parseInt(filterTaskType))
		}
		else {
			filteredTasks = sortedItemsWeb
		}

		if (filterTaskStatus <= 2) {
			filteredTasks = filteredTasks.filter(task => task.taskStatus === parseInt(filterTaskStatus))
		}

		return filteredTasks
	}

	const handleTypeFilter = itemValue => {
		setFilterTaskType(itemValue)
	}

	const handleStatusFilter = itemValue => {
		setFilterTaskStatus(itemValue)
	}

	useEffect(() => {
		setActiveSections(taskExpand)
	}, [filtered])

	const handleVerificationLink = async () => {
		try {
			setVerificationModalVisible(false)
			ventiState.set(theme.storageKeys.pleaseWaitMessage, "Please stand by while we contact your local Banks. This may take up to 60 seconds.")

			let operation = getTaskType(activeTask.taskType)
			if (operation === "VOIE") operation = "VOI"

			const verificationRequest = await verify({
				requestID: "",
				loanID: loanId,
				operations: [operation.toLowerCase()]
			})
			ventiState.set(theme.storageKeys.pleaseWaitMessage, "")
			ventiState.set(theme.storageKeys.verificationRequestId, verificationRequest.requestId)
			setTimeout(() => {
				if (!verificationRequest.ssoUrls?.AccountChek) {
					ventiState.set(theme.storageKeys.pleaseWaitMessage, "")
					ventiState.set(theme.storageKeys.errorObject, {
						message: 'We were unable to connect with your local banks. Please try again later.'
					})
					return
				}
				const popup = window.open(verificationRequest.ssoUrls.AccountChek, "_blank")
				if (!popup || popup.closed || typeof popup.closed === "undefined") {
					ventiState.set(theme.storageKeys.infoMessage, "Pop-up Blocker is enabled! Please add this site to your exception list and try again.")
				}
			}, 500)
		} catch (e) {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, "")
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const handleTypeChange = (itemValue) => {
		handleTypeFilter(itemValue)
	}

	const handleStatusChange = (itemValue) => {
		handleStatusFilter(itemValue)
	}

	return (
		<>
			<ModalPleaseWait visible={fetchingDisclosureInfo}
				message={`Fetching Disclosure Package Information`}
			/>

			<ModalDocumentType visible={documentModalVisible} tasksToComplete={tasksToComplete}
				setDocumentModalVisible={setDocumentModalVisible} uploadDocumentType={uploadDocumentType}
				setUploadDocumentType={setUploadDocumentType} confirmModal={uploadDocument}
				uploadDocumentSuccess={uploadDocumentSuccess} setUploadDocumentSuccess={setUploadDocumentSuccess}
				cancelModal={onCancelUploadDocumentModal} loadingDocument={true}
			/>

			<ModalTask
				task={activeTask}
				pickDocument={pickDocument}
				uploadingTaskDocument={uploadingTaskDocument}
				visible={taskModalVisible}
				setTaskModalVisible={setTaskModalVisible}
				taskValue={taskValue}
				setTaskValue={setTaskValue}
				handleTaskSubmit={handleTaskSubmit}
				uploadDocumentSuccess={uploadDocumentSuccess}
				setUploadDocumentSuccess={setUploadDocumentSuccess}
				errorModalVisible={errorModalVisible}
				setErrorModalVisible={setErrorModalVisible}
				setActiveTask={setActiveTask}
			/>

			<ModalUploadSizeLimit
				visible={modalSizeLimitVisible}
				closeModal={() => setModalSizeLimitVisible(false)}
			/>

			<ModalUploadMimeType
				visible={modalMimeTypeVisible}
				closeModal={() => setModalMimeTypeVisible(false)}
			/>

			<ModalVerificationTask
				siteConfig={siteConfig}
				visible={verificationModalVisible}
				taskType={getTaskType(activeTask?.taskType)}
				closeModal={() => setVerificationModalVisible(false)}
				onNo={() => {
					setActiveTask({ ...activeTask, taskType: 0, verification: true })
					setVerificationModalVisible(false)
					setTaskModalVisible(true)
				}}
				onYes={handleVerificationLink}
			/>

			<Page page="tasks" title="Tasks" isFullWidth={true}>
				<div className="pl-5 pr-5 pb-10 pt-5 h-screen overflow-auto">
					<div className="flex items-center text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
						<div>{user?.role === Roles.loanOfficer ? "Borrower" : "My"} Tasks</div>
						<div className="ml-3">
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="refresh"
								onClick={refreshTasks}
								disabled={loadingTasks}
								sx={bgColor}
							>
								<img
									className="w-14 mt-1"
									src={themeMUI.palette.mode === 'light' ? RefreshPNG : RefreshDarkPNG}
									onMouseOver={e => (e.currentTarget.src = themeMUI.palette.mode === 'light' ? RefreshGIF : RefreshDarkGIF)}
									onMouseOut={e => (e.currentTarget.src = themeMUI.palette.mode === 'light' ? RefreshPNG : RefreshDarkPNG)}
									alt="refresh"
								/>
							</IconButton>
						</div>
					</div>
					<p className="pb-8 text-lg text-slate-500">Please complete the tasks listed below by uploading the appropriate document. You may also be asked to complete missing information or sign a document. Completing all of your tasks in a timely manner will speed up the approval process. Acceptable file formats for document uploads are PDF, JPG and PNG.</p>
					<div className="flex flex-col items-start w-full">
						<div className="flex flex-col md:flex-row">
							<FormControl
								id="filterTaskStatus"
								variant="standard"
								sx={[width < 768 ? { width: 256 } : { width: 300, marginRight: 5 }]}
							>
								<InputLabel>{filterTaskStatus !== 3 && "Filter by status..."}</InputLabel>
								<Select
									value={filterTaskStatus}
									onChange={e => handleStatusChange(e.target.value)}
									label="Filter by status..."
								>
									<MenuItem key="default" value={3}>Filter by status...</MenuItem>
									<MenuItem value={0}>Incomplete</MenuItem>
									<MenuItem value={1}>Under Review</MenuItem>
									<MenuItem value={2}>Complete</MenuItem>
								</Select>
							</FormControl>
							<FormControl
								id="filterTaskType"
								variant="standard"
								sx={[width < 768 ? { width: 256, marginTop: 2 } : { width: 300 }]}
							>
								<InputLabel>{filterTaskType !== "all" && "Filter by type..."}</InputLabel>
								<Select
									value={filterTaskType}
									onChange={e => handleTypeChange(e.target.value)}
									label="Filter by type..."
								>
									<MenuItem key="default" value="all">Filter by type...</MenuItem>
									<MenuItem value={0}>Document Requested</MenuItem>
									<MenuItem value={1}>Information Requested</MenuItem>
									<MenuItem value={2}>Need Signature</MenuItem>
									<MenuItem value={3}>Disclosure Package</MenuItem>
									<MenuItem value={3}>Electronic Consent</MenuItem>
								</Select>
							</FormControl>
						</div>
						{loadingTasks &&
							<div className="flex justify-center w-full mt-10">
								<Loading size="small" />
							</div>
						}
						{!loadingTasks && handleFilters(sortedItemsWeb).length === 0 ?
							<>
								<p className="mt-10 dark:text-white">No records match the requested filters.</p>
							</>
							:
							handleFilters(sortedItemsWeb).map((task, index) => {
								if (!loadingTasks && width < 768) {
									return (
										<div
											key={index}
											className="flex flex-col align-center w-full max-w-sm my-4 px-4 py-5 shadow shadow-gray-600/30 rounded-lg dark:shadow-gray-400/40"
										>
											<p className="tex-lg font-bold mb-6 dark:text-white">{task.taskName}</p>
											<p className="mb-3 dark:text-slate-400">{getTaskType(task.taskType)}</p>
											<p className="mb-3 dark:text-slate-400">{getTaskStatusName(task.taskStatus)}</p>
											<p className="text-slate-600 text-sm mb-6 dark:text-slate-400">{task.taskDescription}</p>
											{![Roles.realtor].includes(user.role) && loanDocuments.find((doc) => doc.documentBucketTitle === task.taskLOSIdentifier) &&
												<>
													<p className="my-5 self-center">Uploaded Documents</p>
													{getUserDocuments(task.taskLOSIdentifier).map((doc, index) =>
														<div key={index} className="mb-2">
															{doc.documentName && doc.documentName.length > 30
																? <div>
																	<Tooltip title={doc.documentName}>
																		<span className="cursor-pointer hover:underline" style={{ color: theme.siteConfig.color.primary }} onClick={() => handleDocumentPress(doc)}>{doc.documentName.slice(0, 30) + "..."}</span>
																	</Tooltip>
																</div>
																: <div><span className="cursor-pointer hover:underline" style={{ color: theme.siteConfig.color.primary }} onClick={() => handleDocumentPress(doc)}>{doc.documentName}</span></div>
															}
														</div>
													)}
												</>
											}
											{
												![Roles.realtor].includes(user.role) && ((task.taskType === 0 && task.taskStatus !== 2) ||
													(task.taskStatus === 0 && !activeSubmissions.includes(task.taskName)) || task.taskType === 5) &&

												<Button
													id={`Tasks${task.id}TableButton`}
													text={getTaskButtonTitle(task)}
													onClick={() => handleTaskButtonPress(task)}
													variant="outlined"
													style={{ width: 200, marginBottom: 5, alignSelf: "center" }}
												/>
											}
										</div>
									)
								} else if (!loadingTasks && width >= 768) {
									return (
										<div
											key={index}
											className="flex flex-col align-center justify-between w-full my-4 px-4 py-5 shadow shadow-gray-600/30 rounded-lg dark:shadow-gray-400/40"
										>
											<div className="flex justify-between wrap items-center mb-10">
												<p className="tex-lg md:w-2/3 font-bold dark:text-white">{task.taskType === 3 ? "Loan Disclosures" : task.taskName}</p>
												<div className="flex md:w-1/3 justify-around items-center">
													<p className="mr-6 dark:text-slate-400">{getTaskType(task.taskType)}</p>
													<p className="mr-6 dark:text-slate-400">{getTaskStatusName(task.taskStatus)}</p>
													{
														![Roles.realtor].includes(user.role) && ((task.taskType === 0 && task.taskStatus !== 2) ||
															(task.taskStatus === 0 && !activeSubmissions.includes(task.taskName)) || task.taskType === 5) &&

														<Button
															id={`Tasks${task.id}TableButton`}
															text={getTaskButtonTitle(task)}
															onClick={() => handleTaskButtonPress(task)}
															variant="outlined"
															style={{ width: 160, paddingRight: 10 }}
														/>
													}
												</div>
											</div>
											<p className="text-slate-400">{task.taskDescription}</p>
											{loanDocuments.find((doc) => doc.documentBucketTitle === task.taskLOSIdentifier) &&
												<>
													<p className="my-5">Uploaded Documents</p>
													{getUserDocuments(task.taskLOSIdentifier).map((doc, index) =>
														<div key={index} className="mb-2">
															{doc.documentName && doc.documentName.length > 30
																? <div>
																	<Tooltip title={doc.documentName}>
																		<span className="cursor-pointer hover:underline" style={{ color: theme.siteConfig.color.primary }} onClick={() => handleDocumentPress(doc)}>{doc.documentName.slice(0, 30) + "..."}</span>
																	</Tooltip>
																</div>
																: <div><span className="cursor-pointer hover:underline" style={{ color: theme.siteConfig.color.primary }} onClick={() => handleDocumentPress(doc)}>{doc.documentName}</span></div>
															}
														</div>
													)}
												</>
											}
										</div>
									)
								}
							})
						}
					</div>
				</div>
			</Page>
		</>
	)
}
