import React from "react"
import { TextField, InputLabel, MenuItem, FormControl, Select,
	InputAdornment } from "@mui/material"
import { applyMask } from "../../services/utils"

export const LOCalculatorForm = ({ formData, setFormData, handleZipCodeBlur, loanLocked, width, counties, handleCountyChange, stacked = false }) => {
	const updateFormData = (field, val) => setFormData(formData => ({ ...formData, [field]: val }))
	const updateNumeric = (field, val) => {
		setFormData(formData => ({ ...formData, [field]: applyMask(val, "numeric") }))
	}

	const updateCurrency = (field, val) => {
		// setFormData(formData => ({ ...formData, [field]: val }))
		setFormData(formData => ({ ...formData, [field]: applyMask(val, "fullCurrency") }))
	}

	const calculatePaymentInfo = (field, value) => {
		let price, payment, percent, loanAmount

		switch (field) {
			case 'propertyValue':
				// changes loan amount
				updateCurrency('propertyValue', value)
				price = parseFloat((value || '0.00').replace(/,/g, ''))
				payment = parseFloat((formData.downPaymentAmount || '0.00').replace(/,/g, ''))
				loanAmount = price - payment
				percent = (payment / price * 100).toFixed(3)

				updateFormData('downPaymentPercent', percent)
				updateCurrency('loanAmount', loanAmount)
				break
			case 'downPaymentAmount':
				// Changes down payment percent & loan amount
				updateCurrency(field, value)
				price = parseFloat((formData.propertyValue || '0.00').replace(/,/g, ''))
				payment = parseFloat((value || '0.00').replace(/,/g, ''))
				loanAmount = price - payment
				percent = (payment / price * 100).toFixed(3)

				updateFormData('downPaymentPercent', percent)
				updateCurrency('loanAmount', loanAmount)
				break
			case 'downPaymentPercent':
				// change down payment amount and loan amount
				updateFormData(field, value)
				percent = parseFloat(value || '0')
				price = parseFloat((formData.propertyValue || '0.00').replace(/,/g, ''))
				payment = percent / 100 * price
				loanAmount = price - payment

				updateCurrency('downPaymentAmount', payment)
				updateCurrency('loanAmount', loanAmount)
				break
			case 'loanAmount':
				updateCurrency(field, value)
				// changes down payment amount and down payment percent
				loanAmount = parseFloat((value || '0.00').replace(/,/g, ''))
				price = parseFloat((formData.propertyValue || '0.00').replace(/,/g, ''))
				payment = price - loanAmount
				percent = payment / price * 100

				updateCurrency('downPaymentAmount', payment)
				updateFormData('downPaymentPercent', percent)
				break
		}
	}

	const flexDirection = width >= 768 && !stacked ? 'flex-row' : 'flex-col';
	const mr = width >= 768 && !stacked ? 2 : 0;
	return (
		<form className="flex flex-col items-center w-full">
			<div className={`${flexDirection} flex w-full items-end`}>
				<FormControl
					key={"LOCalculatorFormMortgageTypePicker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
				>
					<InputLabel>Loan Purpose</InputLabel>
					<Select
						value={formData?.loanPurpose}
						onChange={e => updateFormData("loanPurpose", e.target.value)}
						label="Loan Purpose"
						disabled={loanLocked}
					>
						<MenuItem value="">Loan Purpose</MenuItem>
						<MenuItem value="Purchase">Purchase</MenuItem>
						<MenuItem value="NoCash-Out Refinance">Refi</MenuItem>
						<MenuItem value="Cash-Out Refinance">Cash Out Refi</MenuItem>
					</Select>
				</FormControl>
				<FormControl
					key={"LOCalculatorFormLoanTypePicker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
				>
					<InputLabel>Loan Type</InputLabel>
					<Select
						value={formData?.loanType}
						onChange={e => updateFormData("loanType", e.target.value)}
						label="Loan Type"
						disabled={loanLocked}
					>
						<MenuItem value="">Loan Type</MenuItem>
						<MenuItem value="Conventional">CONV</MenuItem>
						<MenuItem value="FHA">FHA</MenuItem>
						<MenuItem value="USDA">USDA</MenuItem>
						<MenuItem value="VA">VA</MenuItem>
					</Select>
				</FormControl>
			</div>
			{formData.loanPurpose === 'Purchase' ? <>
					<div className={`${flexDirection} flex w-full items-end`}>
						<TextField
							onChange={e => calculatePaymentInfo("propertyValue", e.target.value)}
							value={formData?.propertyValue}
							label="Property Value"
							placeholder="Property Value"
							id={`LOCalculatorFormPropertyValueInput`}
							variant="standard"
							className="w-full sm:px-12"
							sx={{mb: 2, mr}}
							disabled={loanLocked}
							InputProps={{
								startAdornment: formData?.propertyValue ?
									<InputAdornment position="start">$</InputAdornment> : ""
							}}
						/>
						<TextField
							onChange={e => calculatePaymentInfo("downPaymentAmount", e.target.value)}
							value={formData?.downPaymentAmount}
							label="Down Payment Amount"
							placeholder="Down Payment Amount"
							id={`LOCalculatorFormDownPaymentAmountInput`}
							variant="standard"
							className="w-full sm:px-12"
							sx={{mb: 2, mr}}
							disabled={loanLocked}
							InputProps={{
								startAdornment: formData?.downPaymentAmount ?
									<InputAdornment position="start">$</InputAdornment> : ""
							}}
						/>
					</div>
					<div className={`${flexDirection} flex w-full items-end`}>
						<TextField
							onChange={e => calculatePaymentInfo("downPaymentPercent", e.target.value)}
							value={formData?.downPaymentPercent}
							label="Down Payment Percent"
							placeholder="Down Payment Percent"
							id={`LOCalculatorFormDownPaymentPercentInput`}
							variant="standard"
							className="w-full sm:px-12"
							sx={{mb: 2, mr}}
							disabled={loanLocked}
							InputProps={{
								endAdornment: formData?.downPaymentPercent ?
									<InputAdornment position="end">%</InputAdornment> : ""
							}}
						/>
						<TextField
							onChange={e => calculatePaymentInfo("loanAmount", e.target.value)}
							value={formData?.loanAmount}
							label="Loan Amount"
							placeholder="Loan Amount"
							id={`LOCalculatorFormLoanAmountInput`}
							variant="standard"
							className="w-full sm:px-12"
							sx={{mb: 2, mr}}
							disabled={loanLocked}
							InputProps={{
								startAdornment: formData?.loanAmount ?
									<InputAdornment position="start">$</InputAdornment> : ""
							}}
						/>
					</div>
				</> :
				<div className={`${flexDirection} flex w-full items-end`}>
					<TextField
						onChange={e => calculatePaymentInfo("propertyValue", e.target.value)}
						value={formData?.propertyValue}
						label="Property Value"
						placeholder="Property Value"
						id={`LOCalculatorFormPropertyValueInput`}
						variant="standard"
						className="w-full sm:px-12"
						sx={{mb: 2, mr}}
						disabled={loanLocked}
						InputProps={{
							startAdornment: formData?.propertyValue ?
								<InputAdornment position="start">$</InputAdornment> : ""
						}}
					/>
					<TextField
						onChange={e => calculatePaymentInfo("loanAmount", e.target.value)}
						value={formData?.loanAmount}
						label="Loan Amount"
						placeholder="Loan Amount"
						id={`LOCalculatorFormLoanAmountInput`}
						variant="standard"
						className="w-full sm:px-12"
						sx={{mb: 2, mr}}
						disabled={loanLocked}
						InputProps={{
							startAdornment: formData?.loanAmount ?
								<InputAdornment position="start">$</InputAdornment> : ""
						}}
					/>
				</div>}
			<div className={`${flexDirection} flex w-full items-end`}>
				<TextField
					onChange={e => updateNumeric("zipCode", e.target.value)}
					value={formData?.zipCode}
					label="Zip Code"
					placeholder="Zip Code"
					id={`LOCalculatorFormZipCodeInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
					disabled={loanLocked}
					inputProps={{maxLength: 5}}
					onBlur={handleZipCodeBlur}
				/>
				<TextField
					onChange={e => updateNumeric("creditScore", e.target.value)}
					value={formData?.creditScore}
					label="Credit Score"
					placeholder="Credit Score"
					id={`LOCalculatorFormCreditScoreInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
					disabled={loanLocked}
					inputProps={{maxLength: 3}}
					max={850}
				/>
			</div>
			<div className={`${flexDirection} flex w-full items-end`}>
				<TextField
					onChange={e => updateCurrency("taxes", e.target.value)}
					value={formData?.taxes}
					label="Monthly Taxes"
					placeholder="Monthly Taxes"
					id={`LOCalculatorFormTaxesInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
					disabled={loanLocked}
					InputProps={{
						startAdornment: formData?.loanAmount ?
							<InputAdornment position="start">$</InputAdornment> : ""
					}}
				/>
				<TextField
					onChange={e => updateCurrency("insurance", e.target.value)}
					value={formData?.insurance}
					label="Monthly Insurance"
					placeholder="Monthly Insurance"
					id={`LOCalculatorFormInsuranceInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
					disabled={loanLocked}
					InputProps={{
						startAdornment: formData?.loanAmount ?
							<InputAdornment position="start">$</InputAdornment> : ""
					}}
				/>
			</div>
			<div className={`${flexDirection} flex w-full items-end`}>
				<FormControl
					key={"RateFormEscrowPicker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
				>
					<InputLabel>Escrow</InputLabel>
					<Select
						value={formData?.escrow}
						onChange={e => updateFormData("escrow", e.target.value)}
						label="Escrow"
						disabled={loanLocked}
					>
						<MenuItem value="">Escrow</MenuItem>
						<MenuItem value="Y">Taxes and Insurance</MenuItem>
						<MenuItem value="N">No Escrow</MenuItem>
					</Select>
				</FormControl>
				<TextField
					onChange={e => updateFormData("totalAssets", e.target.value)}
					value={formData?.totalAssets}
					label="Total Assets"
					placeholder="Total Assets"
					id={`LOCalculatorFormTotalAssetsInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
					disabled
					InputProps={{
						startAdornment: formData?.totalAssets ?
							<InputAdornment position="start">$</InputAdornment> : ""
					}}
				/>
			</div>
			<div className={`${flexDirection} flex w-full items-end`}>
				<FormControl
					key={"LOCalculatorFormLoanTerm1Picker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
				>
					<InputLabel>Loan Term 1</InputLabel>
					<Select
						value={formData?.loanTerm1}
						onChange={e => updateFormData("loanTerm1", e.target.value)}
						label="Loan Term"
						disabled={loanLocked}
					>
						<MenuItem value="">Loan Term 1</MenuItem>
						<MenuItem value="360">30 Years</MenuItem>
						<MenuItem value="300">25 Years</MenuItem>
						<MenuItem value="240">20 Years</MenuItem>
						<MenuItem value="180">15 Years</MenuItem>
						<MenuItem value="120">10 Years</MenuItem>
					</Select>
				</FormControl>
				<FormControl
					key={"LOCalculatorFormLoanTerm2Picker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
				>
					<InputLabel>Loan Term 2</InputLabel>
					<Select
						value={formData?.loanTerm2}
						onChange={e => updateFormData("loanTerm2", e.target.value)}
						label="Loan Term"
						disabled={loanLocked}
					>
						<MenuItem value="">Loan Term 2</MenuItem>
						<MenuItem value="360">30 Years</MenuItem>
						<MenuItem value="300">25 Years</MenuItem>
						<MenuItem value="240">20 Years</MenuItem>
						<MenuItem value="180">15 Years</MenuItem>
						<MenuItem value="120">10 Years</MenuItem>
					</Select>
				</FormControl>
			</div>
			<div className={`${flexDirection} flex w-full items-end`}>
				<FormControl
					key={"LOCalculatorFormPropertyOccupancyPicker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
				>
					<InputLabel>Property Occupancy</InputLabel>
					<Select
						value={formData?.propertyOccupancy}
						onChange={e => updateFormData("propertyOccupancy", e.target.value)}
						label="Property Occupancy"
						disabled={loanLocked}
					>
						<MenuItem value="">Property Occupancy</MenuItem>
						<MenuItem value="PrimaryResidence">Primary Residence</MenuItem>
						<MenuItem value="SecondHome">Second Home</MenuItem>
						<MenuItem value="InvestmentProperty">Investment</MenuItem>
					</Select>
				</FormControl>
				<FormControl
					key={"LOCalculatorFormLoanTerm2Picker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
				>
					<InputLabel>Home Type</InputLabel>
					<Select
						value={formData?.propertyType}
						onChange={e => updateFormData("propertyType", e.target.value)}
						label="Home Type"
						disabled={loanLocked}
					>
						<MenuItem value="">Home Type</MenuItem>
						<MenuItem value="SingleFamily">Single Family</MenuItem>
						<MenuItem value="MultiFamily">Multi Family 2-4 Units</MenuItem>
						<MenuItem value="Condo">Condo</MenuItem>
						<MenuItem value="TownHome">Town Home</MenuItem>
						<MenuItem value="CoOp">Co-Op</MenuItem>
					</Select>
				</FormControl>
			</div>
			<div className={`${flexDirection} flex w-full items-end`}>
				<TextField
					onChange={e => updateCurrency("BorrowerIncome", e.target.value)}
					value={formData?.borrowerIncome}
					label="Borrower Income"
					placeholder="Borrower Income"
					id={`LOCalculatorFormBorrowerIncomeInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
					disabled
					InputProps={{
						startAdornment: formData?.propertyValue ?
							<InputAdornment position="start">$</InputAdornment> : ""
					}}
				/>
				<FormControl
					key={"LOCalculatorFormLienType"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
				>
					<InputLabel>Lien Type</InputLabel>
					<Select
						value={formData?.lienType}
						onChange={e => updateFormData("lienType", e.target.value)}
						label="Lien Type"
						disabled={loanLocked}
					>
						<MenuItem key="First Lien" value="First Lien">First Lien</MenuItem>
						<MenuItem value="Second Lien">Subordinate Lien</MenuItem>
					</Select>
				</FormControl>
				{/* <TextField
                    onChange={e => updateFormData("loanProgram", e.target.value)}
                    value={formData?.loanProgram}
                    label="Loan Program"
                    placeholder="Loan Program"
                    id={`LOCalculatorFormLoanProgramInput`}
                    variant="standard"
                    className="w-full sm:px-12"
                    sx={{ mb: 2, mr }}
                    disabled={loanLocked}
                    inputProps={{ maxLength: 5 }}
                /> */}
			</div>
			<div className={`${flexDirection} flex w-full items-end`}>
				<TextField
					onChange={e => updateFormData("rate", e.target.value)}
					value={formData?.rate}
					label="Rate"
					placeholder="Rate"
					id={`LOCalculatorFormRateInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
					disabled={loanLocked}
					InputProps={{
						endAdornment: formData?.rate ?
							<InputAdornment position="end">%</InputAdornment> : ""
					}}
				/>
				{counties.length < 2
					? <TextField
						onChange={e => handleCountyChange(e.target.value)}
						value={formData?.county}
						label="County"
						placeholder="County"
						id={`LOCalculatorFormCountyInput`}
						variant="standard"
						className="w-full sm:px-12"
						sx={{mb: 2, mr}}
						disabled={loanLocked}
					/>
					: <FormControl
						key={"LOCalculatorFormLoanTerm2Picker"}
						variant="standard"
						className="w-full sm:px-12"
						sx={{mb: 2, mr}}
					>
						<InputLabel>County</InputLabel>
						<Select
							value={formData?.county}
							onChange={e => updateFormData("county", e.target.value)}
							label="County"
							disabled={loanLocked}
						>
							<MenuItem value="">County</MenuItem>
							{counties.map((option, index) =>
								<MenuItem key={index} value={option}>{option}</MenuItem>
							)}
						</Select>
					</FormControl>
				}
			</div>
			<div className={`${flexDirection} flex w-full items-end`}>
				<TextField
					onChange={e => updateFormData("flood", e.target.value)}
					value={formData?.flood}
					label="Monthly Flood"
					placeholder="Monthly Flood"
					id={`LOCalculatorFormFloodInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
					disabled={loanLocked}
					InputProps={{
						startAdornment: formData?.flood ?
							<InputAdornment position="start">$</InputAdornment> : ""
					}}
				/>
				<TextField
					onChange={e => updateFormData("hoa", e.target.value)}
					value={formData?.hoa}
					label="Monthly HOA"
					placeholder="Monthly HOA"
					id={`LOCalculatorFormHOAInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
					disabled={loanLocked}
					InputProps={{
						startAdornment: formData?.hoa ?
							<InputAdornment position="start">$</InputAdornment> : ""
					}}
				/>
			</div>
			<div className={`${flexDirection} flex w-full items-end`}>
				<TextField
					onChange={e => updateFormData("miFactor", e.target.value)}
					value={formData?.miFactor}
					label="MI Factor"
					placeholder="MI Factor"
					id={`LOCalculatorFormMIFactorInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
					disabled
					InputProps={{
						endAdornment: formData?.miFactor ?
							<InputAdornment position="end">%</InputAdornment> : ""
					}}
				/>
				<TextField
					onChange={e => updateFormData("mi", e.target.value)}
					value={formData?.mi}
					label="MI"
					placeholder="MI"
					id={`LOCalculatorFormMIInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr}}
					disabled
					InputProps={{
						startAdornment: formData?.mi ?
							<InputAdornment position="start">$</InputAdornment> : ""
					}}
				/>
			</div>
		</form>
)
}
