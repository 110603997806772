import React from "react";
import RadioControl from "./RadioControl";
import {FormControlLabel, Radio} from "@mui/material";

const RadioControlYesNo = (props) => {
	return (
		<RadioControl {...props}>
			<FormControlLabel
				value={true}
				control={
					<Radio size="small" />
				}
				label="Yes"
			/>
			<FormControlLabel
				value={false}
				control={
					<Radio size="small" />
				}
				label="No"
			/>
		</RadioControl>
	)
}

export default RadioControlYesNo;
