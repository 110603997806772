import { useCallback } from "react"
import { useAppContext } from "./index"
import { appActions } from "./AppReducer"
import { navigationLinking } from "../../services/navigation"

export const useAppContextActions = () => {
	const { dispatch } = useAppContext()

	const applySiteConfig = useCallback((siteConfig) => {
		dispatch({
			type: appActions.setSiteConfig,
			payload: { ...siteConfig }
		})
	}, [])

	const applyAuthToken = useCallback((authToken) => {
		dispatch({
			type: appActions.setAuthToken,
			payload: authToken
		})
	}, [])

	const applyUser = useCallback((user) => {
		dispatch({
			type: appActions.setUser,
			payload: user
		})
	}, [])

	const applyInvite = useCallback((invite) => {
		dispatch({
			type: appActions.setInvite,
			payload: invite
		})
	}, [])

	const applyRedirectUrl = useCallback((url) => {
		dispatch({
			type: appActions.setRedirectUrl,
			payload: url
		})
	}, [])

	const logOut = useCallback((route = `/${navigationLinking.SignIn}`) => {
		window.location.href = '/'
	}, [])

	return { applySiteConfig, applyAuthToken, applyUser, applyInvite, applyRedirectUrl, logOut }
}
