import React, { useState } from "react"
import { TextField, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material"
import { ModalColorPicker } from "../modals/ModalColorPicker"
import { ModalUploadSizeLimit } from "../modals/ModalUploadSizeLimit"
import { getTheme } from "../../config"
import { fileMBSizes } from "../../services/utils"

const theme = getTheme()

const ImagePicker = ({ title, formData, handleImagePicker, field, id }) => (
	<>
		<p className="text-sm font-rubik">{title}</p>
		<div className="flex flex-row mt-2.5 mb-4 items-center">
			<div className="w-52 h-10 rounded-tl rounded-bl border border-gray-300 flex items-center justify-center border-r-0">
				{!formData?.[field] ?
					<p className="pl-3.5 text-sm font-rubik">Choose your image</p> :
					<img
						className="h-10 rounded-tl rounded-bl"
						src={formData?.[field]}
						alt={field}
					/>
				}
			</div>
			<Button
				id={id}
				variant="contained"
				component="label"
				style={{
					textTransform: "none",
					height: 40,
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					boxShadow: "none"
				}}
			>
				Browse
				<input
					onChange={(e) => handleImagePicker(e, field)}
					hidden
					accept="image/*"
					multiple
					type="file"
					name="myImage"
				/>
			</Button>
		</div>
	</>
)

const ColorPicker = ({ title, formData, field, openModal }) => (
	<div className="flex flex-col mr-10">
		<p className="text-sm font-rubik">{title}</p>
		<div
			className="w-14 h-14 rounded border border-slate-300"
			style={{
				backgroundColor: formData?.[field] === "" ?
					theme.color.primary.white : formData?.[field]
			}}
		>
			<Button
				onClick={() => openModal(field, formData?.[field])}
				style={{ height: 55, minWidth: 55, padding: 0 }}
				hidden
			/>
		</div>
	</div>
)

const IconPicker = ({ title, formData, field, handleImagePicker }) => (
	<div className="flex flex-col mr-10">
		<p className="text-sm font-rubik">{title}</p>
		<div className="w-14 h-14 rounded border border-slate-300">
			<Button
				component="label"
				style={{
					height: 55,
					minWidth: 55,
					padding: 0,
					backgroundImage: formData?.[field] === "" ? null : `url(${formData?.[field]})`,
					backgroundRepeat: "round"
				}}
			>
				<input
					onChange={(e) => handleImagePicker(e, field)}
					hidden
					accept="image/*"
					multiple
					type="file"
					name="myImage"
				/>
			</Button>
		</div>
	</div>
)

export const WebsiteSettings = ({ formData, setFormData, entityType, width }) => {
	const [modalColorPicker, setModalColorPicker] = useState({ open: false, colorName: "", modalColor: "", dataColor: "" })
	const [modalSizeLimitVisible, setModalSizeLimitVisible] = useState(false)

	const openModal = (colorName, dataColor) => {
		if (dataColor === "") {
			dataColor = theme.color.primary.white
		}
		setModalColorPicker({ open: true, colorName: colorName, dataColor: dataColor, modalColor: "" })
	}

	const updatePOSSiteConfiguration = (field, value) => {
		setFormData(formData => ({ ...formData, [field]: value }))
	}

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result)
			}
			fileReader.onerror = (error) => {
				reject(error)
			}
		})
	}

	const handleImagePicker = async (e, field) => {
		const file = e.target.files[0]

		if (file?.size > fileMBSizes["20"]) {
			setModalSizeLimitVisible(true)
		} else {
			const base64 = await convertBase64(file)
			setFormData({ ...formData, [field]: base64 })
		}
	}

	return (
		<>
			<ModalColorPicker
				modalColorPicker={modalColorPicker}
				setModalColorPicker={setModalColorPicker}
				setFormData={setFormData}
				formData={formData}
			/>
			<ModalUploadSizeLimit
				visible={modalSizeLimitVisible}
				closeModal={() => setModalSizeLimitVisible(false)}
			/>
			<div className="flex flex-col md:flex-row">
				<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
					<div className="flex flex-row w-full items-end">
						<FormControl
							key={"WebsiteSettingsLandingPageTypePicker"}
							variant="standard"
							className="w-full sm:px-12"
							sx={{ mb: 2, mr: 2 }}
						>
							<InputLabel>Landing Page Type</InputLabel>
							<Select
								value={formData?.landingPageType || 0}
								onChange={e => updatePOSSiteConfiguration("landingPageType", e.target.value)}
								label="Landing Page Type"
							>
								<MenuItem value={0}>Default Landing Page</MenuItem>
								<MenuItem value={1}>Condensed Landing Page</MenuItem>
							</Select>
						</FormControl>
					</div>
					{entityType !== 3 &&
						<ImagePicker
							title="Main Banner (1270 x 415)"
							formData={formData}
							handleImagePicker={handleImagePicker}
							field="bannerUrl"
							id="WebsiteSettingsUpdateMainBannerImageButton"
						/>
					}
					<ImagePicker
						title="Secondary Banner (1270 x 300)"
						formData={formData}
						handleImagePicker={handleImagePicker}
						field="secondaryBannerUrl"
						id="WebsiteSettingsUpdateSecondaryBannerImageButton"
					/>
					<ImagePicker
						title="Brand Logo (620 x 120)"
						formData={formData}
						handleImagePicker={handleImagePicker}
						field="logoUrl"
						id="WebsiteSettingsUpdateLogoImageButton"
					/>
					<ImagePicker
						title="Portal Logo (600 x 300)"
						formData={formData}
						handleImagePicker={handleImagePicker}
						field="portalLogoUrl"
						id="WebsiteSettingsUpdatePortalLogoImageButton"
					/>
					{![0, 1, 2].includes(entityType) &&
						<ImagePicker
							title="Headshot (300 x 300)"
							formData={formData}
							handleImagePicker={handleImagePicker}
							field="profilePhotoUrl"
							id="WebsiteSettingsUpdateProfilePhotoImageButton"
						/>
					}
					<ImagePicker
						title="App Background Image"
						formData={formData}
						handleImagePicker={handleImagePicker}
						field="backgroundImageUrl"
						id="WebsiteSettingsUpdateBackgroundImageButton"
					/>
					{entityType === 2 &&
						<ImagePicker
							title="Location Image"
							formData={formData}
							handleImagePicker={handleImagePicker}
							field="locationImageUrl"
							id="WebsiteSettingsUpdateLocationImageButton"
						/>
					}
					<div className="flex flex-row justify-between w-72 mt-5">
						<ColorPicker title="Bg Color" formData={formData} field="backgroundColor" openModal={openModal} />
						<ColorPicker title="Primary" formData={formData} field="primaryColor" openModal={openModal} />
						<ColorPicker title="Secondary" formData={formData} field="secondaryColor" openModal={openModal} />
					</div>
					<div className="flex flex-row justify-between w-72 mt-5">
						<ColorPicker title="Text" formData={formData} field="textColor" openModal={openModal} />
						<ColorPicker title="Icon" formData={formData} field="iconColor" openModal={openModal} />
						<IconPicker title="Favicon" formData={formData} field="iconUrl" handleImagePicker={handleImagePicker} />
					</div>
					<div>
						<div className="mt-8 mb-4 font-bold">Dark Mode</div>
						<ImagePicker
							title="Brand Logo (620 x 120)"
							formData={formData}
							handleImagePicker={handleImagePicker}
							field="darkModeLogoUrl"
							id="WebsiteSettingsUpdateDarkModeLogoImageButton"
						/>
						<ImagePicker
							title="Portal Logo (600 x 300)"
							formData={formData}
							handleImagePicker={handleImagePicker}
							field="darkModePortalLogoUrl"
							id="WebsiteSettingsUpdateDarkPortalLogoImageButton"
						/>
						<div className="flex flex-row justify-between w-72 mt-5">
							<ColorPicker title="Primary" formData={formData} field="darkModePrimaryColor" openModal={openModal} />
							<ColorPicker title="Secondary" formData={formData} field="darkModeSecondaryColor" openModal={openModal} />
							<IconPicker title="Favicon" formData={formData} field="darkModeIconUrl" handleImagePicker={handleImagePicker} />
						</div>
					</div>
				</div>

				<div className="w-full mt-5 md:mt-0 md:ml-2 md:w-1/2 md:mb-0 md:max-w-screen-sm">
					<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
						<TextField
							id={`AdminAccountWebsiteSettingsFacebookUrl`}
							placeholder="Enter Facebook URL"
							value={formData?.facebookUrl}
							label="Facebook"
							onChange={e => updatePOSSiteConfiguration("facebookUrl", e.target.value)}
							variant="standard"
							className="w-full sm:px-12"
							sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
						/>
						<TextField
							id={`AdminAccountWebsiteSettingsTwitterUrl`}
							placeholder="Enter Twitter URL"
							value={formData?.twitterUrl}
							label="Twitter"
							onChange={e => updatePOSSiteConfiguration("twitterUrl", e.target.value)}
							variant="standard"
							className="w-full sm:px-12"
							sx={{ mb: 2 }}
						/>
					</div>
					<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
						<TextField
							id={`AdminAccountWebsiteSettingsInstagramUrl`}
							placeholder="Enter Instagram URL"
							value={formData?.instagramUrl}
							label="Instagram"
							onChange={e => updatePOSSiteConfiguration("instagramUrl", e.target.value)}
							variant="standard"
							className="w-full sm:px-12"
							sx={{ mb: 2, mr: width > 468 ? 2 : 0 }}
						/>
						<TextField
							id={`AdminAccountWebsiteSettingsLinkedInUrl`}
							placeholder="Enter LinkedIn URL"
							value={formData?.linkedInUrl}
							label="LinkedIn"
							onChange={e => updatePOSSiteConfiguration("linkedInUrl", e.target.value)}
							variant="standard"
							className="w-full sm:px-12"
							sx={{ mb: 2 }}
						/>
					</div>
					<p className="mt-5 md:mt-0 mb-2.5 text-sm font-rubik">Footer Disclaimer Text 1</p>
					<TextField
						id="AdminBranchProfileFooterDisclaimerText1TextArea"
						placeholder="Type something..."
						value={formData?.footerDisclaimerText1}
						onChange={e => updatePOSSiteConfiguration("footerDisclaimerText1", e.target.value)}
						className="w-full"
						multiline
						rows={10}
						sx={{ mb: 2 }}
					/>
					<p className="mt-5 md:mt-0 mb-2.5 text-sm font-rubik">Footer Disclaimer Text 2</p>
					<TextField
						id="AdminBranchProfileFooterDisclaimerText2TextArea"
						placeholder="Type something..."
						value={formData?.footerDisclaimerText2}
						onChange={e => updatePOSSiteConfiguration("footerDisclaimerText2", e.target.value)}
						className="w-full"
						multiline
						rows={10}
						sx={{ mb: 2 }}
					/>
				</div>
			</div>
		</>
	)
}
