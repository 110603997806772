import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import { LoadingBtn } from '../Button'
import ChangePassword from '../ChangePassword'
import { updateUserPassword } from '../../services/client'
import { ModalYesNo } from './ModalYesNo'
import { useAlert } from '../../hooks';

export const ModalChangePassword = ({ modal, modalData, modalTitle, setModal, user }) => {
	const [loading, setLoading] = useState(false)
	const [password, setPassword] = useState("")
	const [passwordConfirmed, setPasswordConfirmed] = useState("")
	const [disabledChangePwButton, setDisabledChangePwButton] = useState(true)
	const [serverError, setServerError] = useState("")
	const [modalConfirmation, setModalConfirmation] = useState({ open: false, text: "The password has been reset", isOkButton: true })
	const { alert } = useAlert()


	const closeModal = () => {
		setModal({ data: {}, title: "", visible: false })
	}

	const handleChangePassword = async () => {
		setServerError("")
		setLoading(true)

		try {
			await updateUserPassword(modalData.id, password)
			setLoading(false)
			setPassword("")
			setModal({ ...modal, visible: false})
			alert('The password was successfully changed')
		} catch (e) {
			setServerError(e)
			setLoading(false)
		}
	}

	return (
		<Dialog
			open={modal}
			onClose={closeModal}
			aria-labelledby="responsive-dialog-title"
			sx={{
				"& .MuiDialog-paper": {
					margin: 1
				},
			}}
		>
			<div className="flex flex-col items-center px-5 sm:px-10 py-8 sm:py-10">
				<ModalYesNo
					modalConfirmation={modalConfirmation}
					setModalConfirmation={() => {
						setModalConfirmation({ ...modalConfirmation, open: false })
						closeModal()
					}}
				/>
				<p className="text-sm sm:text-2xl">{modalTitle}</p>
				<ChangePassword
					passwordLabel={"New Password"}
					confirmPasswordLabel={"Confirm New Password"}
					userPassword={password}
					setUserPassword={setPassword}
					setConfirmButton={setDisabledChangePwButton}
					confirmButtonEnabled={!disabledChangePwButton}
					passwordConfirmed={passwordConfirmed}
					setPasswordConfirmed={setPasswordConfirmed}
					hideConfirmPassword
				/>
				{serverError && <p className={[serverError ? "text-sm text-red-500" : "hidden"]}>{serverError}</p>}
				<div className="w-64 sm:w-96 mt-4">
					<LoadingBtn
						id={`ModalChangePasswordButton`}
						disabled={!disabledChangePwButton}
						loading={loading}
						onClick={handleChangePassword}
						text="Change password"
					/>
				</div>
			</div>
		</Dialog>
	)
}
