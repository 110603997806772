import { useState } from 'react'
import {
	DialogContent, Dialog, FormControl, Select, MenuItem, InputLabel,
	DialogActions
} from '@mui/material'
import { Button } from "../Button"
import { isDarkMode } from "../../services/helper"

export const ModalGeneratedDocumentSelector = ({ visible, setVisible, siteConfig, options, submit }) => {
	const [selected, setSelected] = useState('')

	const handleSelect = e => {
		const id = e.target.value
		setSelected(id)
	}

	const handleClose = () => {
		setSelected('')
		setVisible(false)
	}

	const handleContinue = () => {
		submit(selected)
	}

	return (
		<Dialog
			open={visible}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogContent>
				<div><img alt={`${siteConfig.companyName} logo`} className={`w-full`} src={isDarkMode() && siteConfig?.darkModeLogoUrl ? siteConfig.darkModeLogoUrl : siteConfig?.logoUrl} /></div>
				<div className='text-center mt-5 mb-4 font-bold text-lg'>Which Document Template would you like to apply?</div>
				<div className='w-full'>
					<FormControl sx={{ width: "100%", mt: 1 }}>
						<InputLabel>Document Templates</InputLabel>
						<Select
							variant="standard"
							value={selected}
							label="Please select a Document Template"
							onChange={handleSelect}
						>
							{options?.map((option, index) => (
								<MenuItem value={option.id} key={index}>
									{option.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</DialogContent>
			<DialogActions className='mr-5 mb-2'>
				<Button
					onClick={handleClose}
					text="Cancel"
				/>
				<Button
					onClick={e => handleContinue(e)}
					disabled={selected.length < 1}
					text={selected.length < 1 ? 'Choose a Template' : 'Generate Document'}
				/>
			</DialogActions>
		</Dialog>
	)
}
