import React, { useState, useEffect } from "react"
import { Dialog, Button, FormControl, InputLabel, Select, MenuItem, TextField, IconButton } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { Icon } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { getTaskType, states } from "../../services/helper"
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import { Loading } from '../Loading'
import { getTheme } from '../../config';

const theme = getTheme()

export const ModalTask = ({ visible, setTaskModalVisible, taskValue, setTaskValue, task = {}, handleTaskSubmit,
														pickDocument, uploadingTaskDocument }) => {
	const muiTheme = useTheme()
	const fullScreen = useMediaQuery(muiTheme.breakpoints.down("sm"))

	const [buttonDisabled, setButtonDisabled] = useState(true)
	const [validationMessage, setValidationMessage] = useState(null)

	if (!task) return (<></>)

	const dataType = (task.fieldDataType || "").toLowerCase()

	const handleSelectChange = v => {
		if (v) setButtonDisabled(false)
		setTaskValue(v)
	}

	const handleInputChangeText = (value) => {
		let newValue
		switch (dataType) {
			case "zipcode":
				newValue = value.replace(/\D/g, "")
				if (newValue.length <= 5)
					setTaskValue(newValue)
				setButtonDisabled(newValue.length !== 5)
				break
			case "integer":
				newValue = value.replace(/\D/g, "")
				setTaskValue(newValue)
				setButtonDisabled(newValue.length < 1)
				break
			case "decimal":
				newValue = value.replace(/[^0-9.]/g, "")
				setTaskValue(newValue)
				setButtonDisabled(newValue.length < 1)
				break
			case "ssn":
				newValue = value.replace(/\D/g, "")
				if (newValue.length > 9) {
					newValue = newValue.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3")
				}
				else if (newValue.length > 4) {
					newValue = newValue.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3")
				}
				else if (newValue.length > 2) {
					newValue = newValue.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2")
				}
				else {
					newValue = newValue.replace(/^(\d*)/, "$1")
				}

				setTaskValue(newValue)
				setButtonDisabled(newValue.length < 11)
				break
			default:
				newValue = value
				setTaskValue(newValue)
				setButtonDisabled(newValue.length < 2)
		}
	}

	const handleModalClose = () => {
		setTaskValue("")
		setTaskModalVisible(false)
	}

	const handleInputBlur = () => {
		switch (dataType) {
			case "ssn":
				if (!/^([1-9])(?!\1{2}-\1{2}-\1{4})[1-9]{2}-[1-9]{2}-[1-9]{4}/g.test(taskValue)) setValidationMessage("Please enter a valid ssn")
				else setValidationMessage(null)
				break
			case "zipcode":
				if (taskValue.length < 5) setValidationMessage("Please enter a valid zipcode")
				else setValidationMessage(null)
				break
			default:
				if (taskValue.length < 1) setValidationMessage("Please enter a value")
				else setValidationMessage(null)
				break
		}
	}
	let maxLength

	if (dataType === "zipcode") maxLength = 5

	return (
		<Dialog
			open={visible}
			onClose={() => setTaskModalVisible(false)}
		>
			<>
				<IconButton
					aria-label="close"
					onClick={() => setTaskModalVisible(false)}
					sx={{ position: "absolute", right: 8, top: 8 }}
				>
					<CloseIcon />
				</IconButton>

				<div className={`${fullScreen ? "flex-col items-center pt-10 pb-4 px-5" : "flex-col py-8 px-10 justify-center"} flex`}>
					<p className="text-lg font-bold text-center">
						{getTaskType(task.taskType)} Task
					</p>
					<p className="mb-5 text-center">{task.taskType === 0 ? `${task.taskDescription}` : `Enter the requested information below`}</p>
					{task.taskType === 0 &&
						<div
							className="flex flex-col items-center border-2 rounded-lg border-neutral-200 p-5 cursor-pointer relative w-full"
						>
							{uploadingTaskDocument ?
								<>
									<p className="text-lg font-bold text-center">
										Please be patient while the document is processed
									</p>
									<div className="flex self-center justify-center w-64 md:w-96 mt-5">
										<Loading size="small" />
									</div>
								</> :
								<>
									<Icon
										aria-label="upload"
										color="primary"
										sx={{ height: 96, width: 96 }}
									>
										<FileUploadOutlinedIcon sx={{ height: 96, width: 96 }} />
									</Icon>
									<p className="text-sm mb-3 font-bold text-slate-400 text-center">
										Select document for upload
									</p>

									<Button
										variant="outlined"
										component="label"
										style={fullScreen ? { width: 180, marginBottom: 12 } : { width: 200, marginRight: 12 }}
									>
										{`${fullScreen ? "TAP" : "CLICK"} TO CHOOSE FILE`}
										<input
											onChange={(e) => pickDocument(e, "task")}
											hidden
											multiple
											type="file"
											accept={theme.validation.allowedDocumentTypes.join(', ')}
										/>
									</Button>
									<input
										onChange={(e) => pickDocument(e, "task")}
										type="file"
										className="w-full h-full absolute opacity-0 cursor-pointer -bottom-0"
										accept={theme.validation.allowedDocumentTypes.join(', ')}
									/>
								</>
							}
						</div>
					}

					{task.taskType === 1 &&
						<div className="flex flex-col">
							{["string", "integer", "decimal", "zipcode", "ssn"].includes(dataType) &&
								<TextField
									onChange={e => handleInputChangeText(e.target.value)}
									onBlur={handleInputBlur}
									value={taskValue}
									label={taskValue === "" ? null : task.taskDescription}
									placeholder={task.taskDescription}
									inputProps={{ maxLength: maxLength }}
									className={`${fullScreen ? "w-52" : "w-72"} self-center`}
									variant="standard"
									error={validationMessage}
									helperText={validationMessage ? validationMessage : ""}
								/>
							}

							{dataType === "state" &&
								<FormControl sx={fullScreen ? { width: 220 } : { width: 290 }}>
									<InputLabel>Select a state...</InputLabel>
									<Select
										value={taskValue}
										onChange={v => handleSelectChange(v.target.value)}
										label="Select a state..."
									>
										<MenuItem key="default" value="">Select a state...</MenuItem>
										{states.map((state, index) =>
											<MenuItem key={index} value={state.value}>
												{state.label}
											</MenuItem>)}
									</Select>
								</FormControl>
							}

							<div className={`${fullScreen ? "flex-col items-center" : "flex-row"} flex mt-5`}>
								<Button
									id="Cancel"
									onClick={handleModalClose}
									style={fullScreen ? { width: 208, marginBottom: 12 } : { width: 140, marginRight: 12 }}
									variant="outlined"
								>
									Cancel
								</Button>
								<Button
									disabled={buttonDisabled}
									onClick={() => handleTaskSubmit(taskValue)}
									style={fullScreen ? { width: 208, marginBottom: 12 } : { width: 140 }}
									variant="contained"
								>
									Submit
								</Button>
							</div>
						</div>
					}

					{task.taskType === 2 && <div
						className="flex flex-col items-center border-2 rounded-lg border-neutral-200 p-5 cursor-pointer relative w-full"
					>
						{task.taskDescription}
						<div className={`${fullScreen ? "flex-col items-center" : "flex-row"} flex mt-5`}>
							<Button
								id="Cancel"
								onClick={handleModalClose}
								style={fullScreen ? { width: 208, marginBottom: 12 } : { width: 140, marginRight: 12 }}
								variant="outlined"
							>
								Cancel
							</Button>
							<Button
								disabled={buttonDisabled}
								onClick={() => handleTaskSubmit(taskValue)}
								style={fullScreen ? { width: 208, marginBottom: 12 } : { width: 140 }}
								variant="contained"
							>
								Submit
							</Button>
						</div>
					</div>}

				</div>
			</>
		</Dialog>
	)
}
