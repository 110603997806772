import React, { useState, useEffect } from "react"
import { filter } from "lodash"
import { useVenti } from "venti"
import { DataTable } from "../../../components/DataTable"
import Page from "../../../components/Page"
import { useAppContext } from "../../../components/AppContext"
import { useAppContextActions } from "../../../components/AppContext/AppHooks"
import { getTheme } from "../../../config"
import {
	getUsers,
	createUser,
	updateUser,
	deleteCorporate,
	undeleteCorporate, undeleteUser, deleteUser
} from '../../../services/client';
import { getErrorMessage } from "../../../services/helper"
import userQuestions from "../../../workflows/user-questions.js"
import { useAlert } from "../../../hooks"
import { ModalRemoveRecordConfirm } from '../../../components/modals/ModalRemoveRecordConfirm';

const theme = getTheme()

export default function AdminUsersView() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user, siteConfig } = state
	const ventiState = useVenti()

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])
	const [dataCopy, setDataCopy] = useState([])
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] = useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [rowToRestore, setRowToRestore] = useState()

	const headers = [
		{ id: "email", label: "Email" },
		{ id: "last", label: "Last Name" },
		{ id: "first", label: "First Name" },
		{ id: "role", label: "Role" },
		{ id: "created", label: "Created On" },
	]

	const fields = [
		"email",
		"lastName",
		"firstName",
		"role",
		"createdAt",
	]

	useEffect(() => {
		(async () => {
			if (!user.isLoggedIn) await logOut()
			else await loadUsers()
		})()

	}, [])

	const loadUsers = async () => {
		try {
			setFetching(true)
			const users = await getUsers(true) || []
			setData(users)
			setDataCopy(users)
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const handleFilterByRoles = (value) => {
		if (value === "") {
			setData(dataCopy)
		} else {
			setData(filter(dataCopy, { role: value }))
		}
	}

	const saveUser = async data => {
		if (data?.id) {
			return updateUser(data)
		}

		return createUser({
			accountID: siteConfig.id,
			siteConfigurationId: siteConfig.id,
			userRole: data.role,
			...data
		})
	}

	const showRemoveModal = row => {
		setRemoveModalVisible(true)
		setRowToRemove({ ...row, name: `${row.firstName} ${row.lastName}` })
	}

	const removeUser = async () => {
		setRemoveModalVisible(false)

		try {
			await deleteUser(rowToRemove.id)
			await loadUsers()
			setRowToRemove(null)
			alert(`User "${rowToRemove.name}" successfully removed`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const showRestoreModal = row => {
		setRestoreModalVisible(true)
		setRowToRestore({ ...row, name: `${row.firstName} ${row.lastName}` })
	}

	const restoreUser = async () => {
		setRestoreModalVisible(false)

		try {
			await undeleteUser(rowToRestore.id)
			alert(`Branch ${rowToRestore.name} successfully restored`)
			setRowToRestore(null)
			await loadUsers()
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	return (
		<Page title="Users" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-screen overflow-auto">
				<ModalRemoveRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeUser}
					row={rowToRemove}
				/>
				<ModalRemoveRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restoreUser}
					row={rowToRestore}
				/>
				<DataTable
					data={data}
					headers={headers}
					fields={fields}
					entity={"User"}
					title={"Users"}
					fetching={fetching}
					tableType={"users"}
					buttonTitle={"Add User"}
					get={data}
					set={setData}
					questions={userQuestions}
					save={saveUser}
					remove={showRemoveModal}
					restore={showRestoreModal}
					refresh={loadUsers}
					handleFilterByRoles={handleFilterByRoles}
				/>
			</div>
		</Page >
	)
}
