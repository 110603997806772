import { useState } from "react"
import { Dialog } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { Button } from "../Button"
import { useAppContext } from "../AppContext"
import { isDarkMode } from "../../services/helper"

export const ModalExportData = ({ visible, setVisible, action }) => {
	const { state } = useAppContext()
	const { siteConfig } = state
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
	const [value, setValue] = useState('csv')

	const handleChange = (event) => {
		setValue(event.target.value)
	};

	const exportData = () => {
		setVisible(false)
		action(value)
	}

	return (
		<Dialog
			open={visible}
			aria-labelledby="responsive-dialog-title"
		>
			<div className="flex flex-col items-center px-6 md:max-w-lg py-8 md:px-10">
				<img src={isDarkMode() && siteConfig?.darkModeLogoUrl ? siteConfig.darkModeLogoUrl : siteConfig?.logoUrl} alt="Company Logo" className="w-64 sm:w-96 py-10" />
				<FormControl className={`mb-3`}>
					<FormLabel id="radio-buttons-group-label">Choose a file format to export</FormLabel>
					<RadioGroup
						aria-labelledby="radio-buttons-group-label"
						value={value}
						onChange={handleChange}
						name="radio-buttons-group"
					>
						<FormControlLabel value="csv" control={<Radio />} label=".csv" />
						<FormControlLabel value="xlsx" control={<Radio />} label=".xlsx" />
						<FormControlLabel value="json" control={<Radio />} label=".json" />
					</RadioGroup>
				</FormControl>
				<Button
					id="ModalYesNoOKButton"
					text="Export"
					onClick={exportData}
					style={{ width: fullScreen ? "90%" : 290, marginBottom: 10, marginTop: 10 }}
					variant="contained"
				/>
				<Button
					id="ModalYesNoYesButton"
					text="Cancel"
					onClick={() => setVisible(false)}
					style={{ width: fullScreen ? "90%" : 290, marginBottom: 10 }}
					variant="outlined"
				/>
			</div>
		</Dialog>
	)
}
