import React, {useEffect, useState} from "react";
import Select from './Select';
import {getWorkflowField} from "../../services/utils";
import {omit} from 'lodash';

const WorkflowSelect = ({name, workflow, ...rest}) => {
	const [menuItems, setMenuItems] = useState([]);
	useEffect(() => {
		async function getFieldOptions() {
			const result = await getWorkflowField(workflow, name);
			if(result) {
				setMenuItems(result.options);
			} else {
				console.warn(`No fieldId ${name} found in ${workflow}`)
			}
		}
		getFieldOptions();
	}, [name, workflow])

	return (
		<Select name={name} menuItems={menuItems} {...omit(rest, 'menuItems')} />
	)
}

export default WorkflowSelect;
