import React, { useState } from 'react'
import { LoanDetailsCard } from './LoanDetailsCard'

export const Assets = ({ data, loading, index }) => {
	const [title, setTitle] = useState(index === 0 ? 'Asset Account' : `Asset Account ${index + 1}`)

	let indexBasedFields = [
		{ key: `BorrowerAssetsInstitution${index}`, title: 'Institution Name', value: null },
		{ key: `BorrowerAssetsAccountType${index}`, title: 'Account Type', value: null },
		{ key: `BorrowerAssetsAccountBalance${index}`, title: 'Current Balance', value: null }
	]

	const assets = indexBasedFields.map(e => {
		e.value = data[`${e.key}`]
		return e
	})

	if (index > 0 && !data[`BorrowerAssetsInstitution${index}`]) return <></>

	return (
		<>
			<LoanDetailsCard
				title={title} data={assets} loading={loading} style={{ marginBottom: 0 }}
			/>
		</>
	)
}
