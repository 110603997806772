import React, { useEffect, useState } from "react"
import { useVenti } from "venti"
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import {
	Card,
	CardContent,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextField
} from '@mui/material'
import Page from "../../../components/Page"
import { getTheme } from "../../../config"
import { getForm, saveForm } from "../../../services/client"
import { a11yProps, formTypes, getErrorMessage, questionTypes } from '../../../services/helper';
import { useAlert } from "../../../hooks"
import Prequal from "../../../workflows/borrower-prequal.json"
import SuperShort from "../../../workflows/super-short-app.json"
import Borrower from "../../../workflows/borrower-app.json"
import CoBorrower from "../../../workflows/coborrower-app.json"
import LOBorrower from "../../../workflows/lo-borrower-app.json"
import LOCoBorrower from "../../../workflows/lo-coborrower-app.json"
import Test from "../../../workflows/test-app.json"
import SpanishLOBorrower from "../../../workflows/lo-borrower-app-spanish.json"
import SpanishBorrower from "../../../workflows/borrower-app-spanish.json"
import SpanishPrequal from "../../../workflows/borrower-prequal-spanish.json"
import LoanApp from '../../loanapp/LoanApp'
import { getExampleQuestionByType, isJsonString } from '../../../services/utils'
import { LoadingBtn } from '../../../components/Button'
import { ModalWorkflowQuestion } from '../../../components/modals/ModalWorkflowQuestion'
import TabPanel from '../../../components/TabPanel'
import MailMergeSelect from '../../../components/MailMergeSelect'
import { useAppContext } from '../../../components/AppContext';

const theme = getTheme()

const Initial = [{
	"title": "The Big POS Workflow Editor",
	"heading": "Welcome to the Future of Lending Applications",
	"question": "With The Big Point of Sale's Workflow Editor, you can create and customize workflows to meet your specific lending needs. Use the panel on the left to add question, the middle section will display the source code that drives the application, and this area will show you a preview of the workflow as you build it. If you need help, contact our support team and we'll be happy to assist you.",
	"type": "information",
	"buttonText": "OK I Got It",
	"logo": true,
	"qId": "WelcomeStatement",
	"fieldId": "WelcomeStatement",
	"losFieldId": "",
	"name": "Welcome Statement",
	"target": ""
}]

const defaultApps = {
	Initial,
	Prequal,
	"Super Short": SuperShort,
	Borrower,
	CoBorrower,
	"Spanish Borrower": SpanishBorrower,
	"Spanish Prequal": SpanishPrequal,
	"LO Borrower": LOBorrower,
	"LO CoBorrower": LOCoBorrower,
	"LO Spanish Borrower": SpanishLOBorrower,
	Test
}

export default function WorkflowEdit() {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const { user } = state
	const id = ventiState.get(theme.storageKeys.editingWorkflowId) || ''
	const themeMUI = useTheme()
	const [fetching, setFetching] = useState(false)
	const [saving, setSaving] = useState(false)
	const [importFileName, setImportFileName] = useState('')
	const [workflow, setWorkflow] = useState({
		id: "",
		name: "",
		formJSON: "[]",
		type: 0,
		borrowerType: 0,
		isDefault: false
	})
	const [activeQuestion, setActiveQuestion] = useState('')
	const [questionModalOpen, setQuestionModalOpen] = useState(false)
	const [tab, setTab] = useState(0)
	const [targets, setTargets] = useState([])

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const updateWorkflow = (key, value) => {
		setWorkflow(current => ({ ...current, [key]: value }))
	}

	const updateQuestion = question => {
		const newJSON = JSON.parse(workflow.formJSON).map(q => q.qId !== question.qId ? q : question)
		updateWorkflow('formJSON', JSON.stringify(newJSON, undefined, 4))
	}

	useEffect(() => {
		if (id && id !== 'new') {
			setFetching(true)
			getForm(id).then(data => {
				setFetching(false)
				setWorkflow({ ...data, formJSON: JSON.stringify(data.formJSON, undefined, 4) })
			}).catch(e => {
				setFetching(false)
				ventiState.set(theme.storageKeys.errorObject, {
					message: getErrorMessage(e)
				})
			})
		}
	}, [id])

	useEffect(() => {
		if (workflow?.formJSON && isJsonString(workflow.formJSON)) {
			setTargets(JSON.parse(workflow.formJSON)?.map(q => q.qId).filter(t => !!t).sort())
		}
	}, [workflow])

	const canSubmit = workflow?.name && workflow?.formJSON && isJsonString(workflow.formJSON)

	useEffect(() => {
		if (id === 'new') setWorkflow(current => ({ ...current, formJSON: JSON.stringify(defaultApps[importFileName || 'Initial'], undefined, 4) }))
	}, [importFileName, id])

	const updateJSONText = e => {
		const text = e.target.value
		setWorkflow(current => ({ ...current, formJSON: text }))
	}

	// const appendItem = type => {
	// 	const newQuestion = getExampleQuestionByType(type)
	// 	newQuestion.qId = `${newQuestion.type}${JSON.parse(workflow.formJSON).length + 1}`
	// 	newQuestion.fieldId = newQuestion.qId
	// 	setWorkflow(current => {
	// 		const override = JSON.parse(current.formJSON)
	// 		if (override.length) {
	// 			const lastQuestion = override.pop()
	// 			lastQuestion.target = newQuestion.qId
	// 			override.push(lastQuestion)
	// 		}
	// 		current.formJSON = JSON.stringify([...override, newQuestion], undefined, 4)
	// 		return current
	// 	})
	// }

	// const reset = () => {
	// 	setWorkflow(current => ({ ...current, formJSON: JSON.stringify(defaultApps[importFileName || 'Initial'], undefined, 4) }))
	// }

	// const clear = () => {
	// 	setWorkflow(current => ({ ...current, formJSON: JSON.stringify([], undefined, 4) }))
	// 	setImportFileName('')
	// }

	const save = () => {
		setSaving(true)
		if (workflow.isDefault) {
			delete workflow.id
			delete workflow.isDefault
			workflow.accountID = user.accountID
		}

		saveForm({ ...workflow, formJSON: JSON.parse(workflow.formJSON) })
			.then(() => {
				alert(`Workflow ${workflow.name} successfully ${workflow.id ? "updated" : "created"}`)
			})
			.catch(e => {
				ventiState.set(theme.storageKeys.errorObject, {
					message: getErrorMessage(e)
				})
			})
			.finally(() => {
				setSaving(false)
			})
	}

	const handleAddQuestion = e => {
		setActiveQuestion(JSON.stringify(getExampleQuestionByType(e.target.value), undefined, 4))
		setQuestionModalOpen(true)
	}

	const editQuestion = question => {
		setActiveQuestion(JSON.stringify(question, undefined, 4))
		setQuestionModalOpen(true)
	}

	return (
		<Page isFullWidth={true} title="Edit Workflow">
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<div className={`flex justify-between`}>
					<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
						Workflows - {id === "new" ? "Create" : `${workflow.name}`}
					</p>
					<LoadingBtn
						id={`AdminWorkflowEditSaveButton`}
						disabled={!canSubmit}
						loading={saving}
						text={`${workflow?.id ? "Update Workflow" : "Create Workflow"}`}
						onClick={save}
						fullWidth={false}
						style={{ height: 40 }}
					/>
				</div>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={tab}
					onChange={handleTabChange}
					aria-label="Tabs"
				>
					<Tab label="Edit Workflow" {...a11yProps(0)} />
					<Tab label="Preview" {...a11yProps(1)} />
				</Tabs>
				<SwipeableViews
					axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={tab} index={0} dir={themeMUI.direction}>
						<Card>
							<CardContent>
								<MailMergeSelect combinedOptions />
								<div className={`flex space-between`}>
									<div className={`w-1/5 pr-6`}>
										<FormControl sx={{ width: "100%", mt: 1 }}>
											<TextField
												className={`w-full`}
												value={workflow.name}
												onChange={e => updateWorkflow('name', e.target.value)}
												label={`Name`}
												placeholder={'Name the workflow...'}
												variant='standard'
											/>
										</FormControl>
										<FormControl variant="standard" sx={{ width: "100%", mt: 3 }}>
											{!isNaN(workflow.type) && <InputLabel>Application Type</InputLabel>}
											<Select
												label={'Application Type'}
												className={`w-full`}
												value={workflow.type}
												onChange={e => updateWorkflow('type', e.target.value)}
												variant='standard'
											>
												{formTypes.map((name, index) => <MenuItem key={index} value={index}>{name}</MenuItem>)}
												<MenuItem value={-1}>- Workflow Block -</MenuItem>
											</Select>
										</FormControl>
										<FormControl variant="standard" sx={{ width: "100%", mt: 3 }}>
											{!isNaN(workflow.borrowerType) && <InputLabel>Borrower Role</InputLabel>}
											<Select
												label={'Borrower Role'}
												className={`w-full`}
												value={workflow.borrowerType}
												onChange={e => updateWorkflow('borrowerType', e.target.value)}
												variant='standard'
											>
												<MenuItem value={0}>Borrower</MenuItem>
												<MenuItem value={1}>Co-Borrower</MenuItem>
											</Select>
										</FormControl>
										<FormControl sx={{ width: "100%", mt: 1 }}>
											<TextField
												className={`w-full`}
												value={workflow.description}
												onChange={e => updateWorkflow('description', e.target.value)}
												label={`Description`}
												placeholder={'Describe the workflow...'}
												variant='standard'
												multiline
												rows={5}
											/>
										</FormControl>
										<FormControl sx={{ width: "100%", mt: 1 }}>
											<InputLabel className={`text-lg`}>Add a Question</InputLabel>
											<Select
												className={`w-full`}
												onChange={handleAddQuestion}
												variant='standard'
											>
												{questionTypes.map(option => (
													<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
												))}
											</Select>
										</FormControl>
									</div>
									<div className={`w-4/5`}>
										<FormControl sx={{ width: "100%", mt: 1 }}>
											<TextField
												className={`w-full`}
												multiline
												rows={120}
												value={workflow.formJSON || ''}
												onChange={updateJSONText}
											/>
										</FormControl>
									</div>
								</div>
							</CardContent>
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={1} dir={themeMUI.direction}>
						<Card>
							<CardContent>
								<div className={`w-full border scroll-y h-full`} style={{ height: 1000, overflowY: 'scroll' }}>
									<LoanApp
										questionsOverride={workflow.formJSON}
										editQuestion={editQuestion}
									/>
								</div>
							</CardContent>
						</Card>
					</TabPanel>
				</SwipeableViews>
			</div>
			{!!activeQuestion && <ModalWorkflowQuestion
				targets={targets}
				question={activeQuestion}
				visible={questionModalOpen}
				setQuestion={setActiveQuestion}
				setVisible={setQuestionModalOpen}
				updateActiveQuestion={updateQuestion} />}
		</Page>
	)
}
