import React, { useEffect, useState } from "react"
import { TextField } from "@mui/material"
import { useAppContext } from "../../../components/AppContext"
import { applyMask } from "../../../services/utils"

export const ProfileView = ({ formData, setFormData, width }) => {
	const { state } = useAppContext()
	const { siteConfig } = state
	const [inValidFields, setInValidFields] = useState([])

	useEffect(() => {
		const initialLicenses = JSON.parse(JSON.stringify(formData.licenses))
		setFormData({
			...formData,
			licenses: initialLicenses,
		})
	}, [])

	const updatePOSSiteConfiguration = (field, value) => {
		if (field === "phone" || field === "tollFree" || field === "fax") {
			value = applyMask(value, "phone")
		}

		setFormData(formData => ({ ...formData, [field]: value }))
	}

	const handleInputValid = (id) => {
		return inValidFields.includes(id)
	}

	const handleOnChangeText = (text, field, nativeID) => {
		if (field === "phone" || field === "tollFree" || field === "fax") {
			text = applyMask(text, "phone")
		}

		updatePOSSiteConfiguration(field, text)

		if (text) {
			setInValidFields(inValidFields.filter(item => item !== nativeID))
		}
		else {
			setInValidFields([nativeID, ...inValidFields])
			handleInputValid(text)
		}
	}

	return (
		<div className="flex flex-col">
			<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
				<div className="flex flex-row w-full items-end">
					<TextField
						onChange={e => handleOnChangeText(e.target.value, "url", "AdminAccountProfileSiteUrlInput")}
						value={formData?.url}
						label="The Big POS Landing Page Url *"
						placeholder={`Site Url (Ex: admin.${siteConfig.url.replace(/^[^.]+\./g, "")})`}
						id={`AdminAccountProfileSiteUrlInput`}
						error={handleInputValid("AdminAccountProfileSiteUrlInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						onChange={e => handleOnChangeText(e.target.value, "name", "AdminAccountProfileCompanyNameInput")}
						value={formData?.name}
						label="Legal Company Name *"
						placeholder="Legal Company Name"
						id={`AdminAccountProfileCompanyNameInput`}
						error={handleInputValid("AdminAccountProfileCompanyNameInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						onChange={e => handleOnChangeText(e.target.value, "address", "AdminAccountProfileCompanyAddressInput")}
						value={formData?.address}
						label="Address *"
						placeholder="Address"
						id={`AdminAccountProfileCompanyAddressInput`}
						error={handleInputValid("AdminAccountProfileCompanyAddressInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminAccountProfileCompanyAddress2Input`}
						placeholder="Address Line 2"
						value={formData?.address2}
						label="Address Line 2"
						onChange={e => updatePOSSiteConfiguration("address2", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminAccountProfileCompanyCityInput`}
						placeholder="City"
						value={formData?.city}
						label="City *"
						onChange={e => handleOnChangeText(e.target.value, "city", "AdminAccountProfileCompanyCityInput")}
						error={handleInputValid("AdminAccountProfileCompanyCityInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminAccountProfileCompanyStateInput`}
						placeholder="State"
						value={formData?.state}
						label="State *"
						onChange={e => handleOnChangeText(e.target.value, "state", "AdminAccountProfileCompanyStateInput")}
						error={handleInputValid("AdminAccountProfileCompanyStateInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminAccountProfileCompanyZipInput`}
						placeholder="ZIP"
						value={formData?.zip}
						label="ZIP *"
						onChange={e => handleOnChangeText(e.target.value, "zip", "AdminAccountProfileCompanyZipInput")}
						error={handleInputValid("AdminAccountProfileCompanyZipInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminAccountProfilePhoneInput`}
						placeholder="Billing Phone"
						value={formData?.phone}
						label="Billing Phone *"
						onChange={e => handleOnChangeText(e.target.value, "phone", "AdminAccountProfilePhoneInput")}
						error={handleInputValid("AdminAccountProfilePhoneInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminAccountProfileFaxInput`}
						placeholder="Billing Fax"
						value={formData?.fax}
						label="Billing Fax"
						onChange={e => updatePOSSiteConfiguration("fax", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminAccountProfileTollFreeInput`}
						placeholder="Office Phone"
						value={formData?.tollFree}
						label="Office Phone"
						onChange={e => updatePOSSiteConfiguration("tollFree", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
			</div>
		</div>
	)
}
