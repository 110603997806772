import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from 'react-chartjs-2';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {TableCell} from "@mui/material";
import {useWindowSize} from "../../hooks";
import {applyMask} from "../../services/utils";
import {navigationLinking} from "../../services/navigation";
import {useNavigate} from "react-router-dom";
import {Button} from "../Button";
import {Roles} from "../../services/client";
import {useAppContext} from "../AppContext";

ChartJS.register(ArcElement, Tooltip, Legend);

const MonthlyPaymentChart = ({ data }) => {
	const [width] = useWindowSize()
	const navigate = useNavigate()
	const { state } = useAppContext()
	const { user } = state

	const options = {
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						let value = context.parsed || 0;
						return `$${applyMask(value, 'fullCurrency')}`;
					}
				}
			}
		}
	}

	if(data.totalMonthlyPayment <= 0) {
		return (
			<>
				<p className="text-slate-700 font-rubik text-md dark:text-slate-400 mb-2">No data available</p>
				{[Roles.loanOfficer, Roles.branchManager].includes(user.role) &&
					<Button text="Run Monthly Payment Calculation"
									onClick={() => navigate(`/${navigationLinking.LoanCalculator}`)} />
				}
			</>
		)
	}

	return (
		<div className="lg:grid lg:grid-cols-2 lg:gap-3">
			<div className="flex justify-center mb-3 md:w-full lg:mb-0">
				<div className="w-2/3 md:w-full">
					<Doughnut data={data} options={options} />
				</div>

			</div>
			<div>
				<TableContainer sx={width > 768 ? { maxWidth: 500 } : {}}>
					<Table sx={width > 768 ? { maxWidth: 500 } : {}} aria-label="simple table">
						<TableBody>
							{data.datasets[0].data.map((item, i) => (
								<TableRow key={`monthly-payment-chart-${i}`}>
									<TableCell align="left">
										<div className="flex flex-row">
											<div className="h-5 w-5 mr-2" style={{ backgroundColor: data.datasets[0].backgroundColor[i] }} />
											<div>{data.labels[i]}</div>
										</div>
									</TableCell>
									<TableCell align="right">
										${applyMask(item.toFixed(2), "fullCurrency")}
									</TableCell>
								</TableRow>
							))}
							<TableRow>
								<TableCell align="left">
									<strong>Total Monthly Payment</strong>
								</TableCell>
								<TableCell align="right">
									<strong>
										${applyMask(data.totalMonthlyPayment, "fullCurrency")}
									</strong>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	)
}

export default MonthlyPaymentChart
