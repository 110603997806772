import React, { useState, useEffect } from "react"
import PublishSubscribe from "publish-subscribe-js"
import { useNavigate } from "react-router-dom"
import { useVenti } from "venti"
import { useAppContext } from "../../../components/AppContext"
import { useAppContextActions } from "../../../components/AppContext/AppHooks"
import { ModalRemoveRecordConfirm } from "../../../components/modals/ModalRemoveRecordConfirm"
import Page from "../../../components/Page"
import { DataTable } from "../../../components/DataTable"
import { updateBusinessRule, getBusinessRule,
	getAllDocumentBuckets } from "../../../services/client"
import { getErrorMessage } from "../../../services/helper"
import { navigationLinking } from "../../../services/navigation"
import { getTheme } from "../../../config"
import tasksQuestions from "../../../workflows/tasks-questions"
import { useAlert } from "../../../hooks"
import { v4 as uuid } from "uuid"

const theme = getTheme()

const headers = [
	{ id: "tn", label: "Task Name" },
	{ id: "type", label: "Type" },
	{ id: "category", label: "Category" },
	{ id: "losi", label: "LOS Identifier" },
	{ id: "ddfa", label: "Days Due From App" },
	// { id: "created", label: "Created" },
]
const fields = ["taskName", "taskType", "taskCategory", "taskLOSIdentifier",
	"daysDueFromApplication",
	// "createdAt"
]

export default function AdminRuleTasks() {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const ruleId = ventiState.get(theme.storageKeys.viewingRuleId)
	const navigate = useNavigate()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user } = state

	const [rule, setRule] = useState({})
	const [tasksList, setTasksList] = useState([])
	const [fetching, setFetching] = useState(true)
	const [questions, setQuestions] = useState([])
	const [documentBuckets, setDocumentBuckets] = useState([])
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [rowToRemove, setRowToRemove] = useState()

	useEffect(() => {
		(async () => {
			if (!user?.isLoggedIn) await logOut()
			else if (!ruleId) navigate(`/${navigationLinking.AdminRules}`)
			else {
				await loadDocumentBuckets()
				await loadRuleTasks()
			}
		})()
	}, [ruleId])

	const loadRuleTasks = async () => {
		try {
			setFetching(true)
			const rule = await getBusinessRule(ruleId)
			setRule(rule)
			setTasksList(rule.tasks)
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const loadDocumentBuckets = async () => {
		try {
			const docBuckets = await getAllDocumentBuckets()
			setDocumentBuckets(docBuckets)
			//const loanFields = await getAllLoanFields()

			setQuestions(tasksQuestions.map(q => {
				if (q.lookup) {
					// eslint-disable-next-line default-case
					switch (q.lookup) {
						// todo: decrease size of field list or autocomplete
						// case "losFields":
						// 	q.options = loanFields.map(o => ( { value: o.id, label: o.description } ))
						// 	break
						case "losDocumentBuckets":
							q.options = docBuckets.map((o, index) => ({ value: index + 1, label: o }))
							break
					}
				}
				return q
			}))
		} catch (e) {
			setQuestions(tasksQuestions.map(q => {
				if (q.lookup) {
					// eslint-disable-next-line default-case
					switch (q.lookup) {
						// todo: decrease size of field list or autocomplete
						// case "losFields":
						// 	q.options = loanFields.map(o => ( { value: o.id, label: o.description } ))
						// 	break
						case "losDocumentBuckets":
							q.type = 'text'
							break
					}
				}
				return q
			}))
		}
	}

	const showRemoveModal = row => {
		setRemoveModalVisible(true)
		setRowToRemove({ ...row, name: row.taskName })
	}

	const removeTask = async () => {
		setRemoveModalVisible(false)

		try {
			await updateBusinessRule({ ...rule, tasks: rule.tasks.filter(task => task.id !== rowToRemove.id) })
			setTasksList(tasksList => tasksList.filter(task => task.id !== rowToRemove.id))
			setRowToRemove(null)
			PublishSubscribe.publish("UPDATE_ADMIN_COMPONENT_STATE")
			alert(`Task "${rowToRemove.name}" successfully removed`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const saveTask = async data => {
		data.taskType = parseInt(data.taskType)
		data.targetUserRole--
		data.taskCategory--

		if (data.taskType === 0 && !isNaN(parseInt(data.taskLOSIdentifier))) data.taskLOSIdentifier = documentBuckets[data.taskLOSIdentifier - 1]

		if (!data.id) {
			const task = {
				id: uuid(),
				taskName: data.taskName,
				taskType: data.taskType,
				taskCategory: data.taskCategory,
				eSignatureURL: data.eSignatureUrl,
				fieldDataType: 'STRING',
				targetUserRole: rule.targetUserRole,
				taskDescription: data.taskDescription,
				taskLOSIdentifier: data.taskLOSIdentifier,
				daysDueFromApplication: parseInt(data.daysDueFromApplication)
			}
			rule.tasks.push(task)
		} else {
			rule.tasks = rule.tasks.map((row => row.id === data.id ? data : row))
		}

		try {
			const res = await updateBusinessRule(rule)
			setRule(res)
			setTasksList(res.tasks)
			PublishSubscribe.publish('UPDATE_ADMIN_COMPONENT_STATE')
			alert(`Task "${data.taskName}" successfully ${data.id ? "updated" : "created"}`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	return (
		<Page title="Tasks" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-screen overflow-auto">
				<ModalRemoveRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeTask}
					row={rowToRemove}
				/>
				<DataTable
					entity={"Task"}
					buttonTitle={"Add Task"}
					data={tasksList}
					headers={headers}
					title={`${rule.id ? `Tasks for ${rule.name}` : "Loading tasks..."}`}
					tableType={"tasks"}
					fetching={fetching}
					get={tasksList}
					remove={showRemoveModal}
					set={setTasksList}
					hideNew={!questions.length}
					fields={fields}
					refresh={loadRuleTasks}
					questions={tasksQuestions}
					save={saveTask}
				/>
			</div>
		</Page>
	)
}
