import React from "react";
import * as Sentry from "@sentry/react";
import WarningIcon from '@mui/icons-material/Warning';
import Heading from "./Heading";
import config from "../config"
import {Button} from "./Button";

const ErrorBoundary = ({ children, fallback }) => {
	const { email, phone } = config.theme.support;

	const onRefreshClick = () => {
		window.location.reload();
	}

	const fallbackDefault = (
		<div className="p-7">
			<div className="bg-red-100 px-3 py-2 rounded border-red-300 text-red-800">
				<div className="flex">
					<WarningIcon className="mt-1" />
					<div className="ml-2">
						<Heading>An error has occurred</Heading>
						<div className="font-rubik font-bold dark:text-white">
							Please email {' '}
							<a href={`mailto:${email}`}>{email}</a> or call <a href={`tel:${phone}`}>{phone}</a> if the problem persists.
						</div>
					</div>
				</div>
			</div>
			<div className="mt-2">
				<Button text="Refresh Page" onClick={onRefreshClick} />
			</div>
		</div>
	)

	return (
		<Sentry.ErrorBoundary fallback={fallback || fallbackDefault}>
			{children}
		</Sentry.ErrorBoundary>
	)
}

export default ErrorBoundary;
