import { useState, useEffect } from "react"
import RichTextEditor from "react-rte"

export const TextEditor = ({ initialValue = "", onChange, placeholder = "" }) => {
	const [editorValue, setEditorValue] = useState(
		RichTextEditor.createValueFromString(initialValue || '', "html")
	)

	const toolbarConfig = {
		display: ["INLINE_STYLE_BUTTONS"],
		INLINE_STYLE_BUTTONS: [
			{ label: "Bold", style: "BOLD", className: "custom-css-class" },
			{ label: "Italic", style: "ITALIC" },
			{ label: "Underline", style: "UNDERLINE" }
		],
	}

	useEffect(() => {
		if (typeof onChange === "function") {
			onChange(editorValue.toString("html"))
		}
	}, [editorValue, onChange])

	return (
		<RichTextEditor
			placeholder={placeholder}
			value={editorValue}
			onChange={setEditorValue}
			toolbarConfig={toolbarConfig}
			className="h-72 mb-4"
			rootStyle={{
				fontFamily: "Rubik",
				overflow: "auto"
			}}
		/>
	)
}
