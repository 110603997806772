import { useState, useRef, useEffect } from "react"
import TextField from '@mui/material/TextField'
import AuthWrapper from './AuthWrapper'
import ChangePassword from '../../components/ChangePassword'
import { Button, LoadingBtn } from '../../components/Button'
import { useAppContext } from '../../components/AppContext'
import { getTheme } from '../../config'
import { navigationLinking } from "../../services/navigation"
import { isEmailValid, isPhoneNumberValid, isDarkMode } from '../../services/helper'
import {
	authenticate, generateDisclosuresSSOLoginUrl,
	getMe,
	registerBorrower,
	verifyBorrowerInvitation
} from '../../services/client'
import { applyMask, getRouteFromInvite } from '../../services/utils';
import Link from '@mui/material/Link'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppContextActions } from '../../components/AppContext/AppHooks'
import { Typography } from '@mui/material'
import { useVenti } from 'venti'

const theme = getTheme()

export default function CreateAccount({ route = {} }) {
	const { state } = useAppContext()
	const { applyUser, applyAuthToken, applyInvite } = useAppContextActions()
	const navigate = useNavigate()
	const { siteConfig, user, invite } = state
	const params = useParams()

	const ventiState = useVenti()
	let redirectRouteFromSignUp = ventiState.get(theme.storageKeys.redirectRoute) || navigationLinking.Portal

	const [loading, setLoading] = useState(false)
	const [invalidInviteToken, setInvalidInviteToken] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [enabledSignUpButton, setEnabledSignUpButton] = useState(true)

	const [userEmail, setUserEmail] = useState('')
	const [emailValid, setEmailValid] = useState(true)
	const [emailBlurred, setEmailBlurred] = useState(false)

	const [userPhone, setUserPhone] = useState('')
	const [phoneValid, setPhoneValid] = useState(true)
	const [phoneBlurred, setPhoneBlurred] = useState(false)

	const [userFirstName, setUserFirstName] = useState('')
	const [firstNameValid, setFirstNameValid] = useState(true)
	const [firstNameBlurred, setFirstNameBlurred] = useState(false)

	const [userLastName, setUserLastName] = useState('')
	const [lastNameValid, setLastNameValid] = useState(true)
	const [lastNameBlurred, setLastNameBlurred] = useState(false)

	const [userPassword, setUserPassword] = useState('')
	const [passwordConfirmed, setPasswordConfirmed] = useState('');
	const mountedRef = useRef(true)

	const [branchId, setBranchId] = useState(null)
	const [userTitle, setUserTitle] = useState('')

	const handleInputChange = (text, name) => {
		if (name === 'phone') {
			setUserPhone(applyMask(text, 'phone'))
			setPhoneValid(isPhoneNumberValid(applyMask(text, 'phone')))
		} else if (name === 'email') {
			let val = text.trim().toLowerCase()
			setUserEmail(val)
			setEmailValid(isEmailValid(val))
		}
		else if (name === 'first') {
			setUserFirstName(text)
			setFirstNameValid(text.length > 0)
		}
		else if (name === 'last') {
			setUserLastName(text)
			setLastNameValid(text.length > 0)
		}
	}

	const handleInputBlur = (val, name) => {
		switch (name) {
			case 'email':
				setEmailBlurred(true)
				setEmailValid(isEmailValid(val))
				break

			case 'phone':
				setPhoneBlurred(true)
				setPhoneValid(isPhoneNumberValid(userPhone))
				break

			case 'first':
				setFirstNameBlurred(true)
				setFirstNameValid(userFirstName.length > 0)
				break

			case 'last':
				setLastNameBlurred(true)
				setLastNameValid(userLastName.length > 0)
				break

			default:
				break
		}
	}

	const handleRegisterBorrower = () => {
		setLoading(true)
		setErrorMessage('')

		let data = {
			branchId: branchId,
			siteConfigurationId: siteConfig.id,
			email: userEmail,
			password: userPassword,
			phone: userPhone,
			firstName: userFirstName,
			lastName: userLastName,
			title: userTitle,
			sendNotification: true
		}

		if (params?.token) {
			data.inviteCode = params.token
			data.sendNotification = false
		}

		registerBorrower(data)
			.then(() => {
				authenticate(userEmail, userPassword, siteConfig.id).then(async login => {

					if (login?.access_token) {
						try {
							const me = await getMe(login.access_token)

							const userData = { ...login, ...me, disclosuresSSOLoginUrl: '' }

							if (siteConfig.disclosuresSSOClientID && siteConfig.disclosuresSSOSiteID) {
								userData.disclosuresSSOLoginUrl = await generateDisclosuresSSOLoginUrl(siteConfig, login.access_token)
							}

							let authTokenExp = new Date()
							authTokenExp.setMinutes(authTokenExp.getMinutes() + (userData.expires_in / 60) - 5)
							const user = {
								...userData,
								isLoggedIn: true,
								tokenExp: authTokenExp
							}

							applyAuthToken(login.access_token)
							applyUser(user)
							ventiState.set(theme.storageKeys.authToken, login.access_token)
							ventiState.set(theme.storageKeys.user, user)

							if (invite?.emailAddress) {
								navigate(getRouteFromInvite(invite))
							} else
								navigate(`/${redirectRouteFromSignUp}`)

						} catch (e) {
							setLoading(false)
						}
					} else {
						setErrorMessage('Error retrieving authentication info')
						setLoading(false)
					}
				})
			})
			.catch((err) => {
				setLoading(false)

				if (err.data?.errorCode === 'AlreadyExists') {
					setErrorMessage('An account with this email address already exists')
				} else {
					setErrorMessage('An unexpected error has occurred')
				}

				if (!mountedRef.current) return null
			})
	}

	useEffect(() => {
		if (user?.isLoggedIn) {
			navigate(`/${redirectRouteFromSignUp}`)
			return
		}
		if (params && params.token) {
			(async () => {
				try {
					const borrowerInvite = await verifyBorrowerInvitation(params.token)
					let route
					if (borrowerInvite.userRole === 'Borrower')
						route = borrowerInvite.route || (borrowerInvite.completedLoanApplication ? navigationLinking.Portal : navigationLinking.InviteApply)
					else
						route = navigationLinking.Portal
					applyInvite({
						...borrowerInvite,
						route
					})
				} catch (e) {
					console.log(e)
					setInvalidInviteToken(true)
				}
			})()
		}

		return () => {
			mountedRef.current = false
		}
	}, [])

	useEffect(() => {
		if (invite?.emailAddress) {

			if (invite.isExistingAccount) {
				navigate(`/${navigationLinking.SignIn}`)
				return
			}

			setUserFirstName(invite.firstName)
			setUserLastName(invite.lastName)
			setUserEmail(applyMask(invite.emailAddress, 'email'))
			setUserPhone(applyMask(invite.phoneNumber, 'phone'))
		}
	}, [invite])

	if (invalidInviteToken) return (
		<AuthWrapper siteConfig={siteConfig} title="Create Account">
			<>
				<img
					className="w-48 mb-5 lg:mb-0 lg:w-72 lg:absolute lg:top-5 lg:right-5"
					src={isDarkMode() && siteConfig?.darkModePortalLogoUrl ? siteConfig.darkModePortalLogoUrl : siteConfig?.portalLogoUrl || siteConfig?.logoUrl}
					alt="Logo"
				/>
				<p className="text-lg font-rubik sm:text-2xl font-bold mb-6 sm:mb-0 dark:text-white">The invite has expired</p>
				<p className="mt-10 text-md font-rubik sm:text-xl font-bold mb-6 sm:mb-0 dark:text-white">Please request a new invite from your Loan Officer</p>
				<p className="mt-10 text-md font-rubik sm:text-xl font-bold mb-6 sm:mb-0 dark:text-white"><Button onClick={() => navigate('/')} text={`Go Back`} /></p>
			</>
		</AuthWrapper>
	)

	return (
		<AuthWrapper siteConfig={siteConfig} title="Create Account">
			<>
				<img
					className="w-48 mb-5 lg:mb-0 lg:w-72 lg:absolute lg:top-5 lg:right-5"
					src={isDarkMode() && siteConfig?.darkModePortalLogoUrl ? siteConfig.darkModePortalLogoUrl : siteConfig?.portalLogoUrl || siteConfig?.logoUrl}
					alt="Logo"
				/>
				<p className="text-lg sm:text-2xl font-bold mb-6 dark:text-white">Create an account</p>
				<TextField
					onChange={(e) => handleInputChange(e.target.value, 'email')}
					onBlur={(e) => { handleInputBlur(e.target.value, 'email') }}
					value={userEmail}
					label={userEmail === '' ? null : 'Email address'}
					placeholder="Email address"
					error={!emailValid && emailBlurred}
					id={`CreateAccountEmail`}
					helperText={!emailValid && emailBlurred ? "Email address is not valid" : ""}
					variant="standard"
					className="w-64 sm:w-96 sm:px-12"
					disabled={!!invite?.emailAddress}
				/>
				<TextField
					onChange={(e) => handleInputChange(e.target.value, 'phone')}
					onBlur={(e) => { handleInputBlur(e.target.value, 'phone') }}
					value={userPhone}
					label={userPhone === '' ? null : 'Phone number'}
					placeholder="Phone number"
					error={!phoneValid && phoneBlurred}
					id={`CreateAccountPhone`}
					helperText={!phoneValid && phoneBlurred ? "Phone number is not valid" : ""}
					variant="standard"
					className="w-64 sm:w-96 sm:px-12"
					style={{ marginTop: 16 }}
					disabled={!!invite?.phoneNumber}
				/>
				<TextField
					onChange={(e) => handleInputChange(e.target.value, 'first')}
					onBlur={(e) => { handleInputBlur(e.target.value, 'first') }}
					value={userFirstName}
					label={userFirstName === '' ? null : 'First name'}
					placeholder="First name"
					error={!firstNameValid && firstNameBlurred}
					id={`CreateAccountFirstName`}
					helperText={!firstNameValid && firstNameBlurred ? "First name is not valid" : ""}
					variant="standard"
					className="w-64 sm:w-96 sm:px-12"
					style={{ marginTop: 16 }}
					disabled={!!invite?.firstName}
				/>
				<TextField
					onChange={(e) => handleInputChange(e.target.value, 'last')}
					onBlur={(e) => { handleInputBlur(e.target.value, 'last') }}
					value={userLastName}
					label={userLastName === '' ? null : 'Last name'}
					placeholder="Last name"
					error={!lastNameValid && lastNameBlurred}
					id={`CreateAccountLastName`}
					helperText={!lastNameValid && lastNameBlurred ? "Last name is not valid" : ""}
					variant="standard"
					className="w-64 sm:w-96 sm:px-12"
					style={{ marginTop: 16 }}
					disabled={!!invite?.lastName}
				/>
				<ChangePassword
					id={`CreateAccount`}
					passwordLabel={'Create password'}
					confirmPasswordLabel={'Confirm password'}
					userPassword={userPassword}
					setUserPassword={setUserPassword}
					handleSubmit={handleRegisterBorrower}
					setConfirmButton={setEnabledSignUpButton}
					confirmButtonEnabled={enabledSignUpButton}
					userEmail={userEmail}
					passwordConfirmed={passwordConfirmed}
					setPasswordConfirmed={setPasswordConfirmed}
					email={true}
					hideConfirmPassword
				/>
				{errorMessage && <p className={`mt-4 ${errorMessage ? "text-sm text-red-500" : "hidden"}`} >{errorMessage}</p>}
				<div className="w-full mt-4">
					<LoadingBtn
						id={`CreateAccountButton`}
						disabled={!enabledSignUpButton || !userEmail || !userPhone || !emailValid || !phoneValid}
						loading={loading}
						onClick={handleRegisterBorrower}
						text="Sign Up"
					/>
				</div>
				<div className="mt-4">
					<Typography>
						<span className="text-sm dark:text-white">Already have an account?&nbsp;</span>
						<Link
							className="text-blue-500 cursor-pointer"
							onClick={() => {
								navigate(`/${navigationLinking.SignIn}`)
							}}
							rel="noopener noreferrer"
							underline="hover"
							style={{ color: theme.siteConfig.color.primary, marginTop: 16 }}
						>
							<Typography variant="link" className="text-sm">Sign In</Typography>
						</Link>
					</Typography>
				</div>
			</>
		</AuthWrapper>
	)
}
