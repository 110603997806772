import Dialog from "@mui/material/Dialog"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { Button } from "../Button"
import AssetsGIF from "assets/lordicons/AssetsAnimated.gif"

export const ModalVerificationTask = ({ visible, closeModal, onYes, onNo, taskType, siteConfig = {} }) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

	const messages = {
		"VOA": {
			text1: "Verify Your Financial Assets",
			text2: `You may VERIFY YOUR ASSETS by signing-in to your online banking system and choosing the accounts to share with ${siteConfig.companyName}, or you may upload your bank statements manually.`,
			buttonTitle: `Bank Login`
		},
		"VOI": {
			text1: "Verify Your Income",
			text2: `You may VERIFY YOUR INCOME by signing-in to your employee payroll system and allowing ${siteConfig.companyName} to access this information, or you may upload your pay stubs manually.`,
			buttonTitle: `Payroll Login`
		},
		"VOE": {
			text1: "Verify Your Employment",
			text2: `You may VERIFY YOUR EMPLOYMENT by signing-in to your employee payroll system and allowing ${siteConfig.companyName} to access this information, or you may upload your pay stubs manually.`,
			buttonTitle: "Payroll Login"
		},
		"VOIE": {
			text1: "Verify Your Income & Employment",
			text2: `You may VERIFY YOUR INCOME AND EMPLOYMENT by signing-in to your employee payroll system and allowing ${siteConfig.companyName} to access this information, or you may choose to upload your pay stubs manually. Additionally, we may call your employer to verify your employment`,
			buttonTitle: "Payroll Login"
		}
	}

	return (
		<Dialog
			open={visible}
		>
			<IconButton
				aria-label="close"
				onClick={closeModal}
				sx={{ position: "absolute", right: 8, top: 8 }}
			>
				<CloseIcon />
			</IconButton>
			<div className={`${fullScreen ? "flex-col items-center py-4 px-3" : "flex-row py-5 pr-5 justify-center items-center"} flex`}>
				<img src={siteConfig.assetsAnimatedIcon || AssetsGIF} alt="AssetGif" className="h-40" />
				<div>
					<div>
						<p className="text-lg text-slate-500 font-bold text-center">
							{!!taskType && messages[taskType]?.text1}
						</p>
						<p className="text-sm text-slate-500 px-3 pt-3 text-center">
							{!!taskType && messages[taskType]?.text2}
						</p>
						<p className="text-sm text-slate-500 px-3 pt-3 pb-5 text-center">
							Please make sure to allow pop-ups for this site
						</p>
					</div>
					<div className={`${fullScreen ? "flex-col items-center" : "flex-row"} flex`}>
						<Button
							id="ModalYesNoNoButton"
							text={"Manual Upload"}
							onClick={onNo}
							style={fullScreen ? { width: 220, marginBottom: 12 } : { width: 200, marginRight: 12 }}
							variant="outlined"
						/>
						<Button
							id="ModalHasIncompleteTasksNoButton"
							text={!!taskType ? messages[taskType]?.buttonTitle : "Verify Me"}
							onClick={onYes}
							style={fullScreen ? { width: 220, marginBottom: 12 } : { width: 200 }}
						/>
					</div>
				</div>
			</div>
		</Dialog>
	)
}
