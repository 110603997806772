import React from "react";
import {InputAdornment} from "@mui/material";
import {merge} from "lodash";
import TextField from './TextField';
import {applyMask} from "../../services/utils";

const MoneyField = ({ InputProps, ...rest }) => {
	const startAdornment = <InputAdornment position="start">$</InputAdornment>;
	const mergedInputProps = InputProps ? merge(InputProps,{ startAdornment }) : { startAdornment };

	const onChange = (event) => {
		event.target.value = applyMask(event.target.value, "currency");
	}

	return (
		<TextField
			onChange={onChange}
			InputProps={mergedInputProps}
			{...rest}
		/>
	)
}

export default MoneyField
