import { useState, createRef } from "react"

export const Carousel = ({ data, staticImage }) => {
	const [currentItem, setCurrentItem] = useState(0)

	const refs = data.reduce((acc, val, i) => {
		acc[i] = createRef()
		return acc
	}, {})

	const scrollToItem = i => {
		setCurrentItem(i);
		refs[i]?.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
			inline: 'start',
		})
	}

	const totalLength = data.length

	const nextItem = () => {
		if (currentItem >= totalLength - 1) {
			scrollToItem(0)
		} else {
			scrollToItem(currentItem + 1)
		}
	}

	const previousItem = () => {
		if (currentItem === 0) {
			scrollToItem(totalLength - 1)
		} else {
			scrollToItem(currentItem - 1)
		}
	}

	const arrowStyle =
		'absolute text-white text-base md:text-2xl z-10 bg-black h-8 w-8 md:h-10 md:w-10 rounded-full opacity-75 flex items-center justify-center'

	const sliderControl = isLeft => (
		<button
			type="button"
			onClick={isLeft ? previousItem : nextItem}
			className={`${arrowStyle} ${isLeft ? '-left-4' : '-right-4'}`}
			style={{ top: '40%' }}
		>
            <span role="img" aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
                {isLeft ? '◀' : '▶'}
            </span>
		</button>
	)

	return (
		<div className="p-12 pt-6 flex justify-center w-screen md:w-full items-center">
			<div className="relative w-full">
				<div className="flex overflow-x-hidden scroll snap-x-mandatory h-48 md:h-96 bg-white rounded dark:bg-slate-700">
					{sliderControl(true)}
					{data.map((slide, i) => {
						if (slide.review.length < 400) return (
							<div className="w-full flex justify-between flex-shrink-0 px-5 py-2 md:pt-7 md:pl-10 md:pb-0 md:pr-0 dark:bg-slate-700" key={i} ref={refs[i]}>
								<div className="flex flex-col justify-between">
									<p className="text-sm md:text-base font-montserrat">{slide.review}</p>
									{/* <div>
                                        <p className="text-xs md:font-bold md:pb-1">{slide?.ProviderEmail}</p>
                                        <p className="text-xs md:font-bold md:pb-5">{slide?.position}</p>
                                    </div> */}
								</div>
								<img src={staticImage} className="w-16 object-contain hidden md:block md:w-80 md:self-end" alt={slide.id} />
							</div>
						)
						return false
					})}
					{sliderControl()}
				</div>
			</div>
		</div>
	)
}
