import React from "react"
import { Dialog, Icon, Button } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { Loading } from "../Loading"
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import { getTheme } from '../../config';

export const ModalDocumentDragOrUpload = ({ visible, pickDocument, uploading, cancelModal }) => {
	const muiTheme = useTheme()
	const theme = getTheme()
	const fullScreen = useMediaQuery(muiTheme.breakpoints.down("md"))

	return (
		<Dialog
			open={visible}
			onClose={cancelModal}
		>
			<>
				<IconButton
					aria-label="close"
					onClick={cancelModal}
					sx={{ position: "absolute", right: 8, top: 8 }}
				>
					<CloseIcon />
				</IconButton>

				<div className={`${fullScreen ? "flex-col items-center pt-10 pb-4 px-3" : "flex-col py-8 px-10 justify-center"} flex`}>
					{uploading ?
						<>
							<div className="flex self-center justify-center w-64 md:w-96 mt-5">
								<Loading size="small" />
							</div>
							<p className="text-lg font-bold text-center">
								Uploading...
							</p>
						</>
						: <div className="flex flex-col items-center justify-center">
							<Icon
								aria-label="upload"
								color="primary"
								sx={{ height: 96, width: 96 }}
							>
								<FileUploadOutlinedIcon sx={{ height: 96, width: 96 }} />
							</Icon>
							<p className="text-lg text-slate-500 font-bold text-center dark:text-white">
								Upload a document
							</p>
							<div className="flex flex-col">
								<Button
									id="ModalDocumentDragOrUploadPickButton"
									style={{ width: 220, marginBottom: 12, marginTop: 12 }}
									variant="contained"
									component="label"
								>
									Choose File
									<input
										onChange={(e) => pickDocument(e)}
										hidden
										multiple
										type="file"
										accept={theme.validation.allowedDocumentTypes.join(', ')}
									/>
								</Button>
								<Button
									id="Cancel"
									onClick={cancelModal}
									style={{ width: 220, marginBottom: 12 }}
									variant="outlined"
								>
									Cancel
								</Button>
							</div>
						</div>
					}
				</div>
			</>
		</Dialog>
	)
}
