import React, { useEffect, useState } from "react"
import { useVenti } from "venti"
import PublishSubscribe from "publish-subscribe-js"
import Page from "../../../components/Page"
import { DataTable } from "../../../components/DataTable"
import { useAppContext } from "../../../components/AppContext"
import { useAppContextActions } from "../../../components/AppContext/AppHooks"
import { getTheme } from "../../../config"
import { getDevices } from '../../../services/client';
import { getErrorMessage } from "../../../services/helper"
import { useAlert } from "../../../hooks"

const theme = getTheme()

export default function AdminDevicesView() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user } = state
	const ventiState = useVenti()

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])

	const headers = [
		{ id: "name", label: "Name" },
		{ id: "serialNumber", label: "Serial #" },
		{ id: "type", label: "Device Type" },
		{ id: "status", label: "Status" }
	]

	const fields = [
		"name", 
		"serialNumber", 
		"type", 
		"status"
	]

	useEffect(() => {
		(async () => {
			if (!user.isLoggedIn) await logOut()
			else await loadDevices(user.accountID)
		})()
	}, [user])

	useEffect(() => {
		PublishSubscribe.subscribe("UPDATE_ADMIN_COMPONENT_STATE", async () => {
			await updateDevices()
		})

		return () => {
			PublishSubscribe.unsubscribe("UPDATE_ADMIN_COMPONENT_STATE")
		}
	}, [])

	const loadDevices = async () => {
		try {
			setFetching(true)
			const devicesData = await getDevices(user.accountID)
			setData(devicesData.rows)
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const updateDevices = async () => {
		const data = await getDevices(user.accountID)
		setData(data.rows)
	}

	return (
		<Page title="Loan Officers" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-screen overflow-auto">
				<DataTable
					hideNew
					data={data}
					fields={fields}
					headers={headers}
					entity={"Device"}
					title={"Kiosks"}
					fetching={fetching}
					refresh={loadDevices}
					allowExport
				/>
			</div>
		</Page>
	)
}
