import React, { useState } from 'react'
import { LoanDetailsCard } from './LoanDetailsCard'

export const RealEstateOwned = ({ data, loading, index }) => {
	const [title, setTitle] = useState(index === 0 ? 'Real Estate Owned' : `Real Estate ${index + 1}`)

	let indexBasedFields = [
		{ key: `IsBorrowerOwnRealEstate${index}`, title: 'Do you own any real estate?', value: null },
		{ key: `BorrowerRealEstatePropertyAddressStreet${index}`, title: 'Street Address', value: null },
		{ key: `BorrowerRealEstatePropertyAddressUnitType${index}`, title: 'Unit Type', value: null },
		{ key: `BorrowerRealEstatePropertyAddressUnit${index}`, title: 'Unit / Apt #', value: null },
		{ key: `BorrowerRealEstatePropertyAddressCity${index}`, title: 'City', value: null },
		{ key: `BorrowerRealEstatePropertyAddressState${index}`, title: 'State', value: null },
		{ key: `BorrowerRealEstatePropertyAddressZip${index}`, title: 'Zip', value: null },
		{
			key: `BorrowerRealEstatePropertyValueGroup${index}`, title: 'Real Estate Property Value', value: [
				{ key: `BorrowerRealEstatePropertyValue${index}`, title: 'Estimated Property Value', value: null },
				{ key: `BorrowerRealEstatePropertyPurchasePrice${index}`, title: 'Original Purchase Price', value: null },
			]
		},
		{ key: `BorrowerRealEstatePropertyUse${index}`, title: 'Real Estate Property Use', value: null },
		{
			key: `BorrowerRealEstatePropertyInfoGroup${index}`, title: 'Real Estate Property Details', value: [
				{ key: `BorrowerRealEstatePropertyYearBuilt${index}`, title: 'Year Built', value: null },
				{ key: `BorrowerRealEstatePropertyNumUnits${index}`, title: 'Number of Units', value: null },
			]
		},
		{ key: `IsBorrowerRealEstatePropertyIncome${index}`, title: 'Do you have any income pertaining to the property?', value: null },
		{ key: `BorrowerRealEstatePropertyIncomeGross${index}`, title: 'Gross Monthly Income', value: null },
		{ key: `BorrowerRealEstatePropertyExpenses${index}`, title: 'What are the combined taxes, insurance & expenses for the property at ?', value: null },
	]

	const realEstateOwned = indexBasedFields.map(r => {
		if (r.key === 'IsBorrowerOwnRealEstate') {
			return {
				key: 'IsBorrowerOwnRealEstate',
				title: 'Do you own any real estate?',
				value: data.IsBorrowerOwnRealEstate0
			}
		}

		else if (r.key === 'BorrowerRealEstatePropertyAddressStreet') {
			return {
				key: 'BorrowerRealEstatePropertyAddressStreet',
				title: 'Street Address',
				value: data[`BorrowerRealEstatePropertyAddressStreet${index}`]
			}
		}

		else if (r.key === 'BorrowerRealEstatePropertyAddressUnit') {
			return {
				key: 'BorrowerRealEstatePropertyAddressUnit',
				title: 'Unit / Apt #',
				value: data[`BorrowerRealEstatePropertyAddressUnit${index}`]
			}
		}

		else if (r.key === 'BorrowerRealEstatePropertyAddressUnitType') {
			return {
				key: 'BorrowerRealEstatePropertyAddressUnitType',
				title: 'Unit Type',
				value: data[`BorrowerRealEstatePropertyAddressUnitType${index}`]
			}
		}

		else if (r.key === 'BorrowerRealEstatePropertyAddressCity') {
			return {
				key: 'BorrowerRealEstatePropertyAddressCity',
				title: 'City',
				value: data[`BorrowerRealEstatePropertyAddressCity${index}`]
			}
		}

		else if (r.key === 'BorrowerRealEstatePropertyAddressState') {
			return {
				key: 'BorrowerRealEstatePropertyAddressState',
				title: 'State',
				value: data[`BorrowerRealEstatePropertyAddressState${index}`]
			}
		}

		else if (r.key === 'BorrowerRealEstatePropertyAddressZip') {
			return {
				key: 'BorrowerRealEstatePropertyAddressZip',
				title: 'Zip',
				value: data[`BorrowerRealEstatePropertyAddressZip${index}`]
			}
		}

		else if (r.key === `BorrowerRealEstatePropertyIncomeGross${index}`) {
			return {
				key: `BorrowerRealEstatePropertyIncomeGross${index}`,
				title: 'Gross Monthly Income',
				value: data[`BorrowerRealEstatePropertyIncomeGross${index}`]
			}
		}

		else if (r.key === `BorrowerRealEstatePropertyExpenses${index}`) {

			return {
				key: `BorrowerRealEstatePropertyExpenses${index}`,
				title: 'What are the combined taxes, insurance & expenses for the property at ?',
				value: index === 0 ? data[`BorrowerRealEstatePropertyExpenses`] : data[`BorrowerRealEstatePropertyExpenses${index}`]
			}
		}

		else if (r.key === `BorrowerRealEstatePropertyValueGroup${index}`) {
			return {
				key: `BorrowerRealEstatePropertyValueGroup${index}`,
				title: 'Real Estate Property Value',
				value: [
					{
						key: `BorrowerRealEstatePropertyValue${index}`,
						title: 'Estimated Property Value',
						value: data[`BorrowerRealEstatePropertyValue${index}`]
					},
					{
						key: `BorrowerRealEstatePropertyPurchasePrice${index}`,
						title: 'Original Purchase Price',
						value: data[`BorrowerRealEstatePropertyPurchasePrice${index}`]
					},
				]
			}
		}

		else if (r.key === `BorrowerRealEstatePropertyUse${index}`) {

			return {
				key: `BorrowerRealEstatePropertyUse${index}`,
				title: 'Real Estate Property Use',
				value: index === 0 ? data[`BorrowerRealEstatePropertyUse`] : data[`BorrowerRealEstatePropertyUse${index - 1}`]
			}
		}

		else if (r.key === `BorrowerRealEstatePropertyInfoGroup${index}`) {
			return {
				key: `BorrowerRealEstatePropertyInfoGroup${index}`,
				title: 'Real Estate Property Details',
				value: [
					{
						key: `BorrowerRealEstatePropertyYearBuilt${index}`,
						title: 'Year Built',
						//TODO: There is no BorrowerRealEstatePropertyYearBuilt0
						value: data[`BorrowerRealEstatePropertyYearBuilt${index + 1}`]
					},
					{
						key: `BorrowerRealEstatePropertyNumUnits${index}`,
						title: 'Number of Units',
						value: data[`BorrowerRealEstatePropertyNumUnits${index}`]
					},
				]
			}
		}
		r.value = data[r.key]
		return r
	})

	if (index > 0 && !data[`IsBorrowerOwnRealEstate${index}`]) return <></>

	return (
		<>
			<LoanDetailsCard
				title={title} data={realEstateOwned} loading={loading} style={{ marginBottom: 0 }}
			/>
		</>
	)
}
