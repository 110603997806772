import React, { useEffect, useState } from "react"
import { useVenti } from "venti"
import PublishSubscribe from "publish-subscribe-js"
import { filter } from "lodash"
import Page from "../../../components/Page"
import { DataTable } from "../../../components/DataTable"
import { useAppContext } from "../../../components/AppContext"
import { useAppContextActions } from "../../../components/AppContext/AppHooks"
import { getTheme } from "../../../config"
import { ModalRemoveRecordConfirm } from "../../../components/modals/ModalRemoveRecordConfirm"
import { deleteUser, getPartners, undeleteCorporate } from '../../../services/client';
import { getErrorMessage } from "../../../services/helper"
import { useAlert } from "../../../hooks"

const theme = getTheme()

export default function AdminPartnerView() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user } = state
	const ventiState = useVenti()

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])
	const [dataCopy, setDataCopy] = useState([])
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] = useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [rowToRestore, setRowToRestore] = useState()

	const headers = [
		{ id: "fn", label: "First Name" },
		{ id: "ln", label: "Last Name" },
		{ id: "title", label: "Title" },
		{ id: "email", label: "Email" },
		{ id: "corporateName", label: "Brand" },
		{ id: "branchName", label: "Branch" },
		{ id: "domain", label: "URL" },
		{ id: "role", label: "Role" },
		{ id: "created", label: "Created On" },
	]

	const fields = [
		"firstName",
		"lastName",
		"title",
		"email",
		"corporateName",
		"branchName",
		"url",
		"role",
		"createdAt",
	]

	useEffect(() => {
		(async () => {
			if (!user.isLoggedIn) await logOut()
			else await loadPartners()
		})()
	}, [user])

	useEffect(() => {
		PublishSubscribe.subscribe("UPDATE_ADMIN_COMPONENT_STATE", async () => {
			await updatePartners()
		})

		return () => {
			PublishSubscribe.unsubscribe("UPDATE_ADMIN_COMPONENT_STATE")
		}
	}, [])

	const loadPartners = async () => {
		try {
			setFetching(true)
			const partnersData = await getPartners('realtor', true)
			const partners = []
			partnersData?.forEach(item => {
				item?.siteConfigurations.forEach(site => {
					partners.push({ ...item, ID: item.id, url: site.url, siteConfigurationId: site.id })
				})
			})
			setData(partners)
			setDataCopy(partners)
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const handleFilterByBranches = (value) => {
		if (!value) {
			setData(dataCopy)
		} else {
			setData(filter(dataCopy, { branchID: value }))
		}
	}

	const handleFilterByCorporations = (value) => {
		if (!value) {
			setData(dataCopy)
		} else {
			setData(filter(dataCopy, { corporateID: value }))
		}
	}

	const showRemoveModal = row => {
		setRemoveModalVisible(true)
		setRowToRemove({ ...row, name: `${row.firstName} ${row.lastName}` })
	}

	const updatePartners = async () => {
		setData(await getPartners())
	}

	const removePartner = async () => {
		setRemoveModalVisible(false)

		try {
			await deleteUser(rowToRemove.id)
			await loadPartners()
			alert(`Loan Officer ${rowToRemove.name} successfully removed`)
			setRowToRemove(null)
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const showRestoreModal = row => {
		setRestoreModalVisible(true)
		setRowToRestore(row)
	}

	const restorePartner = async () => {
		setRestoreModalVisible(false)

		try {
			await undeleteCorporate(rowToRestore.id)
			setData(data => data.filter(d => d.id !== rowToRestore.id))
			alert(`Partner ${rowToRestore.name} successfully restored`)
			setRowToRestore(null)
			await loadPartners()
		} catch (e) {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	return (
		<Page title="Partners" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-screen overflow-auto">
				<ModalRemoveRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removePartner}
					row={rowToRemove}
				/>
				<ModalRemoveRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restorePartner}
					row={rowToRestore}
				/>
				<DataTable
					data={data}
					fields={fields}
					headers={headers}
					entity={"Partner"}
					title={"Partners"}
					buttonTitle={"Add Partner"}
					fetching={fetching}
					refresh={loadPartners}
					remove={showRemoveModal}
					restore={showRestoreModal}
					handleFilterByBranches={handleFilterByBranches}
					handleFilterByCorporations={handleFilterByCorporations}
					allowExport
				/>
			</div>
		</Page>
	)
}
