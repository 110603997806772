import React from "react";
import { Stepper } from "@mui/material"
import MobileStepper from "../MobileStepper";
import LoanMilestoneStep from "./LoanMilestoneStep";

const LoanMilestones = ({ loan, activeStep, className = undefined }) => {
	const { milestones } = loan;
	if(milestones.length === 0) {
		return null;
	}

	return (
		<div className={className}>
			<div className="lg:hidden">
				<MobileStepper activeStep={activeStep}
											 steps={milestones}
											 labelKey="name"
				               showCompletedLabel
											 renderStep={(milestone) => (
												 <LoanMilestoneStep key={milestone.id}
																						loan={loan}
																						milestone={milestone}/>
											 )}/>
			</div>
			<div className="hidden lg:block">
				<Stepper activeStep={activeStep} alternativeLabel nonLinear>
					{milestones.map((milestone) =>
						<LoanMilestoneStep key={milestone.id}
															 loan={loan}
															 milestone={milestone}/>)}
				</Stepper>
			</div>
		</div>
	)
}

export default LoanMilestones;
