import { roles } from '../services/helper'

export default [
	{
		title: "Task Category",
		question: "Select the task category",
		type: "picker",
		qId: "taskCategory",
		target: "taskType",
		fieldId: "taskCategory",
		default: "Select category",
		options: [
			{
				value: 1,
				label: "Application"
			},
			{
				value: 2,
				label: "Conditions"
			},
			{
				value: 3,
				label: "Closing"
			},
			{
				value: 4,
				label: "PostClosing"
			}
		],
		name: "Task Category"
	},
	{
		title: "Task Definition",
		question: "What task is this rule asking to be completed?",
		type: "choice",
		qId: "taskType",
		fieldId: "taskType",
		target: "taskDescription",
		options: [
			{
				label: "Provide a Document",
				value: 0,
				target: "documentTask"
			},
			{
				label: "Request Information",
				value: 1,
				target: "questionTask"
			},
			{
				label: "Verify Assets (VOA)",
				value: 5
			},
			{
				label: "Verify Income (VOI)",
				value: 6
			},
			{
				label: "Verify Employment (VOE)",
				value: 7
			},
		]
	},
	{
		title: "Question",
		question: "What information would you like the user to provide?",
		type: "text",
		qId: "questionTask",
		target: "mappedTo",
		fieldId: "taskName",
		placeholder: "Enter task name",
		name: "questionTask"
	},
	{
		title: "Loan Field Selection",
		question: "What field do you want this answer mapped to",
		type: "text",
		// lookup: "losFields",
		qId: "mappedTo",
		target: "taskDescription",
		fieldId: "taskLOSIdentifier",
		//default: "Reporting database field look up",
	},
	{
		title: "Document Task",
		question: "What document would you like the user to provide?",
		type: "text",
		qId: "documentTask",
		target: "losIdentifier",
		fieldId: "taskName",
		placeholder: "Type Question",
		name: "question"
	},
	{
		title: "Document Bucket Selection",
		question: "Please select the document bucket the rule requires",
		type: "picker",
		options: [],
		lookup: "losDocumentBuckets",
		qId: "losIdentifier",
		target: "taskDescription",
		fieldId: "taskLOSIdentifier",
		placeholder: "Enter bucket name",
		default: "Select Document"
	},
	{
		title: "Signature Document Selection",
		question: "Please select the signature document",
		type: "picker",
		qId: "signatureTask",
		target: "ruleRequiredDescription",
		fieldId: "eSignatureURL",
		default: "Select document",
		options: [
			{
				value: 0,
				label: "Signature 1"
			},
			{
				value: 1,
				label: "Signature 2"
			},
			{
				value: 2,
				label: "Signature 3"
			}
		]
	},
	{
		title: "Task Description",
		question: "Provide a user facing description explaining why this task is required",
		type: "textarea",
		qId: "taskDescription",
		target: "taskDuration",
		fieldId: "taskDescription",
		placeholder: "Enter description"
	},
	{
		title: "Task Duration",
		question: "Select the number of days until task expires",
		type: "picker",
		qId: "taskDuration",
		fieldId: "daysDueFromApplication",
		default: "Select duration",
		options: [
			{
				value: 3,
				label: "3 Days"
			},
			{
				value: 4,
				label: "4 Days"
			},
			{
				value: 5,
				label: "5 Days"
			},
			{
				value: 6,
				label: "6 Days"
			},
			{
				value: 7,
				label: "7 Days"
			},
			{
				value: 8,
				label: "8 Days"
			},
			{
				value: 9,
				label: "9 Days"
			},
			{
				value: 10,
				label: "10 Days"
			}
		]
	}
]
