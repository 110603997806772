import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { isEqual } from "lodash"
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import { useVenti } from "venti"
import PublishSubscribe from "publish-subscribe-js"
import { Tabs, Tab, Card, CardContent } from "@mui/material"
import { ProfileView } from "./Profile"
import Page from "../../../components/Page"
import { LoadingBtn } from "../../../components/Button"
import { ModalYesNo } from "../../../components/modals/ModalYesNo"
import { ModalError } from "../../../components/modals/ModalError"
import { ModalSetLOUserPassword } from "../../../components/modals/ModalSetLOUserPassword"
import { useAppContext } from "../../../components/AppContext"
import { Loading } from "../../../components/Loading"
import { Features } from "../../../components/admin/Features"
import { WebsiteSettings } from "../../../components/admin/WebsiteSettings"
import { Integrations } from "../../../components/admin/Integrations"
import { navigationLinking } from "../../../services/navigation"
import { siteConfigurationModel } from "../../../services/utils"
import {
	getLoanOfficerSiteConfiguration, getLoanOfficer, createUser, updateUser,
	updateLoanOfficerSiteConfiguration, createLoanOfficerSiteConfiguration
} from "../../../services/client"
import { a11yProps, isEmailValid, getErrorMessage, validateSiteConfigurationData } from "../../../services/helper"
import { getTheme } from "../../../config"
import { useAlert, useWindowSize } from "../../../hooks"

const theme = getTheme()

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div className="mt-5">{children}</div>
			)}
		</div>
	)
}

export default function AdminLoanOfficerEdit({ navigation }) {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const navigate = useNavigate()
	const { siteConfig, user } = state
	const ventiState = useVenti()
	const [width] = useWindowSize()
	const themeMUI = useTheme()

	const siteConfigurationId = ventiState.get(theme.storageKeys.editingLoanOfficerSiteConfigurationId)
	let loId = ventiState.get(theme.storageKeys.editingLoanOfficerId)

	if (!loId) {
		navigate(`/${navigationLinking.AdminLoanOfficer}`)
	}

	if (loId === "new") loId = null
	const [tab, setTab] = useState(0)

	const [modalConfirmation, setModalConfirmation] = useState({ open: false, text: "", leavePage: false, canBeSaved: false, isSave: false, event: null })
	const [loading, setLoading] = useState(false)
	const [initialState, setInitialState] = useState({ ...siteConfigurationModel, entityType: 3 })
	const [formData, setFormData] = useState({ ...siteConfigurationModel, entityType: 3 })
	const [canBeSubmitted, setCanBeSubmitted] = useState(true)
	const [password, setPassword] = useState()
	const [showSetUserPasswordModal, setShowSetUserPasswordModal] = useState(false)
	const [errorModalVisible, setErrorModalVisible] = useState(false)
	const [errorMsg, setErrorMsg] = useState("")
	const initialRender = useRef(true)

	useEffect(() => {
		const { domain, ...rest } = formData
		const canBeSubmitted = formData?.url?.length > 0 &&
			isEmailValid(formData?.email?.trim()) &&
			// formData?.phone?.replace(/\D/g, "").length === 10 &&
			formData?.firstName?.trim().length > 0 &&
			formData?.lastName?.trim().length > 0 &&
			formData?.title?.trim().length > 0 &&
			formData?.licenses?.length > 0 &&
			!isEqual(initialState, rest)

		setCanBeSubmitted(canBeSubmitted)

		if (initialRender.current) {
			initialRender.current = false
		}
	}, [formData, initialState])

	useEffect(() => {
		if (loId && siteConfigurationId) {
			getLoanOfficerSiteConfiguration(loId, siteConfigurationId)
				.then(data => {
					// Should merge with LO data as endpoint on top doesn't have all needed data
					getLoanOfficer(loId)
						.then(response => {
							const loData = {
								...data,
								branchID: response.branchID,
								firstName: response.firstName,
								lastName: response.lastName,
								title: response.title,
							}
							setFormData(loData)
							setInitialState(loData)
						}).catch((e) => {
							alert(getErrorMessage(e), { severity: "error" })
							ventiState.set(theme.storageKeys.errorMessage,
								e?.data?.message || theme.api_messages.server_error)
						})
				})
				.catch(e => {
					alert(getErrorMessage(e), { severity: "error" })
					ventiState.set(theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error)
				})
		}
	}, [loId, siteConfigurationId])

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const handleCheckFormLicenses = () => {
		formData.licenses.length === 0 ?
			setModalConfirmation({
				open: true,
				text: theme.modal.licensesText,
				leavePage: false,
				canBeSaved: false,
				isSave: true,
				event: null
			}) : saveForm()
	}

	const saveForm = async () => {
		// Check the URL is the correct format and matches the domain
		const parts = formData.url.split(".")
		if (parts.length !== 3) {
			setErrorMsg(`The format of the URL you've entered is not valid. It should appear like loanofficer.${formData.domain}`)
			setErrorModalVisible(true)
			return
		}

		const subdomain = parts.shift()
		if (parts.join(".") !== formData.domain) {
			setErrorMsg(`The domain you've entered does not match the domain of the branch. It should appear like ${subdomain}.${formData.domain}`)
			setErrorModalVisible(true)
			return
		}

		setLoading(true)

		try {
			if (formData.id) {
				await updateUser({
					id: formData.entityID,
					"email": formData.email,
					"phone": formData.phone,
					"firstName": formData.firstName,
					"lastName": formData.lastName,
					"title": formData.title,
					"branchId": formData.branchID,
				})
				await updateLoanOfficerSiteConfiguration(formData, formData.entityID, formData.id)
				PublishSubscribe.publish("UPDATE_ADMIN_COMPONENT_STATE")
				alert(`Loan Officer "${formData.name}" successfully updated`)
				navigate(`/${navigationLinking.AdminLoanOfficer}`)
			} else {
				const loanOfficer = await createUser({
					"email": formData.email,
					"phone": formData.phone,
					"firstName": formData.firstName,
					"lastName": formData.lastName,
					"title": formData.title,
					"branchId": formData.branchID,
					"userRole": "LoanOfficer"
				})

				validateSiteConfigurationData(formData)

				await createLoanOfficerSiteConfiguration(formData, loanOfficer.id)
				PublishSubscribe.publish("UPDATE_ADMIN_COMPONENT_STATE")
				alert(`Loan Officer "${formData.name}" successfully created`)
				navigate(`/${navigationLinking.AdminLoanOfficer}`)
			}
		} catch (e) {
			setLoading(false)
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const modalConfirm = async (modalConfirmation) => {
		setLoading(true)
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({ open: false, text: "", leavePage: false })
	}

	return (
		<Page isFullWidth={true} title={loId ? `Edit Loan Officer` : "New Loan Officer"}>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
			/>
			<ModalError visible={errorModalVisible} setVisible={setErrorModalVisible} message={errorMsg} />
			<ModalSetLOUserPassword
				data={formData}
				visible={showSetUserPasswordModal}
				setVisible={setShowSetUserPasswordModal}
				siteConfig={siteConfig}
			/>
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">{loId ? `Edit Loan Officer` : "New Loan Officer"}</p>
				{loId && !initialState.id
					? <div className="flex justify-center dark:bg-[#121212]" style={{ height: `calc(100vh - 216px)` }}><Loading size="small" /></div>
					: (
						<>
							<div className="flex flex-wrap justify-end my-5">
								<LoadingBtn
									id={`AdminLoanOfficerEditSaveButton`}
									disabled={!canBeSubmitted}
									loading={loading}
									text={`${formData?.id ? "Update Loan Officer" : "Create Loan Officer"}`}
									onClick={handleCheckFormLicenses}
									fullWidth={false}
								/>
							</div>
							<Tabs
								variant="scrollable"
								scrollButtons="auto"
								value={tab}
								onChange={handleTabChange}
								aria-label="Tabs"
							>
								<Tab label="LO Profile" {...a11yProps(0)} />
								<Tab label="Branding & Images" {...a11yProps(1)} />
								<Tab label="Features" {...a11yProps(2)} />
								<Tab label="Integrations" {...a11yProps(3)} />
							</Tabs>
							<SwipeableViews
								axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
								index={tab}
								onChangeIndex={handleChangeIndex}
							>
								<TabPanel value={tab} index={0} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<ProfileView user={user} siteConfig={siteConfig} formData={formData} setFormData={setFormData} width={width} setPassword={setPassword} password={password} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={1} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<WebsiteSettings formData={formData} setFormData={setFormData} entityType={3} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={2} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<Features formData={formData} setFormData={setFormData} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={3} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<Integrations formData={formData} setFormData={setFormData} entityType={3} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
							</SwipeableViews>
						</>
					)
				}
			</div>
		</Page>
	)
}
