import { useEffect, useRef, useState } from "react"
import { TextField, Link, Typography } from '@mui/material'
import AuthWrapper from "./AuthWrapper"
import { useAppContextActions } from "../../components/AppContext/AppHooks"
import { useAppContext } from "../../components/AppContext"
import { LoadingBtn } from "../../components/Button"
import { getTheme } from '../../config'
import { navigationLinking } from "../../services/navigation"
import { isEmailValid, getErrorMessage, isDarkMode } from '../../services/helper'
import {
	authenticate, getMe, getLoanOfficer, verifyRegistration,
	resendRegistrationCode, Roles
} from '../../services/client'
import { useLocation, useNavigate } from "react-router-dom"
import { useVenti } from 'venti'
import { applyMask } from "../../services/utils"
import { useAlert } from "../../hooks"

const theme = getTheme()

export default function ConfirmAccount({ email }) {
	const ventiState = useVenti()
	const { state } = useAppContext()
	const navigate = useNavigate()
	const location = useLocation()
	const { alert } = useAlert()
	const { applyAuthToken, applyUser } = useAppContextActions()
	const { siteConfig, redirectRoute } = state
	let redirectRouteFromSignIn = redirectRoute || navigationLinking.Portal

	const [loading, setLoading] = useState(false)
	const [resending, setResending] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const [emailConfirm, setEmailConfirm] = useState('')
	const [emailConfirmValidation, setEmailConfirmValidation] = useState(true)
	const [emailBlurred, setEmailBlurred] = useState(false)

	const [passwordConfirm, setPasswordConfirm] = useState('')
	const [passwordConfirmValidation, setPasswordConfirmValidation] = useState(true)
	const [passwordBlurred, setPasswordBlurred] = useState(false)

	const [codeConfirm, setCodeConfirm] = useState('')
	const [codeConfirmValidation, setCodeConfirmValidation] = useState(true)
	const [codeBlurred, setCodeBlurred] = useState(false)
	const [resendCode, setResendCode] = useState(false)
	const mountedRef = useRef(true)

	const enabledConfirmAccountButton = isEmailValid(emailConfirm) &&
		passwordConfirm.length >= theme.validation.passwordLength &&
		applyMask(codeConfirm, 'numeric') && codeConfirm.length === 6

	const passwordConfirmOnChange = (val) => {
		setPasswordConfirm(val)
		setPasswordConfirmValidation(val.length >= theme.validation.passwordLength)
	}
	const passwordConfirmOnBlur = (e) => {
		setPasswordBlurred(true)
		setPasswordConfirmValidation(e.target.value.length >= theme.validation.passwordLength)
	}

	const emailConfirmOnChange = (val) => {
		setEmailConfirm(val)
		setEmailConfirmValidation(isEmailValid(val))
	}
	const emailConfirmOnBlur = (e) => {
		setEmailBlurred(true)
		setEmailConfirmValidation(isEmailValid(e.target.value))
	}

	const codeConfirmOnChange = (val) => {
		setCodeConfirm(applyMask(val, 'numeric'))
		setCodeConfirmValidation(applyMask(val, 'numeric').length === 6)
	}
	const codeConfirmOnBlur = (e) => {
		setCodeBlurred(true)
		setCodeConfirmValidation(applyMask(e.target.value, 'numeric').length === 6)
	}

	// sets user email when navigating from create account
	useEffect(() => {
		if (location.state && location.state.email) { setEmailConfirm(location.state.email) }
	}, [location])

	const handleConfirmAccount = async () => {
		if (emailConfirmValidation && passwordConfirmValidation) {
			setLoading(true)
			setErrorMessage(false)

			const data = {
				"email": emailConfirm,
				"code": codeConfirm,
				"siteConfigurationId": siteConfig.id
			}

			verifyRegistration(data).then(() => {
				setTimeout(async () => {
					try {
						const login = await authenticate(emailConfirm, passwordConfirm, siteConfig.id)
						if (!mountedRef.current) return null

						if (login?.access_token) {
							try {
								const me = await getMe(login.access_token)

								if ([Roles.loanOfficer, Roles.branchManager].includes(me.role) && me.id !== siteConfig.id && !window.location.hostname.includes('localhost')) {
									const loanOfficer = await getLoanOfficer(me.id, login.access_token)
									//TODO: if more than one siteconfigurationurl we should show a list to choose
									setErrorMessage(`Not authorized. Redirecting to https://${loanOfficer.siteconfigurationurl[0].url}`)
									setLoading(false)
									await window.open(`https://${loanOfficer.siteconfigurationurl[0].url}`, '_blank', 'noopener,noreferrer')
								} else {
									const userData = { ...login, ...me }

									let authTokenExp = new Date()
									authTokenExp.setMinutes(authTokenExp.getMinutes() + (userData.expires_in / 60) - 5)
									const user = {
										...userData,
										isLoggedIn: true,
										tokenExp: authTokenExp
									}

									applyAuthToken(login.access_token)
									applyUser(user)
									ventiState.set(theme.storageKeys.authToken, login.access_token)
									ventiState.set(theme.storageKeys.user, user)

									if ([Roles.loanOfficer, Roles.branchManager].includes(user.role) && (redirectRouteFromSignIn.includes('Apply') || redirectRouteFromSignIn.includes('Prequalify')) && !redirectRouteFromSignIn.includes('LO'))
										redirectRouteFromSignIn = `LO${redirectRouteFromSignIn}`

									if (!navigationLinking[redirectRouteFromSignIn]) redirectRouteFromSignIn = navigationLinking.Portal
									else if (user.role === Roles.admin) redirectRouteFromSignIn = navigationLinking.Portal
									navigate(`/${redirectRouteFromSignIn}`)
								}
							} catch (e) {
								setErrorMessage('Your account does not have access to this environment')
								setLoading(false)
							}
						} else {
							setErrorMessage('Error retrieving authentication info')
							setLoading(false)
						}
					} catch (err) {
						setErrorMessage(err?.data?.message || 'Invalid username and/or password')
						setLoading(false)
					}
				}, 500)
			}).catch((e) => {
				setResendCode(false)
				setLoading(false)
				alert(getErrorMessage(e), { severity: "error" })
			})
		}
	}

	const handleResendRegistrationCode = () => {
		setResending(true)
		setResendCode(false)

		if (emailConfirm && isEmailValid(emailConfirm)) {
			let data = {
				siteConfigurationId: siteConfig.id,
				email: emailConfirm
			}

			resendRegistrationCode(data)
				.then(() => {
					setResendCode(true)
					setResending(false)
					setLoading(false)
				})
				.catch((e) => {
					setLoading(false)
					setResending(false)
					alert(getErrorMessage(e), { severity: "error" })
				})
		} else {
			setEmailBlurred(true)
			setEmailConfirmValidation(false)
		}
	}

	const onKeyDownHandler = (event) => {
		if (event?.keyCode === 13) {
			handleConfirmAccount()
		}
	}

	return (
		<AuthWrapper siteConfig={siteConfig} onKeyDown={onKeyDownHandler}
			title="Confirm Account">
			<>
				<img
					className="w-48 mb-5 lg:mb-0 lg:w-72 lg:absolute lg:top-5 lg:right-5"
					src={isDarkMode() && siteConfig?.darkModePortalLogoUrl ? siteConfig.darkModePortalLogoUrl : siteConfig?.portalLogoUrl || siteConfig?.logoUrl}
					alt="Logo"
				/>
				<p className="text-lg sm:text-2xl font-bold mb-6 dark:text-white">
					Confirm your account
				</p>
				<TextField
					onChange={(e) => emailConfirmOnChange(e.target.value)}
					onBlur={emailConfirmOnBlur}
					value={emailConfirm}
					label={emailConfirm === '' ? null : 'Email'}
					placeholder="Email"
					error={!emailConfirmValidation && emailBlurred}
					id={`ConfirmEmailInput`}
					helperText={!emailConfirmValidation && emailBlurred ? "Email is invalid" : ""}
					variant="standard"
					className="w-64 sm:w-96 sm:px-12"
					style={{ marginTop: 16 }}
					sx={{ input: { fontWeight: 300, fontSize: 16 } }}
				/>
				<TextField
					onChange={(e) => passwordConfirmOnChange(e.target.value)}
					onBlur={passwordConfirmOnBlur}
					value={passwordConfirm}
					label={passwordConfirm === '' ? null : 'Password'}
					placeholder="Password"
					error={!passwordConfirmValidation && passwordBlurred}
					id={`ConfirmPasswordInput`}
					helperText={!passwordConfirmValidation && passwordBlurred ? "Password should be at least 8 characters" : ""}
					variant="standard"
					type="password"
					className="w-64 sm:w-96 sm:px-12"
					style={{ marginTop: 16 }}
					sx={{ input: { fontWeight: 300, fontSize: 16 } }}
				/>
				<TextField
					onChange={(e) => codeConfirmOnChange(e.target.value)}
					onBlur={codeConfirmOnBlur}
					value={codeConfirm}
					label={codeConfirm === '' ? null : 'Code'}
					placeholder="Verification Code"
					error={!codeConfirmValidation && codeBlurred}
					id={`ConfirmCodeInput`}
					helperText={!codeConfirmValidation && codeBlurred ? "Verification code must be 6 numbers" : ""}
					variant="standard"
					inputProps={{ maxLength: 6 }}
					className="w-64 sm:w-96 sm:px-12 h-full"
					style={{ marginTop: 16 }}
					sx={{ input: { fontWeight: 300, fontSize: 16 } }}
				/>
				{errorMessage && <p className="text-sm pt-2.5 text-red-500">{errorMessage}</p>}
				<div className="w-full mt-5">
					<LoadingBtn
						id={`ConfirmAccountButton`}
						disabled={!enabledConfirmAccountButton || loading}
						loading={loading}
						onClick={handleConfirmAccount}
						fullWidth
						variant="contained"
						text="Confirm Account" />
				</div>
				{resendCode &&
					<p className="text-base font-light mt-4">Your code has been sent. Please check your email.</p>
				}
				{resending
					? <div className="w-full mt-3 flex justify-center sm:justify-end">
						<Typography>
							<span className="text-sm">Resending...</span>
						</Typography>
					</div>
					: <div className="w-full mt-3 flex justify-center sm:justify-end">
						<Link
							className="text-blue-500 cursor-pointer"
							onClick={handleResendRegistrationCode}
							rel="noopener noreferrer"
							underline="hover"
							style={{ color: theme.siteConfig.color.primary, marginTop: 16 }}
						>
							<Typography>
								<span className="text-sm">Resend Code?</span>
							</Typography>
						</Link>
					</div>
				}
			</>
		</AuthWrapper>
	)
}


