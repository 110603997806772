import React from "react"
import { TextField, InputLabel, MenuItem, FormControl, Select } from "@mui/material"

export const Integrations = ({ formData, setFormData, entityType, width }) => {
	const updatePOSSiteConfiguration = (field, value) =>
		setFormData(profileFormData => (
			{
				...profileFormData,
				[field]: value
			}
		))

	return (
		<div className="flex flex-col">
			<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
				{entityType !== 0 ?
					<>
						<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
							<TextField
								onChange={e => updatePOSSiteConfiguration("losUserID", e.target.value)}
								value={formData?.losUserID}
								label="LOS User ID"
								placeholder="LOS User ID"
								id={`IntegrationsLOSUserIDInput`}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
							/>
							<TextField
								onChange={e => updatePOSSiteConfiguration("eppsUserName", e.target.value)}
								value={formData?.eppsUserName}
								label="EPPS Username"
								placeholder="EPPS Username"
								id={`IntegrationsEPPSUserNameInput`}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2 }}
							/>
						</div>
						{entityType === 1 &&
							<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
								<FormControl
									key={"IntegrationsLoanChannelPicker"}
									variant="standard"
									className="w-full sm:px-12"
									sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
								>
									<InputLabel>Loan Channel</InputLabel>
									<Select
										value={formData?.loanChannel}
										onChange={e => updatePOSSiteConfiguration("loanChannel", e.target.value)}
										label="Loan Channel"
									>
										<MenuItem value="">Choose a Loan Channel</MenuItem>
										<MenuItem value="Correspondent">Correspondent</MenuItem>
										<MenuItem value="Banked - Wholesale">Banked - Wholesale</MenuItem>
										<MenuItem value="Brokered">Brokered</MenuItem>
										<MenuItem value="Banked - Retail">Banked - Retail</MenuItem>
									</Select>
								</FormControl>
								<TextField
									onChange={e => updatePOSSiteConfiguration("loanFolder", e.target.value)}
									value={formData?.loanFolder}
									label="Loan Folder"
									placeholder="Loan Folder"
									id={`IntegrationsLoanFolderInput`}
									variant="standard"
									className="w-full sm:px-12"
									sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
								/>
								<TextField
									onChange={e => updatePOSSiteConfiguration("loanTemplate", e.target.value)}
									value={formData?.loanTemplate}
									label="Loan Template"
									placeholder="Loan Template"
									id={`IntegrationsLoanTemplateInput`}
									variant="standard"
									className="w-full sm:px-12"
									sx={{ mb: 2 }}
								/>
							</div>
						}
					</>
					:
					<div className="w-full items-end">
						<TextField
							onChange={e => setFormData(current => ({ ...current, servicerLenderId: e.target.value }))}
							value={formData?.servicerLenderId}
							label="Finigree Lender ID"
							placeholder="Finigree Lender ID"
							id={`IntegrationsFinigreeLenderIDInput`}
							variant="standard"
							className="w-full sm:px-12"
							sx={{ mb: 3 }}
						/>
					</div>
				}
				{entityType === 0 && (
					<>
						<TextField
							onChange={e => updatePOSSiteConfiguration("loConnectAppID", e.target.value)}
							value={formData?.loConnectAppID}
							label="LO Connect App ID"
							placeholder="LO Connect App ID"
							id={`IntegrationsDisclosuresSSOClientIDInput`}
							variant="standard"
							className="w-full sm:px-12"
						/>
					</>
				)}
				{entityType === 1 && (
					<>
						<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
							<TextField
								onChange={e => updatePOSSiteConfiguration("disclosuresSSOClientID", e.target.value)}
								value={formData?.disclosuresSSOClientID}
								label="Disclosures SSO Client ID"
								placeholder="Disclosures SSO Client ID"
								id={`IntegrationsDisclosuresSSOClientIDInput`}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
							/>
							<TextField
								onChange={e => updatePOSSiteConfiguration("disclosuresSSOSiteID", e.target.value)}
								value={formData?.disclosuresSSOSiteID}
								label="Disclosures SSO Site ID"
								placeholder="Disclosures SSO Site ID"
								id={`IntegrationsDisclosuresSSOSiteIDInput`}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2 }}
							/>
						</div>
						<div className="flex flex-row w-full items-end">
							<TextField
								onChange={e => updatePOSSiteConfiguration("disclosuresSSOLoginRedirectUri", e.target.value)}
								value={formData?.disclosuresSSOLoginRedirectUri}
								label="Disclosures SSO Login Redirect URI"
								placeholder="Disclosures SSO Login Redirect URI"
								id={`IntegrationsDisclosuresSSOLoginRedirectUriInput`}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2 }}
							/>
						</div>
						<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
							<TextField
								onChange={e => updatePOSSiteConfiguration("disclosuresSSOLogoutRedirectUri", e.target.value)}
								value={formData?.disclosuresSSOLogoutRedirectUri}
								label="Disclosures SSO Logout Redirect URI"
								placeholder="Disclosures SSO Logout Redirect URI"
								id={`IntegrationsDisclosuresSSOLogoutRedirectUriInput`}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2 }}
							/>
							{/* <TextField
                                onChange={e => updatePOSSiteConfiguration("googleAnalyticsKey", e.target.value)}
                                value={formData.googleAnalyticsKey}
                                label="Google Analytics Key"
                                placeholder="Google Analytics Key"
                                id={`GoogleAnalyticsKeyInput`}
                                variant="standard"
                        		className="w-full sm:px-12"
								sx={{ mb: 2 }}
                            /> */}
						</div>
						<div className="flex flex-row w-full items-end">
							<TextField
								onChange={e => updatePOSSiteConfiguration("disclosuresUrl", e.target.value)}
								value={formData?.disclosuresUrl}
								label="Disclosures URL w/Standard Login (no SSO)"
								placeholder="Disclosures URL w/Standard Login (no SSO)"
								id={`IntegrationsDisclosuresUrlInput`}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2 }}
							/>
						</div>
						<div className="flex flex-row w-full items-end">
							<TextField
								onChange={e => updatePOSSiteConfiguration("irsVerificationUrl", e.target.value)}
								value={formData?.irsVerificationUrl}
								label="IRS Verification URL"
								placeholder="IRS Verification URL"
								id={`IntegrationsIRSVerificationUrlInput`}
								variant="standard"
								className="w-full sm:px-12"
								sx={{ mb: 2 }}
							/>
						</div>
					</>)}
			</div>
			<div className="flex w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
			</div>
		</div>
	)
}
