import React, { useState, useEffect } from "react"
import { TextField, InputLabel, MenuItem, FormControl, Select,
	InputAdornment, Icon } from "@mui/material"
import { applyMask } from "../../services/utils"
import { Roles } from "../../services/client"
import { useAppContext } from "../AppContext"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"

export const LOLockedCalculatorForm = ({ formData, setFormData, handleZipCodeBlur, loanLocked, width }) => {
	const { state } = useAppContext()
	const { user } = state

	const role = user.role

	const updateFormData = (field, val) => setFormData(formData => ({ ...formData, [field]: val }))
	const updateNumeric = (field, val) => {
		setFormData(formData => ({ ...formData, [field]: applyMask(val, "numeric") }))
	}
	const updateCurrency = (field, val) => {
		// setFormData(formData => ({ ...formData, [field]: val }))
		setFormData(formData => ({ ...formData, [field]: applyMask(val, "currency") }))
	}

	const [isRealtor, setIsRealtor] = useState(false)

	useEffect(() => {
		if (role !== Roles.loanOfficer && role !== Roles.branchManager) {
			setIsRealtor(true)
		}
	}, [role])

	return (
		<form className="flex flex-col items-center w-full">
			<div className={`${width >= 768 ? "flex-row" : "flex-col"} flex w-full items-end`}>
				<FormControl
					key={"LOCalculatorFormMortgageTypePicker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
				>
					<InputLabel>Loan Purpose 1</InputLabel>
					<Select
						value={formData?.loanPurpose}
						onChange={e => updateFormData("loanPurpose", e.target.value)}
						label="Loan Purpose"
						disabled={loanLocked || isRealtor}
						endAdornment={
							<InputAdornment position="end">
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -5,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
						}
					>
						<MenuItem value="">Loan Purpose</MenuItem>
						<MenuItem value="Purchase">Purchase</MenuItem>
						<MenuItem value="Refinance">Refi</MenuItem>
						<MenuItem value="CashOutRefinance">Cash Out Refi</MenuItem>
					</Select>

				</FormControl>
				<TextField
					onChange={e => updateCurrency("propertyValue", e.target.value)}
					value={formData?.propertyValue}
					label="Property Value"
					placeholder="Property Value"
					id={`LOCalculatorFormPropertyValueInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{ mb: 2, mr: width >= 768 ? 2 : 0 }}
					disabled={loanLocked}
					InputProps={{
						startAdornment: formData?.propertyValue ? <InputAdornment position="start">$</InputAdornment> : ""
					}}
				/>
			</div>
			<div className={`${width >= 768 ? "flex-row" : "flex-col"} flex w-full items-end`}>
				<FormControl
					key={"LOCalculatorFormLoanTypePicker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
				>
					<InputLabel>Loan Type</InputLabel>
					<Select
						value={formData?.loanType}
						onChange={e => updateFormData("loanType", e.target.value)}
						label="Loan Type"
						disabled={loanLocked || isRealtor}
						endAdornment={
							<InputAdornment position="end">
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -5,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
						}
					>
						<MenuItem value="">Loan Type</MenuItem>
						<MenuItem value="Conventional">CONV</MenuItem>
						<MenuItem value="FHA">FHA</MenuItem>
						<MenuItem value="USDA">USDA</MenuItem>
						<MenuItem value="VA">VA</MenuItem>
					</Select>
				</FormControl>
				<TextField
					onChange={e => updateCurrency("loanAmount", e.target.value)}
					value={formData?.loanAmount}
					label="Loan Amount"
					placeholder="Loan Amount"
					id={`LOCalculatorFormLoanAmountInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{ mb: 2, mr: width >= 768 ? 2 : 0 }}
					disabled={loanLocked}
					InputProps={{
						startAdornment: formData?.loanAmount ? <InputAdornment position="start">$</InputAdornment> : ""
					}}
				/>
			</div>
			<div className={`${width >= 768 ? "flex-row" : "flex-col"} flex w-full items-end`}>
				<TextField
					onChange={e => updateNumeric("zipCode", e.target.value)}
					value={formData?.zipCode}
					label="Zip Code"
					placeholder="Zip Code"
					id={`LOCalculatorFormZipCodeInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{ mb: 2, mr: width >= 768 ? 2 : 0 }}
					disabled={loanLocked}
					inputProps={{ maxLength: 5 }}
					onBlur={handleZipCodeBlur}
				/>
				{/* <TextField
                    onChange={e => updateNumeric("creditScore", e.target.value)}
                    value={formData?.creditScore}
                    label="Credit Score"
                    placeholder="Credit Score"
                    id={`LOCalculatorFormCreditScoreInput`}
                    variant="standard"
                    className="w-full sm:px-12"
                    sx={{ mb: 2, mr: width >= 768 ? 2 : 0 }}
                    disabled={loanLocked}
                    inputProps={{ maxLength: 3 }}
                /> */}
				<TextField
					onChange={e => updateCurrency("Taxes", e.target.value)}
					value={formData?.taxes}
					label="Monthly Taxes"
					placeholder="Monthly Taxes"
					id={`LOCalculatorFormTaxesInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{ mb: 2, mr: width >= 768 ? 2 : 0 }}
					disabled={loanLocked}
					InputProps={{
						startAdornment: formData?.taxes ? <InputAdornment position="start">$</InputAdornment> : ""
					}}
				/>
			</div>
			<div className={`${width >= 768 ? "flex-row" : "flex-col"} flex w-full items-end`}>
				<TextField
					onChange={e => updateCurrency("insurance", e.target.value)}
					value={formData?.insurance}
					label="Monthly Insurance"
					placeholder="Monthly Insurance"
					id={`LOCalculatorFormInsuranceInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
					disabled={loanLocked || isRealtor}
					InputProps={{
						startAdornment: formData?.insurance ? <InputAdornment position="start">$</InputAdornment> : "",
						endAdornment:
							<InputAdornment position="end" >
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -2,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
					}}
				/>
				<FormControl
					key={"RateFormEscrowPicker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
				>
					<InputLabel>Escrow</InputLabel>
					<Select
						value={formData?.escrow}
						onChange={e => updateFormData("escrow", e.target.value)}
						label="Escrow"
						disabled={loanLocked || isRealtor}
						endAdornment={
							<InputAdornment position="end">
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -5,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
						}
					>
						<MenuItem value="">Escrow</MenuItem>
						<MenuItem value="Y">Taxes and Insurance</MenuItem>
						<MenuItem value="Т">No Escrow</MenuItem>
					</Select>
				</FormControl>
				{isRealtor ?
					<TextField
						onChange={e => updateFormData("totalAssets", e.target.value)}
						value={formData?.totalAssets}
						label="Total Assets"
						placeholder="Total Assets"
						id={`LOCalculatorFormTotalAssetsInput`}
						variant="standard"
						className="w-full sm:px-12"
						sx={{
							mb: 2,
							mr: width >= 768 ? 2 : 0,
							cursor: "not-allowed",
							".MuiInputBase-input": {
								cursor: "not-allowed"
							}
						}}
						disabled={loanLocked || isRealtor}
						InputProps={{
							startAdornment: formData?.totalAssets ? <InputAdornment position="start">$</InputAdornment> : ""
						}}
					/> : <></>
				}
			</div>
			<div className={`${width >= 768 ? "flex-row" : "flex-col"} flex w-full items-end`}>
				<FormControl
					key={"LOCalculatorFormLoanTerm1Picker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
				>
					<InputLabel>Loan Term 1</InputLabel>
					<Select
						value={formData?.loanTerm1}
						onChange={e => updateFormData("loanTerm1", e.target.value)}
						label="Loan Term"
						disabled={loanLocked || isRealtor}
						endAdornment={
							<InputAdornment position="end">
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -5,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
						}
					>
						<MenuItem value="">Loan Term 1</MenuItem>
						<MenuItem value="360">30 Years</MenuItem>
						<MenuItem value="300">25 Years</MenuItem>
						<MenuItem value="240">20 Years</MenuItem>
						<MenuItem value="180">15 Years</MenuItem>
						<MenuItem value="120">10 Years</MenuItem>
					</Select>
				</FormControl>
				<FormControl
					key={"LOCalculatorFormLoanTerm2Picker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
				>
					<InputLabel>Loan Term 2</InputLabel>
					<Select
						value={formData?.loanTerm2}
						onChange={e => updateFormData("loanTerm2", e.target.value)}
						label="Loan Term"
						disabled={loanLocked || isRealtor}
						endAdornment={
							<InputAdornment position="end">
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -5,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
						}
					>
						<MenuItem value="">Loan Term 2</MenuItem>
						<MenuItem value="360">30 Years</MenuItem>
						<MenuItem value="300">25 Years</MenuItem>
						<MenuItem value="240">20 Years</MenuItem>
						<MenuItem value="180">15 Years</MenuItem>
						<MenuItem value="120">10 Years</MenuItem>
					</Select>
				</FormControl>
			</div>
			<div className={`${width >= 768 ? "flex-row" : "flex-col"} flex w-full items-end`}>
				<FormControl
					key={"LOCalculatorFormPropertyOccupancyPicker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
				>
					<InputLabel>Property Occupancy</InputLabel>
					<Select
						value={formData?.propertyOccupancy}
						onChange={e => updateFormData("propertyOccupancy", e.target.value)}
						label="Property Occupancy"
						disabled={loanLocked || isRealtor}
						endAdornment={
							<InputAdornment position="end">
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -5,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
						}
					>
						<MenuItem value="">Property Occupancy</MenuItem>
						<MenuItem value="PrimaryResidence">Primary Residence</MenuItem>
						<MenuItem value="SecondHome">Second Home</MenuItem>
						<MenuItem value="InvestmentProperty">Investment</MenuItem>
					</Select>
				</FormControl>
				<FormControl
					key={"LOCalculatorFormLoanTerm2Picker"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
				>
					<InputLabel>Home Type</InputLabel>
					<Select
						value={formData?.propertyType}
						onChange={e => updateFormData("propertyType", e.target.value)}
						label="Home Type"
						disabled={loanLocked || isRealtor}
						endAdornment={
							<InputAdornment position="end">
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -5,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
						}
					>
						<MenuItem value="">Home Type</MenuItem>
						<MenuItem value="SingleFamily">Single Family</MenuItem>
						<MenuItem value="MultiFamily">Multi Family 2-4 Units</MenuItem>
						<MenuItem value="Condo">Condo</MenuItem>
						<MenuItem value="TownHome">Town Home</MenuItem>
						<MenuItem value="CoOp">Co-Op</MenuItem>
					</Select>
				</FormControl>
			</div>
			<div className={`${width >= 768 ? "flex-row" : "flex-col"} flex w-full items-end`}>
				{isRealtor ?
					<TextField
						onChange={e => updateCurrency("borrowerIncome", e.target.value)}
						value={formData?.borrowerIncome}
						label="Borrower Income"
						placeholder="Borrower Income"
						id={`LOCalculatorFormBorrowerIncomeInput`}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 768 ? 2 : 0 }}
						disabled={loanLocked || isRealtor}
						InputProps={{
							startAdornment: formData?.borrowerIncome ? <InputAdornment position="start">$</InputAdornment> : ""
						}}
					/> : <></>}
				<FormControl
					key={"LOCalculatorFormLienType"}
					variant="standard"
					className="w-full sm:px-12"
					sx={{mb: 2, mr: width >= 768 ? 2 : 0}}
				>
					<InputLabel>Lien Type</InputLabel>
					<Select
						value={formData?.lienType}
						onChange={e => updateFormData("lienType", e.target.value)}
						label="Lien Type"
						disabled={loanLocked || isRealtor}
						endAdornment={
							<InputAdornment position="end">
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -5,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
						}
					>
						<MenuItem key="First Lien" value="First Lien">First Lien</MenuItem>
						<MenuItem value="Second Lien">Subordinate Lien</MenuItem>
					</Select>
				</FormControl>
				{/* <TextField
                    onChange={e => updateFormData("LoanProgram", e.target.value)}
                    value={formData?.LoanProgram}
                    label="Loan Program"
                    placeholder="Loan Program"
                    id={`LOCalculatorFormLoanProgramInput`}
                    variant="standard"
                    className="w-full sm:px-12"
                    sx={{ mb: 2, mr: width >= 768 ? 2 : 0 }}
                    disabled={loanLocked}
                    inputProps={{ maxLength: 5 }}
                /> */}
			</div>
			<div className={`${width >= 768 ? "flex-row" : "flex-col"} flex w-full items-end`}>
				<TextField
					onChange={e => updateFormData("rate", e.target.value)}
					value={formData?.rate}
					label="Rate"
					placeholder="Rate"
					id={`LOCalculatorFormRateInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
					disabled={loanLocked || isRealtor}
					InputProps={{
						startAdornment: formData?.rate ? <InputAdornment position="start">$</InputAdornment> : "",
						endAdornment:
							<InputAdornment position="end" >
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -2,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
					}}
				/>
				<TextField
					onChange={e => updateFormData("county", e.target.value)}
					value={formData?.county}
					label="County"
					placeholder="County"
					id={`LOCalculatorFormCountyInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
					disabled={loanLocked || isRealtor}
					InputProps={{
						endAdornment:
							<InputAdornment position="end" >
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -2,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
					}}
				/>
			</div>
			<div className={`${width >= 768 ? "flex-row" : "flex-col"} flex w-full items-end`}>
				<TextField
					onChange={e => updateFormData("flood", e.target.value)}
					value={formData?.flood}
					label="Monthly Flood"
					placeholder="Monthly Flood"
					id={`LOCalculatorFormFloodInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
					disabled={loanLocked || isRealtor}
					InputProps={{
						startAdornment: formData?.flood ? <InputAdornment position="start">$</InputAdornment> : "",
						endAdornment:
							<InputAdornment position="end" >
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -2,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
					}}
				/>
				<TextField
					onChange={e => updateFormData("hoa", e.target.value)}
					value={formData?.hoa}
					label="Monthly HOA"
					placeholder="Monthly HOA"
					id={`LOCalculatorFormHOAInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
					disabled={loanLocked || isRealtor}
					InputProps={{
						startAdornment: formData?.hoa ? <InputAdornment position="start">$</InputAdornment> : "",
						endAdornment:
							<InputAdornment position="end" >
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -2,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
					}}
				/>
			</div>
			<div className={`${width >= 768 ? "flex-row" : "flex-col"} flex w-full items-end`}>
				<TextField
					onChange={e => updateFormData("miFactor", e.target.value)}
					value={formData?.miFactor}
					label="MI Factor"
					placeholder="MI Factor"
					id={`LOCalculatorFormMIFactorInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
					disabled={loanLocked || isRealtor}
					endAdornment={
						<InputAdornment position="end">
							<Icon
								aria-label="upload"
								sx={{ height: 30, width: 24 }}
							>
								<LockOutlinedIcon sx={{
									marginLeft: -5,
									height: 24,
									width: 24,
									position: "absolute"
								}} />
							</Icon>
						</InputAdornment>
					}
					InputProps={{
						endAdornment: formData?.miFactor ?
							<>
								<InputAdornment position="end" >
									<Icon
										aria-label="upload"
										sx={{ height: 30, width: 24 }}
									>
										<LockOutlinedIcon sx={{
											marginLeft: -2,
											height: 24,
											width: 24,
											position: "absolute"
										}} />
									</Icon>
								</InputAdornment>
								<InputAdornment position="end">%</InputAdornment>
							</> :
							<InputAdornment position="end" >
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -2,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>

					}}
				/>
				<TextField
					onChange={e => updateFormData("mi", e.target.value)}
					value={formData?.mi}
					label="MI"
					placeholder="MI"
					id={`LOCalculatorFormMIInput`}
					variant="standard"
					className="w-full sm:px-12"
					sx={{
						mb: 2,
						mr: width >= 768 ? 2 : 0,
						cursor: "not-allowed",
						".MuiInputBase-input": {
							cursor: "not-allowed"
						}
					}}
					disabled={loanLocked || isRealtor}
					InputProps={{
						startAdornment: formData?.mi ? <InputAdornment position="start">$</InputAdornment> : "",
						endAdornment:
							<InputAdornment position="end" >
								<Icon
									aria-label="upload"
									sx={{ height: 30, width: 24 }}
								>
									<LockOutlinedIcon sx={{
										marginLeft: -2,
										height: 24,
										width: 24,
										position: "absolute"
									}} />
								</Icon>
							</InputAdornment>
					}}
				/>
			</div>
		</form>
	)
}
