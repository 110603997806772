import React, {useEffect} from "react"
import {Button, Dialog, DialogContent, DialogTitle} from "@mui/material"
import {LoadingBtn} from "../Button";
import DialogActions from "@mui/material/DialogActions";
import {TextField} from "../formControls";
import * as yup from "yup";
import useForm from "../../hooks/useForm";
import { createMilestoneConfiguration, updateMilestoneConfiguration } from '../../services/client';
import {useAlert} from "../../hooks";
import {useVenti} from "venti";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

const ModalMilestoneConfiguration = ({ open, onClose, onSave, loanType, milestoneConfiguration = null }) => {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

	const schema = yup.object().shape({
		name: yup.string().required("Name is required"),
		fieldID: yup.string().required("Field ID is required"),
	});

	const { handleSubmit, reset, formState: { errors, isSubmitting }, control, setValue, getValues } = useForm({
		schema,
		defaultValues: { name: '', fieldID: '', weight: null }
	});

	useEffect(() => {
		if(milestoneConfiguration) {
			const { name, fieldID, weight } = milestoneConfiguration
			setValue('name', name)
			setValue('fieldID', fieldID)
			setValue('weight', weight)
		} else {
			reset()
		}

	}, [milestoneConfiguration, reset, setValue]);

	const save = async () => {
		const data = {...getValues(), loanType}

		try {
			if(milestoneConfiguration) {
				await updateMilestoneConfiguration(milestoneConfiguration.id, data)
			} else {
				await createMilestoneConfiguration(data)
			}
			reset();
			onSave()
		} catch (e) {
			const errorMessage = "There was a problem saving the milestone"
			alert(errorMessage, { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage, errorMessage)
		}

	}

	const onDialogClose = () => {
		reset();
		onClose()
	}

	return (
		<>
			<Dialog
				open={open}
				onClose={onDialogClose}
				aria-labelledby="responsive-dialog-title"
				fullScreen={fullScreen}
			>
				<DialogTitle>{milestoneConfiguration ? 'Edit Milestone Configuration' : 'Add Milestone Configuration'}</DialogTitle>
				<form noValidate>
					<DialogContent dividers>
						<div className="mb-3">
							<TextField name="name"
									   label="Name"
									   className="w-full"
									   sx={{ mb: 2 }}
									   placeholder="Enter name"
									   control={control}
									   errors={errors}
									   fullWidth />
						</div>
						<div>
							<TextField name="fieldID"
									   label="Field ID"
									   className="w-full"
									   sx={{ mb: 2 }}
									   placeholder="Enter field ID"
									   helperText="LOS Date Field ID"
									   control={control}
									   errors={errors}
									   fullWidth />
						</div>
						{milestoneConfiguration &&
							<div>
								<TextField name="weight"
													 label="Weight"
													 type="number"
													 className="w-full"
													 sx={{ mb: 2 }}
													 placeholder="Enter weight"
													 helperText="Weight is used for sorting. The lower the number, the higher it will display in the list."
													 control={control}
													 errors={errors}
													 fullWidth />
							</div>
						}

					</DialogContent>
					<DialogActions className="mt-3">
						<div className="flex justify-center items-center">
							{onClose &&
								<div className="mr-2">
									<Button
										id="Cancel"
										onClick={onDialogClose}
										variant="outlined"
									>
										Cancel
									</Button>
								</div>
							}

							<LoadingBtn
								text="Save"
								onClick={handleSubmit(save)}
								loading={isSubmitting}
							/>
						</div>
					</DialogActions>
				</form>
			</Dialog>
		</>
	)
}

export default ModalMilestoneConfiguration
