import { useState, useEffect } from "react"
import { useVenti } from "venti"
import {
	Link, List, ListItemButton, ListItemText, Collapse, IconButton,
	Divider, Tooltip
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import DownloadIcon from '@mui/icons-material/Download'
import FileViewer from 'react-file-viewer'
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined"
import { useAppContext } from "../../components/AppContext"
import Page from "../../components/Page"
import { ModalDocumentType } from "../../components/modals/ModalDocumentType"
import { ModalUploadSizeLimit } from "../../components/modals/ModalUploadSizeLimit"
import { ModalDocumentDragOrUpload } from "../../components/modals/ModalDocumentDragOrUpload"
import { Loading } from "../../components/Loading"
import { Button } from "../../components/Button"
import { getTheme } from "../../config"
import * as FileSaver from 'file-saver'
import {
	getLoanDocuments,
	getLoanTasks, Roles, addLoanTaskDocument, getLoanDocumentContentUrl
} from '../../services/client';
import { getErrorMessage, isMobileDevice } from "../../services/helper"
import { fileMBSizes, isiOSAndSafariBrowser } from "../../services/utils"
import { useWindowSize, useAlert } from "../../hooks"
import RefreshPNG from "assets/lordicons/Refresh.png"
import RefreshGIF from "assets/lordicons/RefreshAnimated.gif"
import RefreshDarkPNG from "assets/lordicons/RefreshDark.png"
import RefreshDarkGIF from "assets/lordicons/RefreshAnimatedDark.gif"
import { ModalUploadMimeType } from '../../components/modals/ModalUploadMimeType';

const theme = getTheme()

const bgColor = {
	"&:hover": {
		backgroundColor: "transparent"
	}
}

export default function Documents() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { user } = state
	const [width] = useWindowSize()
	const themeMUI = useTheme()

	const [loanDocuments, setLoanDocuments] = useState([])
	const [documentFolders, setDocumentFolders] = useState([])

	const ventiState = useVenti()
	const loanId = ventiState.get(theme.storageKeys.loanId)
	const preloadedTasks = ventiState.get(theme.storageKeys.loanTasks)
	const preloadedDocs = ventiState.get(theme.storageKeys.loanDocs)
	const openDocFoldersState = ventiState.get(theme.openDocFolders)
	const preloadedDocument = ventiState.get(theme.storageKeys.document)

	const [fetchingDocuments, setFetchingDocuments] = useState(false)
	const [fetchingTasks, setFetchingTasks] = useState(false)
	const [uploadDocumentType, setUploadDocumentType] = useState(null)
	const [document, setDocument] = useState(null)
	const [documentTypeModalVisible, setDocumentTypeModalVisible] = useState(false)
	const [documentUploadModalVisible, setDocumentUploadModalVisible] = useState(false)
	const [tasksToComplete, setTasksToComplete] = useState([])
	const [errorModalVisible, setErrorModalVisible] = useState(false)
	const [uploadDocumentSuccess, setUploadDocumentSuccess] = useState(false)
	const [modalSizeLimitVisible, setModalSizeLimitVisible] = useState(false)
	const [modalMimeTypeVisible, setModalMimeTypeVisible] = useState(false)
	const [uploading, setUploading] = useState(false)
	const [viewDocument, setViewDocument] = useState(null)
	const [openMainFolders, setOpenMainFolders] = useState(["documents"])
	const [unassignedFiles, setUnassignedFiles] = useState([])
	const [openDocFolders, setOpenDocFolders] = useState([])

	useEffect(() => {
		if (openDocFoldersState) {
			setOpenDocFolders([openDocFoldersState] || [])
			setOpenMainFolders(["documents"])
		}
	}, [openDocFoldersState])

	useEffect(() => {
		if (!fetchingDocuments)
			setUnassignedFiles(loanDocuments.filter(doc => !doc.documentBucketTitle || !documentFolders.includes(doc.documentBucketTitle)))
	}, [loanDocuments])

	useEffect(() => {
		if (loanId) {
			fetchDocumentsAndTasks(loanId, user, preloadedDocs, false)
		}
	}, [loanId, user, preloadedDocs])

	const refreshDocuments = () => {
		if (loanId) {
			fetchDocumentsAndTasks(loanId, user, preloadedDocs, true)
		}
	}

	const fetchDocumentsAndTasks = async (loanId, user, preloadedDocs, refresh = true) => {
		setFetchingDocuments(true)
		setFetchingTasks(true)

		try {
			let response

			if (preloadedTasks && !refresh)
				response = preloadedTasks
			else
				response = await getLoanTasks(loanId)

			const tasks = response.map((task, index) => task.taskName.length > 0 ?
				{ ...task, taskId: index } : ""
			)
			setTasksToComplete(tasks.filter(task => task.taskType === 0))
			setFetchingTasks(false)
		} catch (e) {
			setFetchingTasks(false)
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || `There was an error fetching the loan tasks. Please contact ${[Roles.loanOfficer, Roles.branchManager].includes(user.role) ? "support" : "your loan officer"}.`)
		}

		try {
			let docResponse
			if (preloadedDocs && !refresh)
				docResponse = preloadedDocs
			else
				docResponse = await getLoanDocuments(loanId)
			setLoanDocuments(docResponse)
			const folders = {}
			docResponse.forEach(row => {
				folders[row.documentBucketTitle] = true
			})
			setDocumentFolders(Object.keys(folders).sort())
			setFetchingDocuments(false)
		} catch (e) {
			setFetchingDocuments(false)
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || `There was an error fetching the loan tasks. Please contact ${[Roles.loanOfficer, Roles.branchManager].includes(user.role) ? "support" : "your loan officer"}.`)
		}
	}

	useEffect(() => {
		if (preloadedDocument?.base64Data && loanDocuments.length) {
			clickFolder(preloadedDocument.documentBucketTitle, "doc")
			handleDocumentPress(preloadedDocument)
		}
	}, [loanDocuments, preloadedDocument])

	const clickFolder = (id, type) => {
		if (type === "main") {
			if (openMainFolders.includes(id)) {
				setOpenMainFolders(openMainFolders.filter(folderId => folderId !== id))
			}
			else {
				setOpenMainFolders(prevOpenMainFolders => [...prevOpenMainFolders, id])
			}
		}

		else if (type === "doc") {
			if (openDocFolders.includes(id)) {
				setOpenDocFolders(openDocFolders.filter(folderId => folderId !== id))
			}
			else {
				setOpenDocFolders(prevOpenDocFolders => [...prevOpenDocFolders, id])
			}
		}
	}

	const handleDocumentPress = (doc) => {
		setViewDocument(doc)
		// if (isMobileDevice()) window.open(getLoanDocumentContentUrl({ loanId, documentId: doc.documentID }))
	}

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result)
			}
			fileReader.onerror = (error) => {
				reject(error)
			}
		})
	}

	const pickDocument = async (docType) => {
		if (docType) setUploadDocumentType(docType)

		if (docType?.target?.files?.length === 0) {
			return
		} else if (docType?.target?.files?.length > 0) {
			let result = docType.target.files[0]

			if (!theme.validation.allowedDocumentTypes.includes(result.type)) {
				return setModalMimeTypeVisible(true)
			}

			if (result?.size > fileMBSizes["20"]) {
				return setModalSizeLimitVisible(true)
			}

			const base64 = await convertBase64(result)
			result.uri = base64

			const doc = {
				"loanGuid": loanId,
				"documents": [{
					"documentBucketTitle": "",
					"documentName": result.name,
					"base64Data": String(result.uri).replace(`data:${result.type};base64,`, ""),
					"extension": /\.(\w+)$/.exec(result.name)[0]
				}]
			}

			await uploadDocument(doc)
		}
	}

	const downloadPDF = async (doc) => {

		return window.open(getLoanDocumentContentUrl({ loanId, documentId: doc.documentID }))

		doc = doc || viewDocument

		if (isiOSAndSafariBrowser()) {
			let fileType = 'application/pdf;charset=UTF-8'
			let linkSource = `data:application/pdf;base64,${doc.base64Data}`

			if (doc.extension) {
				fileType = `application/${doc.extension};charset=UTF-8`
				linkSource = `data:application/${doc.extension};base64,${doc.base64Data}`
			}

			const str = await fetch(linkSource)
			const blobData = await str.blob()

			const data = new Blob([blobData], { type: fileType })
			FileSaver.saveAs(data, doc.documentName
				? doc.documentName : 'document.pdf')
		} else {
			let linkSource = `data:application/pdf;base64,${doc.base64Data}`

			if (doc.extension) {
				linkSource = `data:application/${doc.extension};base64,${doc.base64Data}`
			}

			const downloadLink = window.document.createElement('a')
			const fileName = doc.documentName
				? doc.documentName : 'document.pdf'

			downloadLink.href = linkSource
			downloadLink.download = fileName
			downloadLink.click()
		}
	}

	const uploadDocument = async (doc) => {
		const upload = doc || document

		if (!upload?.documents.length) {
			return console.log("Can't upload empty array")
		}

		try {
			setUploading(true)
			if (uploadDocumentType) {
				upload.documents = upload.documents.map(d => ({
					...d,
					documentBucketTitle: uploadDocumentType
				}))
			}
			// setUploadsInProcess(current => [ ...current, { id: uploadId, name: upload.documents[0].documentName, status: "pending" } ])
			await addLoanTaskDocument(upload)
			setDocumentUploadModalVisible(false)
			setUploading(false)
			setUploadDocumentSuccess(true)
			setDocumentTypeModalVisible(true)
			// setUploadsInProcess(current => current.map(upload => {
			// 	if (upload.id === uploadId) return { ...upload, status: "success"}
			// 	return upload
			// }))
		} catch (e) {
			// setUploadsInProcess(current => current.map(upload => {
			// 	if (upload.id === uploadId) return { ...upload, status: "error"}
			// 	return upload
			// }))
			alert(getErrorMessage(e), { severity: "error" })
			setDocumentUploadModalVisible(false)
			setUploading(false)
			onCancelDocumentTypeModal()
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || `There was an error uploading the document. Please contact ${[Roles.loanOfficer, Roles.branchManager].includes(user.role) ? "support" : "your loan officer"}.`)
		}
	}

	const fileViewerError = e => {
		console.log(e, "error in file-viewer")
	}

	const renderDocPreview = (doc) => {
		if (doc.base64Data && doc.extension === "pdf") {
			return <FileViewer
				fileType={"pdf"}
				filePath={isMobileDevice() ? getLoanDocumentContentUrl({ loanId, documentId: doc.documentID }) : `data:application/pdf;base64,${doc.base64Data}`}
				onError={fileViewerError}
				style={{ flex: 1, width: '100%', height: '100%' }}
				key={doc.documentName}
			/>
		}

		if (doc.base64Data && ["png", "jpg", "jpeg"].includes(doc.extension)) {
			return <img
				src={`data:image/${doc.extension};base64,${doc.base64Data}`}
				style={{ flex: 1 }}
			/>
		}

		return <p className="m-auto text-center dark:text-white">PREVIEW UNAVAILABLE</p>
	}

	const renderDocIframePreview = (doc) => {

		// if (isMobileDevice() && doc.extension === "pdf")
		// 	return (
		// 		<div className={`flex content-center`}>
		// 			<Button text={`Open Document`} onClick={() => window.open(getLoanDocumentContentUrl({ loanId, documentId: doc.documentID }))} />
		// 		</div>
		// 	)

		// if (doc.base64Data && doc.extension === "pdf") {
		// 	return <iframe src={`data:application/pdf;base64,${ viewDocument.base64Data }`} style={{ flex: 1, width: '100%', height: '100%' }} />
		// }

		return <img
			src={`data:application/${doc.extension};base64,${doc.base64Data}`}
			style={{ flex: 1 }}
		/>

	}

	const onCancelDocumentTypeModal = () => {
		setDocument(null)
		setUploadDocumentType(null)
		setDocumentTypeModalVisible(false)
	}

	const onCancelUploadDocumentModal = () => {
		setDocument(null)
		setDocumentUploadModalVisible(false)
	}

	return (
		<>
			<ModalDocumentType
				visible={documentTypeModalVisible}
				tasksToComplete={tasksToComplete}
				setDocumentTypeModalVisible={setDocumentTypeModalVisible}
				uploadDocumentType={uploadDocumentType}
				setUploadDocumentType={setUploadDocumentType}
				confirmModal={uploadDocument}
				cancelModal={onCancelDocumentTypeModal}
				errorModalVisible={errorModalVisible}
				setErrorModalVisible={setErrorModalVisible}
				setUploadDocumentSuccess={setUploadDocumentSuccess}
				uploadDocumentSuccess={uploadDocumentSuccess}
				loadingDocument={true}
			/>

			<ModalDocumentDragOrUpload
				visible={documentUploadModalVisible}
				uploading={uploading}
				pickDocument={pickDocument}
				cancelModal={onCancelUploadDocumentModal}
			/>

			<ModalUploadSizeLimit
				visible={modalSizeLimitVisible}
				closeModal={() => setModalSizeLimitVisible(false)}
			/>

			<ModalUploadMimeType
				visible={modalMimeTypeVisible}
				closeModal={() => setModalMimeTypeVisible(false)}
			/>

			<Page page="documents" title="Documents" isFullWidth={true}>
				<div className="pl-5 pr-5 pb-10 pt-5 h-screen overflow-auto">
					<p className="flex items-center text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
						<div>Documents</div>
						<div className="ml-3">
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="refresh"
								onClick={refreshDocuments}
								disabled={fetchingDocuments || fetchingTasks}
								sx={bgColor}
							>
								<img
									className="w-14 mt-1"
									src={themeMUI.palette.mode === 'light' ? RefreshPNG : RefreshDarkPNG}
									onMouseOver={e => (e.currentTarget.src = themeMUI.palette.mode === 'light' ? RefreshGIF : RefreshDarkGIF)}
									onMouseOut={e => (e.currentTarget.src = themeMUI.palette.mode === 'light' ? RefreshPNG : RefreshDarkPNG)}
									alt="refresh"
								/>
							</IconButton>
						</div>
					</p>
					{(fetchingDocuments || fetchingTasks) &&
						<div className="flex justify-center w-full h-full">
							<Loading size="small" />
						</div>
					}
					{!fetchingDocuments &&
						<div className="flex flex-col w-full border border-slate-500 mt-5">
							<div className={`${width > 768 ? "flex-row" : "flex-col"} flex`}>
								<div className={`${width > 768 ? "max-w-xs" : "w-full"} grow border border-slate-500`}>
									<List>
										{/*<ListItemButton*/}
										{/*	className="dark:text-white"*/}
										{/*	divider*/}
										{/*	onClick={() => clickFolder("unassigned", "main")}*/}
										{/*>*/}
										{/*	<ListItemText primary={`Unassigned Files (${unassignedFiles.length})`} />*/}
										{/*	{unassignedFiles.length > 0 && (openMainFolders.includes("unassigned") ? <ExpandLess /> : <ExpandMore />)}*/}
										{/*</ListItemButton>*/}
										{/*<Collapse in={openMainFolders.includes("unassigned")} timeout="auto" unmountOnExit>*/}
										{/*	<List component="div" disablePadding>*/}
										{/*		{unassignedFiles.map((file, index) => {*/}
										{/*			return (*/}
										{/*				<div className="flex flex-col w-full text-center bg-slate-200 dark:bg-transparent" key={index}>*/}
										{/*					<p className="pt-2 pb-2 dark:text-white">ATTACHED FILES</p>*/}
										{/*					<Divider />*/}
										{/*					<div className="h-20 flex justify-center bg-slate-200 dark:bg-slate-800">*/}
										{/*						<div className="flex flex-col justify-center w-full m-2.5 bg-white rounded-md dark:bg-[#121212]">*/}
										{/*							<Link*/}
										{/*								onClick={() => handleDocumentPress(file)}*/}
										{/*								tabIndex={0}*/}
										{/*								component="button"*/}
										{/*								underline="none"*/}
										{/*							>*/}
										{/*								{file.documentName}*/}
										{/*							</Link>*/}
										{/*						</div>*/}
										{/*					</div>*/}
										{/*				</div>*/}
										{/*			)*/}
										{/*		})}*/}
										{/*	</List>*/}
										{/*</Collapse>*/}
										<ListItemButton
											className="dark:text-white"
											divider
											onClick={() => clickFolder("documents", "main")}
										>
											<ListItemText primary={`Document Folders (${documentFolders.length})`} />
											{openMainFolders.includes("documents") ? <ExpandLess /> : <ExpandMore />}
										</ListItemButton>
										<Collapse in={openMainFolders.includes("documents")} timeout="auto" unmountOnExit>
											{documentFolders.map((folder, index) =>
												<List component="div" disablePadding key={index}>
													<div className="flex bg-blue-100 dark:bg-slate-800">
														<IconButton style={{ backgroundColor: "transparent" }} onClick={() => setDocumentUploadModalVisible(true)} aria-label="comment">
															<CloudUploadOutlinedIcon />
														</IconButton>
														<Divider orientation="vertical" flexItem />
														<ListItemButton className="dark:text-white" onClick={() => clickFolder(folder, "doc")}>
															<ListItemText primary={folder.length <= 35 ? folder : `${folder.slice(0, 35)}...`} />
															{openDocFolders.includes(folder) ? <ExpandLess /> : <ExpandMore />}
														</ListItemButton>
													</div>
													<Collapse in={openDocFolders.includes(folder)} timeout="auto" unmountOnExit>
														<List component="div" disablePadding>
															{loanDocuments.map((doc, index) => {
																if (doc.documentBucketTitle === folder) {
																	return (
																		<div className="flex- flex-col w-full text-center bg-slate-200 dark:bg-transparent" key={index}>
																			<p className="pt-2 pb-2 dark:text-white">ATTACHED FILES</p>
																			<Divider />
																			<div className="h-20 flex justify-center bg-slate-200 dark:bg-slate-800">
																				<div className="break-all flex flex-col justify-center w-full m-2.5 h-16 px-2.5 py-1.5 bg-white rounded-md dark:bg-[#121212]">
																					<Link
																						onClick={() => handleDocumentPress(doc)}
																						tabIndex={0}
																						component="button"
																						underline="none"
																					>
																						{doc.documentName.length > 36 ? doc.documentName.slice(0, 36) + "..." : doc.documentName}
																					</Link>
																					<p className="text-sm text-slate-400">{new Date(doc.createdAt).toLocaleString()}</p>
																				</div>
																			</div>
																		</div>
																	)
																}
															})}
														</List>
													</Collapse>
												</List>
											)}
										</Collapse>
									</List>
								</div>
								<div className={`${width > 768 ? "w-48" : "w-full items-center justify-center flex flex-wrap"} border border-slate-500`}>
									{openDocFolders.length === 0 &&
										<p className="m-2.5 text-center dark:text-white">Select a folder to preview</p>
									}
									{openDocFolders[0] && loanDocuments.filter(doc => openDocFolders.includes(doc.documentBucketTitle)).length === 0 &&
										<p className="dark:text-white">No documents were found in this folder</p>
									}
									{loanDocuments.filter(doc => openDocFolders.includes(doc.documentBucketTitle)).map(doc =>
										<div key={doc.documentName} className="mx-auto">
											<div
												onClick={() => handleDocumentPress(doc)}
												className="mx-auto w-36 h-48 my-2.5 p-2 border border-slate-500 rounded-md hover:cursor-pointer hover:border-black hover:dark:border-white"
											>
												{renderDocPreview(doc)}
											</div>
											<div className="mb-4 font-bold text-center">
												<Tooltip title="Download">
													<IconButton
														size="large"
														edge="start"
														color="inherit"
														aria-label="Download"
														onClick={() => downloadPDF(doc)}
													>
														<DownloadIcon sx={{ color: themeMUI.palette.mode === 'dark' ? "#fff" : "#000" }} />
													</IconButton>
												</Tooltip>
												{doc.documentName && doc.documentName.length > 16
													? <Tooltip title={doc.documentName}>
														<span onClick={() => downloadPDF(doc)}>{doc.documentName.slice(0, 16) + "..."}</span>
													</Tooltip>
													: <span onClick={() => downloadPDF(doc)}>{doc.documentName}</span>
												}
											</div>
										</div>
									)}
								</div>

								{
									<div className="flex grow border border-slate-500 p-1.5 overflow-auto">
										<div
											style={{ height: `calc(100vh - 110px)` }}
											className={`${viewDocument?.base64Data ? "" : "items-center justify-center"} flex w-full flex-1`}
										>
											{!viewDocument?.base64Data ?
												<div className="flex flex-col">
													<p className="text-center dark:text-white">
														Please select a document from the list or &nbsp;
													</p>
													<Link
														onClick={() => setDocumentUploadModalVisible(true)}
														tabIndex={0}
														component="button"
														underline="none"
													>
														Upload a Document
													</Link>
												</div>
												: <div className="break-all flex flex-col w-full show-on-hover" style={{ height: `calc(100vh - 110px)` }}>
													{!isMobileDevice() && <div className="self-end mb-2"><Button text="Download" onClick={() => downloadPDF(viewDocument)} /></div>}
													<div style={{ height: `calc(100vh - 110px)` }}>{renderDocIframePreview(viewDocument)}</div>
													{isMobileDevice() && <div className="mb-4 font-bold text-center">
														<Tooltip title="Download">
															<IconButton
																size="large"
																edge="start"
																color="inherit"
																aria-label="Download"
																onClick={() => downloadPDF(viewDocument)}
															>
																<DownloadIcon sx={{ color: themeMUI.palette.mode === 'dark' ? "#fff" : "#000" }} />
															</IconButton>
														</Tooltip>
														<span onClick={() => downloadPDF(viewDocument)}>{viewDocument.documentName}</span>
													</div>}
												</div>
											}
										</div>
									</div>}
							</div>
						</div>
					}
				</div>
			</Page>
		</>
	)
}
