import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useVenti } from 'venti'
import Page from '../../components/Page'
import { Footer } from '../../components/Footer'
import { CardItem } from '../../components/CardItem'
import { Loading } from '../../components/Loading'
import { useAppContext } from '../../components/AppContext'
import { useAlert, useWindowSize } from '../../hooks'
import { getTheme } from '../../config'
import { ModalAppError } from '../../components/modals/ModalAppError'
import { ModalTutorial } from '../../components/modals/ModalTutorial'
import { ModalAddCoBorrower } from '../../components/modals/ModalAddCoBorrower'
import { ModalPleaseWait } from '../../components/modals/ModalPleaseWait'
import { ModalWizard } from '../../components/modals/ModalWizard'
import { ModalLoanAppSelector } from '../../components/modals/ModalLoanAppSelector'
import inviteCoBorrowerQuestions from '../../workflows/portal-invite-coborrower-questions.json'
import {
	getLoan,
	sendBorrowerInvitation,
	getLoanDocuments,
	getLoanTasks,
	Roles,
	getBorrowerApplications,
	getFormsBySiteConfig,
	getLoanDraft,
	deleteLoanDraft,
	updateLoanApplication
} from '../../services/client'
import { getErrorMessage } from '../../services/helper'
import { navigationLinking, getActionsByRole } from '../../services/navigation'
import userAvatar from 'assets/images/landingPages/user-avatar.png'
import { ModalNewUser } from '../../components/modals/ModalNewUser'
import Typography from '@mui/material/Typography'
import { routeTypes } from '../loanapp/LoanApp'
import { ModalYesNo } from '../../components/modals/ModalYesNo'
import { useAppContextActions } from '../../components/AppContext/AppHooks'

const theme = getTheme()
const middleScreen = 768

export default function Portal() {
	const { alert } = useAlert()
	const mountedRef = useRef(true)
	const navigate = useNavigate()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const { applyUser } = useAppContextActions()
	const { siteConfig, socialLinks, legalLinks, user } = state
	const [width] = useWindowSize()

	const loanId = ventiState.get(theme.storageKeys.loanId) || ''
	const appSubmitted = ventiState.get(theme.storageKeys.appSubmitted)
	const appPosting = ventiState.get(theme.storageKeys.appPosting)
	const inviteCoBorrower = ventiState.get(theme.storageKeys.inviteCoBorrower)
	const appFailed = ventiState.get(theme.storageKeys.appFailed)
	const failoverModalVisible = ventiState.get(theme.storageKeys.failoverModalVisible)
	const newUser = ventiState.get(theme.storageKeys.newUser)
	const ventiLoanData = ventiState.get(theme.storageKeys.loanData)
	const coBorrowerRoute = ventiState.get(theme.storageKeys.coBorrowerRoute)
	const isFirstLoad = !ventiState.get(theme.storageKeys.isFirstLoad)
	const loanOfficer = ventiState.get(theme.storageKeys.loanOfficer) || {}

	const [actions, setActions] = useState([])
	const [inviteData, setInviteData] = useState({})
	const [loanData, setLoanData] = useState(ventiLoanData || {})
	const [fetchingApplications, setFetchingApplications] = useState(false)
	const [fetchingApplication, setFetchingApplication] = useState(false)
	const [fetchingDisclosureInfo] = useState(false)
	const [loanApplications, setLoanApplications] = useState([])
	const [addCoBorrower, setAddCoBorrower] = useState(false)
	const [draftModalConfirmation, setDraftModalConfirmation] = useState({ open: false, text: "", leavePage: false, canBeSaved: false, isSave: false, event: null })
	const [loanIdModalVisible, setLoanIdModalVisible] = useState(false)
	const [consentQuestions, setConsentQuestions] = useState([])
	const [tutorialVisible, setTutorialVisible] = useState(false)
	const [modalWizard, setModalWizard] = useState({
		visible: inviteCoBorrower || false,
		data: {},
		title: ''
	})

	const [outstandingTaskCount, setOutstandingTaskCount] = useState(0)

	const disclosuresEnabled = siteConfig.enabledServices.disclosures && siteConfig.disclosuresSSOLoginRedirectUri && siteConfig.disclosuresSSOSiteID

	const _actionsFilter = action => {
		switch (action.id) {
			case 'Disclosures':
				return disclosuresEnabled
			case 'SpanishFullApp':
				return siteConfig.spanishFullApp
			case 'SpanishPrequal':
				return siteConfig.spanishPrequal
			case 'Calculator':
				return loanData.LoanLocked !== 'True'
			case 'Payment':
				return siteConfig.enabledServices.payments && !!siteConfig.servicerLenderId
			case 'QuickPricer':
				return siteConfig.enabledServices.pricingCalculator
			case 'LOLeadApp':
				return siteConfig.enabledServices.leadApp
			case 'AgentReferral':
				return siteConfig.enabledServices.leadApp
			default:
				return true
		}
	}

	const _getPageActions = (actions) => {
		if (window.innerWidth < middleScreen) {
			return actions.filter(item => item.id !== 'Workflows')
		}

		return actions
	}

	const sendInvitation = async data => {
		data.sourceURL = `https://${siteConfig.url}/verify/invite`
		data.siteConfigurationID = siteConfig.id

		if (loanId) {
			data.loanID = loanId

			closeInviteModal()

			try {
				await sendBorrowerInvitation(data)
				alert("The invitation was sent successfully")
				ventiState.set(theme.storageKeys.successMessage, 'The invitation was sent successfully.')
			} catch (err) {
				alert(getErrorMessage(err), { severity: "error" })
				ventiState.set(theme.storageKeys.errorMessage, 'An error occurred while attempting to send the invitation. Please try again later.')
			}
		} else {
			setInviteData(data)
		}
	}

	const handleDuplicateLoan = async () => {
		navigate(`/${navigationLinking.Apply}`)
	}

	const handleDisclosures = async () => {
		// setModals({ tasks: false, disclosures: false })

		if (user.disclosuresSSOLoginUrl) {
			return window.open(`${user.disclosuresSSOLoginUrl}`, '_blank', 'noopener,noreferrer')
		} else {
			navigate(`/${navigationLinking.Disclosures}`)
		}
	}

	const handleOpenPayment = () => {
		if (siteConfig.servicerLenderId || true)
			return window.open(`https://pay.finigree.com/loanPayment?lenderId=${siteConfig.servicerLenderId || 'ABSL'}&loanGuiId=${loanId.slice(1, -1)}`, '_blank')
	}

	const handleOpenLOConnect = () => {
		let url = 'https://encompassloconnect.com/pipeline'

		if (loanId) {
			const formId = '3ea1f6a1-afc2-4b01-b299-e69e9ba6262e'
			url += `/${loanId.slice(1, -1)}/standard-forms/${formId}`
		}
		window.open(url, '_blank', 'noopener,noreferrer')
	}

	const closeInviteModal = () => {
		setModalWizard({ data: {}, title: '', visible: false })
		ventiState.set(theme.storageKeys.inviteCoBorrower, false)
	}

	const handleAddCoBorrower = () => {
		if (loanData.loanLocked !== 'True' || loanData.LoanLocked === 'True')
			setAddCoBorrower(true)
		else ventiState.set(theme.storageKeys.errorObject, { message: "Cannot add a Co-Borrower to a Locked Loan File" })
	}

	useEffect(() => {
		if (coBorrowerRoute) handleAddCoBorrower()
	}, [coBorrowerRoute])

	useEffect(() => {
		if (!mountedRef.current) return null

		if (user?.isLoggedIn) {
			setActions(_getPageActions(getActionsByRole(user, siteConfig, loanId)))

			// // if the user only has one loan, set the loanId
			// if (!appSubmitted && user.loanIDs?.length === 1) {
			// 	ventiState.set(theme.storageKeys.loanId, user.loanIDs[0])
			// }
		} else {
			navigate(`/${navigationLinking.SignIn}`)
		}
	}, [user, loanId, siteConfig])

	useEffect(() => {
		if (loanId) {
			(async () => {
				try {
					const docs = await getLoanDocuments(loanId)
					ventiState.set(theme.storageKeys.loanDocs, docs)
				} catch (e) {
					alert(getErrorMessage(e), { severity: "error" })
					ventiState.set(theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error)
				}
			})()
		}
	}, [loanId])

	useEffect(() => {
		setActions(_getPageActions(getActionsByRole(user, siteConfig, loanId))) //.filter(_actionsFilter)))
		if (loanId && user.role !== Roles.admin) {
			(async () => {
				try {
					const tasks = await getLoanTasks(loanId)
					if (!mountedRef.current) return;

					ventiState.set(theme.storageKeys.loanTasks, tasks)

					const activeTasks = tasks.filter(task => task.taskStatus === 0)

					const tasksCount = activeTasks.length
					setOutstandingTaskCount(tasksCount)

					// const hasDisclosureTasks = activeTasks.some(task => 3 === task.taskType)
					// setHasDisclosures(hasDisclosureTasks)

				} catch (e) {
					alert(getErrorMessage(e), { severity: "error" })
					ventiState.set(theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error)
				}
			})()
		}
	}, [loanId])

	useEffect(() => {
		if (inviteData?.EmailAddress) {
			(async () => {
				try {
					await sendBorrowerInvitation(Object.assign({ loanID: loanId }, inviteData))
					alert("The invitation was sent successfully")
					ventiState.set(theme.storageKeys.inviteCoBorrower, false)
					setInviteData({})
				} catch (e) {
					alert(getErrorMessage(e), { severity: "error" })
					ventiState.set(theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error)
				}
			})()
		}
	}, [inviteData, loanId])

	useEffect(() => {
		if (loanId && user.role !== Roles.admin) {
			(async () => {
				try {
					!loanIdModalVisible && ventiState.set(theme.storageKeys.pleaseWaitMessage, 'Fetching loan information')
					setFetchingApplication(true)
					const data = await getLoan(loanId)
					const subjectPropertyAddress = []
					subjectPropertyAddress.push(data.SubjectPropertyAddressStreet || 'TBD')
					if (data.SubjectPropertyAddressUnitNumber) subjectPropertyAddress.push(`${data.SubjectPropertyAddressUnitType} ${data.SubjectPropertyAddressUnitNumber}`)
					if (data.SubjectPropertyAddressCity) subjectPropertyAddress.push(`${data.SubjectPropertyAddressCity}, ${data.SubjectPropertyAddressState} ${data.SubjectPropertyAddressZip}`)

					setLoanData({ ...data, subjectPropertyAddress  })
					ventiState.set(theme.storageKeys.loanOfficer, data.loanOfficerSiteConfiguration)
					ventiState.set(theme.storageKeys.loanData, data)
				} catch (e) {
					stopImpersonation()
					alert(getErrorMessage(e), { severity: "error" })
					ventiState.set(theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error)
				} finally {
					setFetchingApplication(false)
					ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
				}
			})()
		}
	}, [loanId])


	useEffect(() => {
		if (
			user?.isLoggedIn
			&& [Roles.loanOfficer, Roles.branchManager, Roles.borrower].includes(user.role)
		) {
			setFetchingApplications(true)
				; (async () => {
					try {
						if ([Roles.borrower].includes(user.role)) {
							const loanApps = await getBorrowerApplications()
							setLoanApplications(loanApps.applications)
							if (loanApps.applications.length && isFirstLoad) {
								ventiState.set(theme.storageKeys.isFirstLoad, true)
								if (loanApps.applications.length > 1) {
									if (!user.drafts.length) setLoanIdModalVisible(true)
								} else {
									ventiState.set(theme.storageKeys.loanId, loanApps.applications[0].loanID)
								}
							}
						}
						setFetchingApplications(false)
					} catch (e) {
						console.log(e)
						setFetchingApplications(false)
						ventiState.set(theme.storageKeys.errorMessage, 'There was a problem fetching your account details. Please try again later.')
					}
				})()
		}

		if (loanData && 'False' === loanData.EConsented) {
			(async () => {
				let questions
				const form = await getFormsBySiteConfig({
					siteConfigurationId: siteConfig.id,
					formType: routeTypes.indexOf('EConsent')
				})
				if (!!form.id && form.id !== '00000000-0000-0000-0000-000000000000')
					questions = form.formJSON
				else
					questions = JSON.parse(JSON.stringify(require('../../workflows/e-consent.json')))

				// remove credit auth from workflow if non-borrower
				if ('True' === loanData.CreditAuthed) {
					const qIndex = questions.findIndex(q => 'BorrowerCreditAuth' === q.fieldId)
					if (-1 !== qIndex) {
						questions[qIndex].qId = 'remove'
						if (questions[qIndex+1]?.qId)
							questions[qIndex-1].target = questions[qIndex+1].qId
						else questions[qIndex-1].target = ''
					}
				}

				// remove credit auth from workflow if non-borrower
				if ('Y' === loanData.BorrowerTCPAOptIn) {
					const qIndex = questions.findIndex(q => 'BorrowerTCPAOptIn' === q.fieldId)
					if (-1 !== qIndex) {
						questions[qIndex].qId = 'remove'
						if (questions[qIndex+1]?.qId)
							questions[qIndex-1].target = questions[qIndex+1].qId
						else questions[qIndex-1].target = ''
					}
				}

				setConsentQuestions(questions.filter(q => q.qId !== 'remove'))
			})()
		}
	}, [user, loanData])

	useEffect(() => {
		if (user.isLoggedIn && user.drafts?.length > 0 && !appPosting) {
			setDraftModalConfirmation(current => ({ ...current, open: true, text: "You have an application in progress. Would you like to continue where you left off?" }))
		}
	},[user, appPosting])

	const handleAppLink = async (action) => {
		if (action.route) navigate(`/${action.route}`, { state: action } || {})
		else if (action.action) {
			switch (action.action) {
				case 'invite': handleAddCoBorrower()
					break
				case 'duplicate': await handleDuplicateLoan()
					break
				case 'disclosures': await handleDisclosures()
					break
				case 'payment': handleOpenPayment()
					break
				case 'loConnect': handleOpenLOConnect()
					break
				case 'loanDetails': await handleOpenLoanDetails()
					break
				default:
					return
			}
		} else if (action.href) {
			window.open(action.href, '_blank', 'noopener,noreferrer')
		}
	}

	const handleOpenLoanDetails = async () => {
		await ventiState.set(theme.storageKeys.editingLoanApplicationId, loanId)
		navigate(`/${navigationLinking.LoanApplication}`)
	}

	const handleSendEConsent = async (data) => {
		const questions = consentQuestions
		try {
			setConsentQuestions([])
			ventiState.set(theme.storageKeys.pleaseWaitMessage, 'Please wait while we verify your account')
			await updateLoanApplication(loanId, { LoanID: loanId, SiteConfigurationID: siteConfig.id, ...data })
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			ventiState.set(theme.storageKeys.successObject, { message: `Authorization was successful. Access to your account has been enabled` })
			setTutorialVisible(true)
		} catch (err) {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			ventiState.set(theme.storageKeys.infoMessage, 'It looks like your loan is currently being worked on. Please check back again later.')
			setConsentQuestions(questions)
		}
	}

	const stopImpersonation = () => {
		ventiState.set(theme.storageKeys.loanId, null)
		ventiState.set(theme.storageKeys.loanData, null)
		ventiState.set(theme.storageKeys.loanTasks, null)
		ventiState.set(theme.storageKeys.loanDocs, null)

		setFetchingApplication(false)
		setLoanApplications([])
		setLoanData({})
		setActions(_getPageActions(getActionsByRole(user, siteConfig).filter(_actionsFilter)))
	}

	const closeTutorial = () => {
		setTutorialVisible(false)
		ventiState.set(theme.storageKeys.appSubmitted, false)
	}

	let slideLogoUri

	// eslint-disable-next-line default-case
	switch (true) {
		case user.role === Roles.loanOfficer:
			slideLogoUri = userAvatar
			break
		case [Roles.borrower, Roles.superAdmin].includes(user.role):
			slideLogoUri = loanOfficer.profilePhotoUrl || siteConfig.profilePhotoUrl || siteConfig.portalLogoUrl
	}

	const handleOpenDraft = async () => {
		try {
			setDraftModalConfirmation(current => ({ ...current, open: false }))
			const draft = await getLoanDraft(user.drafts[0].id)
			applyUser({ ...user, drafts: []})
			ventiState.set(theme.storageKeys.loanDraft, draft)
			navigate(`/${ navigationLinking[draft.routeName || 'Apply'] }`)
		} catch (e) {
			console.log(e)
			ventiState.set(theme.storageKeys.errorObject, { message: 'There was a problem retrieving the saved application.'})
		}
	}

	const handleRemoveDraft = async () => {
		try {
			setDraftModalConfirmation(current => ({ ...current, open: false }))
			await deleteLoanDraft(user.drafts[0].id)
			applyUser({ ...user, drafts: [] })
		} catch (e) {
			ventiState.set(theme.storageKeys.errorObject, { message: 'There was a problem removing the saved application.'})
		}
	}

	return (
		<>
			<Page page="app-portal" title="Portal" isFullWidth={true}>
				<div className="-z-1 xl:w-full xl:flex xl:items-baseline xl:flex-row xl:justify-center">
					<div className="w-full m-0">
						<div className="w-full m-0 h-full pb-7 flex flex-col md:flex-row">
							{width > 1023 && [Roles.borrower, Roles.superAdmin].includes(user.role) &&
								<img
									className="max-w-[200px] self-start lg:mt-6 lg:ml-6"
									src={slideLogoUri}
									alt="logo"
								/>
							}

							{/* Borrower */}
							{[Roles.borrower, Roles.superAdmin].includes(user.role) &&
								<>
									<div className="pt-5 md:pb-8 pl-6 pr-2 ml-2 lg:px-2">
										{(siteConfig.entityType === 3 || loanOfficer.name) && <p className="text-lg font-rubik font-bold mb-1.5 pb-2.5 dark:text-white">Your Loan Officer</p>}
										<p className="text-2xl font-rubik font-bold dark:text-white">{loanOfficer.name || siteConfig.name}</p>
										{!!(loanOfficer.nmlsid || siteConfig.nmlsid) && <p className="flex first-letter:font-rubik font-bold mb-5 text-slate-500 dark:text-white">NMLS #: {loanOfficer.nmlsid || siteConfig.nmlsid}</p>}
										<p className="flex font-rubik text-slate-500">mobile:&nbsp;
											<a
												target="_blank"
												href={`tel:${loanOfficer.phone || siteConfig.phone}`}
												rel="noopener noreferrer"
												className="hover:underline"
											>
												{loanOfficer.phone || siteConfig.phone}
											</a>
										</p>
										{!!siteConfig.tollFree &&
											<p className="flex font-rubik text-slate-500">office:&nbsp;
												<a
													target="_blank"
													href={`tel:${loanOfficer.tollFree || siteConfig.tollFree}`}
													rel="noopener noreferrer"
													className="hover:underline"
												>
													{loanOfficer.tollFree || siteConfig.tollFree}
												</a>
											</p>
										}
										{!!siteConfig.fax && <p className="flex font-rubik text-slate-500">fax: {siteConfig.fax}</p>}
										{!!(loanOfficer.email || siteConfig.email) &&
											<p className="flex font-rubik font-bold md:mb-5 text-slate-500">email:&nbsp;
												<a
													target="_blank"
													href={`mailto:${loanOfficer.email || siteConfig.email}`}
													rel="noopener noreferrer"
													className="hover:underline"
												>
													{loanOfficer.email || siteConfig.email}
												</a>
											</p>
										}
									</div>
								</>
							}

							{loanId && [Roles.loanOfficer, Roles.branchManager, Roles.settlementAgent, Roles.realtor].includes(user.role) &&
								<div className="pt-5 pb-8 pl-6 pr-5 break-words">
									{!loanData?.BorrowerEmail
										? <p className="self-baseline font-rubik text-lg mt-1 text-slate-600">Fetching loan data...</p>
										: <>
											<p className="self-baseline font-rubik text-lg mt-1 dark:text-white flex"><div className={`pr-1 text-slate-500`}>Loan Number:</div><div className={`font-bold`}>{loanData?.LoanNumber}</div></p>
											<p className="self-baseline font-rubik text-lg mt-1 dark:text-white flex"><div className={`pr-1 text-slate-500`}>Primary Borrower:</div><div className={`font-bold`}>{loanData?.BorrowerFirstName} {loanData?.BorrowerLastName}</div></p>
											<p className="self-baseline font-rubik text-lg mt-1 dark:text-white flex"><div className={`pr-1 text-slate-500`}>Contact Phone:</div><div className={`font-bold`}>{loanData?.BorrowerContactPhone}</div></p>
											<p className="self-baseline font-rubik text-lg mt-1 dark:text-white flex"><div className={`pr-1 text-slate-500`}>Email:</div><div className={`font-bold`}>{loanData?.BorrowerEmail}</div></p>
											<p className="self-baseline font-rubik text-lg mt-1 dark:text-white flex"><div className={`pr-1 text-slate-500`}>Address:</div><div className={`font-bold`}>{loanData?.subjectPropertyAddress?.join(', ')}</div></p>
										</>
									}

									<button onClick={stopImpersonation}>
										<p className="font-bold text-sm text-white mt-1 bg-red-500 w-30 p-1 text-center rounded-sm md:mt-2.5 md:w-36">{[Roles.loanOfficer, Roles.branchManager].includes(user.role) ? 'Stop Impersonating' : 'Close File'}</p>
									</button>
								</div>
							}

							{!loanId && [Roles.loanOfficer, Roles.branchManager].includes(user.role) &&
								<div className="border-b-2 pt-8 pb-8 pl-0 w-full">
									<p className="text-2xl font-rubik font-bold pl-8 dark:text-white">Loan Officer Dashboard</p>
								</div>
							}

							{!loanId && [Roles.realtor].includes(user.role) &&
								<div className="border-b-2 pt-8 pb-8 pl-0 w-full">
									<p className="text-2xl font-rubik font-bold pl-8 dark:text-white">Agent Dashboard</p>
								</div>
							}

							{/* ADMIN */}
							{user.role === Roles.admin &&
								<div className="border-b-2 pt-8 pb-8 pl-0 w-full">
									<p className="text-2xl font-rubik font-bold pl-8 dark:text-white">Admin Dashboard</p>
								</div>
							}

							{/*{!!loanId && [Roles.borrower, Roles.superAdmin].includes(user.role) &&*/}
							{/*	<div className="md:pt-4 pb-8 md:mt-2.5 pl-6 md:px-2">*/}
							{/*		<div className="flex flex-col justify-around">*/}
							{/*			<div className="mt-10">*/}
							{/*				{fetchingApplication*/}
							{/*					? <p className="text-slate-600 font-rubik">Fetching loan information...</p>*/}
							{/*					: <>*/}
							{/*						<p className="font-rubik dark:text-white">Loan Details</p>*/}
							{/*						<p className="font-bold font-rubik mb-5 text-slate-500">${loanData.LoanAmount} ({loanData.LoanPurpose})</p>*/}
							{/*						<p className="text-slate-500 font-rubik">{loanData.SubjectPropertyAddressCity}, {loanData.SubjectPropertyAddressState} {loanData.SubjectPropertyAddressZip}</p>*/}
							{/*					</>*/}
							{/*				}*/}
							{/*			</div>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*}*/}


							{[Roles.borrower].includes(user.role) && loanData?.LoanAmount && <div className="pt-5 ml-40 justify-self-end hidden md:block">
								<p className="text-lg font-rubik font-bold mb-1.5 pb-2.5 dark:text-white">Your Loan Summary</p>
								<div><span className="text-slate-400">Borrower Name:</span> {loanData.BorrowerFirstName} {loanData.BorrowerLastName}</div>
								{!!loanData.LoanPurpose && <div><span className="text-slate-400">Purpose:</span> {loanData.LoanPurpose}</div>}
								{loanData.LoanPurpose === 'Purchase' && <div><span className="text-slate-400">Purchase Price:</span> ${loanData.SubjectPropertySalePrice}</div>}
								{loanData.LoanPurpose === 'Purchase' && <div><span className="text-slate-400">Down Payment:</span> ${loanData.DownPaymentAmount || 0}</div>}
								<div><span className="text-slate-400">Loan Amount:</span> ${loanData.LoanAmount}</div>
								<div><span className="text-slate-400">Term:</span> {loanData.LoanTerm ? `${parseInt(loanData.LoanTerm, 10) / 12} Years` : 'Pending'}</div>
								<div><span className="text-slate-400">Type:</span> {loanData.CurrentLoanType}</div>
								<div><span className="text-slate-400">Status:</span> {loanData.LoanStatus}</div>
								{loanApplications.length > 1 && <Typography onClick={() => setLoanIdModalVisible(true)} variant="link" className="font-bold text-sm">work with a different application</Typography>}
							</div>}</div>
					</div>

				</div>

				<div className="border-b-2">
					<div className="flex items-center flex-col w-full">
						<div
							className="flex flex-row flex-wrap justify-center max-w-screen-xl mx-auto mb-10"
							style={{ minHeight: "300px" }}
						>
							{!fetchingApplication ? actions.map((action, index) => {
								if (siteConfig.enabledServices[action.id] !== false)
									return (
										<CardItem
											item={action}
											key={index}
											index={index}
											onClick={() => handleAppLink(action)}
											source={'Portal'}
											notificationCount={action.id === 'Tasks' ? outstandingTaskCount : 0}
										/>
									)
							})
								: <Loading size="small" />}
						</div>
						<div />
					</div>
				</div>
				<Footer
					siteConfig={siteConfig}
					socialLinks={socialLinks}
					legalLinks={legalLinks}
				/>
			</Page>

			<ModalWizard
				modal={modalWizard.visible}
				modalData={modalWizard.data}
				setModal={closeInviteModal}
				questions={inviteCoBorrowerQuestions}
				save={sendInvitation}
				finalButtonText={`Send Invitation`}
				modalTitle={modalWizard.title}
			/>

			<ModalYesNo
				modalConfirmation={draftModalConfirmation}
				modalConfirm={handleOpenDraft}
				modalDismiss={handleRemoveDraft}
				setModalConfirmation={setDraftModalConfirmation}
			/>

			<ModalLoanAppSelector
				visible={loanIdModalVisible && loanApplications.length > 1 && Roles.borrower === user.role && !consentQuestions.length}
				setVisible={setLoanIdModalVisible}
				siteConfig={siteConfig}
				loanApplications={loanApplications}
				setActiveLoanApp={selectedApp => ventiState.set(theme.storageKeys.loanId, selectedApp.loanID)}
			/>

			<ModalNewUser visible={newUser && user.isLoggedIn} />

			<ModalAddCoBorrower
				blacklist={[loanData.BorrowerEmail]}
				role={user?.role}
				visible={addCoBorrower && !appSubmitted && !appFailed}
				setVisible={setAddCoBorrower}
				siteConfig={siteConfig} />

			<ModalTutorial visible={tutorialVisible || (appSubmitted && !newUser && !loanApplications.length && isFirstLoad)}
				setVisible={closeTutorial} siteConfig={siteConfig} width={width} user={user} />

			<ModalPleaseWait visible={appPosting && (!appSubmitted || !!loanApplications.length) && !appFailed && !inviteCoBorrower && !newUser}
				logo={siteConfig.logoUrl}
				message={`Please be patient while we prepare your application. Do not close this window, refresh the page or hit the back button on your browser.`} />

			<ModalPleaseWait visible={fetchingApplication && [Roles.loanOfficer, Roles.branchManager].includes(user.role)}
				logo={siteConfig.logoUrl}
				message={`Fetching loan information`} />

			<ModalPleaseWait visible={!appSubmitted && !appFailed && fetchingApplications && !loanId && [Roles.borrower, Roles.superAdmin].includes(user.role)}
				logo={siteConfig.logoUrl}
				message={`Retrieving account information`} />

			<ModalPleaseWait visible={fetchingDisclosureInfo} logo={siteConfig.logoUrl}
				message={`Fetching Disclosure Package Information`} />

			<ModalAppError visible={!appSubmitted && failoverModalVisible}
				siteConfig={siteConfig} />

			{ consentQuestions.length > 0 && <ModalWizard
				modalTitle="Welcome to the Borrower Portal!"
				finalButtonText="Finish"
				questions={consentQuestions}
				siteConfig={siteConfig}
				modal={Roles.borrower === user.role}
				setModal={() => false}
				save={handleSendEConsent}
			/>}
		</>
	)
}
