import React, { useState, useEffect } from "react"
import { useVenti } from "venti"
import Page from "../../../components/Page"
import { getNotificationTemplates } from "../../../services/client"
import { getErrorMessage } from "../../../services/helper"
import { getTheme } from "../../../config"
import { useAlert } from "../../../hooks"
import { DataTable } from '../../../components/DataTable';

const theme = getTheme()

const headers = [
	{ id: "name", label: "Name" },
	{ id: "subject", label: "Subject" },
	{ id: "description", label: "Description" },
	{ id: "emailEnabled", label: "Email Enabled" },
	{ id: "textEnabled", label: "Text Enabled"},
	{ id: "isDefault", label: "System Default" }
]
const fields = ["name", "subject", "description", "emailEnabled", "textEnabled", "isDefault"]

export default function AdminNotificationTemplates() {
	const ventiState = useVenti()
	const { alert } = useAlert()
	const [tableData, setTableData] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	// const [modalConfirmation, setModalConfirmation] = useState({
	// 	showModal: false,
	// 	modalText: "Are you absolutely sure?",
	// 	onDeleteModal: null,
	// 	notificationData: []
	// })

	// const showDeleteModal = (data) => {
	//     setModalConfirmation({ ...modalConfirmation, showModal: true, notificationData: data, onDeleteModal: deleteNotification })
	// }

	// const deleteNotification = () => {
	// 	try {
	// 		let stateNotifications = [...tableData]
	// 		const updatedState = stateNotifications.filter((n) => n.name !== modalConfirmation.notificationData.name)
	// 		setTableData(updatedState)
	// 		if (Array.isArray(tableData) && tableData.length) {
	// 			state.unset("notifications")
	// 			state.set("notifications", [])
	// 		} else {
	// 			state.set("notifications", updatedState)
	// 		}
	// 		setModalConfirmation({ ...modalConfirmation, showModal: false })
	// 	} catch (e) {
	// 		alert(getErrorMessage(e), { severity: "error" })
	// 		ventiState.set(theme.storageKeys.errorMessage,
	// 			e?.data?.message || theme.api_messages.server_error)
	// 	}
	// }

	useEffect(() => {
		getNotificationTemplates()
			.then(data => {
				const overrides = data.filter(row => !row.isDefault).map(row => row.name)
				setTableData(data.filter(row => !row.isDefault || !overrides.includes(row.name)))
			})
			.catch((e) => {
				alert(getErrorMessage(e), { severity: "error" })
				ventiState.set(theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}, [])

	return (
		<Page page="app-notification-templates" title="Notification Templates" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">Notification Templates</p>
				<DataTable
					loading={isLoading}
					data={tableData}
					headers={headers}
					fields={fields}
					entity={"NotificationTemplate"}
					hideNew
				/>
			</div>
		</Page>
	)
}
