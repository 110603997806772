import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import { Button } from '../Button'
import { CardItem } from '../CardItem'
import TasksAnimatedIcon from 'assets/lordicons/RulesAnimated.gif'
import DisclosuresAnimatedIcon from 'assets/lordicons/SignatureAnimated.gif'
import DocumentsAnimatedIcon from 'assets/lordicons/DocumentsAnimated.gif'
import LoanDetailsAnimatedIcon from 'assets/lordicons/ListAnimated.gif'
import { useAppContext } from '../AppContext'
import { isDarkMode } from '../../services/helper'

const items = [
	{
		staticIcon: TasksAnimatedIcon,
		name: "Tasks",
		description: "View My Tasks"
	},
	{
		staticIcon: DisclosuresAnimatedIcon,
		name: "Esign Documents",
		description: "Sign Documents"
	},
	{
		staticIcon: DocumentsAnimatedIcon,
		name: "Documents",
		description: "Borrower Documents"
	},
	{
		staticIcon: LoanDetailsAnimatedIcon,
		name: "Loan Details",
		description: "Track Application"
	}
]

const content = [
	{
		title: "APPLICATION TASKS",
		content: "During your mortgage application process you can return to this section of the borrower portal to see if there are any outstanding tasks for your transaction."
	},
	{
		title: "LOAN DISCLOSURES",
		content: "Over the course of the mortgage application, you will use the disclosures button to access any documentation waiting for eSignature."
	},
	{
		title: "LOAN DOCUMENTS",
		content: "Use the documents button to review all loan documents you have provided. You can even return here after the transaction closes to get copies of your loan records."
	},
	{
		title: "LOAN DETAILS",
		content: "You can review the details of your current or past mortgage application from the loan details button."
	}
]

export const ModalTutorial = ({ visible = false, setVisible, width }) => {
	const { state } = useAppContext()
	const { siteConfig, user } = state

	const [page, setPage] = useState(0)
	const [loading, setLoading] = useState(false)

	const handleNext = () => {
		setLoading(true)
		setTimeout(() => setLoading(false), 3000)
		if (!user.disclosuresSSOLoginUrl && page === 0)
			setPage(page + 2)
		else setPage(page + 1)
	}

	return (
		<Dialog
			maxWidth="md"
			open={visible}
			aria-labelledby="responsive-dialog-title"
		>
			<div className="flex flex-col items-center px-6 py-8 md:px-10">
				<p className="font-semibold text-justify mb-5 md:mb-0 dark:text-white">Your application is being submitted. Please do not close this window or refresh the page. Click Next to continue.</p>
				<img src={isDarkMode() && siteConfig?.darkModeLogoUrl ? siteConfig.darkModeLogoUrl : siteConfig?.logoUrl} alt="Company Logo" className="w-64 sm:w-96 py-5 md:py-10" />
				<div
					style={{ width: width < 768 ? "100%" : 600 }}
					className="flex flex-col items-center md:flex-row md:rounded-3xl md:bg-zinc-300 dark:bg-black md:mx-10 md:h-60 md:mb-20"
				>
					<div className="md:-ml-12">
						<CardItem source={`ModalTutorial`} item={items[page]} />
					</div>
					<div className="
								flex flex-col
								w-full items-center
								mb-4 px-3
								md:rounded-3xl md:shadow-[0px_0px_10px_rgba(48,48,48,0.2)] md:shadow-gray-700/30 dark:shadow-gray-400/40 md:items-center
								md:bg-white dark:bg-[#121212] md:ml-48 md:z-10 md:mt-10 md:absolute md:w-96 md:h-60 md:px-6
						">
						<p className="font-black text-xl md:text-2xl my-5 text-center dark:text-white">{content[page].title}</p>
						<p className="hidden md:block font-semibold text-justify mb-5 md:mb-0 dark:text-white">{content[page].content}</p>
					</div>
				</div>
				<p className="md:hidden font-semibold text-justify mb-5 md:mb-0 dark:text-white">{content[page].content}</p>
				<div className={`${page === 0 ? "md:justify-end" : "md:justify-between"} flex flex-col md:flex-row  items-center w-full`}>
					{page > 0 &&
						<Button
							selected
							id="ModalConfirmCloseButton"
							text="Prev"
							onClick={() => setPage(page - 1)}
							style={width < 768 ? { width: "100%", marginBottom: 16 } : { width: 280 }}
							variant="contained"
						/>}
					{page < 3 &&
						<Button
							selected={!loading}
							id="ModalConfirmCloseButton"
							text="Next"
							onClick={handleNext}
							style={width < 768 ? { width: "100%", marginBottom: 16 } : { width: 280 }}
							variant="contained"
						/>}
					{page === 3 &&
						<Button
							selected={!loading}
							id="ModalConfirmCloseButton"
							text="Close"
							onClick={() => setVisible(false)}
							style={width < 768 ? { width: "100%", marginBottom: 16 } : { width: 280 }}
							variant="contained"
						/>}
				</div>
			</div>
		</Dialog>
	)
}
