import {useCallback, useEffect, useState} from "react";

const useLoanStatus = ({ loan }) => {
	const { milestones } = loan
	const [activeStep, setActiveStep] = useState(-1)
	const [lastCompletedMilestone, setLastCompletedMilestone] = useState(null)
	const [nextMilestone, setNextMilestone] = useState(null)

	useEffect(() => {
		let lastCompletedStep = -1;
		milestones.forEach((milestone, i) => {
			if(milestone.completed) {
				lastCompletedStep = i;
			}
			if(lastCompletedStep > -1) {
				setLastCompletedMilestone(milestones[lastCompletedStep])
				setActiveStep(lastCompletedStep)
			}
			const nextStep = lastCompletedStep + 1;
			setActiveStep(nextStep);
			if(nextStep < milestones.length) {
				setNextMilestone(milestones[nextStep]);
			} else {
				setNextMilestone(null);
			}
		})
	}, [milestones]);

	const getMonthlyPaymentChartData = useCallback(() => {
		const chartProps = {
			'MonthlyPaymentAmount': 'Monthly Payment',
			'MonthlyPaymentTaxes': 'Taxes',
			'MonthlyPaymentHOA': 'HOA',
			'MonthlyPaymentInsurance': 'Insurance',
			'MonthlyPaymentMortgageInsurance': 'Mortgage Insurance'
		}
		const data = [];
		Object.keys(chartProps).forEach(prop => {
			data.push(loan[prop] ? parseNumberString(loan[prop]) : 0)
		})

		return {
			labels: Object.values(chartProps),
			datasets: [
				{
					data,
					backgroundColor: [
						'#0073c7',
						'#FF4069',
						'#FF9F40',
						'#FFCD56',
						'#4BC0C0',
						'#36A2EB',
					],
					borderColor: [
						'#005288',
						'#dc2850',
						'#d07d2b',
						'#dcac3a',
						'#35a9a9',
						'#2887c7',
					],
					borderWidth: 1,
				},
			],
			totalMonthlyPayment: parseNumberString(loan.MonthlyPaymentPrincipalInterest)
		}
	}, [loan])

	const parseNumberString = (str) => {
		return str ? parseFloat(str.replace(/,/g, "")) : null
	}

	return {
		activeStep,
		lastCompletedMilestone,
		nextMilestone,
		getMonthlyPaymentChartData
	}
}

export default useLoanStatus;
