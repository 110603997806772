import { useEffect } from "react"
import { useAppContext } from "./AppContext"
// import ChatBot from "./ChatBot"

export default function Page({ children, page = "", title,
															 isFullWidth = false,
															 noDarkMode = false }) {
	const { state } = useAppContext()
	const { siteConfig } = state

	useEffect(() => {
		const titleName = siteConfig?.name || ""

			document.title = title
				? titleName
					? `${titleName} - ${title}`
					: title
				: titleName
	}, [title, siteConfig])


	return (
		<div className={`${noDarkMode ? "" : "dark:bg-[#121212]"}`}>
			<div className={`${page} ${isFullWidth ? "" : "max-w-screen-xl"} mx-auto ${noDarkMode ? "" : "dark:bg-[#121212]"}`}>
				{children}
				{/*{process.env.REACT_APP_ENV_NAME !== 'shared' && user?.isLoggedIn && ["LoanOfficer", "BranchManager"].includes(user.role) && <ChatBot />}*/}
			</div>
		</div>
	)
}
