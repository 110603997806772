import React, { useEffect, useState } from "react"
import { TextField } from "@mui/material"
import { useAppContext } from "../../../components/AppContext"
import { StateLicenses } from "../../../components/admin/StateLicenses"
import { applyMask } from "../../../services/utils"
import { TextEditor } from "../../../components/formControls/TextEditor"
import { states } from "../../../services/helper"

export const ProfileView = ({ formData, setFormData, width }) => {
	const { state } = useAppContext()
	const { siteConfig } = state
	const [inValidFields, setInValidFields] = useState([])

	useEffect(() => {
		const initialLicenses = JSON.parse(JSON.stringify(formData.licenses))
		setFormData({
			...formData,
			licenses: initialLicenses
		})
	}, [])

	const handleIntroductionChange = (newIntroduction) => {
		if (newIntroduction !== formData.introduction) {
			setFormData({
				...formData,
				introduction: newIntroduction,
			})
		}
	}

	const updatePOSSiteConfiguration = (field, value) => {
		if (field === "phone" || field === "tollFree") {
			value = applyMask(value, "phone")
		}

		setFormData(formData => ({ ...formData, [field]: value }))
	}

	const handleInputValid = (id) => {
		return inValidFields.includes(id)
	}

	const handleOnChangeText = (field, text, nativeID) => {
		updatePOSSiteConfiguration(field, text.trim().toLowerCase())
		if (text) {
			setInValidFields(inValidFields.filter(item => item !== nativeID))
		}
		else {
			setInValidFields([nativeID, ...inValidFields])
			handleInputValid(text)
		}
	}

	const handleStateLicensesSelect = (selectedState) => {
		let licenses = formData.licenses
		if (selectedState === 'all') licenses = states.map(state => state.value)
		else if (selectedState === 'none')licenses = []
		else {
			const isChecked = licenses.includes(selectedState)
			if (isChecked) licenses = formData.licenses.filter(l => l !== selectedState)
			else licenses.push(selectedState)
		}

		setFormData(formData => ({ ...formData, licenses }))
	}

	return (
		<div className="flex flex-col md:flex-row">
			<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminCorporateProfileSiteUrlInput`}
						placeholder={`Site Url (Ex: company.${siteConfig.url.replace(/^[^.]+\./g, "")})`}
						label="The Big POS Landing Page Url *"
						value={formData?.url}
						onChange={e => handleOnChangeText("url", e.target.value, "AdminAccountProfileSiteUrlInput")}
						error={handleInputValid("AdminAccountProfileSiteUrlInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminCorporateProfileNameInput`}
						placeholder="Name"
						value={formData?.name}
						label="Brand Name *"
						onChange={e => updatePOSSiteConfiguration("name", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminCorporateProfileEmailInput`}
						placeholder="Email Address"
						value={formData?.email}
						label="Email Address"
						onChange={e => updatePOSSiteConfiguration("email", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminCorporateProfileAddressInput`}
						placeholder="Street Address"
						value={formData?.address}
						label="Street Address"
						onChange={e => updatePOSSiteConfiguration("address", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminCorporateProfileUnitInput`}
						placeholder="Suite / Unit #"
						value={formData?.address2}
						label="Suite / Unit #"
						onChange={e => updatePOSSiteConfiguration("address2", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminCorporateProfileCityInput`}
						placeholder="City"
						value={formData?.city}
						label="City"
						onChange={e => updatePOSSiteConfiguration("city", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminCorporateProfileStateInput`}
						placeholder="State"
						value={formData?.state}
						label="State"
						onChange={e => updatePOSSiteConfiguration("state", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminCorporateProfileZipInput`}
						placeholder="ZIP"
						value={formData?.zip}
						label="ZIP"
						onChange={e => updatePOSSiteConfiguration("zip", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminCorporateProfileNMLSIDInput`}
						placeholder="NMLS ID"
						value={formData?.nmlsid?.toString() || ""}
						label="NMLS ID"
						onChange={e => updatePOSSiteConfiguration("nmlsid", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminCorporateProfilePhoneInput`}
						placeholder="Contact Phone"
						value={formData?.phone}
						label="Contact Phone"
						onChange={e => updatePOSSiteConfiguration("phone", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminCorporateProfileTollFreeInput`}
						placeholder="Office Phone"
						value={formData.tollFree}
						label="Office Phone"
						onChange={e => updatePOSSiteConfiguration("tollFree", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminCorporateProfileCompanyWebsiteUrlInput`}
						placeholder="Company Website URL"
						label="Company Website URL"
						value={formData?.companyUrl}
						onChange={e => handleOnChangeText("companyUrl", e.target.value, "AdminCorporateProfileCompanyWebsiteUrlInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminCorporateProfileContactUsUrlInput`}
						placeholder="Contact Us Url"
						label="Contact Us Url"
						value={formData?.contactUsUrl}
						onChange={e => handleOnChangeText("contactUsUrl", e.target.value, "AdminCorporateProfileContactUsUrlInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminCorporateProfilePrivacyPolicyUrlInput`}
						placeholder="Privacy Policy Url"
						label="Privacy Policy Url"
						value={formData?.privacyPolicyUrl}
						onChange={e => handleOnChangeText("privacyPolicyUrl", e.target.value, "AdminCorporateProfilePrivacyPolicyUrlInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminCorporateProfileTermsUrlInput`}
						placeholder="Terms of Use Url"
						label="Terms of Use Url"
						value={formData?.termsUrl}
						onChange={e => handleOnChangeText("termsUrl", e.target.value, "AdminCorporateProfileTermsUrlInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminCorporateProfileLicenseInfoUrlInput`}
						placeholder="License Info Url"
						label="License Info Url"
						value={formData?.licenseInfoUrl}
						onChange={e => handleOnChangeText("licenseInfoUrl", e.target.value, "AdminCorporateProfileLicenseInfoUrlInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
			</div>

			<div className="w-full md:ml-5 md:w-1/2 md:mx-2 md:mb-0 md:max-w-screen-sm">
				<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">Corporate Introduction</p>
				<TextEditor
					placeholder="Type something..."
					initialValue={formData?.introduction}
					onChange={handleIntroductionChange}
				/>
				<StateLicenses Licenses={formData?.licenses} handleStateLicensesSelect={handleStateLicensesSelect} />
			</div>
		</div>
	)
}
