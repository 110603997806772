import {useMemo} from "react";
import {get} from 'lodash';

const useFormField = ({name,errors}) => {
	const error = useMemo(() => {
		if(errors && !name) {
			console.warn("'name' is required when using the 'errors' prop");
		}
		return name && errors ? get(errors, name) : undefined;
	}, [name, errors]);

	return {
		error
	}
}

export default useFormField;
