import React, { useState } from "react"
import Dialog from "@mui/material/Dialog"
import { LoadingBtn } from "../Button/LoadingButton"
import ChangePassword from "../ChangePassword"
import { registerLoanOfficer } from "../../services/client"
import { isDarkMode } from '../../services/helper'

export const ModalSetLOUserPassword = ({ visible, setVisible, data, siteConfig }) => {
	const [loading, setLoading] = useState(false)
	const [userPassword, setUserPassword] = useState("")
	const [passwordConfirmed, setPasswordConfirmed] = useState("")
	const [errorMessage, setErrorMessage] = useState()
	const [disabledSignUpButton, setDisabledSignUpButton] = useState(true)

	const handlePasswordSubmit = async () => {
		setLoading(true)
		try {
			await registerLoanOfficer({
				SiteConfigurationID: data.SiteConfigurationID,
				AccountID: siteConfig.id,
				Email: data.Email,
				Password: userPassword,
				Phone: data.POSSiteConfiguration.Phone.replace(/\D/g, ""),
				FirstName: data.FirstName,
				LastName: data.LastName
			})
			setLoading(false)
			setVisible(false)
		} catch (e) {
			setErrorMessage((e && e.ModelState && e.ModelState[""][0]) || "Unable to create LO user account. Please contact your administrator.")
			setLoading(false)
		}
	}

	return (
		<Dialog
			open={visible}
			aria-labelledby="responsive-dialog-title"
			sx={{
				"& .MuiDialog-paper": {
					margin: 1
				},
			}}
		>
			<div className="flex flex-col items-center px-5 sm:px-10 py-8 sm:py-10">
				<img src={isDarkMode() && siteConfig?.darkModeLogoUrl ? siteConfig.darkModeLogoUrl : siteConfig?.logoUrl} alt="Company Logo" className="w-64 sm:w-96" />
				<p className="text-sm mt-5 sm:text-lg sm:mt-10">Set Loan Officer User Password</p>
				<p className="w-64 sm:w-96 text-sm sm:text-lg mt-2.5">It is important to choose a password that you will remember.</p>
				<ChangePassword
					passwordLabel={"Create password"}
					confirmPasswordLabel={"Confirm password"}
					userPassword={userPassword}
					setUserPassword={setUserPassword}
					setConfirmButton={setDisabledSignUpButton}
					confirmButtonEnabled={!disabledSignUpButton}
					passwordConfirmed={passwordConfirmed}
					setPasswordConfirmed={setPasswordConfirmed}
					start
				/>
				{errorMessage && <p className={[errorMessage ? "text-sm text-red-500" : "hidden"]}>{errorMessage}</p>}
				<div className="w-64 sm:w-96 mt-4">
					<LoadingBtn
						id={`CreateAccountButton`}
						disabled={!disabledSignUpButton}
						loading={loading}
						onClick={handlePasswordSubmit}
						text="Sign Up"
					/>
				</div>
			</div>
		</Dialog>
	)
}
