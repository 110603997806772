import moment from 'moment'
import { getTheme } from '../config'
import { isEmpty, forEach } from "lodash"

const theme = getTheme()

/**
 * @param {string} email
 * @returns {boolean} - return TRUE if valid
 */
export const isEmailValid = function (email) {
	if (!email) {
		return false
	}

	return !email.includes(' ') && /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email.toLowerCase())
}

/**
 * @param {string} phone
 * @returns {boolean} - return TRUE if valid
 */
export const isPhoneNumberValid = function (phone) {
	return /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone)
}

/**
 * Format time with am / pm
 * @param date
 * @returns {string|*}
 */
export const formatAMPM = (date) => {
	if (!date) return date
	let hours = date.getHours()
	let minutes = date.getMinutes().toString()
	let ampm = hours >= 12 ? 'pm' : 'am'
	hours = hours % 12
	hours = hours ? hours : 12
	minutes = minutes.padStart(2, '0')
	return hours + ':' + minutes + ' ' + ampm
}

/**
 * Format dates
 * @param date
 * @param includeTime
 * @returns {string|*}
 */
export const formatDate = (date, includeTime = true) => {
	if (!date) return date
	const jsDate = new Date(date)
	let ret = (jsDate.getMonth() + 1) + '/' + jsDate.getDate() + '/' + jsDate.getFullYear()
	if (includeTime) ret += ` ${formatAMPM(jsDate)}`
	return ret
}

export const getRoleName = role => {
	return theme.roles[role || 0]
}

export const getTaskCategoryName = category => {
	const categories = [
		"Application",
		"Conditions",
		"Closing",
		"PostClosing"
	]

	return categories[category || 0]
}

export const getTaskType = type => {
	const types = [
		'Document',
		'Field',
		'E-Signature',
		'Disclosure',
		'Electronic Consent',
		'VOA',
		'VOI',
		'VOE',
		'VOC',
		"Payment",
		"VOIE",
		"IRS ID.ME",
		"Verification"
	]

	return types[type]
}

export const a11yProps = index => {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	}
}

/**
 * @param {string} ssn
 * @returns {boolean} - return TRUE if valid
 */
export const isSSNValid = function (ssn) {
	return /^\d{3}-?\d{2}-?\d{4}$/.test(ssn)
}

/**
 * @param {string} dob
 * @returns {boolean} - return TRUE if valid
 */
export const isDOBValid = function (dob) {
	if (dob.length < 10) return false
	const m = moment(dob, "MM/DD/YYYY")
	const now = moment()
	const diff = now.diff(m, "seconds")
	return (dob.length === 10 && !isNaN(diff) && diff >= 567648000)
}

export const isDateValid = function (date) {
	if (date.length < 10) return false
	const m = moment(date, "MM/DD/YYYY")
	const now = moment()
	const diff = now.diff(m, "seconds")
	return (date.length === 10 && !isNaN(diff) && diff >= 0)
}

export const isFutureDateValid = function (date) {
	if (date.length < 10) return false
	const m = moment(date, "MM/DD/YYYY")
	const now = moment()
	const diff = now.diff(m, "seconds")
	return (date.length === 10 && !isNaN(diff) && diff < 0)
}

export const isValidYear = function (value) {
	return (value && !isNaN(value) && value >= 1500 && value <= new Date().getFullYear())
}

export const isValidYears = function (value) {
	return (value > -1 && !isNaN(value))
}

export const isValidMonths = function (value) {
	return (!isNaN(value) && value > -1 && value < 12)
}

export const isValidCurrency = function (value) {
	return (parseFloat(value) >= 0 && !isNaN(parseFloat(value)))
}
export const isValidDecimal = function (value) {
	return (value > -1 && !isNaN(value))
}

export const isPositiveInt = function (value) {
	return (value && !isNaN(value))
}

export function closestToZero(numbers) {
	if (!numbers.length) {
		return 0;
	}

	let closest = 0;

	for (let i = 0; i < numbers.length; i++) {
		if (closest === 0) {
			closest = numbers[i];
		} else if (numbers[i] > 0 && numbers[i] <= Math.abs(closest)) {
			closest = numbers[i];
		} else if (numbers[i] < 0 && - numbers[i] < Math.abs(closest)) {
			closest = numbers[i];
		}
	}

	return closest;
}

export const textIsValid = function (value, type = null) {
	let valid
	switch (type) {
		case 'phone':
			valid = isPhoneNumberValid(value)
			break
		case 'email':
			valid = isEmailValid(value)
			break
		case 'ssn':
			valid = isSSNValid(value)
			break
		case 'dob':
			valid = isDOBValid(value)
			break
		case 'date':
			valid = isDateValid(value)
			break
		case 'futureDate':
			valid = isFutureDateValid(value)
			break
		case 'year':
			valid = isValidYear(value)
			break
		case 'years':
			valid = isValidYears(value)
			break
		case 'months':
			valid = isValidMonths(value)
			break
		case 'currency':
			valid = isValidCurrency(value)
			break
		case 'decimal':
			valid = isValidDecimal(value)
			break
		case 'positive':
			valid = isPositiveInt(value)
			break
		case 'alpha':
			valid = !!value && value.length > 0
			break
		case 'numeric':
			valid = !isNaN(parseInt(value, 10))
			break
		case 'alphanumeric':
			valid = !!value && value.length > 0
			break
		case 'zipcode':
			valid = !isNaN(parseInt(value, 10)) && value.length === 5
			break
		case 'password':
			valid = theme.validation.passwordRegex.test(value)
			break;
		case null:
			valid = !!value && value.length > 0
			break
		case '':
			valid = !!value && value.length > 0
			break
		default:
			const exp = new RegExp(RegExp.escape(type))
			valid = exp.test(value)
	}

	return valid
}

export const stateKeys = [
	'BorrowerCurrentEmploymentAddressState',
	'BorrowerCurrentEmploymentAddressState0',
	'BorrowerCurrentEmploymentAddressState1',
	'BorrowerCurrentEmploymentAddressState2',
	'BorrowerHomeAddressState',
	'BorrowerMailingAddressState',
	'BorrowerRealEstatePropertyAddressState',
	'BorrowerRealEstatePropertyAddressState0',
	'BorrowerRealEstatePropertyAddressState1',
	'BorrowerRealEstatePropertyAddressState2',
	'SubjectPropertyAddressState'
]

export const states = [
	{ 'label': 'Alabama', 'value': 'AL' },
	{ 'label': 'Alaska', 'value': 'AK' },
	{ 'label': 'American Samoa', 'value': 'AS' },
	{ 'label': 'Arizona', 'value': 'AZ' },
	{ 'label': 'Arkansas', 'value': 'AR' },
	{ 'label': 'California', 'value': 'CA' },
	{ 'label': 'Colorado', 'value': 'CO' },
	{ 'label': 'Connecticut', 'value': 'CT' },
	{ 'label': 'Delaware', 'value': 'DE' },
	{ 'label': 'District of Columbia', 'value': 'DC' },
	{ 'label': 'States of Micronesia', 'value': 'FM' },
	{ 'label': 'Florida', 'value': 'FL' },
	{ 'label': 'Georgia', 'value': 'GA' },
	{ 'label': 'Guam', 'value': 'GU' },
	{ 'label': 'Hawaii', 'value': 'HI' },
	{ 'label': 'Idaho', 'value': 'ID' },
	{ 'label': 'Illinois', 'value': 'IL' },
	{ 'label': 'Indiana', 'value': 'IN' },
	{ 'label': 'Iowa', 'value': 'IA' },
	{ 'label': 'Kansas', 'value': 'KS' },
	{ 'label': 'Kentucky', 'value': 'KY' },
	{ 'label': 'Louisiana', 'value': 'LA' },
	{ 'label': 'Maine', 'value': 'ME' },
	{ 'label': 'Marshall Islands', 'value': 'MH' },
	{ 'label': 'Maryland', 'value': 'MD' },
	{ 'label': 'Massachusetts', 'value': 'MA' },
	{ 'label': 'Michigan', 'value': 'MI' },
	{ 'label': 'Minnesota', 'value': 'MN' },
	{ 'label': 'Mississippi', 'value': 'MS' },
	{ 'label': 'Missouri', 'value': 'MO' },
	{ 'label': 'Montana', 'value': 'MT' },
	{ 'label': 'Nebraska', 'value': 'NE' },
	{ 'label': 'Nevada', 'value': 'NV' },
	{ 'label': 'New Hampshire', 'value': 'NH' },
	{ 'label': 'New Jersey', 'value': 'NJ' },
	{ 'label': 'New Mexico', 'value': 'NM' },
	{ 'label': 'New York', 'value': 'NY' },
	{ 'label': 'North Carolina', 'value': 'NC' },
	{ 'label': 'North Dakota', 'value': 'ND' },
	{ 'label': 'Northern Mariana Islands', 'value': 'MP' },
	{ 'label': 'Ohio', 'value': 'OH' },
	{ 'label': 'Oklahoma', 'value': 'OK' },
	{ 'label': 'Oregon', 'value': 'OR' },
	{ 'label': 'Palau', 'value': 'PW' },
	{ 'label': 'Pennsylvania', 'value': 'PA' },
	{ 'label': 'Puerto Rico', 'value': 'PR' },
	{ 'label': 'Rhode Island', 'value': 'RI' },
	{ 'label': 'South Carolina', 'value': 'SC' },
	{ 'label': 'South Dakota', 'value': 'SD' },
	{ 'label': 'Tennessee', 'value': 'TN' },
	{ 'label': 'Texas', 'value': 'TX' },
	{ 'label': 'Utah', 'value': 'UT' },
	{ 'label': 'Vermont', 'value': 'VT' },
	{ 'label': 'Virgin Islands', 'value': 'VI' },
	{ 'label': 'Virginia', 'value': 'VA' },
	{ 'label': 'Washington', 'value': 'WA' },
	{ 'label': 'West Virginia', 'value': 'WV' },
	{ 'label': 'Wisconsin', 'value': 'WI' },
	{ 'label': 'Wyoming', 'value': 'WY' }
]

export const hasEmptyProperties = obj => {
	if (typeof obj !== 'object') return true

	const values = Object.values(obj)

	for (let i in values) {
		if (!values[i]) return true
	}

	return false
}

/**
 * @param {string} key
 */
export const getItemAsyncStorage = async (key) => {
	try {
		return localStorage.getItem(key)
	} catch (error) {
		console.log('ERROR: getItemAsyncStorage ', key, error)
		return null
	}
}

/**
 * @param {string} key
 * @param {string} data
 */
export const setItemAsyncStorage = (key, data) => {
	try {
		localStorage.setItem(key, data)
	} catch (error) {
		console.log('ERROR: setItemAsyncStorage ', key, data, error)
	}
}

export const getTaskStatusName = type => {
	const names = [
		'Incomplete',
		'Under Review',
		'Complete'
	]
	return names[type]
}

export const getTaskButtonTitle = task => {
	if (task.taskType === 0 && task.taskStatus === 1) return 'Upload Another'

	return theme.taskButtonTitles[task.taskType]
}

export const loanDisclosureText = `This information does not guarantee loan approval, nor is it an offer or commitment to make a loan at the above rates and terms. Interest rates and terms are subject to daily change without notice. Contact a Loan Officer at 888-342-0000 for current rates.`
export const loanPaymentNotice = "Please remember that we do not have all your information. The payment listed does not include taxes or insurance premiums. If your LTV > 80$, private mortgage insurance will be added to your monthly mortgage payment. Your actual payments will vary based on your individual situation and current rates."

export const defaultOptions = [
	{ label: ' - Choose a Mail Merge Field to Copy - ', value: '' }
]

export const siteConfigOptions = [
	{ label: 'Company Name', value: 'companyName' },
	{ label: 'Company Street Address', value: 'companyAddress' },
	{ label: 'Company Street Address Line 2', value: 'companyAddress2' },
	{ label: 'Company Address City', value: 'companyCity' },
	{ label: 'Company Address State', value: 'companyState' },
	{ label: 'Company Address Zip', value: 'companyZip' },
	{ label: 'Company Phone', value: 'companyPhone' },
	{ label: 'Company Fax', value: 'companyFax' },
	{ label: 'Company NMLSIS', value: 'companyNMLSID' },
	{ label: 'Company Website URL', value: 'companyUrl' },
	{ label: 'Disclosures URL', value: 'disclosuresUrl' },
	{ label: 'Portal Background Image', value: 'backgroundImageUrl' },
	{ label: 'Location Image', value: 'locationImageUrl' },
	{ label: 'Footer Disclaimer Text 1', value: 'footerDisclaimerText1' },
	{ label: 'Footer Disclaimer Text 2', value: 'footerDisclaimerText2' },
	{ label: 'Contact Us URL', value: 'contactUsUrl' },
	{ label: 'License Info URL', value: 'licenseInfoUrl' },
	{ label: 'Background Color', value: 'backgroundColor' },
	{ label: 'Terms and Conditions Url', value: 'termsUrl' },
	{ label: 'Twitter URL', value: 'twitterUrl' },
	{ label: 'Facebook URL', value: 'facebookUrl' },
	{ label: 'LinkedIn URL', value: 'linkedInUrl' },
	{ label: 'Instagram URL', value: 'instagramUrl' },
	{ label: 'Logo', value: 'logoUrl' },
	{ label: 'Portal Logo', value: 'portalLogoUrl' },
	{ label: 'Icon', value: 'iconUrl' },
	{ label: 'Banner', value: 'bannerUrl' },
	{ label: 'Secondary Banner', value: 'secondaryBannerUrl' },
	{ label: 'Profile Photo', value: 'profilePhotoUrl' },
	{ label: 'Primary Color', value: 'primaryColor' },
	{ label: 'Secondary Color', value: 'secondaryColor' },
	{ label: 'TextColor', value: 'textColor' },
	{ label: 'Name', value: 'name' },
	{ label: 'Address', value: 'address' },
	{ label: 'Address2', value: 'address2' },
	{ label: 'City', value: 'city' },
	{ label: 'State', value: 'state' },
	{ label: 'Zip', value: 'zip' },
	{ label: 'Phone', value: 'phone' },
	{ label: 'Fax', value: 'fax' },
	{ label: 'Office', value: 'tollFree' },
	{ label: 'NMLSID', value: 'nmlsid' },
	{ label: 'Introduction Title', value: 'introductionTitle' },
	{ label: 'Introduction', value: 'introduction', html: true },
].sort((a, b) => a.label > b.label ? 1 : -1)

export const loanFieldOptions = [
	{ label: 'Loan Number', value: 'LoanNumber' },
	{ label: 'Loan Purpose', value: 'LoanPurpose' },
	{ label: 'Loan Type', value: 'CurrentLoanType' },
	{ label: 'Loan Amount', value: 'LoanAmount' },
	{ label: 'Down Payment Amount', value: 'DownPaymentAmount' },
	{ label: 'Loan Term', value: 'LoanTerm' },
	{ label: 'LTV', value: 'LTV' },
	{ label: 'CLTV', value: 'CLTV' },
	{ label: 'Rate', value: 'Rate' },
	{ label: 'Texas Reviewed Additional Items', value: 'TXReviewedAdditionalItems' },
	{ label: 'Texas Required Additional Items', value: 'TXRequiredAdditionalItems' },
	{ label: 'Borrower First Name', value: 'BorrowerFirstName' },
	{ label: 'Borrower Last Name', value: 'BorrowerLastName' },
	{ label: 'Borrower Email', value: 'BorrowerEmail' },
	{ label: 'BorrowerContactPhone', value: 'SubjectPropertySalePrice' },
	{ label: 'Has a Co-Borrower', value: 'HasCoBorrower' },
	{ label: 'CoBorrower First Name', value: 'CoBorrowerFirstName' },
	{ label: 'CoBorrower Last Name', value: 'CoBorrowerLastName' },
	{ label: 'CoBorrower Email', value: 'CoBorrowerEmail' },
	{ label: 'Borrower Middle Initial', value: 'BorrowerLastName' },
	{ label: 'Borrower Suffix', value: 'BorrowerSuffix' },
	{ label: 'Referrer', value: 'Referrer' },
	{ label: 'Borrower Email', value: 'BorrowerEmail' },
	{ label: 'BorrowerContactPhone', value: 'BorrowerContactPhone' },
	{ label: 'Borrower DOB', value: 'BorrowerDOB' },
	{ label: 'Borrower Marital Status', value: 'BorrowerMaritalStatus' },
	{ label: 'Borrower Home Address Street', value: 'BorrowerHomeAddressStreet' },
	{ label: 'Borrower Home Address Unit Type', value: 'BorrowerHomeAddressUnitType' },
	{ label: 'Borrower Home Address Unit Number', value: 'BorrowerHomeAddressUnitNumber' },
	{ label: 'Borrower Home Address City', value: 'BorrowerHomeAddressCity' },
	{ label: 'Borrower Home Address State', value: 'BorrowerHomeAddressState' },
	{ label: 'Borrower Home Address Zip', value: 'BorrowerHomeAddressZip' },
	{ label: 'Borrower Mailing Address Street', value: 'BorrowerHomeAddressStreet' },
	{ label: 'Borrower Mailing Address Unit Type', value: 'BorrowerMailingAddressUnitType' },
	{ label: 'Borrower Mailing Address Unit Number', value: 'BorrowerHomeAddressUnitNumber' },
	{ label: 'Borrower Mailing Address City', value: 'BorrowerHomeAddressCity' },
	{ label: 'Borrower Mailing Address State', value: 'BorrowerHomeAddressState' },
	{ label: 'Borrower Mailing Address Zip', value: 'BorrowerHomeAddressZip' },
	{ label: 'Subject Property Address City', value: 'SubjectPropertyAddressCity' },
	{ label: 'Subject Property Address State', value: 'SubjectPropertyAddressState' },
	{ label: 'Subject Property Address Zip', value: 'SubjectPropertyAddressZip' },
	{ label: 'Subject Property Num Units', value: 'SubjectPropertyNumUnits' },
	{ label: 'Subject Property Year Built', value: 'SubjectPropertyYearBuilt' },
	{ label: 'Subject Property Use', value: 'SubjectPropertyUse' },
	{ label: 'Subject Property Residency Type', value: 'SubjectPropertyResidencyType' },
].sort((a, b) => a.label > b.label ? 1 : -1)

export const groupedOptions = [
	{
		label: '',
		options: defaultOptions,
	},
	{
		label: 'Website Configuration',
		options: siteConfigOptions,
	},
	{
		label: 'Loan Fields',
		options: loanFieldOptions,
	},
]

export const getErrorMessage = (error, type = "") => {
	if (error?.data?.message === "Exception Occurred" && type === "support") {
		return `There was a problem processing your request. Please contact Big POS customer support (${theme.support.email}).`
	}

	if (error?.data?.message === "Exception Occurred") {
		return `System Error. Please contact your administrator. ErrorId: ${error?.data?.logId}`
	}

	if (error?.data?.message) {
		return error?.data?.message
	}

	return theme.api_messages.server_error
}

export const getErrorCode = (error, type = "") => {
	if (error?.data?.errorCode) {
		return error?.data?.errorCode
	}

	return theme.api_messages.server_error
}

export const validateSiteConfigurationData = (data) => {
	delete data.entityID

	forEach(data, function (value, key) {
		if (isEmpty(data[key])) {
			delete data[key]
		}
	})
}

export const isDarkMode = () => {
	return localStorage.theme === 'dark'
}

export const isMobileDevice = () => {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const newShade = (hexColor, magnitude) => {
	hexColor = hexColor.replace(`#`, ``)

	if (hexColor.length === 6) {
		const decimalColor = parseInt(hexColor, 16)
		let r = (decimalColor >> 16) + magnitude
		r > 255 && (r = 255)
		r < 0 && (r = 0)
		let g = (decimalColor & 0x0000ff) + magnitude
		g > 255 && (g = 255)
		g < 0 && (g = 0)
		let b = ((decimalColor >> 8) & 0x00ff) + magnitude
		b > 255 && (b = 255)
		b < 0 && (b = 0)

		return `#${(g | (b << 8) | (r << 16)).toString(16)}`
	} else {
		return hexColor
	}
}

export const getEntityName = (entity, plural = false) => {
	let val = entity
	.replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
	.replace(/([a-z\d])([A-Z])/g, '$1 $2')
	.replace(/([a-zA-Z])(\d)/g, '$1 $2')
	.replace(/^./, function(str){ return str.toUpperCase(); })
	.trim()
	if (val === 'Corporate') val = 'Brand'
	if (!plural) return val

	if (val === 'Corporate') return 'Brands'
	if (val === 'Branch') return 'Branches'
		return `${val}s`
}

export const appChoices = ['Prequal', 'Borrower', 'CoBorrower', 'LO Borrower', 'LO CoBorrower', 'LO Spanish Borrower', 'Spanish Borrower', 'Spanish Prequal', 'Test']

export const questionTypes = [
	{ label: 'Information', value: 'information' },
	{ label: 'Choice', value: 'choice' },
	{ label: 'Boolean',	value: 'boolean' },
	{ label: 'Text Input', value: 'text' },
	{ label: 'SSN Input', value: 'ssn' },
	{ label: 'Email Input', value: 'email' },
	{ label: 'Phone Input', value: 'phone' },
	{ label: 'DOB Input', value: 'dob' },
	{ label: 'Currency Input', value: 'text' },
	{ label: 'Picker', value: 'picker' },
	{ label: 'Range Input', value: 'range' },
	{ label: 'State Picker', value: 'state' },
	{ label: 'Licensed State', value: 'licensedState' },
	{ label: 'Input Group', value: 'group' },
	{ label: 'Places Lookup', value: 'place' },
	{ label: 'Address Group', value: 'addressGroup' },
	{ label: 'Contact Group', value: 'contactInfoGroup' },
	{ label: 'Declaration', value: 'declaration' },
	{ label: 'Authorization', value: 'authorization' },
	{ label: 'Upload', value: 'upload' },
	{ label: 'Photo', value: 'photo' },
]

export const documentTemplateTypes = [
	{ label: 'Prequal Letter', value: 'PrequalLetter', active: true },
	{ label: 'Preapproval Letter', value: 'PreapprovalLetter', active: true },
	{ label: 'E-Consent', value: 'EConsent', active: true },
	{ label: 'Credit Auth', value: 'CreditAuthorization', active: false },
	{ label: 'Text Opt In', value: 'TextOptIn', active: false },
]

export const borrowerTypes = [
	'Borrower',
	'CoBorrower',
	'Non-Borrowing Spouse'
]

export const formTypes = [
	'Prequal',
	'Full App',
	'Co-Borrower Invite Full App',
	'LO Borrower Full App',
	'LO Co-Borrower Full App',
	'Co-Borrower Invite Prequal',
	'Spanish Prequal',
	'Spanish Full App',
	'LO Borrower Prequal',
	'LO Co-Borrower Prequal',
	'LO Lead App',
	'Super-Short App',
	'Co-Borrower Prequal (by Borrower)',
	'Co-Borrower Full App (by Borrower)',
	'Open House',
	'Agent Client Buyer',
	'Agent Client Seller',
	'Agent Client Lifestyle',
	'Walmart',
	'Open House',
	'EConsent & Credit Auth'
]

export const validationOptions = [
	{ label: 'Email Address', value: 'email', message: 'Please enter a valid email address' },
	{ label: 'Phone Number', value: 'phone', message: 'Please enter a valid phone number', },
	{ label: 'Past Date', value: 'date', message: 'Please enter a valid past date in mm/dd/yyyy format' },
	{ label: 'Future Date', value: 'futureDate', message: 'Please enter a valid future date in mm/dd/yyyy format' },
	{ label: 'Birthdate', value: 'dob', message: `Please enter a valid birthdate before ${moment().subtract(18, "years").toDate().toLocaleDateString()}` },
	{ label: 'Social Security Number', value: 'ssn', message: 'Please enter a valid Social Security Number' },
	{ label: 'Currency Integer ($1,250)', value: 'Please enter a valid dollar amount' },
	{ label: 'Currency Float ($1,250.00)', value: 'Please enter a valid dollar amount' },
	{ label: 'Letters Only', value: 'alpha', message: 'Please enter a value' },
	{ label: 'Numbers Only', value: 'numeric', message: 'Please enter a number' },
	{ label: 'Letters and Numbers', value: 'alphanumeric', message: 'Please enter a value' },
	{ label: 'Year (YYYY)', value: 'year', message: 'Please enter a valid year' },
	{ label: 'Years', value: 'years', message: 'Please enter a valid number of years' },
	{ label: 'Months', value: 'months', message: 'Please enter a valid number of months' },
	{ label: 'Positive Number', value: 'positive', message: 'Please enter a value' },
	{ label: 'Zipcode', value: 'zipcode', message: 'Please enter a valid zipcode' },
	{ label: 'Password', value: 'password', message: 'Password does not meet the criterion' },
]

export const branchTypes = [
	{ label: 'Mortgage', value: 'Mortgage' },
	{ label: 'Real Estate', value: 'RealEstate' },
]

export function getValidationMessage(type) {
	if (type === 'required') return 'Please enter a value'
	return validationOptions.find(v => v.value === type)?.message
}
