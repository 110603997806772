import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faXTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"

import userAvatar from "assets/images/landingPages/user-avatar.png"
import { getTitleByEntityType } from '../../services/utils'

export const LoanOfficerCenterView = ({ siteConfig = {}, user = {} }) => (
	<div className="flex flex-col items-center bg-slate-100 lg:rounded-3xl md:py-12 px-2 md:px-12 md:mb-10 mb-0 dark:bg-slate-800 md:rounded-none">
		<div className="flex flex-col items-center w-full">
			<p className="text-center font-rubik font-bold mt-5 mb-5 md:mt-0 md:mb-6 text-2xl sm:text-3xl dark:text-white">
				Your {siteConfig.userTitle || getTitleByEntityType(siteConfig?.entityType)}
			</p>
			<img
				className="w-48 h-48 rounded-full object-cover"
				alt="Avatar"
				src={siteConfig?.profilePhotoUrl ? siteConfig.profilePhotoUrl : userAvatar}
			/>
			<p className="font-rubik text-center text-2xl sm:text-3xl mb-5 mt-5">
				{siteConfig?.name}
			</p>
			<div className="flex justify-center flex-row">
				{siteConfig?.facebookUrl && <a
					className="flex justify-center items-center w-8 h-8 mr-4"
					target="_blank"
					href={siteConfig.facebookUrl}
					rel="noopener noreferrer"
				>
					<FontAwesomeIcon icon={faFacebookF} />
				</a>}
				{siteConfig?.instagramUrl && <a
					className="flex justify-center items-center w-8 h-8 mr-4"
					target="_blank"
					href={siteConfig.instagramUrl}
					rel="noopener noreferrer"
				>
					<FontAwesomeIcon icon={faInstagram} />
				</a>}
				{siteConfig?.twitterUrl && <a
					className="flex justify-center items-center w-8 h-8 mr-4"
					target="_blank"
					href={siteConfig.twitterUrl}
					rel="noopener noreferrer"
				>
					<FontAwesomeIcon icon={faXTwitter} />
				</a>}
				{siteConfig?.linkedInUrl && <a
					className="flex justify-center items-center w-8 h-8"
					target="_blank"
					href={siteConfig.linkedInUrl}
					rel="noopener noreferrer"
				>
					<FontAwesomeIcon icon={faLinkedin} />
				</a>}
			</div>
			<div className="flex items-center">
				<div className="flex justify-center flex-wrap mt-5 mb-1 flex-col md:flex-row">
					{!!siteConfig?.phone && <div className="flex mr-0 md:mr-5 mb-2.5 md:mb-0">
						<p className="font-rubik text-md">mobile:&nbsp;&nbsp;</p>
						<a
							target="_blank"
							href={`tel:${siteConfig?.phone}`}
							rel="noopener noreferrer"
							className="hover:underline"
						>
							<p className="text-slate-500 font-rubik font-light text-md dark:text-slate-400">{siteConfig?.phone}</p>
						</a>
					</div>}
					{!!siteConfig?.fax &&
						<div className="flex mr-0 md:mr-5 mb-2.5 md:mb-0">
							<p className="font-rubik text-md">fax:&nbsp;&nbsp;</p>
							<p className="text-slate-500 font-rubik font-light text-md dark:text-slate-400">{siteConfig.fax}</p>
						</div>
					}
					{!!siteConfig?.tollFree &&
						<div className="flex mr-0 md:mr-5 mb-2.5 md:mb-0">
							<p className="font-rubik text-md">office:&nbsp;&nbsp;</p>
							<a
								target="_blank"
								href={`tel:${siteConfig.tollFree}`}
								rel="noopener noreferrer"
								className="hover:underline"
							>
								<p className="text-slate-500 font-rubik font-light text-md dark:text-slate-400">{siteConfig.tollFree}</p>
							</a>
						</div>
					}
					{!!siteConfig?.email &&
						<div className="flex mr-0 md:mr-5 mb-2.5 md:mb-0 text-center">
							<p className="font-rubik text-md">email:&nbsp;&nbsp;</p>
							<a
								target="_blank"
								href={`mailto:${siteConfig.email}`}
								rel="noopener noreferrer"
								className="hover:underline"
							>
								<p className="text-slate-500 font-rubik font-light text-md dark:text-slate-400">{siteConfig.email}</p>
							</a>
						</div>
					}
					{!!siteConfig?.nmlsid && <div className="flex mr-0 md:mr-5 mb-2.5 md:mb-0 text-center">
						<p className="font-rubik text-md">NMLS #:&nbsp;&nbsp;</p>
						<p className="text-slate-500 font-rubik font-light text-md dark:text-slate-400">{siteConfig?.nmlsid}</p>
					</div>}
				</div>
			</div>
		</div>
	</div>
)
