import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import DoneIcon from '@mui/icons-material/Done'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { navigationLinking } from "../../../services/navigation"
import { useAppContext } from "../../../components/AppContext"
import { HeaderOpen } from "../../../components/HeaderOpen"
import { Footer } from "../../../components/Footer"
import Page from "../../../components/Page"
import RateAndPayment from './RateAndPayment'
import Fees from '../../protected/Fees'
import Amortization from './Amortization'

export default function PricingDetails() {
	const { state } = useAppContext()
	const navigate = useNavigate()
	const [value, setValue] = useState('0')

	const { siteConfig, user, socialLinks, legalLinks } = state
	const highlightItems = ["Most popular loan", "Fixed payment life of loan", "Considered least risky", "Good if you stay long term"]
	const routes = [
		{ key: 'rateAndPayment', title: 'RATE & PAYMENT', index: '0' },
		{ key: 'fees', title: 'FEES', index: '1' },
		// { key: 'amortization', title: 'AMORTIZATION', index: '2' },
	]

	const TabPanel = ({ value, route }) => {
		if (route.key === 'rateAndPayment' && value === route.index) {
			return <RateAndPayment />
		}
		if (route.key === 'fees' && value === route.index) {
			return <Fees />
		}
		if (route.key === 'amortization' && value === route.index) {
			return <Amortization />
		}
		return null
	}

	useEffect(() => {
		if (siteConfig !== null) {
			if (siteConfig.entityType === 0) navigate(`/${navigationLinking.AdminDashboard}`)
		}
	}, [siteConfig])

	const handleChange = (event, newValue) => {
		setValue(newValue)
	};

	return (
		<Page page="app-landing" noDarkMode>
			<HeaderOpen siteConfig={siteConfig} user={user} />

			<div className="flex flex-col justify-center pl-5 pr-5 pb-10">
				<div className="w-full mt-10 pb-2.5 flex items-center border-b flex-col md:flex-row">
					<p className="text-3xl font-bold text-gray-500 text-center md:text-left">Loan Details & Summary</p>
				</div>
				<div className="flex flex-col md:flex-row py-5">
					<div className="md:max-w-sm md:mr-10">
						<p className="text-xl md:text-2xl font-bold font-rubik text-gray-500">30 Year Fixed Conventional</p>
						<p className="text-lg font-bold mt-4 text-gray-300">Your interest rate and monthly payment will stay fixed for 30 years and never change.</p>
					</div>
					<div className="md:max-w-xs mt-5 md:mt-0">
						<div className="pt-4 pr-4 pb-5 pl-4 border rounded border-gray-500">
							<p className="text-2xl font-bold font-rubik text-gray-500">Loan Highlights</p>
							{highlightItems.map((item, index) => (
								<div className="flex flex-row items-center mt-2.5" key={index}>
									<DoneIcon color="success" fontSize="small" />
									<p className="pl-1 font-bold text-gray-300">{item}</p>
								</div>
							))}
						</div>
					</div>
				</div>
				<Box sx={{ width: '100%', typography: 'body1' }}>
					<TabContext sc={{ minHeight: 12.5 }} value={value}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList variant="scrollable" onChange={handleChange}>
								{routes.map(route => <Tab label={route.title} key={route.key} value={route.index} />)}
							</TabList>
						</Box>
						{routes.map((route, index) => {
							return <TabPanel key={index} value={value} route={route} />
						})}
					</TabContext>
				</Box>
			</div>

			<Footer
				siteConfig={siteConfig}
				socialLinks={socialLinks}
				legalLinks={legalLinks}
			/>
		</Page>
	)
}
