import React, { useEffect, useRef, useState } from "react"
import { isEqual } from "lodash"
import { useVenti } from "venti"
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import PublishSubscribe from "publish-subscribe-js"
import { Tabs, Tab, Card, CardContent } from "@mui/material"
import { ProfileView } from "./Profile"
import Page from "../../../components/Page"
import { LoadingBtn } from "../../../components/Button"
import { ModalYesNo } from "../../../components/modals/ModalYesNo"
import { Loading } from "../../../components/Loading"
import { siteConfigurationModel } from "../../../services/utils"
import { getDevice, updateDevice } from "../../../services/client"
import { a11yProps, getErrorMessage } from "../../../services/helper"
import { getTheme } from "../../../config"
import { useWindowSize, useAlert } from "../../../hooks"
import { Apps } from "./Apps"
import { RemoteInfo } from "./RemoteInfo"

const theme = getTheme()

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div className="mt-5">{children}</div>
			)}
		</div>
	)
}

export default function AdminDeviceEdit({ navigation }) {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const [width] = useWindowSize()
	const themeMUI = useTheme()

	const deviceId = ventiState.get(theme.storageKeys.editingDeviceId)

	const [tab, setTab] = useState(0)

	const [modalConfirmation, setModalConfirmation] = useState({ open: false, text: "", leavePage: false, canBeSaved: false, isSave: false, event: null })
	const [loading, setLoading] = useState(false)
	const [initialState, setInitialState] = useState({ ...siteConfigurationModel, entityType: 1 })
	const [formData, setFormData] = useState({ ...siteConfigurationModel, entityType: 1 })
	const [canBeSubmitted, setCanBeSubmitted] = useState(false)
	const initialRender = useRef(true)

	useEffect(() => {
		const canBeSubmitted = !isEqual(initialState, formData)

		setCanBeSubmitted(canBeSubmitted)

		if (initialRender.current) {
			initialRender.current = false
		}
	}, [formData, initialState])

	useEffect(() => {
		if (deviceId) {
			getDevice(deviceId)
				.then(data => {
					setFormData(data)
					setInitialState(data)
				})
				.catch(e => {
					alert(getErrorMessage(e), { severity: "error" })
					ventiState.set(theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error)
				})
		}
	}, [deviceId])

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const saveDevice = async () => {
		setLoading(true)

		try {
			await updateDevice(formData)
			PublishSubscribe.publish("UPDATE_ADMIN_COMPONENT_STATE")
			alert(`Device "${formData.name}" successfully updated`)
			setLoading(false)
		} catch (e) {
			setLoading(false)
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const modalConfirm = async (modalConfirmation) => {
		setLoading(true)
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({ open: false, text: "", leavePage: false })
	}

	return (
		<Page isFullWidth={true} title={`Edit Device`}>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
			/>
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">Edit Device</p>
				{deviceId && !initialState.id
					? <div className="flex justify-center dark:bg-[#121212]" style={{ height: `calc(100vh - 216px)` }}><Loading size="small" /></div>
					: (
						<>
							<div className="flex flex-wrap justify-end my-5">
								<LoadingBtn
									id={`AdminDeviceEditSaveButton`}
									loading={loading}
									text={`Update Device`}
									onClick={saveDevice}
									fullWidth={false}
								/>
							</div>
							<Tabs
								variant="scrollable"
								scrollButtons="auto"
								value={tab}
								onChange={handleTabChange}
								aria-label="Tabs"
							>
								<Tab label="Device Profile" {...a11yProps(0)} />
								<Tab label="Apps" {...a11yProps(1)} />
								<Tab label="Remote" {...a11yProps(2)} />
							</Tabs>
							<SwipeableViews
								axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
								index={tab}
								onChangeIndex={handleChangeIndex}
							>
								<TabPanel value={tab} index={0} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<ProfileView formData={formData} setFormData={setFormData} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={1} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<Apps formData={formData} setFormData={setFormData} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={2} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<RemoteInfo formData={formData} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
							</SwipeableViews>
						</>
					)
				}
			</div>
		</Page>
	)
}
