import React, {useMemo} from "react";
import {
	FormControl,
	InputLabel,
	Select as MuiSelect,
	FormHelperText,
	MenuItem
} from '@mui/material';
import {Controller} from "react-hook-form";
import useFormField from "../../hooks/useFormField";

const Select = ({ variant = 'standard', name, selectProps, labelProps, label, placeholder, children, errors, control, defaultValue, displayEmpty, menuItems, ...rest } ) => {
	const {error} = useFormField({name, errors});
	const EmptyMenuItem = useMemo(() => displayEmpty ? <MenuItem value=""><em>{placeholder || 'Select value'}</em></MenuItem> : undefined, [displayEmpty, placeholder]);
	const MenuItems = useMemo(() =>
		menuItems ? menuItems.map((item, i) => <MenuItem key={`${name}-menu-item-${i}`} value={typeof item === 'object' ? item.value : item}>{typeof item === 'object' ? item.label : item}</MenuItem>) : undefined, [menuItems]);
	return (
		<FormControl {...rest}
		             variant={variant}
		             error={!!error}>
			<InputLabel {...labelProps}>{label}</InputLabel>
			{control &&
				<Controller name={name}
				            control={control}
				            defaultValue={defaultValue}
				            render={({ field }) => (
											<MuiSelect {...selectProps} {...field}>
					              {EmptyMenuItem}
												{MenuItems}
												{children}
											</MuiSelect>
				            )} />
			}
			{!control &&
				<MuiSelect name={name}
				           defaultValue={defaultValue}
				           {...selectProps}>
					{EmptyMenuItem}
					{MenuItems}
					{children}
				</MuiSelect>
			}
			{error &&
				<FormHelperText>{error.message}</FormHelperText>
			}
		</FormControl>
	)
}

export default Select
