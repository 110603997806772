import React from "react"
import MaintenanceImg from "assets/images/maintenance.jpg"
import Page from "../../components/Page"
import { useAppContext } from "../../components/AppContext"
import { isDarkMode } from '../../services/helper'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Maintenance() {
	const { state } = useAppContext()
	const { siteConfig, socialLinks } = state

	return (
		<Page page="app-not-found">
			<div className="flex flex-col items-center justify-center h-screen">
				{siteConfig && siteConfig.logoUrl ?
					<img
						className="w-60 sm:w-80 mb-8"
						src={isDarkMode() && siteConfig?.darkModeLogoUrl ? siteConfig.darkModeLogoUrl : siteConfig?.logoUrl}
						alt="Logo"
					/> :
					<img
						className="w-60 sm:w-80 mb-8"
						src={MaintenanceImg}
						alt="Logo"
					/>
				}
				<div className="p-5">
					<p className="text-center text-2xl font-bold font-rubik">We'll Be Right Back!</p>
					<p className="text-slate-600 pt-2.5 my-2.5 mx-auto text-center font-rubik">Sorry, we are down for maintenance but will be back in no time!</p>
					{siteConfig && siteConfig.email &&
						<div className="flex flex-col sm:flex-row justify-center items-center sm:items-baseline">
							<p className="flex sm:my-2.5 text-center font-rubik text-slate-600">
								If you have any questions feel free to&nbsp;
							</p>
							<a
								target="_blank"
								href={`mailto:${siteConfig.email}`}
								rel="noopener noreferrer"
								className="hover:none"
							>
								<p className="text-blue-600 font-rubik">contact us.</p>
							</a>
						</div>
					}
				</div>
				<div className="flex flex-wrap px-5 justify-center">
					{socialLinks && socialLinks.map((link, index) => (
						<a
							target="_blank"
							rel="noreferrer"
							href={link.link}
							className="flex items-center mb-4 hover:none"
							key={`${link.linkItem + index}`}
						>
							<div className="flex items-center w-5 h-5">
								<FontAwesomeIcon icon={link.icon} key={`${link.icon + index}`} className="dark:text-slate-500" />
							</div>
							<p className="text-base font-rubik text-gray-600 mr-4">{link.linkItem}</p>
						</a>
					))}
				</div>
			</div>
		</Page>
	)
}
