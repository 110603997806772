import { noop } from "lodash"
import { createContext, useContext } from "react"

export const AlertsDispatchContext = createContext({
	dispatch: noop
})

export function useAlertsDispatch() {
	return useContext(AlertsDispatchContext)
}

export const AlertsStateContext = createContext({
	state: { alerts: [] }
})

export function useAlertsState() {
	return useContext(AlertsStateContext)
}
