import React, { useEffect, useState } from "react"
import { useVenti } from "venti"
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import {
	Tabs,
	Tab,
	Card,
	CardContent,
	Button,
	FormControl,
	InputLabel,
	TextField,
	Select,
	MenuItem,
	Autocomplete,
	FormControlLabel,
	Typography,
	Switch
} from '@mui/material';
import Page from "../../../components/Page"
import { useAppContext } from "../../../components/AppContext"
import { getTheme } from "../../../config"
import {
	getLoanOfficers,
	getDocumentTemplate,
	saveDocumentTemplate,
	generateDocumentFromTemplate,
	getSiteConfigurationByUrl
} from '../../../services/client';
import {
	a11yProps,
	getErrorMessage,
	documentTemplateTypes
} from '../../../services/helper';
import { useAlert } from "../../../hooks"
import sampleLoanData from "../../../services/sampleLoanData.json"
import { replaceVars } from '../../../services/utils';
import { LoadingBtn } from '../../../components/Button';
import MailMergeSelect from '../../../components/MailMergeSelect';

const theme = getTheme()

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div className="mt-5">{children}</div>
			)}
		</div>
	)
}

export default function AdminDocumentTemplateEdit(props) {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const { siteConfig, user } = state
	const [tab, setTab] = useState(0)
	const [saving, setSaving] = useState(false)
	const [loanOfficer, setLoanOfficer] = useState(null)
	const [loanOfficers, setLoanOfficers] = useState([])
	const [loanId, setLoanId] = useState('')
	const [selectedSiteConfig, setSelectedSiteConfig] = useState({})
	const [preview, setPreview] = useState('')
	const [todaysDate, setTodaysDate] = useState('')
	const [hidePreview, setHidePreview] = useState(false)
	const themeMUI = useTheme()

	const id = ventiState.get(theme.storageKeys.editingDocumentTemplateId)

	const [template, setTemplate] = useState({
		id: "",
		name: "",
		htmlBody: "",
		type: "",
		description: "",
		createdAt: new Date(),
		updatedAt: new Date()
	})

	useEffect(() => {
		const now = new Date()
		setTodaysDate(`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`)
	}, [])

	useEffect(() => {
		if (id !== 'new')
			getDocumentTemplate(id).then(data => {
				setTemplate(data)
			}).catch(e => {
				alert(getErrorMessage(e), { severity: "error" })
				ventiState.set(theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error)
			})

		getLoanOfficers().then((items) => {
			const loanOfficersData = []
			items?.forEach(item => {
				item?.siteConfigurations.forEach(site => {
					loanOfficersData.push({ ...item, label: `${item.firstName} ${item.lastName}`, url: site.url, siteConfigurationId: site.id })
				})
			})
			setLoanOfficers(loanOfficersData)
		})
	}, [id])

	const canSubmit = template?.name.length > 0 && template?.htmlBody.length > 0

	const saveTemplate = () => {
		setSaving(true)
		if (template.isDefault) {
			delete template.id
			delete template.isDefault
			template.accountID = user.accountID
		}

		saveDocumentTemplate(template)
			.then(() => {
				alert(`Document Template ${template.name} successfully ${template.id ? "updated" : "created"}`)
			})
			.catch(e => {
				alert(getErrorMessage(e), { severity: "error" })
				ventiState.set(theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error)
			})
			.finally(() => {
				setSaving(false)
			})
	}

	const handleLoanOfficerSelect = async (event, value) => {
		if (value)
			setSelectedSiteConfig(await getSiteConfigurationByUrl(value.siteConfigurations[0].url))
		else setSelectedSiteConfig({})
		setLoanOfficer(value)
	}


	const generatePreview = () => {
		generateDocumentFromTemplate({
			templateID: id,
			loanID: loanId,
			siteConfigurationID: siteConfig.id,
			preview: false
		}).then(response => {
			setPreview(response)
		}).catch((e) => {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		})
	}

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const renderDocPreview = (doc) => {
		return <img
			src={`data:application/${doc.extension};base64,${doc.base64Data}`}
			style={{ flex: 1 }}
		/>
	}

	const handleLoanGUIDChange = (e) => {
		const val = e.target.value.replace(/[^a-f0-9-]/g, '');
		setLoanId(val)
	}

	const loanIdIsValid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(loanId)

	return (
		<Page isFullWidth={true} title="Edit">
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
					Document Templates - {id === "new" ? "Create" : `${template.name}`}
				</p>
				<div className="flex flex-wrap justify-end my-5">
					<LoadingBtn
						id={`AdminCorporateEditSaveButton`}
						disabled={!canSubmit || saving || 0 !== tab}
						loading={saving}
						text={template.id ? "Update" : "Create"}
						onClick={saveTemplate}
						fullWidth={false}
					/>
				</div>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={tab}
					onChange={handleTabChange}
					aria-label="Tabs"
				>
					<Tab label="Edit" {...a11yProps(0)} />
					<Tab label="Full Preview" {...a11yProps(1)} />
					<Tab label="Test" {...a11yProps(2)} />
				</Tabs>
				<SwipeableViews
					axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={tab} index={0} dir={themeMUI.direction}>
						<MailMergeSelect combinedOptions />
						<Card className="mt-1% pt-1% px-5% flex w-full">
							<CardContent className="w-1/2">
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<FormControlLabel
										control={
											<Switch
												checked={!hidePreview}
												onChange={() => setHidePreview(current => !current)}
												color="primary"
											/>
										}
										label={`${!hidePreview ? 'Hide' : 'Show'} HTML Preview`}
									/>
								</FormControl>
							</CardContent>
						</Card>
						<Card className="pt-1% pb-5% px-5% flex w-full">
							<CardContent className={`w-${!hidePreview ? '1/2' : 'full'}`}>
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<InputLabel>Document Type</InputLabel>
									<Select
										variant="standard"
										value={template.type}
										onChange={e => setTemplate(current => ({ ...current, type: e.target.value }))}
										label="Document Type"
										placeholder="Document Type"
									>
										<MenuItem value="" key="none">Select a Document Type</MenuItem>
										{documentTemplateTypes.filter(t => t.active).map((option, index) => (
											<MenuItem key={index} value={option.value}>{option.label}</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<TextField
										placeholder="Name *"
										value={template.name}
										label="Name"
										onChange={e => setTemplate(current => ({ ...current, name: e.target.value }))}
										variant="standard"
										className="w-full sm:px-12"
										required
									/>
								</FormControl>
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<TextField
										placeholder="Description"
										value={template.description}
										label="Description"
										onChange={e => setTemplate(current => ({ ...current, description: e.target.value }))}
										variant="standard"
										className="w-full sm:px-12"
									/>
								</FormControl>
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<TextField
										placeholder="HTML Document Body"
										value={template.htmlBody}
										label="HTML Document Body"
										onChange={e => setTemplate(current => ({ ...current, htmlBody: e.target.value }))}
										variant="standard"
										className="w-full sm:px-12"
										style={{ backgroundColor: "#efefef", padding: 5 }}
										multiline
										rows={20}
										required
									/>
								</FormControl>
							</CardContent>
							{!hidePreview && <CardContent className="w-1/2 border">
								<iframe className="w-full h-full min-h-200" title="Template" srcDoc={template.htmlBody} />
							</CardContent>}
						</Card>
					</TabPanel>

					<TabPanel value={tab} index={1} dir={themeMUI.direction}>
						<Card className="pt-1% pb-5% px-5%">
							<Autocomplete
								disablePortal
								id="loan-officer-autocomplete"
								value={loanOfficer}
								options={loanOfficers.sort((a, b) => -b.firstName.localeCompare(a.firstName))}
								onChange={(event, value) => handleLoanOfficerSelect(event, value)}
								renderOption={(props, option) => {
									return (
										<li {...props} key={option.id}>
											{option.label}
										</li>
									)
								}}
								renderInput={(params) => <TextField {...params} label="Loan Officer" />}
							/>
							{!!selectedSiteConfig.id
								? <iframe className="w-full h-full min-h-200 pt-5" title="Template" srcDoc={replaceVars(template.htmlBody, { ...selectedSiteConfig, ...sampleLoanData, Date: todaysDate })} />
								: <Typography className="pt-5 text-center" variant="h5">Select a site to preview</Typography>
							}
						</Card>
					</TabPanel>

					<TabPanel value={tab} index={2} dir={themeMUI.direction}>
						<Card className="py-1% pb-5% px-5%">
							<CardContent>
								<div className="flex flex-row-reverse justify-start mb-10 items-center">
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={!loanOfficer || !loanIdIsValid}
										onClick={generatePreview}
									>
										Generate PDF Preview
									</Button>
								</div>
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<Autocomplete
										includeInputInList
										disablePortal
										value={loanOfficer}
										options={loanOfficers.sort((a, b) => -b.firstName.localeCompare(a.firstName))}
										onChange={(event, value) => handleLoanOfficerSelect(event, value)}
										renderOption={(props, option) => {
											return (
												<li {...props} key={option.id}>
													{option.label}
												</li>
											)
										}}
										renderInput={(params) => <TextField {...params} label="Loan Officer" />}
									/>
								</FormControl>
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<TextField
										placeholder="Loan GUID"
										value={loanId}
										label="Loan GUID"
										onChange={handleLoanGUIDChange}
										error={loanId.length > 0 && !loanIdIsValid}
										variant="standard"
										className="w-full sm:px-12"
									/>
									{loanId.length > 0 && !loanIdIsValid && <p className="text-sm text-red-500 mt-5">Enter a valid Loan GUID</p>}
								</FormControl>
								{!!preview && renderDocPreview(preview)}
							</CardContent>
						</Card>
					</TabPanel>
				</SwipeableViews>
			</div>
		</Page>
	)
}
