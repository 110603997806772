import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import { isEqual } from "lodash"
import { useVenti } from "venti"
import PublishSubscribe from "publish-subscribe-js"
import {
	Tabs, Tab, Card, CardContent, FormControlLabel, Checkbox, FormGroup,
	Typography
} from "@mui/material"
import { ProfileView } from "./Profile"
import Page from "../../../components/Page"
import TabPanel from "../../../components/TabPanel"
import { LoadingBtn } from "../../../components/Button"
import { ModalYesNo } from "../../../components/modals/ModalYesNo"
import { Loading } from "../../../components/Loading"
import { Features } from "../../../components/admin/Features"
import { WebsiteSettings } from "../../../components/admin/WebsiteSettings"
import { Integrations } from "../../../components/admin/Integrations"
import { navigationLinking } from "../../../services/navigation"
import { siteConfigurationModel } from "../../../services/utils"
import {
	createCorporation, getCorporationSiteConfiguration,
	createCorporationSiteConfiguration,
	updateCorporationSiteConfiguration
} from "../../../services/client"
import { a11yProps, getErrorMessage, validateSiteConfigurationData } from "../../../services/helper"
import { getTheme } from "../../../config"
import { useWindowSize, useAlert } from "../../../hooks"

const theme = getTheme()

export default function AdminCorporateEdit({ navigation }) {
	const navigate = useNavigate()
	const { alert } = useAlert()
	const ventiState = useVenti()
	const [width] = useWindowSize()
	const themeMUI = useTheme()

	const siteConfigurationId = ventiState.get(theme.storageKeys.editingCorporateSiteConfigurationId)
	let corporateId = ventiState.get(theme.storageKeys.editingCorporateId)

	if (!corporateId && !siteConfigurationId) {
		navigate(`/${navigationLinking.AdminCorporate}`)
	}

	if (corporateId === "new") corporateId = null
	const [tab, setTab] = useState(0)

	const [modalConfirmation, setModalConfirmation] = useState({ open: false, text: "", leavePage: false, canBeSaved: false, isSave: false, event: null })
	const [loading, setLoading] = useState(false)
	const [initialState, setInitialState] = useState({ ...siteConfigurationModel, entityType: 1 })
	const [formData, setFormData] = useState({ ...siteConfigurationModel, entityType: 1 })
	const [canBeSubmitted, setCanBeSubmitted] = useState(false)
	const initialRender = useRef(true)

	useEffect(() => {
		const canBeSubmitted = !!(formData.url.trim() !== "" &&
			formData.licenses.length > 0 && formData.name.length &&
			!isEqual(initialState, formData))

		setCanBeSubmitted(canBeSubmitted)

		if (initialRender.current) {
			initialRender.current = false
		}
	}, [formData, initialState])

	useEffect(() => {
		if (corporateId && siteConfigurationId) {
			// with a V2 api we have one to many siteConfigurations
			// so we should think later how to display that on UI
			getCorporationSiteConfiguration(corporateId, siteConfigurationId)
				.then(data => {
					setFormData(data)
					setInitialState(data)
				})
				.catch(e => {
					alert(getErrorMessage(e), { severity: "error" })
					ventiState.set(theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error)
				})
		}
	}, [corporateId, siteConfigurationId])

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const handleCheckFormLicenses = () => {
		formData.licenses.length === 0 ?
			setModalConfirmation({
				open: true,
				text: theme.modal.licensesText,
				leavePage: false,
				canBeSaved: false,
				isSave: true,
				event: null
			}) : saveForm()
	}

	const saveForm = async () => {
		setLoading(true)

		try {
			if (formData.id) {
				await updateCorporationSiteConfiguration(formData, corporateId, formData.id)
				PublishSubscribe.publish("UPDATE_ADMIN_COMPONENT_STATE")
				alert(`Brand "${formData.name}" successfully updated`)
				navigate(`/${navigationLinking.AdminCorporate}`)
			} else {
				const corporation = await createCorporation({
					name: formData.name
				})

				validateSiteConfigurationData(formData)

				await createCorporationSiteConfiguration(formData, corporation.id)
				PublishSubscribe.publish("UPDATE_ADMIN_COMPONENT_STATE")
				alert(`Brand "${formData.name}" successfully created`)
				navigate(`/${navigationLinking.AdminCorporate}`)
			}
		} catch (e) {
			setLoading(false)
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const modalConfirm = async (modalConfirmation) => {
		setLoading(true)
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({ open: false, text: "", leavePage: false })
	}

	return (
		<Page isFullWidth={true} title={corporateId ? "Edit Brand" : "New Brand"}>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
			/>
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">{corporateId ? "Edit Brand" : "New Brand"}</p>
				{corporateId && !initialState.id
					? <div className="flex justify-center dark:bg-[#121212]" style={{ height: `calc(100vh - 216px)` }}><Loading size="small" /></div>
					: (
						<>
							<div className="flex flex-wrap justify-end my-5">
								{formData?.id &&
									<FormGroup>
										<FormControlLabel
											label={<Typography className="dark:text-white">Apply to All Child Sites</Typography>}
											key="AdminCorporateEditApplyToChildrenButton"
											control={
												<Checkbox
													id="AdminCorporateEditApplyToChildrenButton"
													checked={formData?.updateDownstream || false}
													onChange={() => setFormData(current => ({ ...current, updateDownstream: !current.updateDownstream }))}
													inputProps={{ "aria-label": "controlled" }}
												/>
											}
										/>
									</FormGroup>
								}
								<LoadingBtn
									id={`AdminCorporateEditSaveButton`}
									disabled={!canBeSubmitted}
									loading={loading}
									text={`${formData?.id ? "Update Brand" : "Create Brand"}`}
									onClick={handleCheckFormLicenses}
									fullWidth={false}
								/>
							</div>
							<Tabs
								variant="scrollable"
								scrollButtons="auto"
								value={tab}
								onChange={handleTabChange}
								aria-label="Tabs"
							>
								<Tab label="Corporate Profile" {...a11yProps(0)} />
								<Tab label="Branding & Images" {...a11yProps(1)} />
								<Tab label="Features" {...a11yProps(2)} />
								<Tab label="Integrations" {...a11yProps(3)} />
							</Tabs>
							<SwipeableViews
								axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
								index={tab}
								onChangeIndex={handleChangeIndex}
							>
								<TabPanel value={tab} index={0} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<ProfileView formData={formData} setFormData={setFormData} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={1} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<WebsiteSettings formData={formData} setFormData={setFormData} entityType={1} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={2} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<Features formData={formData} setFormData={setFormData} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={3} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<Integrations formData={formData} setFormData={setFormData} entityType={1} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
							</SwipeableViews>
						</>
					)
				}
			</div>
		</Page>
	)
}
