import React, { useState } from 'react'
import { useAppContext } from "../../components/AppContext"
import Page from "../../components/Page"
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ChangePassword from '../../components/ChangePassword'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import SwipeableViews from 'react-swipeable-views'
import { a11yProps, getErrorMessage } from "../../services/helper"
import { TextField } from "@mui/material"
import { LoadingBtn } from "../../components/Button"
import { changePassword } from "../../services/client"
import IconButton from '@mui/material/IconButton'
import InputAdornment from "@mui/material/InputAdornment"
import { useTheme } from '@mui/material/styles'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import PublishSubscribe from 'publish-subscribe-js'
import { getTheme } from "../../config"
import { useAlert } from "../../hooks"

const themePOS = getTheme()

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div className="mt-5">{children}</div>
			)}
		</div>
	)
}

export default function UserProfile() {
	const { alert } = useAlert()
	const [tab, setTab] = useState(0)
	const [userPassword, setUserPassword] = useState('')
	const [oldPassword, setOldPassword] = useState('')
	const [loading, setLoadingChange] = useState(false)
	const [passwordConfirmed, setPasswordConfirmed] = useState('')
	const [enabledButton, setEnabledButton] = useState(true)
	const [showOldPassword, setShowOldPassword] = useState(false);
	const theme = useTheme()
	const { state } = useAppContext()
	const { user } = state

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const handleChangePassword = async () => {
		setLoadingChange(true)

		try {
			await changePassword(oldPassword, userPassword)
			setLoadingChange(false)
			setOldPassword('')
			setUserPassword('')
			alert("Account password successfully changed")
		} catch (e) {
			setLoadingChange(false)
			alert(getErrorMessage(e), { severity: "error" })
		}
	}

	const changeMode = () => {
		PublishSubscribe.publish("SET_DARK_MODE",
			theme.palette.mode === 'light' ? 'dark' : 'light')
	}

	return (
		<Page page="app-profile" title="My Profile" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">My Profile</p>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={tab}
					onChange={handleTabChange}
					aria-label="Tabs"
				>
					<Tab label="Personal" {...a11yProps(0)} />
					<Tab label="Credentials" {...a11yProps(1)} />
					<Tab label="Settings" {...a11yProps(2)} />
				</Tabs>
				<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={tab} index={0} dir={theme.direction}>
						<Card>
							<CardContent>
								<div className="mb-2"><span className="font-bold">First Name: </span>{user.firstName}</div>
								<div className="mb-2"><span className="font-bold">Last Name:</span> {user.lastName}</div>
								<div><span className="font-bold">Email:</span> {user.email}</div>
							</CardContent>
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={1} dir={theme.direction}>
						<Card>
							<CardContent>
								<div className="flex flex-col items-center">
									<TextField
										onChange={(e) => setOldPassword(e.target.value)}
										value={oldPassword}
										label={oldPassword === '' ? null : 'Password'}
										placeholder="Old Password"
										id={`ChangePasswordInput`}
										variant="standard"
										type={showOldPassword ? "text" : "password"}
										className="w-64 mx-2.5 sm:w-96 sm:px-12"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowOldPassword(!showOldPassword)}
														onMouseDown={() => setShowOldPassword(!showOldPassword)}
													>
														{showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</div>
								<ChangePassword
									id={`ChangePassword`}
									passwordLabel={'New Password'}
									confirmPasswordLabel={'Confirm password'}
									userPassword={userPassword}
									setUserPassword={setUserPassword}
									setConfirmButton={setEnabledButton}
									confirmButtonEnabled={enabledButton}
									passwordConfirmed={passwordConfirmed}
									setPasswordConfirmed={setPasswordConfirmed}
								/>
								<div className="flex flex-col items-center mt-5">
									<div className="w-64 mx-2.5 sm:w-96 sm:px-12">
										<LoadingBtn
											id={`ChangePasswordButton`}
											disabled={!enabledButton || !oldPassword || loading}
											loading={loading}
											onClick={handleChangePassword}
											fullWidth
											variant="contained"
											text="Change Password" />
									</div>
								</div>
							</CardContent>
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={2} dir={theme.direction}>
						<Card>
							<CardContent>
								<div className="mt-2">
									Turn on {theme.palette.mode === 'light' ? 'Dark' : 'Light'} mode
									<IconButton sx={{ ml: 1 }} onClick={changeMode} color="inherit">
										{theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
									</IconButton>
								</div>
							</CardContent>
						</Card>
					</TabPanel>
				</SwipeableViews>
			</div>
		</Page>
	)
}
