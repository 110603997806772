import { getTheme } from '../config'
const theme = getTheme()

const questions = [
	{
		title: "What would you like to call this rule?",
		type: "text",
		qId: "ruleName",
		target: "description",
		fieldId: "name",
		placeholder: "Enter Rule Name",
		name: "Rule Name"
	},
	{
		title: "Rule Description",
		question: "Please describe this rule",
		type: "textarea",
		qId: "description",
		target: "loanPurpose",
		fieldId: "description",
		placeholder: "Enter description",
		name: "Description"
	},
	// {
	// 	title: "What User Role should this rule apply to?",
	// 	type: "picker",
	// 	qId: "targetUserRole",
	// 	target: "applyToAllBorrowerPairs",
	// 	fieldId: "targetUserRole",
	// 	default: "Select User Role",
	// 	options: theme.roles.map((r, index) => {
	// 		return {
	// 			value: index + 1,
	// 			label: r
	// 		}
	// 	}),
	// 	name: "Target User Role"
	// },
	// {
	// 	title: "Borrower Pairs Filter",
	// 	question: "Apply this task to All Borrower Pairs?",
	// 	type: "choice",
	// 	qId: "applyToAllBorrowerPairs",
	// 	fieldId: "applyToAllBorrowerPairs",
	// 	target: "loanPurpose",
	// 	hideIf: "targetUserRole!==1",
	// 	options: [
	// 		{
	// 			label: "Yes",
	// 			value: true
	// 		},
	// 		{
	// 			label: "No",
	// 			value: false
	// 		}
	// 	],
	// 	name: "All Borrower Pairs"
	// },

	{
		title: "Loan Purpose Filter",
		question: "Apply this task to the following Loan Purpose",
		type: "choice",
		qId: "loanPurpose",
		fieldId: "loanPurpose",
		target: "loanTypes",
		options: [
			{
				label: "Purchase",
				value: "Purchase"
			},
			{
				label: "Cash Out Refinance",
				value: "CashOutRefinance"
			},
			{
				label: "Rate and Term Refinance",
				value: "RateTermRefinance"
			},
			{
				label: "Apply to All",
				value: "All"
			}
		],
		name: "Loan Purpose"
	},
	{
		title: "Loan Type Filter",
		question: "Apply this task to the following Loan Type",
		type: "choice",
		qId: "loanTypes",
		fieldId: "loanType",
		target: "occupancyTypes",
		options: [
			{
				label: "Conventional",
				value: "Conventional"
			},
			{
				label: "VA",
				value: "VA"
			},
			{
				label: "FHA",
				value: "FHA"
			},
			{
				label: "USDA",
				value: "USDA"
			},
			{
				label: "Apply to All",
				value: "All"
			}
		],
		name: "Loan Type"
	},
	{
		title: "Occupancy Filter",
		question: "Apply this task to the following Occupancy Type",
		type: "choice",
		qId: "occupancyTypes",
		fieldId: "occupancyType",
		target: "propertyTypes",
		options: [
			{
				label: "Primary Residence",
				value: "PrimaryResidence"
			},
			{
				label: "Second Home",
				value: "SecondHome"
			},
			{
				label: "Investment property",
				value: "InvestmentProperty"
			},
			{
				label: "Apply to All",
				value: "All"
			}
		],
		name: "Occupancy Type"
	},
	{
		title: "Property Type Filter",
		question: "Apply this task to the following Property Type (all field 1041 options)",
		type: "choice",
		qId: "propertyTypes",
		fieldId: "propertyType",
		target: "borrowerEmploymentTypes",
		options: [
			{
				label: "Attached",
				value: "Attached",
			},
			{
				label: "Condominium",
				value: "Condominium"
			},
			{
				label: "Co-Operative",
				value: "Cooperative"
			},
			{
				label: "Detached",
				value: "Detached"
			},
			{
				label: "High Rise Condo",
				value: "HighRiseCondominium"
			},
			{
				label: "Manufactured Housing",
				value: "ManufacturedHousing"
			},
			{
				label: "PUD",
				value: "PUD"
			},
			{
				label: "Detached Condo",
				value: "DetachedCondo"
			},
			{
				label: "Apply to All",
				value: "All"
			}
		],
		name: "Property Type"
	},
	{
		title: "Employment Type Filter",
		question: "Apply this task to the following Employment Type",
		type: "choice",
		qId: "borrowerEmploymentTypes",
		fieldId: "borrowerEmploymentType",
		target: "saveInfo",
		options: [
			{
				label: "Standard Wage Earner",
				value: "StandardWageEarner"
			},
			{
				label: "Self Employed",
				value: "SelfEmployed"
			},
			{
				label: "Retired",
				value: "Retired"
			},
			{
				label: "Apply to All",
				value: "All"
			}
		],
		name: "Employment Type"
	},
	{
		title: "Review and Save",
		question: "Please review your answers and click save",
		type: "review",
		qId: "saveInfo",
		fieldId: "",
		name: "save",
		buttonText: "Save Rule"
	},
	{
		title: "Loan Feature Filter",
		question: "Apply this task to the following Loan Feature",
		type: "choice",
		qId: "specialLoanFeatures",
		fieldId: "specialLoanFeature",
		options: [
			{
				label: "Rental Income Used to qualify",
				value: "RentalIncomeUsedToQualify"
			},
			{
				label: "Permanent Resident Alien",
				value: "PermanentResidentAlien"
			},
			{
				label: "Non Permanent Resident Alien",
				value: "NonPermanentResidentAlien"
			},
			{
				label: "Home Ready",
				value: "HomeReady"
			},
			{
				label: "Home Possible",
				value: "HomePossible"
			},
			{
				label: "Apply to All",
				value: "All"
			}
		]
	}
]

export default questions
