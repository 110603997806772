import { useVenti } from "venti"
import Dialog from "@mui/material/Dialog"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { useAppContextActions } from "../AppContext/AppHooks"
import { isDarkMode } from "../../services/helper"
import { Button } from "../Button"
import { getTheme } from "../../config"

const theme = getTheme()

export const ModalAppError = ({ visible = false, siteConfig }) => {
	const { logOut } = useAppContextActions()
	const ventiState = useVenti()
	const muiTheme = useTheme()
	const fullScreen = useMediaQuery(muiTheme.breakpoints.down("md"))

	const handleOKPress = async () => {
		ventiState.set(theme.storageKeys.failoverModalVisible, false)
		await logOut()
	}

	return (
		<Dialog
			maxWidth="sm"
			open={visible}
			aria-labelledby="responsive-dialog-title"
		>
			<div className="flex flex-col items-center px-6 py-8 md:px-10">
				<img src={isDarkMode() && siteConfig?.darkModeLogoUrl ? siteConfig.darkModeLogoUrl : siteConfig?.logoUrl} alt="Company Logo" className="w-64 sm:w-96 py-10" />
				<p className="font-lg mb-10 text-center text-slate-500 dark:text-white">
					Congratulations! Your loan application has been submitted and is being processed. Once this is completed, you will receive an email with instructions and next steps for your mortgage experience.
				</p>
				<Button
					id="ModalConfirmCloseButton"
					text="OK"
					onClick={handleOKPress}
					style={fullScreen ? { width: "90%" } : { width: 290 }}
					variant="outlined"
				/>
			</div>
		</Dialog>
	)
}
