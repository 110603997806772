import { useRef, useState, useEffect } from 'react'
import { TextField, Link, Typography, InputAdornment, IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import AuthWrapper from './AuthWrapper'
import { useAppContextActions } from '../../components/AppContext/AppHooks'
import { useAppContext } from '../../components/AppContext'
import { LoadingBtn } from '../../components/Button'
import { getTheme } from '../../config'
import { navigationLinking } from '../../services/navigation'
import { isEmailValid, isDarkMode } from '../../services/helper'
import {
	authenticate,
	getMe,
	getLoanOfficer,
	Roles,
	generateDisclosuresSSOLoginUrl,
	createLoanApplication
} from '../../services/client'
import { useLocation, useNavigate } from 'react-router-dom'
import { useVenti } from 'venti'
import { ReportProblemSharp } from '@mui/icons-material'
import { getRouteFromInvite } from '../../services/utils';

const theme = getTheme()

export default function SignInView() {
	const ventiState = useVenti()
	const navigate = useNavigate()
	const location = useLocation()
	const { state } = useAppContext()
	const { applyAuthToken, applyUser } = useAppContextActions()
	const { siteConfig, redirectRoute, invite } = state
	let redirectRouteFromSignIn = redirectRoute || navigationLinking.Portal

	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [emailSignIn, setEmailSignIn] = useState('')
	const [emailSignInValidation, setEmailSignInValidation] = useState(true)
	const [emailBlurred, setEmailBlurred] = useState(false)
	const [passwordSignIn, setPasswordSignIn] = useState('')
	const [passwordSignInValidation, setPasswordSignInValidation] = useState(true)
	const [passwordBlurred, setPasswordBlurred] = useState(false)
	const [showPassword, setShowPassword] = useState(false)

	const postLoanData = ventiState.get(theme.storageKeys.postLoanData) || {}

	const mountedRef = useRef(true)
	const enabledSignInButton = isEmailValid(emailSignIn) && passwordSignIn.length >= theme.validation.passwordLength

	useEffect(() => {
		if (postLoanData.BorrowerEmail) setEmailSignIn(postLoanData.BorrowerEmail)
	}, [postLoanData])

	// sets user email when navigating from verify forgot password
	useEffect(() => {
		if (location.state && location.state.email) { setEmailSignIn(location.state.email) }
	}, [location])

	// sets email from invite
	useEffect(() => {
		if (invite?.emailAddress) {
			setEmailSignIn(invite.emailAddress)
		}
	}, [invite])

	const passwordSignInOnChange = (val) => {
		setPasswordSignIn(val)
		setPasswordSignInValidation(val.length >= theme.validation.passwordLength)
	}
	const passwordSignInOnBlur = (e) => {
		setPasswordBlurred(true)
		setPasswordSignInValidation(e.target.value.length >= theme.validation.passwordLength)
	}

	const emailSignInOnChange = (val) => {
		setEmailSignIn(val)
		setEmailSignInValidation(isEmailValid(val))
	}

	const emailSignInOnBlur = (e) => {
		setEmailBlurred(true)
		setEmailSignInValidation(isEmailValid(e.target.value))
	}

	const handleSignIn = () => {
		if (emailSignInValidation && passwordSignInValidation) {
			setLoading(true)
			setErrorMessage(false)

			authenticate(emailSignIn, passwordSignIn, siteConfig.id).then(async login => {
				if (!mountedRef.current) return

				if (login?.access_token) {
					try {
						const me = await getMe(login.access_token)

						if ([Roles.loanOfficer, Roles.branchManager].includes(me.role) && me.id !== siteConfig.id && !window.location.hostname.includes('localhost')) {
							const loanOfficer = await getLoanOfficer(me.id, login.access_token)
							//TODO: if more than one siteConfigurationurl we should show a list to choose
							if (!loanOfficer.siteConfigurations.some(s => s.id === siteConfig.id)) {
								setErrorMessage(`Not authorized. Redirecting to https://${loanOfficer.siteConfigurations[0].url}`)
								setLoading(false)
								await window.open(`https://${loanOfficer.siteConfigurations[0].url}`, '_blank', 'noopener,noreferrer')
								return
							}
						}

						const userData = { ...login, ...me, disclosuresSSOLoginUrl: '' }

						if (me.role === Roles.borrower && siteConfig.disclosuresSSOLoginRedirectUri) {
							userData.disclosuresSSOLoginUrl = await generateDisclosuresSSOLoginUrl(siteConfig, login.access_token)
						}

						let authTokenExp = new Date()
						authTokenExp.setMinutes(authTokenExp.getMinutes() + (userData.expires_in / 60) - 5)
						const user = {
							...userData,
							isLoggedIn: true,
							tokenExp: authTokenExp
						}

						applyAuthToken(login.access_token)
						applyUser(user)
						ventiState.set(theme.storageKeys.authToken, login.access_token)
						ventiState.set(theme.storageKeys.authTokenExp, authTokenExp)
						ventiState.set(theme.storageKeys.user, user)

						if ([Roles.loanOfficer, Roles.branchManager].includes(user.role) && (redirectRouteFromSignIn.includes('Apply') || redirectRouteFromSignIn.includes('Prequalify')) && !redirectRouteFromSignIn.includes('LO'))
							redirectRouteFromSignIn = `LO${redirectRouteFromSignIn}`

						if (!navigationLinking[redirectRouteFromSignIn] || user.role === Roles.admin) redirectRouteFromSignIn = navigationLinking.Portal

						if (invite?.emailAddress) {
							navigate(getRouteFromInvite(invite))
						} else
							navigate(`/${redirectRouteFromSignIn}`)

						// If there's loan data to send, send it now
						if (!!postLoanData.BorrowerEmail) {
							ventiState.set(theme.storageKeys.appPosting, true)
							ventiState.set(theme.storageKeys.appSubmitted, true)
							const loanId = await createLoanApplication(postLoanData)
							ventiState.set(theme.storageKeys.loanId, loanId)
							ventiState.set(theme.storageKeys.appPosting, false)
							ventiState.unset(theme.storageKeys.postLoanData)

						}
					} catch (e) {
						console.log(e)
						setErrorMessage('Your account does not have access to this environment')
						setLoading(false)
					}
				} else {
					setErrorMessage('Error retrieving authentication info')
					setLoading(false)
				}
			})
				.catch((err) => {
					console.log(err)
					if (err?.data?.errorCode === 'Unconfirmed') navigate('/confirm-account')
					else {
						setErrorMessage(err?.data?.message || 'Invalid username and/or password')
						setLoading(false)
					}
				})
		}
	}

	const onKeyDownHandler = (event) => {
		if (event?.keyCode === 13) {
			handleSignIn()
		}
	}

	return (
		<AuthWrapper siteConfig={siteConfig} onKeyDown={onKeyDownHandler}
			title="Sign In">
			<>
				<img
					className="w-48 mb-5 lg:mb-0 lg:w-72 lg:absolute lg:top-5 lg:right-5"
					src={isDarkMode() && siteConfig?.darkModePortalLogoUrl ? siteConfig.darkModePortalLogoUrl : siteConfig?.portalLogoUrl || siteConfig?.logoUrl}
					alt="Logo"
				/>
				{!!postLoanData.BorrowerEmail && <p>
					<p className="text-md sm:text-1xl font-bold mb-6 text-red dark:text-white">
						<ReportProblemSharp /> Existing user account detected
					</p>
				</p>}
				<p className="text-lg sm:text-2xl font-bold mb-6 dark:text-white">
					{!!postLoanData.BorrowerEmail ? 'Please S' : 'S'}ign in to your account
				</p>
				<TextField
					onChange={(e) => emailSignInOnChange(e.target.value)}
					onBlur={emailSignInOnBlur}
					value={emailSignIn}
					label={emailSignIn === '' ? null : 'Email'}
					placeholder="Email"
					error={!emailSignInValidation && emailBlurred}
					id={`SignInEmailInput`}
					helperText={!emailSignInValidation && emailBlurred ? "Email is not valid" : ""}
					variant="standard"
					className="w-64 sm:w-96 sm:px-12 dark:text-white"
					disabled={!!postLoanData.BorrowerEmail || !!invite?.emailAddress}
					style={{ marginTop: 16 }}
				/>
				<TextField
					onChange={(e) => passwordSignInOnChange(e.target.value)}
					onBlur={passwordSignInOnBlur}
					value={passwordSignIn}
					label={passwordSignIn === '' ? null : 'Password'}
					placeholder="Password"
					error={!passwordSignInValidation && passwordBlurred}
					id={`SignInPasswordInput`}
					helperText={!passwordSignInValidation && passwordBlurred ? "Password must be at least 8 characters" : ""}
					variant="standard"
					type={showPassword ? "text" : "password"}
					className="w-64 sm:w-96 sm:px-12 dark:text-white"
					style={{ marginTop: 16 }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setShowPassword(!showPassword)}
									onMouseDown={() => setShowPassword(!showPassword)}
								>
									{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
				{errorMessage &&
					<p className="w-64 sm:w-96 text-sm pt-3 pb-2 text-red-500">
						{errorMessage}
						{errorMessage === 'User is not confirmed.' &&

							<Link
								className="text-blue-500 pl-1 cursor-pointer"
								onClick={() => {
									navigate(`/${navigationLinking.ConfirmAccount}`)
								}}
								rel="noopener noreferrer"
								underline="hover"
								style={{ color: theme.siteConfig.color.primary, marginTop: 16, display: 'inline' }}
							>
								<span className="text-sm">Confirm your account</span>
							</Link>

						}
					</p>
				}

				<div className="w-full mt-6">
					<LoadingBtn
						id={`SignInButton`}
						disabled={!enabledSignInButton || loading}
						loading={loading}
						onClick={handleSignIn}
						fullWidth
						variant="contained"
						text="Sign In" />
				</div>
				<div className="mt-4">
					<div className="text-sm dark:text-white pb-2">Don't have an account?&nbsp;</div>
					<div className="text-sm dark:text-white">Please check your email inbox for an invite from your Loan Officer</div>
				</div>
				<div className="w-full flex justify-end">
					<Link
						className="text-blue-500 mt-4 cursor-pointer"
						onClick={() => {
							navigate(`/${navigationLinking.ForgotPassword}`)
						}}
						rel="noopener noreferrer"
						underline="hover"
						style={{ color: theme.siteConfig.color.primary, marginTop: 16 }}
					>
						<Typography variant='link' className="text-sm">
							I Forgot My Password
						</Typography>
					</Link>
				</div>
			</>
		</AuthWrapper>
	)
}
