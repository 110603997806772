import Dialog from "@mui/material/Dialog"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { Button } from "../Button"

export const ModalRawData = ({ visible = false, closeModal, data }) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

	return (
		<Dialog
			open={visible}
			aria-labelledby="responsive-dialog-title"
		>
			<div className="items-center px-6 md:max-w-lg py-8 md:px-10">
				<p className="pb-5">
					{Object.keys(data).map(key => <div className="mb-2" key={key}><span className="font-bold">{key}</span>: {data[key]}</div>)}
				</p>
				<Button
					id="ModalYesNoOKButton"
					text={"Close"}
					onClick={closeModal}
					style={fullScreen ? { width: "90%", marginBottom: 10 } : { width: 290, marginBottom: 10 }}
					variant="contained"
				/>
			</div>
		</Dialog>
	)
}
