import { useState } from "react"
import { Add, Check, Close, Delete, Edit } from "@mui/icons-material"
import { TextField } from "@mui/material"

export const Apps = ({ formData, setFormData, width }) => {

  const [editing, setEditing] = useState([])

  function handleOptionValueChange(field, index, event) {
    const value = event.target.value;
    const app = formData.apps.find((a) => a.fieldId === "app")

    app.options[index][field] = value;
    if (field === "label")
      app.options[index]["value"] = value.replace(/\W+/g, "")

    setFormData(current => {
      const { apps, ...rest } = current

      return {
        ...rest,
        apps: apps.map((a) => {
          if (a.fieldId === "app") {
            a.options = app.options
          }
          return a
        }) }
    })
  }

  const handleNewOption = () => {
    const newOption = {
      label: "",
      uri: "",
      value: "New",
      target: "",
    };

    const q = formData.apps.find((a) => a.fieldId === "app")

    const options = q?.options ? [...q.options, newOption] : [newOption]

    const newApps = formData.apps.map((a) => {
      if (a.fieldId === "app") return { ...a, options }
      return a;
    });

    setFormData(current => ({ ...current, apps: newApps }))

    setEditing(current => [...current, q.options.length]);
  };

  const handleRemoveOption = (index) => {
    const options = formData.apps
    .find((a) => a.fieldId === "app")
    ?.options.filter((a, i) => i !== index)
    const newApps = formData.apps.map((a) => {
      if (a.fieldId === "app") a.options = options
      return a
    })
    setFormData(current => ({ ...current, apps: newApps }))
  }

  return (
    <div className="flex w-full">
      <div className="w-full pr-20">
        <div className="flex py-5 border-b-1">
          <div className={`w-1/3 text-20 font-bold`}>Label</div>
          <div className={`w-2/3 text-20 font-bold`}>Link</div>
          <Add
            className={`cursor-pointer`}
            size={24}
            title={`Add New App`}
            onClick={handleNewOption}
          />
        </div>
        {formData.apps
        ?.find((a) => a.fieldId === "app")
        ?.options.map((option, index) => {
          return (
            <div className="flex py-5 border-y" key={index}>
              {!editing.includes(index) ? (
                <>
                  <div className={`pr-10 w-1/3`}>{option.label}</div>
                  <div className={`pr-10 w-2/3`}>{option.uri}</div>
                  <div className={`flex`}>
                    <Edit
                      className={`cursor-pointer`}
                      size={24}
                      title={`Edit App`}
                      onClick={() =>
                        setEditing((current) => [...current, index])
                      }
                    />
                    <Delete
                      className={`ml-10 cursor-pointer`}
                      size={24}
                      title={`Remove App`}
                      onClick={() => handleRemoveOption(index)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <TextField
                    variant={`standard`}
                    className={`mr-10 w-1/3`}
                    value={option.label}
                    label={`Label`}
                    onChange={(event) =>
                      handleOptionValueChange("label", index, event)
                    }
                  />
                  <TextField
                    variant={`standard`}
                    className={`w-2/3`}
                    value={option.uri}
                    label={`URI`}
                    onChange={(event) =>
                      handleOptionValueChange("uri", index, event)
                    }
                  />

                  <div className={`flex`}>
                    {option.new && (
                      <Close
                        className={`ml-10 cursor-pointer`}
                        size={24}
                        onClick={() => handleRemoveOption(index)}
                      />
                    )}
                    <Check
                      className={`ml-10 cursor-pointer`}
                      size={24}
                      onClick={() =>
                        setEditing((current) =>
                          current.filter((i) => i !== index)
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )

}