import React, { useEffect, useState } from "react"
import { useVenti } from "venti"
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { StateLicenses } from "../../../components/admin/StateLicenses"
import { getCorporations, getCorporationSiteConfiguration } from "../../../services/client"
import { branchTypes, getErrorMessage, states } from '../../../services/helper';
import { applyMask } from "../../../services/utils"
import { getTheme } from "../../../config"
import { TextEditor } from "../../../components/formControls/TextEditor"
import { useAlert } from "../../../hooks"

const theme = getTheme()

export const ProfileView = ({ formData, setFormData, width, user, siteConfig }) => {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const [inValidFields, setInValidFields] = useState([])

	const [corporations, setCorporations] = useState([])

	useEffect(() => {
		if (user.isLoggedIn) {
			(async () => {
				try {
					const initialLicenses = JSON.parse(JSON.stringify(formData.licenses))
					const corporationsData = await getCorporations()
					const corporations = [];
					corporationsData?.forEach(item => {
						item?.siteConfigurations.forEach(site => {
							corporations.push({ ...site, corporateId: item.id })
						})
					})
					setCorporations(corporations.sort((a, b) => (a.name > b.name) ? 1 : -1))

					let domain
					if (formData.id) {
						const corp = corporations.find(b => b.id === formData.id)
						const parts = corp ? corp.url.split(".") : formData.url.split(".")
						parts.shift()
						domain = parts.join(".")
					}

					setFormData(formData => ({
						...formData,
						domain,
						licenses: initialLicenses,
					}))
				} catch (e) {
					alert(getErrorMessage(e))
					ventiState.set(theme.storageKeys.errorMessage,
						e?.data?.message || "Unable to fetch the list of corporations. Please contact your administrator.")
				}
			})()
		}
	}, [user])

	const handleIntroductionChange = (newIntroduction) => {
		if (newIntroduction !== formData.introduction) {
			setFormData({
				...formData,
				introduction: newIntroduction,
			})
		}
	}

	const updatePOSSiteConfiguration = (field, value) => {
		if (field === "phone" || field === "tollFree") {
			value = applyMask(value, "phone")
		}

		setFormData(formData => ({ ...formData, [field]: value }))
	}

	const updateCorporateId = async (corporateId) => {
		if (corporateId) {
			const corp = corporations.find(b => b.id === corporateId || b.corporateId === corporateId)
			const parts = corp.url.split(".")
			parts.shift()
			const domain = parts.join(".")
			setFormData(formData => ({ ...formData, corporateId: corporateId, domain }))
		} else {
			setFormData(formData => ({ ...formData, corporateId: "", domain: "" }))
		}

		if (corporateId) {
			try {
				const corp = corporations.find(b => b.id === corporateId || b.corporateId === corporateId)
				console.log(corp)
				const corporation = await getCorporationSiteConfiguration(
					corporateId, corp.id)
				const {
					id,
					entityID,
					entityType,
					url,
					email,
					name,
					address,
					address2,
					city,
					state,
					zip,
					phone,
					fax,
					tollFree,
					licenses,
					nmlsid,
					losUserID,
					enabledServicesID,
					...rest
				} = corporation

				setFormData(formData => ({
					...formData,
					...rest,
					branchId: corp.corporateId
				}))
			} catch (e) {
				alert(getErrorMessage(e), { severity: "error" })
				ventiState.set(theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error)
			}
		}
	}

	const handleInputValid = (id) => {
		return inValidFields.includes(id)
	}

	const handleOnChangeText = async (text, field, nativeID) => {
		updatePOSSiteConfiguration(field, text.trim().toLowerCase())
		if (text) {
			setInValidFields(inValidFields.filter(item => item !== nativeID))
		}
		else {
			setInValidFields([nativeID, ...inValidFields])
			handleInputValid(text)
		}
	}

	const handleStateLicensesSelect = (selectedState) => {
		let licenses = formData.licenses
		if (selectedState === 'all') licenses = states.map(state => state.value)
		else if (selectedState === 'none')licenses = []
		else {
			const isChecked = licenses.includes(selectedState)
			if (isChecked) licenses = formData.licenses.filter(l => l !== selectedState)
			else licenses.push(selectedState)
		}

		setFormData(formData => ({ ...formData, licenses }))
	}

	return (
		<div className="flex flex-col md:flex-row">
			<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
				<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">Corporation</p>
				<div className="flex flex-row w-full items-end">
					<FormControl
						key={"AdminBranchProfileCorporationPicker"}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: 2 }}
					>
						<InputLabel>Select a Corporation...</InputLabel>
						<Select
							value={formData.corporateId}
							onChange={e => updateCorporateId(e.target.value)}
							label="Landing Page Type"
						>
							<MenuItem value="" key="none">Select a Corporation</MenuItem>
							{corporations.map((option, index) => (
								<MenuItem key={index} value={option.corporateId}>{option.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className="flex flex-row w-full items-end">
					<FormControl
						key={"AdminBranchProfileTypePicker"}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: 2 }}
					>
						<InputLabel>Select a Branch Type...</InputLabel>
						<Select
							value={formData.type}
							onChange={e => updatePOSSiteConfiguration('type', e.target.value)}
							label="Landing Page Type"
						>
							<MenuItem value="" key="none">Select a Branch Type</MenuItem>
							{branchTypes.map((option, index) => (
								<MenuItem key={index} value={option.value}>{option.label}</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminBranchProfileSiteUrlInput`}
						placeholder={`Site Url (Ex: branch.${formData.domain || siteConfig.url.replace(/^[^.]+\./g, "")})`}
						label="The Big POS Landing Page Url *"
						value={formData?.url}
						onChange={e => handleOnChangeText(e.target.value, "url", "AdminAccountProfileSiteUrlInput")}
						error={handleInputValid("AdminAccountProfileSiteUrlInput")}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminBranchProfileNameInput`}
						placeholder="Branch name"
						value={formData?.name}
						label="Branch name *"
						onChange={e => updatePOSSiteConfiguration("name", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminBranchProfileEmailInput`}
						placeholder="Email Address"
						value={formData?.email}
						label="Email Address"
						onChange={e => updatePOSSiteConfiguration("email", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminBranchProfileAddressInput`}
						placeholder="Street Address"
						value={formData?.address}
						label="Street Address"
						onChange={e => updatePOSSiteConfiguration("address", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminBranchProfileUnitInput`}
						placeholder="Suite / Unit #"
						value={formData?.address2}
						label="Suite / Unit #"
						onChange={e => updatePOSSiteConfiguration("address2", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminBranchProfileCityInput`}
						placeholder="Company City"
						value={formData?.city}
						label="Company City"
						onChange={e => updatePOSSiteConfiguration("city", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminBranchProfileStateInput`}
						placeholder="State"
						value={formData?.state}
						label="State"
						onChange={e => updatePOSSiteConfiguration("state", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminBranchProfileZipInput`}
						placeholder="Zipcode"
						value={formData?.zip}
						label="Zipcode"
						onChange={e => updatePOSSiteConfiguration("zip", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminBranchProfileNMLSIDInput`}
						placeholder="NMLS ID"
						value={formData?.nmlsid?.toString() || ""}
						label="NMLS ID"
						onChange={e => updatePOSSiteConfiguration("nmlsid", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
					<TextField
						id={`AdminBranchProfilePhoneInput`}
						placeholder="Contact Phone"
						value={formData?.phone}
						label="Contact Phone"
						onChange={e => updatePOSSiteConfiguration("phone", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2, mr: width >= 468 ? 2 : 0 }}
					/>
					<TextField
						id={`AdminBranchProfileTollFreeInput`}
						placeholder="Office Phone"
						value={formData?.tollFree}
						label="Office Phone"
						onChange={e => updatePOSSiteConfiguration("tollFree", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
				<div className="flex flex-row w-full items-end">
					<TextField
						id={`AdminBranchProfileFaxInput`}
						placeholder="Fax"
						label="Fax"
						value={formData?.fax}
						onChange={e => updatePOSSiteConfiguration("fax", e.target.value)}
						variant="standard"
						className="w-full sm:px-12"
						sx={{ mb: 2 }}
					/>
				</div>
			</div>

			<div className="w-full md:ml-5 md:w-1/2 md:mx-2 md:mb-0 md:max-w-screen-sm">
				<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">Branch Description</p>
				<TextEditor
					placeholder="Type something..."
					initialValue={formData?.introduction}
					onChange={handleIntroductionChange}
				/>
				<StateLicenses Licenses={formData?.licenses} handleStateLicensesSelect={handleStateLicensesSelect} />
			</div>
		</div>
	)
}
