import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import HelpIcon from '@mui/icons-material/Help'
import { useVenti } from "venti"
import { getTheme } from '../../config'

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		padding: 10,
		fontSize: 14,
		maxWidth: 40,
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	[`&.${tableCellClasses.body}`]: {
		padding: 10,
		fontSize: 14,
		whiteSpace: "nowrap",
		maxWidth: 40,
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}))

const theme = getTheme()

export const PricingTable = ({ tableState, rates }) => {
	const ventiState = useVenti()
	const ratesData = ventiState.get(theme.storageKeys.publicRates) || rates || {}

	return (
		<TableContainer
			sx={{
				".MuiTable-root": {
					minHeight: 500
				},
				marginTop: tableState.marginTop
			}}
			component={Paper}
		>
			<div className="w-full pl-4 flex items-center h-12 bg-sky-600 rounded-tl-md rounded-tr-md">
				<p className="text-2xl text-white font-medium">{tableState.tableHeader}</p>
				{ratesData.rates?.length && <p className="text-sm text-white pl-4">Loan Options</p>}
			</div>
			<Table aria-label="customized table" sx={{ minWidth: 280 }}>
				<TableHead>
					<TableRow>
						<StyledTableCell>Loan Program</StyledTableCell>
						<StyledTableCell>Rate</StyledTableCell>
						{/* <StyledTableCell>APR<HelpIcon color="action" fontSize="small" className="ml-1" /></StyledTableCell> */}
						<StyledTableCell>Monthly Payment</StyledTableCell>
						<StyledTableCell>Points/Credits<HelpIcon color="action" fontSize="small" className="ml-1" /></StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{ratesData.rates?.map(rate => {
						return (
							<StyledTableRow key={rate.name}>
								<StyledTableCell>{rate.loanProgram}</StyledTableCell>
								<StyledTableCell>{rate.rate}</StyledTableCell>
								<StyledTableCell>${rate.payment.toFixed(2)}</StyledTableCell>
								<StyledTableCell>{rate.price > 0 ? <p className="text-red-700">{rate.price}%</p> : <p className="text-green-600">{rate.price * -1}%</p>}
								</StyledTableCell>
							</StyledTableRow>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
