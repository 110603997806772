import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import { isEqual } from "lodash"
import { useVenti } from "venti"
import PublishSubscribe from "publish-subscribe-js"
import {
	Tabs, Tab, Card, CardContent, FormControlLabel,
	Checkbox, Typography
} from "@mui/material"
import { ProfileView } from "./Profile"
import Page from "../../../components/Page"
import { LoadingBtn } from "../../../components/Button"
import { ModalYesNo } from "../../../components/modals/ModalYesNo"
import { ModalError } from "../../../components/modals/ModalError"
import { useAppContext } from "../../../components/AppContext"
import { Loading } from "../../../components/Loading"
import { Features } from "../../../components/admin/Features"
import { WebsiteSettings } from "../../../components/admin/WebsiteSettings"
import { Integrations } from "../../../components/admin/Integrations"
import { navigationLinking } from "../../../services/navigation"
import { siteConfigurationModel } from "../../../services/utils"
import {
	createBranch, createBranchSiteConfiguration, getBranchSiteConfiguration,
	updateBranchSiteConfiguration, getBranch, updateBranch
} from "../../../services/client"
import { a11yProps, getErrorMessage, validateSiteConfigurationData } from "../../../services/helper"
import { getTheme } from "../../../config"
import { useAlert, useWindowSize } from "../../../hooks"

const theme = getTheme()

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div className="mt-5">{children}</div>
			)}
		</div>
	)
}

export default function AdminBranchEdit({ navigation }) {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const navigate = useNavigate()
	const themeMUI = useTheme()
	const { siteConfig, user } = state
	const ventiState = useVenti()
	const [width] = useWindowSize()

	const siteConfigurationId = ventiState.get(theme.storageKeys.editingBranchSiteConfigurationId)
	let branchId = ventiState.get(theme.storageKeys.editingBranchId)

	if (!branchId) {
		navigate(`/${navigationLinking.AdminBranch}`)
	}

	if (branchId === "new") branchId = null
	const [tab, setTab] = useState(0)

	const [modalConfirmation, setModalConfirmation] = useState({ open: false, text: "", leavePage: false, canBeSaved: false, isSave: false, event: null })
	const [loading, setLoading] = useState(false)
	const [initialState, setInitialState] = useState({ ...siteConfigurationModel, updateDownstream: false, domain: "", entityType: 2 })
	const [formData, setFormData] = useState({ ...siteConfigurationModel, updateDownstream: false, domain: "", entityType: 2 })
	const [canBeSubmitted, setCanBeSubmitted] = useState(true)
	const [errorModalVisible, setErrorModalVisible] = useState(false)
	const [errorMsg, setErrorMsg] = useState("")
	const initialRender = useRef(true)

	useEffect(() => {
		const formCopy = { ...formData }
		delete formCopy.domain

		const canBeSubmitted = formData?.url.trim() !== "" &&
			formData?.licenses.length > 0 && formData?.name.length > 0 &&
			!isEqual(initialState, formCopy)
		setCanBeSubmitted(canBeSubmitted)

		if (initialRender.current) {
			initialRender.current = false
		}
	}, [formData, initialState])

	useEffect(() => {
		if (branchId && siteConfigurationId) {
			getBranchSiteConfiguration(branchId, siteConfigurationId)
				.then(data => {
					getBranch(branchId).then((branch) => {
						setFormData({
							...data,
							corporateId: branch.corporateID,
							type: branch.type
						})
						setInitialState(data)
					})
				})
				.catch(e => {
					alert(getErrorMessage(e), { severity: "error" })
					ventiState.set(theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error)
				})
		}
	}, [branchId, siteConfigurationId])

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const handleCheckFormLicenses = () => {
		formData.licenses.length === 0 ?
			setModalConfirmation({
				open: true,
				text: theme.modal.licensesText,
				leavePage: false,
				canBeSaved: false,
				isSave: true,
				event: null
			}) : saveForm()
	}

	const saveForm = async () => {
		// Check the URL is the correct format and matches the domain
		const parts = formData.url.split(".")
		if (parts.length !== 3) {
			setErrorMsg(`The format of the URL you've entered (${formData.url}) is not valid. It should appear like branch.${formData.domain}`)
			setErrorModalVisible(true)
			return
		}

		const subdomain = parts.shift()
		if (parts.join(".") !== formData.domain) {
			setErrorMsg(`The URL you've entered (${formData.url}) does not match the domain of the brand. It should appear like ${subdomain}.${formData.domain}`)
			setErrorModalVisible(true)
			return
		}

		setLoading(true)

		try {
			if (formData.id) {
				await updateBranch({
					id: branchId,
					name: formData.name,
					corporateID: formData.corporateId,
					type: formData.type
				})
				await updateBranchSiteConfiguration(formData, branchId, formData.id)
				PublishSubscribe.publish("UPDATE_ADMIN_COMPONENT_STATE")
				alert(`Branch "${formData.name}" successfully updated`)
				navigate(`/${navigationLinking.AdminBranch}`)
			} else {
				const branch = await createBranch({
					name: formData.name,
					corporateID: formData.branchId,
					type: formData.type
				})

				validateSiteConfigurationData(formData)

				await createBranchSiteConfiguration(formData, branch.id)
				PublishSubscribe.publish("UPDATE_ADMIN_COMPONENT_STATE")
				alert(`Branch "${formData.name}" successfully created`)
				navigate(`/${navigationLinking.AdminBranch}`)
			}
		} catch (e) {
			setLoading(false)
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		}
	}

	const modalConfirm = async (modalConfirmation) => {
		setLoading(true)
		modalConfirmation.leavePage
			? navigation.dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation({ open: false, text: "", leavePage: false })
	}

	return (
		<Page isFullWidth={true} title={branchId ? `Edit Branch` : "New Branch"}>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
			/>
			<ModalError visible={errorModalVisible} setVisible={setErrorModalVisible} message={errorMsg} />
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">{branchId ? `Edit Branch` : "New Branch"}</p>
				{branchId && !initialState.id
					? <div className="flex justify-center dark:bg-[#121212]" style={{ height: `calc(100vh - 216px)` }}><Loading size="small" /></div>
					: (
						<>
							<div className="flex flex-wrap justify-end my-5">
								{formData?.id &&
									<FormControlLabel
										label={<Typography className="dark:text-white">Apply to All Child Sites</Typography>}
										key="AdminBranchEditApplyToChildrenButton"
										control={
											<Checkbox
												id="AdminBranchEditApplyToChildrenButton"
												checked={formData?.updateDownstream || false}
												onChange={() => setFormData(current => ({ ...current, updateDownstream: !current.updateDownstream }))}
												inputProps={{ "aria-label": "controlled" }}
											/>
										}
									/>
								}
								<LoadingBtn
									id={`AdminBranchEditSaveButton`}
									disabled={!canBeSubmitted}
									loading={loading}
									text={`${formData?.id ? "Update Branch" : "Create Branch"}`}
									onClick={handleCheckFormLicenses}
									fullWidth={false}
								/>
							</div>
							<Tabs
								variant="scrollable"
								scrollButtons="auto"
								value={tab}
								onChange={handleTabChange}
								aria-label="Tabs"
							>
								<Tab label="Branch Profile" {...a11yProps(0)} />
								<Tab label="Branding & Images" {...a11yProps(1)} />
								<Tab label="Features" {...a11yProps(2)} />
								<Tab label="Integrations" {...a11yProps(3)} />
							</Tabs>
							<SwipeableViews
								axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
								index={tab}
								onChangeIndex={handleChangeIndex}
							>
								<TabPanel value={tab} index={0} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<ProfileView user={user} siteConfig={siteConfig} formData={formData} setFormData={setFormData} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={1} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<WebsiteSettings formData={formData} setFormData={setFormData} entityType={2} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={2} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<Features formData={formData} setFormData={setFormData} />
										</CardContent>
									</Card>
								</TabPanel>
								<TabPanel value={tab} index={3} dir={themeMUI.direction}>
									<Card>
										<CardContent>
											<Integrations formData={formData} setFormData={setFormData} entityType={2} width={width} />
										</CardContent>
									</Card>
								</TabPanel>
							</SwipeableViews>
						</>
					)
				}
			</div>
		</Page>
	)
}
