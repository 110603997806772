/**
 * @description Debug Token
 * @type {string}
 */
const debugToken = "eyJraWQiOiJ0TzlWcTRaWFRTUm9GSVp2emxQaHhjdlk5RGdudmVMXC9WcWxMcFBBaUdoQT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlNTRkZjk1NS04MGQ1LTQ5YzQtYTRmYy1mNTZiNmFkNjFmYmMiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0yLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMl90S3ZjeDA5SEkiLCJjbGllbnRfaWQiOiI3ZnFxZzQyNDAzZWRoaGE3OWRuOG5vdGFnMyIsIm9yaWdpbl9qdGkiOiI5MGVjMjYyMi1mMDAxLTQwY2UtODk2MC1hOWUwMzQ3MTZjOWIiLCJldmVudF9pZCI6IjljMzk3ZGM4LTM4MjAtNDgwYy1hYWEzLTQzYjYwNmU4ZGY2OCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2ODQ0NjI2MzYsImV4cCI6MTY4NDQ2NjIzNiwiaWF0IjoxNjg0NDYyNjM2LCJqdGkiOiIwZmZkYzhiYS0xMDkyLTRmYzItOTkzMi05YmY5OTA5OWNmZWYiLCJ1c2VybmFtZSI6ImU1NGRmOTU1LTgwZDUtNDljNC1hNGZjLWY1NmI2YWQ2MWZiYyJ9.NSX42ZB69pffVs06RkCb-RCcwTbBINGaAG--99WujC1AO8-LMmrQDLjfUMk62jkavwXcX_hH992QymkNo-_D7o0sZiWCGxsc9Ll1wBtOlZUAruXHbI6K4FmlMAUfKYT_C--Hfyb19RT4VvEgHKJw_BVs8HYm8aOs05XufGcXEZ0vXXW9DjMtpsdfvXHkDuCTpIpN2uO-ABg4zsf0tUX0hQVftmh7BqqyRK_mozf-F74Jfjod0j0vkhwJgaLUiadimJ_b-0eGoqnQ1giGGffgpK5G7-peiyrnhtyJzTdYA4bNZxghrn8FfdnhWux63IqEp356hV1BXnJnes4ngkyLIg"

/**
 * @description Debug Loan ID
 * @type {string}
 */
const debugLoanId = '{c1f3b00f-fe18-4efe-8f32-c2e1c1feb748}'

/**
 * @description Debug Role
 * @type {string}
 */
// const debugRole = 'Realtor' // Realtor
// const debugRole = 'Borrower' // Borrower
// const debugRole = 'LoanOfficer' // Loan Officer
const debugRole = 'Admin' // Admin

/**
 * @description Debug Enabled
 * @type {boolean}
 */
const debugEnabled = false
// const debugEnabled = true

/**
 * @description Local host alias for running locally
 * @type {string}
 */

// const localhostAlias = "www.mvloans-pos.com"
// const localhostAlias = "kelly.thebigpos.dev"
// const localhostAlias = "jagent.thebigpos.dev"
// const localhostAlias = "realtor.thebigpos.dev"
const localhostAlias = process.env.REACT_APP_LOCALHOST_ALIAS || "joe.ahmcloans.com"
// const localhostAlias = 'matthewv.ahmcloans.com'
// const localhostAlias = "msantos.fop-pos.com"
// const localhostAlias = "www.hsf-pos.com"
// const localhostAlias = "matt.thebigpos.dev"
// const localhostAlias = "www.mvloans-pos.com"

const theme = {
	maintenance: process.env["POS_MAINTENANCE_MODE"] === "true",
	debug: {
		enabled: debugEnabled,
		token: debugToken,
		loanId: debugLoanId,
		user: {
			accountID: "34039be4-eb3c-4091-8a8b-5be79736c830",
			role: debugRole,
			email: "aaquino@thebigpos.com",
			firstName: "Alex",
			lastName: "Aquino",
			phone: "(555) 555-5555",
			loanIDs: [
				debugLoanId
			],
			loans: [
				{
					loanID: debugLoanId,
					loanNumber: "0003021286624",
					createdAt: "2022-11-10T21:04:25.151429Z"
				}
			]
		}
	},
	api: {
		// https://v2.absolute.thebigpos-api.com (Absolute)
		// https://shared.thebigpos-api.com (Shared)
		// https://dev.thebigpos-api.com (Dev)
		// https://v2.dev.thebigpos-api.com (V2 Dev)
		// https://demo.thebigpos-api.com (Demo)
		_localhostAlias: localhostAlias,
		host: {
			production: process.env["REACT_APP_POS_API_HOST"] || "https://shared.thebigpos-api.com",
			// dev: process.env["REACT_APP_POS_API_HOST_DEV"] || "https://v2.dev.thebigpos-api.com"
			// dev: "https://shared.thebigpos-api.com"
			dev: "https://v2.absolute.thebigpos-api.com"
		},
		host2: {
			production: process.env['REACT_APP_POS_API_HOST2'] || 'https://api-v2.thebigpos.dev', // https://pos-api-shared.herokuapp.com
			dev: process.env['REACT_APP_POS_API_HOST2_DEV'] || 'http://localhost:5000'
			// dev: 'https://api.absolutehomemortgage.com'
			// dev: 'https://api.thebigpos.dev'
			// dev: 'https://api.ahmcloans.com'
		},
		matHost: {
			production: process.env['REACT_APP_MAT_API_HOST'] || 'https://admin.mtgautotech.com/v1',
			dev: process.env['REACT_APP_POS_API_HOST2_DEV'] || 'http://localhost:3001/v1'
		}
	},
	encryption: {
		passphrase: 'pU7zS5qP4qA8yG1zV2pT0vL8iB1eA8vL',
	},
	defaultFont: 'Rubik',
	secondaryFont: 'Montserrat',
	validation: {
		passwordLength: 8,
		passwordRegex: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
		passwordRequirements: [
			{
				key: 'length',
				label: "Contains a minimum of 8 characters",
				regex: /^.{8,}$/
			},
			{
				key: 'upper',
				label: "Contains 1 Uppercase Letter (A-Z)",
				regex: /^(?=.*[A-Z]).+$/
			},
			{
				key: 'lower',
				label: "Contains 1 Lowercase Letter (a-z)",
				regex: /^(?=.*[a-z]).+$/
			},
			{
				key: 'number',
				label: "Contains 1 Number (0-9)",
				regex: /^(?=.*[0-9]).+$/
			},
			{
				key: 'special',
				label: "Contains 1 Special Character (!@#$&*)",
				regex: /^(?=.*[!@#$&*]).+$/
			}
		],
		allowedDocumentTypes: [
			'application/pdf',
			'image/jpeg',
			'image/png'
		]
	},
	siteConfig: {
		color: {
			primary: '#000000',
			secondary: '#c3c3c3',
			text: '#303030',
			altText: '#FFFFFF',
			icon: '#000000'
		}
	},
	color: {
		primary: {
			black: '#303030',
			hover_black: '#74798C',
			grey: '#C7C9D1',
			blue: '#4A90E2',
			green: '#6fb265',
			hover_blue: '#E6F1FF',
			border_color: '#d0d0d0',
			white: '#FFFFFF',
			warning: "#FF0000",
			warning_pink: "#F06D82",
			cyan: "#4ABACC",
			active_blue: '#008bff',
			bottom_divider: '#C4CDD5',
			light_gray: '#D5D5D5',
			vlight_gray: '#EBEBEB',
			xlight_gray: '#EDEDED',
			dark_grey: "#858585",
			light_black: '#454F5B'
		},
		shadow: {
			vlight_gray: '#E0E0E0',
			dark_grey: "#141414"
		},
		secondary: {
			violet: '#33DBEE',
			hover_violet: '#6589C6',
			pink: '#F0569C',
		},
		background: {
			grey: '#F5F7FA',
			dark_grey: "#979797",
			soft_blue: "#819DF5",
			very_pale: "#F3F9FF",
			rates: "#e0edff",
			landing_rates: "#4a90e2",
			light_grayish_blue: "#E5E7EC",
			light_gray: '#D5D5D5',
			white: '#FFFFFF',
			warning: '#FF0000',
			blue: '#1E88E5',
			antiqueBlue: '#46637A'
		},
		border: {
			grey_blue: "#DDE7F2",
			soft_blue: "#819DF5",
			grayish_blue: "#BFC0C1",
			active_blue: '#4A90E2',
			bottom_divider: '#C4CDD5',
			light_gray: '#D5D5D5',
			vlight_gray: '#EBEBEB',
			blue: '#5099EE',
			cyan: '#4ACCBE',
			dark_grey: "#979797"
		},
		landing: {
			bg_color: '#eaf1fb',
			text: '#282d30'
		},
		notification: '#F06D82',
		disabled: '#a8aeaf'
	},
	borderRadius: 8,
	button: {
		disabled: '#efefef',
		active_blue: '#008bff',
	},
	storageKeys: {
		account: '@tbp:account',
		user: '@tbp:user',
		siteConfig: '@tbp:siteConfig',
		authToken: '@tbp:token',
		authTokenExp: '@tbp:tokenExp',
		pleaseWaitMessage: '@tbp:pleaseWaitMessage',
		infoMessage: "@tbp:infoMessage",
		errorMessage: '@tbp:errorMessage',
		successMessage: "@tbp:successMessage",
		errorObject: '@tbp:errorObject',
		successObject: "@tbp:successObject",
		infoObject: "@tbp:successObject",
		loanDraft: "@tbp:loanDraft",
		loanId: '@tbp:loanId',
		loanData: '@tbp:loanData',
		loanTasks: '@tbp:loanTasks',
		loanDocs: '@tbp:loanDocs',
		document: '@tbp:document',
		tempPassword: '@tbp:maybe',
		redirectRoute: '@tbp:redirectRoute',
		editingLoanApplicationId: '@tbp:editingLoanApplicationId',
		editingLoanOfficerId: '@tbp:editingLoanOfficerId',
		editingLoanOfficerSiteConfigurationId: '@tbp:editingLoanOfficerSiteConfigurationId',
		editingBranchId: '@tbp:editingBranchId',
		editingBranchSiteConfigurationId: '@tbp:editingBranchSiteConfigurationId',
		editingCorporateId: '@tbp:editingCorporateId',
		editingCorporateSiteConfigurationId: '@tbp:editingCorporateSiteConfigurationId',
		editingAccountId: '@tbp:editingAccountId',
		editingWorkflowId: '@tbp:editingWorkflowId',
		viewingLoanApplicationId: '@tbp:editingLoanApplicationId',
		editingLeadId: '@tbp:editingLeadId',
		editingOpenHouseId: '@tbp:editingOpenHouseId',
		viewingRuleId: '@tbp:viewingRuleId',
		editingRuleId: '@tbp:editingRuleId',
		editingTaskId: '@tbp:editingTaskId',
		editingPartnerId: '@tbp:editingPartnerId',
		editingPartnerSiteConfigurationId: '@tbp:editingPartnerSiteConfigurationId',
		appSubmitted: '@tbp:appSubmitted',
		appPosting: '@tbp:appPosting',
		failoverModalVisible: '@tbp:failoverModalVisible',
		appFailed: '@tbp:appFailed',
		inviteCoBorrower: '@tbp:inviteCoBorrower',
		newUser: '@tbp:newUser',
		failedUser: '@tbp:failedUser',
		invite: '@tbp:invite',
		publicRates: '@tbp:publicRates',
		loanRates: '@tbp:loanRates',
		socialLinks: '@tbp:socialLinks',
		legalLinks: '@tbp:legalLinks',
		ssoToken: '@tbp:ssoToken',
		disclosurePackageInfo: '@tbp:disclosurePackageInfo',
		verificationRequestId: '@tbp:verificationRequestId',
		coBorrowerRoute: "@tbp:coBorrowerRoute",
		coBorrowerSubmitted: "@tbp:coBorrowerSubmitted",
		editingNotificationTemplateId: '@tbp:editingNotificationTemplateId',
		editingDocumentTemplateId: '@tbp:editingDocumentTemplateId',
		postLoanData: '@tbp:postLoanData',
		editingDeviceId: '@tbp:editingDeviceId',
		isFirstLoad: '@tbp:isFirstLoad',
		loanOfficer: '@tbp:loanOfficer'
	},
	support: {
		email: 'support@thebigpos.com',
		phone: '(833) 327-9767',
		website: 'support.thebigpos.com'
	},
	google: {
		apiKey: "AIzaSyCeVODEpDP_qw8hMYiHS3834gfvZVTOcr4",
		region: "us",
		language: "en",
		places: {
			url: "https://eas-cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api"
		}
	},
	modal: {
		leavePageText: 'You have unsaved changes. Navigating away from this page will result in the loss of these changes. Do you wish to proceed?',
		overwriteText: 'The record has been changed since it was loaded. Your changes may overwrite the previous update. Are you sure you want to save?',
		licensesText: 'There are no licensed states selected. This site will not be able to accept applications. Do you wish to proceed?',
		leaveApplyPrequalifyPageText: 'Are you sure you want to leave this page? You will lose all data you\'ve entered.'
	},
	api_messages: {
		server_error: "There was an error processing the request. Please contact your administrator."
	},
	roles: [
	  "Borrower", // 0
	  "LoanOfficer", // 1
	  "Admin", // 2
		"Co-Borrower", // 3
		"Realtor", // 4
		"Loan Officer's Assistant", // 5
		"Closer", // 6,
		"Post Closer", // 7
		"Buyer Agent", // 8
		"Listing Agent", // 9
		"Settlement Agent", // 10
		"Escrow Agent", // 11
		"Title Company", // 12,
		"Buyers Attorney", // 13
		"Sellers Attorney", // 14
		"Appraisal Management Company", // 15
		"Insurance Agent", // 16
		"Branch Manager", // 17
	],
	taskTypes: [
		"Document",
		"Field",
		"E-Signature",
		"Disclosure",
		"Electronic Consent",
		"VOA",
		"VOI",
		"VOE",
		"VOC",
		"Payment",
		"VOIE",
		"IRS ID.ME"
	],
	taskButtonTitles: [
		'Upload',
		'Answer',
		'Sign',
		'Sign',
		'Give Consent',
		'Verify',
		'Verify',
		'Verify',
		'Verify',
		'Make Payment',
		'Verify',
		'Verify'
	],
	sideMenu: 'open',
	openDocFolders: ""
}

export const getTheme = () => theme

export const setThemeColorsAndImages = (siteConfig = {}) => {
	theme.siteConfig.color.primary = siteConfig.primaryColor || theme.siteConfig.color.primary
	theme.siteConfig.color.secondary = siteConfig.secondaryColor || theme.siteConfig.color.secondary
	theme.siteConfig.color.text = siteConfig.textColor || theme.siteConfig.color.text
	theme.siteConfig.color.icon = siteConfig.iconColor || theme.siteConfig.color.icon
	theme.siteConfig.color.background = siteConfig.backgroundColor || theme.siteConfig.color.background
	theme.siteConfig.backgroundImage = siteConfig.backgroundImageUrl || theme.siteConfig.backgroundImage
}

export const setFavicon = (icon) => {
	icon && document.querySelectorAll('link[rel="icon"]').forEach(item => item.setAttribute('href', icon))
	icon && document.querySelectorAll('link[rel="shortcut icon"]').forEach(item => item.setAttribute('href', icon))
}

export const setTitle = (title) => {
	if (title) document.title = title
}

const logging = {
	sentryDSN: process.env.REACT_APP_SENTRY_DSN || 'https://d6286e162ded29df79a965709a885a82@o4506717415145472.ingest.sentry.io/4506717420716032'
}

const exp = {
	getTheme,
	setTitle,
	setFavicon,
	theme,
	logging
}

export default exp
