import HelpIcon from '@mui/icons-material/Help'
import Page from "../../../components/Page"
import { loanDisclosureText, loanPaymentNotice } from '../../../services/helper'
import { getTheme } from '../../../config'

const theme = getTheme()

export default function RateAndPayment() {
	const title = "Monthly Payment"

	const rateAndPayment = [
		{ title: "Rate", value: "2.125%", warning: false, detail: false },
		{ title: "APR", value: "2.459%", warning: false, detail: true },
		{ title: "Fees", value: "$12,219", warning: false, detail: true },
		{ title: "Payment", value: "$939.75", warning: true, detail: true },
		{ title: "Loan Amount", value: "$250,000", warning: false, detail: false, },
		{ title: "Loan Term", value: "30 years", warning: false, detail: false, },
	]

	const secondColumnStart = Math.ceil(rateAndPayment.length / 2)

	const items = [
		{ title: "Principal & Interest", value: "$939.75", warning: false, detail: false },
		{ title: "Mortgage Insurance", value: "$0.00", warning: false, detail: true },
	]

	const ratesPayments = (item, index) => (
		<div className="md:w-full flex flex-row justify-between mb-1 md:max-w-xs" key={index}>
			<p className="text-lg font-extrabold">
				{item.title}:
			</p>
			<p
				className="text-lg font-extrabold"
				style={item.warning ? { color: theme.color.primary.warning } : {}}
			>
				{item.value}
				{item.detail && <HelpIcon color="action" fontSize="small" className="ml-1" />}
			</p>
		</div>
	)

	return (
		<Page page="app-loan-officer-left" title="RateAndPaymentView">
			<div className="pt-5 flex justify-center">
				<div className="md:p-5 w-full self-center md:max-w-screen-md">
					<div className="p-5 mb-5 md:w-full border rounded border-gray-500 flex flex-col md:flex-row justify-between">
						<div className="flex flex-col justify-between md:w-72">
							{rateAndPayment.slice(0, secondColumnStart).map((item, index) =>
								(ratesPayments(item, index))
							)}
						</div>
						<div className="flex flex-col justify-between md:w-72">
							{rateAndPayment.slice(secondColumnStart).map((item, index) =>
								(ratesPayments(item, index))
							)}
						</div>
					</div>
					<div className="w-full mb-2.5">
						<p className="text-2xl font-bold font-rubik text-slate-500">{title}</p>
						<p className="text-lg font-bold mt-2.5 text-slate-300">{loanPaymentNotice}</p>
					</div>
					<div className="w-full md:max-w-lg m-auto">
						<div className="w-full flex flex-row justify-between border-b border-b-slate-500 mt-5">
							<p className="text-lg font-bold font-rubik text-slate-500">Item</p>
							<p className="text-lg font-bold font-rubik text-slate-500">Payment</p>
						</div>
						<div className="w-full flex flex-col justify-between">
							{items.map((item, index) => (
								<div className="w-full flex flex-row justify-between pb-1.5 border-b border-b-slate-500" key={index}>
									<p className="text-lg font-bold text-slate-500"> {item.title} </p>
									<p className="text-lg font-bold ml-2.5 text-slate-500">
										{item.value}
										{item.detail && <HelpIcon color="action" fontSize="small" className="ml-1" />}
									</p>
								</div>
							))}
						</div>
						<div className="w-full flex flex-row justify-between items-center bg-lime-600">
							<p className="text-lg font-bold font-rubik text-slate-600">Payment</p>
							<p className="font-lg font-bold ml-2.5 text-slate-600">$939.75</p>
						</div>
					</div>
					<div className="w-full mt-10">
						<span className="font-bold text-slate-300">
								<span className="font-bold text-slate-500"> Disclosure: </span>{loanDisclosureText}
						</span>
					</div>
				</div>
			</div>
		</Page>
	)
}
