import React from "react"
import { TextField, InputLabel, MenuItem, FormControl, Select } from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import { LoadingBtn } from "../Button/LoadingButton"
import { applyMask } from "../../services/utils"
import { Roles } from "../../services/client"
import { states } from "../../services/helper"

export const RateForm = ({ formData, setFormData, getRate, fetching, ratesButtonDisabled, handleZipCodeBlur, user }) => {
	const statePicker = [{
		id: "RateFormStatePicker",
		formValue: "state",
		label: "State",
		values: states
	}]
	const pickerData = [
		{
			id: "RateFormMortgageTypePicker",
			formValue: "loanPurpose",
			label: "Loan Purpose",
			values: [
				// { value: "", label: "Choose a Loan Purpose" },
				{ value: "Purchase", label: user?.role === Roles.loanOfficer ? `Purchase` : `I Want to Purchase a Home` },
				{ value: "Refinance", label: user?.role === Roles.loanOfficer ? `Refi` : `I Want a Lower Rate - Refinance` },
				{ value: "CashOutRefinance", label: user?.role === Roles.loanOfficer ? `Cash Out Refi` : `I Want to Take Cash Out / Pay Off Debt - Refinance` },
			]
		},
		{
			id: "RateFormLoanTypePicker",
			formValue: "loanType",
			label: "Loan Type",
			values: [
				// { value: "", label: "Choose a Loan Type" },
				{ value: "Conventional", label: "Conventional" },
				{ value: "FHA", label: "FHA" },
				{ value: "USDA", label: "USDA" },
				{ value: "VA", label: "VA" },
			]
		},
		// {
		//     id: "RateFormCreditScorePicker",
		//     formValue: "CreditScore",
		//     label: "Credit Score",
		//     values: [
		//         { value: "", label: "Choose a Credit Score" },
		//         { value: "Excellent", label: "720 - 850 -- Excellent" },
		//         { value: "Good", label: "660 - 719 -- Good" },
		//         { value: "Average", label: "620 - 659 -- Average" },
		//         { value: "Poor", label: "620 - Lower -- Poor" },
		//     ]
		// },
		{
			id: "RateFormPropertyOccupancyPicker",
			formValue: "propertyOccupancy",
			label: "Property Occupancy",
			values: [
				// { value: "", label: "Choose a Property Occupancy" },
				{ value: "PrimaryHome", label: "Primary Home" },
				{ value: "SecondHome", label: "Second Home" },
				{ value: "InvestmentProperty", label: "Investment Property" },
			]
		},
		{
			id: "RateFormHomeTypePicker",
			formValue: "propertyType",
			label: "Home Type",
			values: [
				// { value: "", label: "Choose a Property Occupancy" },
				{ value: "SingleFamily", label: "Single Family" },
				{ value: "MultiFamily", label: "Multi Family 2-4 Units" },
				{ value: "Condo", label: "Condo" },
				{ value: "TownHome", label: "Town Home" },
				{ value: "CoOp", label: "Co-Op" },
			]
		},
		{
			id: "RateFormEscrowPicker",
			formValue: "escrow",
			label: "Escrow",
			values: [
				// { value: "", label: "Choose an Escrow" },
				{ value: "Yes", label: "Yes - Include Taxes" },
				{ value: "No", label: "NO" },
			]
		},
		{
			id: "RateFormLoanTermPicker",
			formValue: "loanTerm",
			label: "Loan Term",
			values: [
				// { value: "", label: "Choose a Loan Term" },
				{ value: "30", label: "30 Years" },
				{ value: "25", label: "25 Years" },
				{ value: "20", label: "20 Years" },
				{ value: "15", label: "15 Years" },
				{ value: "10", label: "10 Years" },
			]
		},
	]

	const fieldData = [
		{
			id: "RateFormPropertyValueInput",
			label: "Property Value",
			formValue: "propertyValue",
			inputProps: true,
			currency: true,
		},
		{
			id: "RateFormLoanAmountInput",
			label: "Loan Amount",
			formValue: "totalMortgageAmount",
			inputProps: true,
			currency: true,
		},
		{
			id: "RateFormZipCodeInput",
			label: "Zip Code",
			formValue: "zipCode",
			inputProps: true,
			maxLength: 5,
			onBlur: true,
			onBlurAction: handleZipCodeBlur
		},
		{
			id: "RateFormCityInput",
			label: "City",
			formValue: "city",
		},
		{
			id: "RateFormCountyInput",
			label: "County",
			formValue: "county",
		},
		{
			id: "RateFormLoanAmountInput",
			label: "Credit Score",
			formValue: "creditScore",
			inputProps: true,
			maxLength: 3,
		},
	]
	const updateFormData = (field, val) => setFormData(formData => ({ ...formData, [field]: val }))
	const updateZipCode = val => {
		setFormData(formData => ({ ...formData, zipCode: applyMask(val, "numeric") }))
	}
	const updateCreditScore = val => {
		setFormData(formData => ({ ...formData, creditScore: applyMask(val, "numeric") }))
	}
	const updateCurrency = (field, val) => {
		setFormData(formData => ({ ...formData, [field]: applyMask(val, "currency") }))
	}

	const onChangeText = (field, value) => {
		switch (field) {
			case "totalMortgageAmount":
				updateCurrency(field, value)
				break
			case "propertyValue":
				updateCurrency(field, value)
				break
			case "zipCode":
				updateZipCode(value)
				break
			case "creditScore":
				updateCreditScore(value)
				break
			default:
				updateFormData(field, value)
		}
	}

	return (
		<form className="flex flex-col items-center w-full">
			{pickerData.slice(0, 1).map(item => (
				<FormControl key={item.id} variant="standard" sx={{ mb: 2.5, width: "100%" }}>
					<InputLabel id={item.id}>{item.label}</InputLabel>
					<Select
						labelId={item.id}
						id={item.id}
						value={formData[item.formValue]}
						onChange={e => updateFormData(item.formValue, e.target.value)}
						label={item.label}
					>
						{item.values.map((menu, index) => <MenuItem key={index} value={menu.value}>{menu.label}</MenuItem>)}
					</Select>
				</FormControl>
			))}
			{fieldData.slice(0, 3).map((item, index) => (
				<TextField
					key={index}
					onChange={(e) => onChangeText(item.formValue, e.target.value)}
					value={formData[item.formValue]}
					label={item.label}
					placeholder={item.label}
					id={item.id}
					InputProps={item.inputProps && {
							startAdornment: item.currency && <InputAdornment position="start">$</InputAdornment>,
						}
					}
					inputProps={item.inputProps && item.maxLength && {
						maxLength: item.maxLength,
						onBlur: (event) => {
							item.onBlurAction && item.onBlurAction(event)
						}
					}}
					variant="standard"
					className="w-full"
					sx={{ marginBottom: 2.5 }}
				/>
			))}
			{statePicker.map(item => (
				<FormControl key={item.id} variant="standard" sx={{ mb: 2.5, width: "100%" }}>
					<InputLabel id={item.id}>{item.label}</InputLabel>
					<Select
						labelId={item.id}
						id={item.id}
						value={formData[item.formValue]}
						onChange={e => updateFormData(item.formValue, e.target.value)}
						label={item.label}
					>
						{item.values.map((menu, index) => <MenuItem key={index} value={menu.value}>{menu.label}</MenuItem>)}
					</Select>
				</FormControl>
			))}
			{fieldData.slice(3).map((item, index) => (
				<TextField
					key={index}
					onChange={(e) => onChangeText(item.formValue, e.target.value)}
					value={formData[item.formValue]}
					label={item.label}
					placeholder={item.label}
					id={item.id}
					InputProps={item.inputProps && item.currency && { startAdornment: <InputAdornment position="start">$</InputAdornment> }}
					inputProps={item.inputProps && item.maxLength && { maxLength: item.maxLength }}
					variant="standard"
					className="w-full"
					sx={{ marginBottom: 2.5 }}
				/>
			))}
			{pickerData.slice(1).map(item => (
				<FormControl key={item.id} variant="standard" sx={{ mb: 2.5, width: "100%" }}>
					<InputLabel id={item.id}>{item.label}</InputLabel>
					<Select
						labelId={item.id}
						id={item.id}
						value={formData[item.formValue]}
						onChange={e => updateFormData(item.formValue, e.target.value)}
						label={item.label}
					>
						{item.values.map((menu, index) => <MenuItem key={index} value={menu.value}>{menu.label}</MenuItem>)}
					</Select>
				</FormControl>
			))}
			<LoadingBtn
				disabled={ratesButtonDisabled}
				style={{ width: 220, height: 40, marginBottom: 20 }}
				text="Get Your Rate"
				variant="contained"
				onClick={getRate}
				loading={fetching}
			/>
		</form>
	)
}
