import { useState } from 'react'
import {
	DialogContent, Dialog, FormControl, Select, MenuItem, InputLabel,
	DialogActions
} from '@mui/material'
import { Button } from "../Button"
import moment from "moment"
import { isDarkMode } from '../../services/helper'

export const ModalLoanAppSelector = ({ visible, setVisible, siteConfig, loanApplications, setActiveLoanApp }) => {
	const [selected, setSelected] = useState('')
	const [selectedApp, setSelectedApp] = useState('')

	const handleSelectOnChange = (event) => {
		let app = loanApplications.filter(loan => loan.loanID === event.target.value)
		setSelected(event.target.value)
		setSelectedApp(app[0])
	}

	const handleContinue = () => {
		setActiveLoanApp(selectedApp)
		setVisible(false)
	}

	return (
		<Dialog
			open={visible}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogContent>
				<div><img className={`w-full`} src={isDarkMode() && siteConfig?.darkModeLogoUrl ? siteConfig.darkModeLogoUrl : siteConfig?.logoUrl} /></div>
				<div className='text-center mt-5 mb-4 font-bold text-lg'>Which loan application would you like to work on?</div>
				<div className='w-full'>
					<FormControl sx={{ width: "100%", mt: 1 }}>
						<InputLabel>Loan Applications</InputLabel>
						<Select
							variant="standard"
							value={selected}
							label="Please select a loan file"
							onChange={(event) => handleSelectOnChange(event)}
						>
							{loanApplications?.map((app, index) => (
								<MenuItem value={app.loanID} key={index}>
									{`${app.loanPurpose} - $${app.loanAmount} - ${app.subjectPropertyCity}, ${app.subjectPropertyState} - ` + moment(app.applicationDate).format('MMM Do YYYY')}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</DialogContent>
			<DialogActions className='mr-5 mb-2'>
				<Button
					onClick={e => handleContinue(e)}
					disabled={selected.length < 1}
					text={selected.length < 1 ? 'Choose a file' : 'Continue'}
				/>
			</DialogActions>
		</Dialog>
	)
}
