import React, { useEffect, useRef, useState } from "react"
import { useVenti } from "venti"
import { Editor } from "@tinymce/tinymce-react"
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import {
	Tabs, Tab, Card, CardContent, FormControlLabel, Button,
	FormControl, ListSubheader, InputLabel, TextField, CircularProgress,
	Switch, FormLabel, Select, MenuItem, Autocomplete, Typography
} from '@mui/material';
import Page from "../../../components/Page"
import { useAppContext } from "../../../components/AppContext"
import { getTheme } from "../../../config"
import {
	getNotificationTemplate, saveNotificationTemplate,
	sendTestNotification, getLoanOfficers, getSiteConfigurationByUrl
} from '../../../services/client';
import { a11yProps, defaultOptions, groupedOptions, getErrorMessage } from "../../../services/helper"
import sampleLoanData from "../../../services/sampleLoanData.json"
import { useAlert } from "../../../hooks"
import MailMergeSelect from '../../../components/MailMergeSelect';
import { LoadingBtn } from '../../../components/Button';
import { replaceVars } from '../../../services/utils';

const theme = getTheme()

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div className="mt-5">{children}</div>
			)}
		</div>
	)
}

export default function AdminNotificationTemplateEdit(props) {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const themeMUI = useTheme()
	const { siteConfig, user } = state
	const [tab, setTab] = useState(0)
	const [saving, setSaving] = useState(false)
	const [loanOfficer, setLoanOfficer] = useState(null)
	const [loanOfficers, setLoanOfficers] = useState([])
	const [selectedSiteConfig, setSelectedSiteConfig] = useState({})
	const [todaysDate, setTodaysDate] = useState('')
	const [hidePreview, setHidePreview] = useState(false)
	const id = ventiState.get(theme.storageKeys.editingNotificationTemplateId)

	const [template, setTemplate] = useState({
		id: "",
		name: "",
		subject: "",
		htmlBody: "",
		plainBody: "",
		textBody: "",
		pushNotificationBody: "",
		textEnabled: false,
		emailEnabled: true,
		pushNotificationEnabled: true,
		description: "",
		createdAt: new Date(),
		updatedAt: new Date()
	})

	const editorRef = useRef(null)

	useEffect(() => {
		getNotificationTemplate(id).then(data => {
			setTemplate(data)
		}).catch(e => {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		})

		getLoanOfficers().then((items) => {
			const loanOfficersData = []
			items?.forEach(item => {
				item?.siteConfigurations.forEach(site => {
					loanOfficersData.push({ ...item, label: `${item.firstName} ${item.lastName}`, url: site.url, siteConfigurationId: site.id })
				})
			})
			setLoanOfficers(loanOfficersData)
		})
	}, [id])

	const canSubmit = template?.name.length > 0 && template?.htmlBody.length > 0

	const handleLoanOfficerSelect = async (event, value) => {
		console.log(value)
		if (value)
			setSelectedSiteConfig(await getSiteConfigurationByUrl(value.siteConfigurations[0].url))
		else setSelectedSiteConfig({})
		setLoanOfficer(value)
	}

	const saveTemplate = () => {
		setSaving(true)
		if (template.isDefault) {
			delete template.id
			delete template.isDefault
			template.accountID = user.accountID
		}

		saveNotificationTemplate(template)
			.then(() => {
				alert(`Notification Template ${template.name} successfully ${template.id ? "updated" : "created"}`)
			})
			.catch(e => {
				alert(getErrorMessage(e), { severity: "error" })
				ventiState.set(theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error)
			})
			.finally(() => {
				setSaving(false)
			})
	}

	const sendTest = () => {
		sendTestNotification({
			templateName: template.name,
			toAddress: loanOfficer?.email || "",
			loanData: sampleLoanData,
			emailSubjectLine: "Test Notification",
			losUserID: loanOfficer?.losUserID || "",
			siteConfigurationId: loanOfficer?.siteConfigurations?.[0].id || siteConfig?.id,
		}).then(() => {
			alert(`Notification ${template.name} successfully sent`)
			setLoanOfficer(null)
		}).catch((e) => {
			alert(getErrorMessage(e), { severity: "error" })
			ventiState.set(theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error)
		})
	}

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	return (
		<Page isFullWidth={true} title="Edit">
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
					Notification Templates - {id === "new" ? "Create" : `${template.description}`}
				</p>
				<div className="flex flex-wrap justify-end my-5">
					<LoadingBtn
						id={`AdminCorporateEditSaveButton`}
						disabled={!canSubmit || saving || 0 !== tab}
						loading={saving}
						text={template.id ? "Update" : "Create"}
						onClick={saveTemplate}
						fullWidth={false}
					/>
				</div>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={tab}
					onChange={handleTabChange}
					aria-label="Tabs"
				>
					<Tab label="Edit" {...a11yProps(0)} />
					<Tab label="Preview" {...a11yProps(1)} />
					<Tab label="Test" {...a11yProps(2)} />
				</Tabs>
				<SwipeableViews
					axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={tab} index={0} dir={themeMUI.direction}>
						<MailMergeSelect combinedOptions />
						<Card className="mt-1% pt-1% px-5% flex w-full">
							<CardContent className="w-1/2">
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<FormControlLabel
										control={
											<Switch
												checked={!hidePreview}
												onChange={() => setHidePreview(current => !current)}
												color="primary"
											/>
										}
										label={`${!hidePreview ? 'Hide' : 'Show'} HTML Preview`}
									/>
								</FormControl>
							</CardContent>
						</Card>
						<Card className="pt-1% pb-5% px-5% flex w-full">
							<CardContent className={`w-${!hidePreview ? '1/2' : 'full'}`}>
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<FormControlLabel
										control={
											<Switch
												checked={template.emailEnabled}
												onChange={event => setTemplate(current => ({ ...current, emailEnabled: event.target.checked }))}
												color="primary"
											/>
										}
										label={ `Email Notifications are ${template.emailEnabled ? 'on' : 'off'}` }
									/>
								</FormControl>
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<FormControlLabel
										control={
											<Switch
												disabled
												checked={template.textEnabled}
												onChange={event => setTemplate(current => ({ ...current, textEnabled: event.target.checked }))}
												color="primary"
											/>
										}
										label={ `SMS Notifications are ${template.textEnabled ? 'on' : 'off'} (requires SMS integration)` }
									/>
								</FormControl>
								{/*<FormControl sx={{ width: "100%", marginBottom: 3 }}>*/}
								{/*	<FormControlLabel*/}
								{/*		control={*/}
								{/*			<Switch*/}
								{/*				checked={template.pushNotificationEnabled}*/}
								{/*				onChange={event => setTemplate(current => ({ ...current, pushNotificationEnabled: event.target.checked }))}*/}
								{/*				color="primary"*/}
								{/*			/>*/}
								{/*		}*/}
								{/*		label="Send Push Notifications"*/}
								{/*	/>*/}
								{/*</FormControl>*/}
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<TextField
										placeholder="Name *"
										value={template.name}
										label="Name"
										onChange={e => setTemplate(current => ({ ...current, name: e.target.value }))}
										variant="standard"
										className="w-full sm:px-12"
										required
										InputProps={{
											readOnly: !!template.id,
										}}
									/>
								</FormControl>
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<TextField
										placeholder="Subject *"
										value={template.subject}
										label="Subject"
										onChange={e => setTemplate(current => ({ ...current, subject: e.target.value }))}
										variant="standard"
										className="w-full sm:px-12"
										required
									/>
								</FormControl>
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<TextField
										placeholder="Description"
										value={template.description}
										label="Description"
										onChange={e => setTemplate(current => ({ ...current, description: e.target.value }))}
										variant="standard"
										className="w-full sm:px-12"
										multiline
										rows={4}
									/>
								</FormControl>
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<TextField
										placeholder="HTML Document Body"
										value={template.htmlBody}
										label="HTML Document Body"
										onChange={e => setTemplate(current => ({ ...current, htmlBody: e.target.value }))}
										variant="standard"
										className="w-full sm:px-12"
										style={{ backgroundColor: "#efefef", padding: 5 }}
										multiline
										rows={20}
										required
									/>
								</FormControl>
								{/*<FormControl sx={{ width: "100%", marginBottom: 3 }}>*/}
								{/*	<FormLabel style={{ fontSize: 11 }}>HTML Email Body</FormLabel>*/}
								{/*	<Editor*/}
								{/*		apiKey="dqujh8dgoi5a8wp74n2stg7g4c97pgf2j4f6gbpxd92ss6lx"*/}
								{/*		onInit={(evt, editor) => editorRef.current = editor}*/}
								{/*		initialValue={template.htmlBody}*/}
								{/*		init={{*/}
								{/*			height: 1000,*/}
								{/*			menubar: false,*/}
								{/*			plugins: [*/}
								{/*				'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',*/}
								{/*				'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',*/}
								{/*				'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'*/}
								{/*			],*/}
								{/*			toolbar: 'undo redo | blocks | ' +*/}
								{/*				'bold italic forecolor | alignleft aligncenter ' +*/}
								{/*				'alignright alignjustify | bullist numlist outdent indent | ' +*/}
								{/*				'removeformat | help',*/}
								{/*			content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'*/}
								{/*		}}*/}
								{/*	/>*/}
								{/*</FormControl>*/}
								<FormControl sx={{ width: "100%", marginBottom: 3 }}>
									<TextField
										placeholder="This text will display if the user is unable to accept HTML emails"
										value={template.plainBody}
										label="Plain Text Email Body"
										onChange={e => setTemplate(current => ({ ...current, plainBody: e.target.value }))}
										variant="standard"
										className="w-full sm:px-12"
										multiline
										style={{ backgroundColor: "#efefef", padding: 5 }}
										rows={12}
									/>
								</FormControl>
								{/*<FormControl sx={{ width: "100%", marginBottom: 3 }}>*/}
								{/*	<TextField*/}
								{/*		placeholder="This text will be sent a text message"*/}
								{/*		value={template.textBody}*/}
								{/*		label="Text Message Body"*/}
								{/*		onChange={e => setTemplate(current => ({ ...current, textBody: e.target.value }))}*/}
								{/*		variant="standard"*/}
								{/*		className="w-full sm:px-12"*/}
								{/*		multiline*/}
								{/*		rows={12}*/}
								{/*	/>*/}
								{/*</FormControl>*/}
								{/*<FormControl sx={{ width: "100%", marginBottom: 3 }}>*/}
								{/*	<TextField*/}
								{/*		placeholder="This text will sent as a push notification to the user's device"*/}
								{/*		value={template.pushNotificationBody}*/}
								{/*		label="Push Notification Body"*/}
								{/*		onChange={e => setTemplate(current => ({ ...current, pushNotificationBody: e.target.value }))}*/}
								{/*		variant="standard"*/}
								{/*		className="w-full sm:px-12"*/}
								{/*		multiline*/}
								{/*		rows={12}*/}
								{/*	/>*/}
								{/*</FormControl>*/}
							</CardContent>
							{!hidePreview && <CardContent className="w-1/2 border">
								<iframe className="w-full h-full min-h-200" title="Template" srcDoc={template.htmlBody} />
							</CardContent>}
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={1} dir={themeMUI.direction}>
						<Card className="pt-1% pb-5% px-5%">
							<Autocomplete
								disablePortal
								id="loan-officer-autocomplete"
								value={loanOfficer}
								options={loanOfficers.sort((a, b) => -b.firstName.localeCompare(a.firstName))}
								onChange={(event, value) => handleLoanOfficerSelect(event, value)}
								renderOption={(props, option) => {
									return (
										<li {...props} key={option.id}>
											{option.label}
										</li>
									)
								}}
								renderInput={(params) => <TextField {...params} label="Loan Officer" />}
							/>
							{!!selectedSiteConfig.id
								? <iframe className="w-full h-full min-h-200 pt-5" title="Template" srcDoc={replaceVars(template.htmlBody, { ...selectedSiteConfig, ...sampleLoanData, Date: todaysDate })} />
								: <Typography className="pt-5 text-center" variant="h5">Select a site to preview</Typography>
							}
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={2} dir={themeMUI.direction}>
						<Card className="py-1% pb-5% px-5%">
							<CardContent>
								<div className="flex flex-row-reverse justify-start mb-10 items-center">
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={!loanOfficer}
										onClick={sendTest}
									>
										Send Test Notification
									</Button>
								</div>
								<Autocomplete
									disablePortal
									id="combo-box-demo"
									value={loanOfficer}
									options={loanOfficers.sort((a, b) => -b.firstName.localeCompare(a.firstName))}
									onChange={(event, value) => handleLoanOfficerSelect(event, value)}
									renderOption={(props, option) => {
										return (
											<li {...props} key={option.id}>
												{option.label}
											</li>
										)
									}}
									renderInput={(params) => <TextField {...params} label="Send To" />}
								/>
							</CardContent>
						</Card>
					</TabPanel>
				</SwipeableViews>
			</div>
		</Page>
	)
}
